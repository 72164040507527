import React from "react";

//? CROPPER
import { Cropper } from "./styles";
import "cropperjs/dist/cropper.css";
import cropperConfig from "./configs";

// import { Container } from './styles';

function CropImage({ setCropper, newImage }) {
  return (
    <Cropper
      {...cropperConfig}
      src={newImage}
      onInitialized={(instance) => {
        setCropper(instance);
      }}
    />
  );
}

export default CropImage;
