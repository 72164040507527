import styled, { css } from "styled-components";

const titleElement = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #8e8e8e;
  transition: 200ms ease-in;
`;

const counterElement = styled.div`
  font-size: 1.8rem;
  padding-left: 0.5rem;
  font-weight: bold;
  color: #383838;
  transition: 200ms ease-in;
`;

export const Cards = {
  Container: styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      width: 100%;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 0px 0px 4px 4px;
      z-index: 1000;
      transition: 200ms ease-in;
      ${({ color }) => css`
        background-color: ${color};
      `};
    }

    &:hover::before {
      height: 100%;
      border-radius: 4px;
      transition: 200ms ease-in;
      z-index: -1;
    }
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
      transition: 200ms ease-in;
      ${titleElement} {
        transition: 200ms ease-in;
        ${({ hoverColor }) => css`
          color: ${hoverColor};
        `}
      }

      ${counterElement} {
        ${({ hoverColor }) => css`
          color: ${hoverColor};
        `}
        transition: 200ms ease-in;
      }
    }

    ${({ active, hoverColor }) =>
      active &&
      css`
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
        transition: 200ms ease-in;

        ${titleElement} {
          ${({ hoverColor }) => css`
            color: ${hoverColor} !important;
          `}
        }

        ${counterElement} {
          color: ${hoverColor} !important;
        }

        &::before {
          height: 100%;
          z-index: -1;
          border-radius: 4px;
        }
      `}
  `,
  Body: styled.div``,
  Title: titleElement,
  Icon: styled.div``,
  Counter: counterElement,
};

export const CardIcon = {
  Container: styled.div``,
};
