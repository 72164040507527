import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
// import { Container } from './styles';

function ChangeDialog({ handleCloseDialog, show, handleChangeState }) {
  const [text, setText] = useState("");
  const { t } = useTranslation(["pm/deliveries"]);
  return (
    <Modal show={show} onHide={handleCloseDialog}>
      <Modal.Header closeButton>
        <Modal.Title>{t("dialog.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">{t("dialog.warning")}</Alert>
        <Pin2GiveForm.Input
          inputProps={{
            onChange: (e) => setText(e.target.value),
            value: text || "",
          }}
          label={t("dialog.deliveryNumber")}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={handleCloseDialog}>
          {t("buttons.close")}
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => handleChangeState(text)}
        >
          {t("buttons.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeDialog;
