import "./SearchInput.css";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SearchInput = (props) => {
  const { t } = useTranslation();
  const { handleChange } = props;
  return (
    <div className="search-input-box">
      <div className="search-input-content">
        <input
          type="search"
          placeholder={t("Store.placeholder")}
          className="shadown-none"
          onChange={handleChange}
        />
        <span>
          <FaSearch />
        </span>
      </div>
    </div>
  );
};

export default SearchInput;
