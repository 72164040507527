import lodash from "lodash";

export const initialValues = {
  overlayConfirm: {
    show: false,
    newSelect: {},
  },
};

export const newSupplier = {
  idSupplier: -1,
  fkStype: -1,
  company: "",
  shortname: "",
  nif: "",
  nib: "",
  url: "",
  email: "",
  email2: "",
  mobile: "",
  phone: "",
  fkAddressPicking: 0,
  fkAddressTax: 0,
  vatpayer: 0,
  str_logo: "",
  wrapup: 0,
};

export const newSupplierProtocol = {
  fkSupplier: -1,
  dateSubmission: "",
  type_: 0,
  dpreparationTime: 1,
  dcomission: 0.0,
  wrapprice: 0,
  enabled: 0,
};

export const newSupplierAddress = {
  invoicingAddress: {
    idAddress: -1,
    atype: 2,
    entity: "",
    nif: "",
    designation: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    fkCountry: -1,
    phone: "",
    email: "",
    default1: 0,
    type: 0,
  },
  collectionAddress: {
    idAddress: -1,
    atype: 2,
    entity: "",
    nif: "",
    designation: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    fkCountry: -1,
    phone: "",
    email: "",
    default1: 0,
    type: 1,
  },
};

export const newFormObject = {
  supplier: newSupplier,
  addressess: newSupplierAddress,
  protocol: newSupplierProtocol,
};

export const createFormObject = (
  supplier = null,
  supplierProtocol = null,
  supplierAddresses = null
) => {
  let dSupplier = newSupplier;
  let dSupplierProtocol = newSupplierProtocol;
  let dSupplierAddresses = newSupplierAddress;

  //* se existir supplier, dSupplier toma o valor de supplier
  if (!lodash.isEmpty(supplier)) dSupplier = supplier;

  //* se existir supplierProtocol, dSupplierProtocol toma o valor de supplierProtocol
  //* se existir supplierAddresses, dSupplierAddresses toma o valor de supplierAddresses
  if (!lodash.isEmpty(supplierProtocol)) {
    dSupplierProtocol = supplierProtocol;

    //* se o type_ do protocolo for igual a 1 e a dcomission diferente de zero,
    //* a comission é multiplicada por 100
    if (
      supplierProtocol.type_ === 1 &&
      supplierProtocol.dcomission !== 0 &&
      supplierProtocol.dcomission <= 1
    ) {
      dSupplierProtocol.dcomission = supplierProtocol.dcomission * 100;
    }
  }
  if (!lodash.isEmpty(supplierAddresses))
    dSupplierAddresses = supplierAddresses;

  return {
    supplier: dSupplier,
    addresses: dSupplierAddresses,
    protocol: dSupplierProtocol,
  };
};

export const convertSupplierAddressesToForm = (array = []) => {
  const invoicingAddress = array[0];

  const collectionAddress = array[1];

  if (invoicingAddress === undefined || collectionAddress === undefined)
    return null;

  return {
    invoicingAddress,
    collectionAddress,
  };
};

export function formatSupplierObject(supplier = {}) {
  const values = supplier;

  if (lodash.isEmpty(values.str_logo)) {
    delete values.str_logo;
  }
  return {
    ...values,
    fkStype: Number(values?.fkStype),
  };
}

export function formatSupplierProtocolObject(
  supplierProtocol = {},
  fkSupplier = -1
) {
  const values = supplierProtocol;

  if (values.type_ === 0) {
    values.dcomission = 0;
  } else {
    values.dcomission = Number(values.dcomission) / 100;
  }
  return {
    ...values,
    dpreparationTime: Number(values.dpreparationTime),
    type_: Number(values.type_),
    wrapprice: Number(values.wrapprice),
    fkSupplier: fkSupplier,
    enabled: Number(values.enabled),
  };
}

export function formatSupplierAddressObject(address, fkSupplier, type) {
  const values = address;

  return {
    ...values,
    fkCountry: Number(values.fkCountry),
    fk_supplier: fkSupplier,
    type,
  };
}
