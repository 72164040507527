import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaBox,
  FaFile,
  FaFileInvoice,
  FaImage,
  FaTruck,
  FaWind,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { usePM } from "../../../../contexts/PMProvider/usePM";
import useSuppliers from "../../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import { CardMenu } from "../../ProductsManagement.style";

// import { Container } from './styles';

function Cards() {
  //!-------------------------------------------------!\\
  //!---------------------hooks-----------------------!\\
  //!-------------------------------------------------!\\
  //* products management
  const { setPMfkSupplier } = usePM();

  //* navigate
  const navigate = useNavigate();

  //* suppliers
  const { suppliers } = useSuppliers();

  //* translations
  const { t } = useTranslation(["pm/suppliers", "overlayConfirm"]);

  //*
  const { role } = useAuth();

  return (
    <Container>
      <Row className="mt-3 justify-content-start">
        <Col xs="12" md="4" lg="3" className="mb-5">
          <CardMenu.Container
            onClick={() => {
              setPMfkSupplier(Number(suppliers.selected?.idSupplier));
              navigate("/productsmanagement/products");
            }}
          >
            <CardMenu.Icon>
              <FaBox />
            </CardMenu.Icon>
            <h5>{t("cards.products")}</h5>
          </CardMenu.Container>
        </Col>
        <Col xs="12" md="4" lg="3" className="mb-5">
          <CardMenu.Container
            onClick={() => {
              setPMfkSupplier(Number(suppliers.selected?.idSupplier));
              navigate("/productsmanagement/references/-");
            }}
          >
            <CardMenu.Icon>
              <FaFileInvoice />
            </CardMenu.Icon>
            <h5>{t("cards.references")}</h5>
          </CardMenu.Container>
        </Col>
        <Col xs="12" md="4" lg="3" className="mb-5">
          <CardMenu.Container
            onClick={() => {
              setPMfkSupplier(Number(suppliers.selected?.idSupplier));
              navigate("/productsmanagement/seasons");
            }}
          >
            <CardMenu.Icon>
              <FaWind />
            </CardMenu.Icon>
            <h5>{t("cards.seasons")}</h5>
          </CardMenu.Container>
        </Col>
        <Col xs="12" md="4" lg="3" className="mb-5">
          <CardMenu.Container
            onClick={() => {
              setPMfkSupplier(Number(suppliers.selected?.idSupplier));
              navigate("/productsmanagement/imagesupload");
            }}
          >
            <CardMenu.Icon>
              <FaImage />
            </CardMenu.Icon>
            <h5>{t("cards.galery")}</h5>
          </CardMenu.Container>
        </Col>
        {process.env.NODE_ENV === "development" && (
          <Col xs="12" md="4" lg="3" className="mb-5">
            <CardMenu.Container
              onClick={() => {
                setPMfkSupplier(Number(suppliers.selected?.idSupplier));
                navigate("/productsmanagement/invoicemanagement");
              }}
            >
              <CardMenu.Icon>
                <FaFile />
              </CardMenu.Icon>
              <h5>{t("cards.invoices")}</h5>
            </CardMenu.Container>
          </Col>
        )}
        {role === 2 && (
          <Col xs="12" md="4" lg="3" className="mb-5">
            <CardMenu.Container
              onClick={() => {
                setPMfkSupplier(Number(suppliers.selected?.idSupplier));
                navigate("/productsmanagement/deliveries?is=2");
              }}
            >
              <CardMenu.Icon>
                <FaTruck />
              </CardMenu.Icon>
              <h5>Encomendas</h5>
            </CardMenu.Container>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Cards;
