import { Col, Row } from "react-bootstrap";
import { Tooltip, IconButton } from "@mui/material";
import { FaArrowLeft, FaBox } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import InformartionPage from "../../components/InformartionPage/InformationPage";
import { useTranslation } from "react-i18next";

const Header = ({ left, center, right, infoKey, adicionalItem }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["pm/header"]);
  return (
    <Row className="mt-4 mb-5">
      <Col
        xs="12"
        className="d-flex flex-column flex-lg-row justify-content-between"
      >
        {left ? (
          <div>
            {left.backButton && (
              <Tooltip title={t("back")} placement="bottom" className="me-3">
                <IconButton
                  edge="end"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaArrowLeft />
                </IconButton>
              </Tooltip>
            )}
            {left.menuButton && (
              <Tooltip title={t("suppliers")} placement="bottom">
                <IconButton
                  edge="end"
                  onClick={() => {
                    navigate("/productsmanagement");
                  }}
                >
                  <FaBox />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : (
          <span />
        )}
        {center ? (
          <div className="app-title ms-auto">{center.title}</div>
        ) : (
          <span></span>
        )}
        <div className="ms-auto me-0">
          {infoKey && (
            <InformartionPage infoKey={infoKey} adicionalItem={adicionalItem} />
          )}
        </div>
        <span />
      </Col>
    </Row>
  );
};

export default Header;
