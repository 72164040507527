import "./Home.css";
import { HomeCarousel } from "../../components/Carousel/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import QrCode from "../../assets/images/qr-code.png";
import { useEffect, useState } from "react";
import { CardNews } from "../../components/Cards/Cards";
import lodash from "lodash";
import { useServices } from "../../services/useServices";

/*i18n */
import { useTranslation } from "react-i18next";
import Tabs from "../../components/Tabs/Tabs";
import ButtonScrollLink from "../../components/ButtonScroll/ButtonScroll";
//add comment test tag

const Home = () => {
  const { t, i18n } = useTranslation();
  const [activities, setActivities] = useState([]);
  const { pin2giveWebEndpoints, getLanguage } = useServices();

  useEffect(() => {
    document.title = "Pin2Give";
    pin2giveWebEndpoints
      .wgetNews(getLanguage())
      .then((response) => {
        // console.log("response", response?.data);
        setActivities(response?.data);
      })
      .catch((error) => console.log("ERROR ON OBTAIN ACTIVITIES", error));
  }, [i18n.language]);

  const getTabs = () => {
    if (!lodash.isEmpty(activities)) {
      return [
        {
          href: "Recent",
          title: t("MainTitles.RecentActivities"),
          offset: -150,
        },
        { href: "P2G", title: "Pin2Give", offset: -150 },
        { href: "Download", title: "Download", offset: -150 },
      ];
    } else {
      return [
        { href: "P2G", title: "Pin2Give", offset: -150 },
        { href: "Download", title: "Download", offset: -150 },
      ];
    }
  };
  return (
    <div className="app-home">
      <HomeCarousel />
      <Container fluid className="sticky-top">
        <Row>
          <Col xs="12">
            <Container className="py-2 py-md-4 bg-white">
              <Tabs tabslist={getTabs()} />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row id="Recent" className="justify-content-center">
          {lodash.isEmpty(activities) ? (
            <></>
          ) : (
            <>
              <Col xs="12" className="text-center mt-5 mb-5">
                <span className="app-title" data-testid="home-title">
                  {t("MainTitles.RecentActivities")}
                </span>
              </Col>
              {activities.map((e, index) => (
                <Col key={index} xs="12" sm="10" lg="6">
                  <CardNews
                    item={e}
                    img={!lodash.isEmpty(e?.link)}
                    video={!lodash.isEmpty(e?.lvideo)}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
        <Row className="justify-content-center" id="P2G">
          <Col xs="12" className="text-center mt-5 mb-3">
            <span className="app-title" data-testid="home-title">
              Pin2Give
            </span>
          </Col>

          <Col xs="11" md="8" className="text-left">
            <p>{t("HomePage.Pin2Give.p1")}</p>
            <div className="text-justify">
              <p>{t("HomePage.Pin2Give.p2")}</p>
              <p>{t("HomePage.Pin2Give.p3")}</p>
              <p>{t("HomePage.Pin2Give.p4")}</p>
              <p>{t("HomePage.Pin2Give.p5")}</p>
              <p>{t("HomePage.Pin2Give.p6")}</p>
              <p>{t("HomePage.Pin2Give.p7")}</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center" id="Download">
          <Col xs="8" className="text-center mt-5 mb-3">
            <span className="app-title">Download</span>
          </Col>
          <Col xs="8" className="text-center">
            <div className="links-store">
              <a
                href="https://play.google.com/store/apps/details?id=pt.techwelf.pin2give"
                target="_blank"
                rel="noreFerrer"
              >
                <AiFillAndroid />
              </a>
              <a
                href="https://apps.apple.com/us/app/pin2give/id1575578414"
                target="_blank"
                rel="noreFerrer"
              >
                <AiFillApple />
              </a>
            </div>
            <img src={QrCode} alt="qr-code" className="mt-5" />
          </Col>
        </Row>
      </Container>
      <ButtonScrollLink />
    </div>
  );
};

export default Home;
