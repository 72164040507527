export const createObject = (fkProduct, fkComposition, reference) => {
  return {
    productCompositionReferencePK: {
      fkProduct,
      fkComposition,
      reference,
    },
    quantity: 0,
  };
};
