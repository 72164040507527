import { UploadFileRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React from "react";
import { Accordion, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useServices } from "../../../services/useServices";
import { Subtitle2 } from "./Deliveries.styles";
import { Link } from "./_accordions/_items/Items.styles";
import { ItemSubtitle } from "./_tables/MainTable.styles";

// import { Container } from './styles';

function Details({ data, selected, type }) {
  //!-------------------------------------------------------------!\\
  //!----------------------------hooks----------------------------!\\
  //!-------------------------------------------------------------!\\
  //* services
  const { ordersEndpoints } = useServices();

  //* translation
  const { t } = useTranslation(["pm/deliveries"]);

  //!-------------------------------------------------------------!\\
  //!---------------------------method----------------------------!\\
  //!-------------------------------------------------------------!\\
  //* é utilizado quando o pdf da guia de transporte
  //* é alterado (retorna o base64)
  const onChangePDF = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      // Select the very first file from list
      var fileToLoad = files[0];
      // FileReader function for read the file.
      var fileReader = new FileReader();
      var base64;
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result;
        uploadPDF.mutate(base64);
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    }
  };

  //!-------------------------------------------------------------!\\
  //!--------------------------services---------------------------!\\
  //!-------------------------------------------------------------!\\
  //* upload da guia de transporte
  const uploadPDF = useMutation((a) =>
    ordersEndpoints.updateTransportGuide(selected, a)
  );

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col xs="12" md="6">
          <Subtitle2>{t("description.products")}</Subtitle2>
          {type === 1 ? (
            <ListGroup>
              {[...(data?.lproducts || [])].map((e, index) => (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{e?.nameProduct}</div>
                    {`${t("description.quantity")}:${e?.quantity} - ${t(
                      "description.wrapper"
                    )}:${
                      e?.gift === 1 ? t("description.yes") : t("description.no")
                    }`}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <Accordion defaultActiveKey="0">
              {[...(data?.lproducts || [])].map((e, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{e?.nameProduct}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <strong>{t("description.quantity")}:</strong>{" "}
                      {e?.quantity || "N/A"}
                    </div>
                    <div>
                      <strong>{t("description.wrapper")}:</strong>{" "}
                      {e?.gift === 1
                        ? t("description.yes")
                        : t("description.no")}
                    </div>
                    <div className="mt-2">
                      <strong>{t("description.composed")}:</strong>
                      <ul>
                        {e.composedBy.map((x, index) => (
                          <li key={index}>{x}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Col>
        {type === 0 && (
          <Col xs="12" md="6">
            <Subtitle2>{t("description.dates")}</Subtitle2>
            <div>
              <strong>{t("description.date_forecast")}:</strong>{" "}
              {moment(data?.date_forecast).format("YYYY-MM-DD") || "N/A"}
            </div>
            <div>
              <strong>{t("description.date_order")}:</strong>{" "}
              {moment(data?.date_order, "YYYY-MM-DDhh:mm:ss").format(
                "YYYY-MM-DD"
              ) || "N/A"}
            </div>
          </Col>
        )}
        {type === 1 && (
          <>
            <Col xs="12" md="6">
              <Subtitle2>{t("description.deliveryAddress")}</Subtitle2>
              {`${data?.ship_street1} ${data?.ship_street2}`}
              <br />
              {`${data?.ship_city}, ${data?.ship_country}`}
            </Col>
            <Col xs="12" className="mt-4">
              <Subtitle2>{t("description.supplier")}</Subtitle2>
              <div>
                <strong>{t("description.apd")}:</strong>{" "}
                {data?.availableToPickupDate || "N/A"}
              </div>
              <div>
                <strong>{t("description.dts")}:</strong>{" "}
                {data?.dateTosend || "N/A"}
              </div>
              <div>
                <strong>{t("description.dtr")}:</strong>{" "}
                {data?.dateToreceive || "N/A"}
              </div>
            </Col>
          </>
        )}
        {type === 1 && (
          <Col xs="12" className="mt-4">
            <Subtitle2>Tracking</Subtitle2>
            <Container>
              <Row>
                <Col xs="12" className="text-end">
                  <Link href={data?.url_tracking} target="_blank">
                    <div className="d-flex align-items-center">
                      <VisibilityRounded fontSize="small" sx={{ mr: 1 }} />{" "}
                      {t("buttons.view") + " "}
                      Tracking
                    </div>
                  </Link>
                </Col>
                <Col xs="12">
                  <ItemSubtitle>
                    {t("description.transportGuide")}
                    <label htmlFor="icon-button-file">
                      <input
                        accept="application/pdf"
                        id="icon-button-file"
                        type="file"
                        className="d-none"
                        onChange={onChangePDF}
                      />
                      {
                        <Tooltip title={t("buttons.uploadPDF")}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <UploadFileRounded fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      }
                    </label>
                  </ItemSubtitle>
                </Col>
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Details;
