import React from "react";
import { Accordion, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useProductsContext from "../../../../contexts/ProductsManagement/Products/useProductsContext";
import { useServices } from "../../../../services/useServices";
import lodash from "lodash";
// import { Container } from './styles';

function Categories() {
  //!----------------------------------------------------!\\
  //!-----------------------hooks------------------------!\\
  //!----------------------------------------------------!\\
  //* services
  const { productsEndpoints, getLanguage } = useServices();

  //* products
  const { products } = useProductsContext();

  //* useTranslation
  const { t } = useTranslation(["pm/products"]);

  //!----------------------------------------------------!\\
  //!-------------------All Categories-------------------!\\
  //!----------------------------------------------------!\\
  //* endpoint
  const { data: categoriesData } = useQuery("getAllInformationCategories", () =>
    productsEndpoints.getAllInformationCategories(getLanguage())
  );

  //!----------------------------------------------------!\\
  //!----------------Categories by Product---------------!\\
  //!----------------------------------------------------!\\
  //* endpoint
  const { data: categoriesByProduct } = useQuery(
    ["getProductCategoriesByProductAndCountry", products?.selected],
    () =>
      productsEndpoints.getProductCategoriesByProductAndCountry(
        products?.selected?.idProduct,
        localStorage.getItem("country_code")
      ),
    {
      enabled:
        products?.selected !== null && products?.selected?.idProduct !== -1,
    }
  );

  //!----------------------------------------------------!\\
  //!-----------------------methods----------------------!\\
  //!----------------------------------------------------!\\
  //* get categories label
  const getCategoryLabel = (fkCategory) => {
    //? armazena o array com as categorias
    let categories = categoriesData?.data;

    //? verificar se a categoria pretendida existe no array
    const exist = categories?.find((e) => e.id === fkCategory);

    //? se não existir retorna string vazia
    if (exist === undefined) return "";

    //? se existir retorna o nome da categoria
    return exist?.name;
  };

  //* get sub categories label
  const getSubCategoryLabel = (fkCategory, fkSubcategory) => {
    //? armazena o array com as categorias
    let categories = categoriesData?.data;

    //? verificar se a categoria pretendida existe no array
    const existCategory = categories?.find((e) => e.id === fkCategory);

    //? se a categoria existir
    if (existCategory !== undefined) {
      //? obter a lista de subcategorias
      const subcategories = existCategory.list;

      //? verificar se a subsubcategoria existe
      const existSubcategory = subcategories.find(
        (e) => e.id === fkSubcategory
      );

      //? se a subcategoria existir retorna o nome da subcategoria
      if (existSubcategory !== undefined) return existSubcategory.name;
    }

    //? se a subcategoria não existir retorna N/A
    return "N/A";
  };

  //* get subsubcategory label
  const getSubsubcategoryLabel = (
    fkCategory,
    fkSubcategory,
    fkSubsubcategory
  ) => {
    //? armazena o array com as categorias
    let categories = categoriesData?.data;

    //? verificar se a categoria pretendida existe no array
    const existCategory = categories?.find((e) => e.id === fkCategory);

    //? se a categoria existir
    if (existCategory !== undefined) {
      //? obter a lista de subcategorias
      const subcategories = existCategory.list;

      //? verificar se a subcategoria existe
      const existSubcategory = subcategories.find(
        (e) => e.id === fkSubcategory
      );

      //? se a subcategorias existir
      if (existSubcategory !== undefined) {
        //? obter a lista de subsubcategorias
        const subsubcategories = existSubcategory.list;

        //? verificar se a subsubcategoria existe
        const existsubsubcategory = subsubcategories.find(
          (e) => e.id === fkSubsubcategory
        );

        //? se a subsubcategoria existir, retorna o nome da subsubcategoria
        if (existsubsubcategory !== undefined) return existsubsubcategory.name;

        //? se não existir subsubcategoria, retorna N/A
        return "N/A";
      }
    }

    //? se não existir subcategoria retorna N/A
    return "N/A";
  };

  return (
    <Accordion.Item className="pin2give-accordion-button" eventKey="0">
      <Accordion.Header>{t("Categories.title")}</Accordion.Header>
      <Accordion.Body className="pb-0 text-start">
        {lodash.isEmpty(categoriesByProduct?.data) ? (
          <div className="pb-3">{t("Categories.empty")}</div>
        ) : (
          <Table responsive className="mb-0">
            <thead className="text-center">
              <tr>
                <th>{t("Categories.fields.category")}</th>
                <th>{t("Categories.fields.subCategory")}</th>
                <th>{t("Categories.fields.subSubCategory")}</th>
              </tr>
            </thead>
            <tbody className="text-center align-middle">
              {categoriesByProduct?.data.map((e, index) => (
                <tr key={index}>
                  <td>
                    {getCategoryLabel(e?.productVsCategoriesPK?.fkCategory)}
                  </td>
                  <td>
                    {getSubCategoryLabel(
                      e?.productVsCategoriesPK?.fkCategory,
                      e?.productVsCategoriesPK?.fkSubcategory
                    )}
                  </td>
                  <td>
                    {getSubsubcategoryLabel(
                      e?.productVsCategoriesPK?.fkCategory,
                      e?.productVsCategoriesPK?.fkSubcategory,
                      e?.productVsCategoriesPK?.fkSubsubcategory
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Categories;
