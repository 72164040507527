import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
  Button,
  Col as BCol,
  Container as BContainer,
  Row as BRow,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTruck, FaTruckLoading } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { useServices } from "../../../services/useServices";
import Header from "../Header";
import { Subtitle } from "./Deliveries.styles";
import Details from "./Details";
import ChangeDialog from "./_dialogs/ChangeDialog";
import MainTable from "./_tables/MainTable";
import { getBadge } from "./_tables/_utils";

// import { Container } from './styles';

function Deliveries() {
  //!-------------------------------------------------------------!\\
  //!----------------------------hooks----------------------------!\\
  //!-------------------------------------------------------------!\\
  //* authentication data
  const { role } = useAuth();

  //* supplier data
  const { fkSupplier, setPMfkSupplier } = usePM();

  //* services
  const { ordersEndpoints, supplierEndpoints } = useServices();

  //* navigate
  const navigate = useNavigate();

  //* usealert
  const alert = useAlert();

  //* translation
  const { t } = useTranslation(["pm/deliveries"]);

  //!-------------------------------------------------------------!\\
  //!----------------------------states---------------------------!\\
  //!-------------------------------------------------------------!\\
  //* selected é onde vai ficar armazenado o order_id utilizado para ver em
  //* descrição e o state_shipping utilizado na descrição
  const [selected, setSelected] = useState({});

  //* é utilizado para alterar o estado do dialog
  const [show, setShow] = useState(false);

  //!-------------------------------------------------------------!\\
  //!---------------------------services--------------------------!\\
  //!-------------------------------------------------------------!\\
  //* details order
  //* é utilizado para obter os dados de uma order com base no id
  //* só é executado se o selected não for vazio e se o utilizador não
  //* for um supplier (tem de ser operador)
  const detailsorder = useQuery(
    ["getDetailsOrder", selected?.order_id],
    () => ordersEndpoints.detailsorder(selected?.order_id),
    {
      enabled: !isEmpty(selected?.order_id) && role !== 2,
    }
  );

  //* details order by supplier
  //* é utilizado para obter os dados de uma encomenda de um supplier
  //* específico.
  //* só é executado se o supplier for diferente de -1 e se o selected não for vazio
  const detailsordersupplier = useQuery(
    ["detailsordersupplier", selected?.order_id],
    () => ordersEndpoints.detailsordersupplier(selected?.order_id, fkSupplier),
    {
      enabled: !isEmpty(selected) && fkSupplier !== -1,
    }
  );

  //* é utilizado para alterar o estado da encomenda
  const changestateordersupp = useMutation(
    (query = null) =>
      ordersEndpoints.changestateordersupp(
        selected?.order_id,
        fkSupplier,
        selected?.state_shipping,
        query || null
      ),
    {
      onSuccess: (response, variables) => {
        if (response.data === 1 && variables !== null) {
          return alert.error(t("alerts.noMatch"));
        }

        if (response.data === 2 && variables !== null) {
          return alert.error(t("alerts.noMatch2"));
        }

        if (response.data === 3 && variables !== null) {
          alert.success(t("alerts.stateUpdate"));
          return setSelected((z) => ({
            ...z,
            state_shipping: z.state_shipping + 10,
            update: true,
          }));
        }

        setSelected((z) => ({
          ...z,
          state_shipping: z.state_shipping + 10,
          update: true,
        }));
      },
      onSettled: () => detailsordersupplier.refetch(),
    }
  );

  //* suppliesr data
  //* é utilizado para selecionar o supplier quando
  //* o utilizador tem role 4
  const suppliers = useQuery(
    "suppliers",
    () => supplierEndpoints.getAllSupplierByPerson(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!-------------------------------------------------------------!\\
  //!---------------------------methods---------------------------!\\
  //!-------------------------------------------------------------!\\
  //* é utilizado para formatar o valor do react select
  const findSupplierLabel = () => {
    const object = [...(suppliers.data?.data || [])].find(
      (e) => e?.idSupplier === fkSupplier
    );

    return { value: object?.idSupplier, label: object?.company };
  };

  //* métodos do dialog para visualizar
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //!-------------------------------------------------------------!\\
  //!--------------------------useEffects-------------------------!\\
  //!-------------------------------------------------------------!\\
  //* é disparado quando a role e o fkSupplier são alterados.
  //* se o fkSupplier for igual a -1 e a role === 2 (supplier)
  //* o utilizador volta para o início porque é necessário um supplier
  //* para obter os dados da encomenda.
  useEffect(() => {
    if (fkSupplier === -1 && role === 2) {
      navigate("/productsmanagement");
    }
  }, [fkSupplier, role]);

  return (
    <BContainer>
      <Header
        left={{ backButton: true, menuButton: true }}
        center={{ title: t("title") }}
      />
      {role === 4 && (
        <BRow>
          <BCol xs="12">
            <Tabs
              variant="pills"
              defaultActiveKey="all"
              transition={false}
              className="mb-3"
              onSelect={() => {
                setSelected({});
                setPMfkSupplier(-1);
              }}
            >
              <Tab eventKey="all" title={t("tabs.t1")}>
                <BContainer fluid className="p-0">
                  <BRow>
                    <BCol xs="12">
                      <MainTable setSelected={setSelected} type={1} />
                    </BCol>
                  </BRow>
                  <BRow className="mt-5">
                    {!isEmpty(selected?.order_id) && (
                      <>
                        <BCol xs="12">
                          <Subtitle>
                            {t("description.title")} {selected?.order_id}
                          </Subtitle>
                        </BCol>
                        <BCol xs="12">
                          <Details
                            data={detailsorder?.data?.data || {}}
                            selected={selected?.order_id}
                            type={1}
                          />
                        </BCol>
                      </>
                    )}
                  </BRow>
                </BContainer>
              </Tab>
              <Tab eventKey="suppliers" title={t("tabs.t2")}>
                <BContainer fluid className="p-0">
                  <BRow className="mb-3">
                    <BCol xs="4">
                      <ReactSelect
                        options={[...(suppliers.data?.data || [])].map((e) => ({
                          value: e?.idSupplier,
                          label: e?.company,
                        }))}
                        value={findSupplierLabel()}
                        onChange={(e) => setPMfkSupplier(e.value)}
                      />
                    </BCol>
                  </BRow>

                  {fkSupplier !== -1 && (
                    <>
                      <BRow>
                        <BCol xs="12">
                          <MainTable
                            setSelected={setSelected}
                            type={0}
                            update={selected?.update || false}
                          />
                        </BCol>
                      </BRow>
                      <BRow className="mt-5">
                        {!isEmpty(selected?.order_id) && (
                          <>
                            <BCol xs="12" className="d-flex align-items-center">
                              <Subtitle>
                                {t("description.title")} {selected?.order_id}
                              </Subtitle>
                              <div className="ms-2">
                                {getBadge(selected?.state_shipping, t)}
                              </div>
                              <div className="d-flex ms-auto">
                                {selected?.state_shipping === 10 && (
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    className="ms-3"
                                    onClick={() =>
                                      changestateordersupp.mutate()
                                    }
                                  >
                                    <FaCheck /> {" " + t("buttons.confirm")}
                                  </Button>
                                )}
                                {selected?.state_shipping === 20 && (
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    className="ms-3"
                                    onClick={() =>
                                      changestateordersupp.mutate()
                                    }
                                  >
                                    <FaTruckLoading />{" "}
                                    {" " + t("buttons.ready")}
                                  </Button>
                                )}
                                {selected?.state_shipping === 30 && (
                                  <Button
                                    variant="success"
                                    size="sm"
                                    className="ms-3"
                                    onClick={handleShow}
                                  >
                                    <FaTruck /> {" " + t("buttons.deliver")}
                                  </Button>
                                )}
                              </div>
                            </BCol>
                            <BCol xs="12">
                              <Details
                                data={detailsordersupplier?.data?.data || {}}
                                selected={selected?.order_id}
                                type={0}
                              />
                            </BCol>
                          </>
                        )}
                      </BRow>
                    </>
                  )}
                </BContainer>
              </Tab>
            </Tabs>
          </BCol>
        </BRow>
      )}

      {role === 2 && (
        <>
          <BRow>
            <BCol xs="12">
              <MainTable
                setSelected={setSelected}
                type={0}
                update={selected?.update || false}
              />
            </BCol>
          </BRow>
          <BRow className="mt-5">
            {!isEmpty(selected?.order_id) && role === 2 && (
              <>
                <BCol xs="12" className="d-flex align-items-center">
                  <Subtitle>
                    {t("description.title")} {selected?.order_id}
                  </Subtitle>
                  <div className="ms-2">
                    {getBadge(selected?.state_shipping, t)}
                  </div>
                  <div className="d-flex ms-auto">
                    {selected?.state_shipping === 10 && (
                      <Button
                        variant="secondary"
                        size="sm"
                        className="ms-3"
                        onClick={() => changestateordersupp.mutate()}
                      >
                        <FaCheck /> {" " + t("buttons.confirm")}
                      </Button>
                    )}
                    {selected?.state_shipping === 20 && (
                      <Button
                        variant="primary"
                        size="sm"
                        className="ms-3"
                        onClick={() => changestateordersupp.mutate()}
                      >
                        <FaTruckLoading /> {" " + t("buttons.ready")}
                      </Button>
                    )}
                    {selected?.state_shipping === 30 && (
                      <Button
                        variant="success"
                        size="sm"
                        className="ms-3"
                        onClick={handleShow}
                      >
                        <FaTruck /> {" " + t("buttons.deliver")}
                      </Button>
                    )}
                  </div>
                </BCol>
                <BCol xs="12">
                  <Details
                    data={detailsordersupplier?.data?.data || {}}
                    selected={selected?.order_id}
                    type={0}
                  />
                </BCol>
              </>
            )}
          </BRow>
        </>
      )}
      <ChangeDialog
        show={show}
        handleCloseDialog={handleClose}
        handleChangeState={(e) => {
          changestateordersupp.mutate(e);
          handleClose();
        }}
      />
    </BContainer>
  );
}

export default Deliveries;
