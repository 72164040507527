import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Cards } from "./_styles/cards.styles";
import MainTable from "./_tables/MainTable";
import lodash from "lodash";
import { useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { initCards } from "./_actions/_data";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FaTruck } from "react-icons/fa";

// import { Container } from './styles';

function Dashboard() {
  //!-----------------------------------------------------------!\\
  //!---------------------------hooks---------------------------!\\
  //!-----------------------------------------------------------!\\
  //* services
  const { dashboardEndpoints } = useServices();

  //* translation
  const { t } = useTranslation(["pm/dashboard"]);

  //* navigate
  const navigate = useNavigate();

  //!-----------------------------------------------------------!\\
  //!---------------------------states--------------------------!\\
  //!-----------------------------------------------------------!\\
  //* products, references, sct, protocols, unselected
  const [cards, setCards] = useState(initCards(t));
  const [cardsSelected, setCardSelected] = useState({});

  const getCards = useQuery(
    "getValuesDashboard",
    () => dashboardEndpoints.getValuesDashboard(),
    {
      onSuccess: (response) => {
        const newCards = [];
        response.data.forEach((element, index) => {
          newCards.push({ ...initCards(t)[index], counter: element });
        });

        setCards(newCards);
      },
    }
  );

  const dashboardData = useQuery(
    ["getDashboardData", cardsSelected.id],
    () => dashboardEndpoints.getDataDashboard(cardsSelected.id),
    {
      enabled: !lodash.isEmpty(cardsSelected),
    }
  );

  return (
    <Container fluid className="p-0">
      <Row>
        {cards.map((e, index) => (
          <Col xs="3" key={index}>
            <Cards.Container
              color={e.color}
              hoverColor={e.hoverColor}
              active={e.id === cardsSelected?.id}
              onClick={() =>
                setCardSelected(cardsSelected.id === e.id ? {} : e)
              }
            >
              <Cards.Body>
                <Cards.Title>{e?.name}</Cards.Title>
                <Cards.Counter>
                  {getCards.isLoading ? (
                    <Spinner animation="border" style={{ color: e.color }} />
                  ) : (
                    e?.counter
                  )}
                </Cards.Counter>
              </Cards.Body>
            </Cards.Container>
          </Col>
        ))}
        <Col xs="3" className="mt-4">
          <Cards.Container
            color="#a20013"
            hoverColor="#fff"
            onClick={() => navigate("/productsmanagement/deliveries")}
          >
            <Cards.Body>
              <Cards.Title>Encomendas</Cards.Title>
              <Cards.Counter>
                <FaTruck />
              </Cards.Counter>
            </Cards.Body>
          </Cards.Container>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="12">
          {!lodash.isEmpty(cardsSelected) && (
            <MainTable
              {...cardsSelected}
              data={dashboardData?.data?.data ?? []}
              isLoading={dashboardData.isLoading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
