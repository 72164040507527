import { Container, Tabs, Row, Col, Tab, Form as FormB } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import ReactQuill from "react-quill";
import lodash from "lodash";
import PlSkeleton from "../PlSkeleton";

const Idiomatics = ({ formik, editable, isLoading }) => {
  //!----------------------------------!\\
  //!----------useTranslation----------!\\
  //!----------------------------------!\\
  const { t } = useTranslation(["pm/producers", "countries"]);
  const keys = [
    { key: "PT", title: t("PT.country", { ns: "countries" }) },
    { key: "EN", title: t("EN.country", { ns: "countries" }) },
    { key: "ES", title: t("ES.country", { ns: "countries" }) },
    { key: "FR", title: t("FR.country", { ns: "countries" }) },
    { key: "DE", title: t("DE.country", { ns: "countries" }) },
    { key: "IT", title: t("IT.country", { ns: "countries" }) },
  ];
  const form = [];

  keys.forEach((e, index) => {
    form.push(
      <Tab
        tabClassName={`${
          !lodash.isEmpty(formik.errors[`${e.key}`]?.designation) ||
          !lodash.isEmpty(formik.errors[`${e.key}`]?.description)
            ? "is-invalid"
            : ""
        }`}
        key={index}
        eventKey={e.key}
        title={e.title}
      >
        <Container className="mt-4 p-0">
          <Row>
            {isLoading ? (
              <PlSkeleton />
            ) : (
              <>
                <Col xs="12">
                  <Pin2GiveForm.Input
                    label="Designation"
                    inputProps={{
                      name: `${e.key}.designation`,
                      readOnly: !editable,
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      value: formik?.values[`${e.key}`]?.designation ?? "",
                    }}
                    error={formik?.errors[`${e.key}`]?.designation}
                  />
                </Col>
                <Col xs="12">
                  <FormB.Group>
                    <FormB.Label>{t("fields.descriptionLG.label")}</FormB.Label>
                    <ReactQuill
                      className="rq-form-input"
                      theme="snow"
                      readOnly={!editable}
                      value={formik.values[`${e.key}`]?.description ?? ""}
                      onChange={(value) => {
                        let str = value;
                        str = str.replace(/(<([^>]+)>)/gi, "");
                        if (lodash.isEmpty(str)) {
                          formik.setFieldValue(`${e.key}.description`, "");
                        } else {
                          formik.setFieldValue(`${e.key}.description`, value);
                        }
                      }}
                    />
                    <FormB.Text className="text-danger">
                      {formik?.errors[`${e.key}`]?.description}
                    </FormB.Text>
                  </FormB.Group>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Tab>
    );
  });

  return (
    <Container className="mt-5">
      <Row>
        <Col xs="12">
          <form onSubmit={formik.handleSubmit}>
            <Tabs className="mb-3 form-tabs" fill variant="tabs">
              {form.map((e) => e)}
            </Tabs>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Idiomatics;
