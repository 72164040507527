import * as Yup from "yup";

export const initValues = {
  name: "",
  fkPtitle: 0,
  nif: "",
  cc: "",
  mobile: "",
  phone: "",
  nib: "",
  fkType: 2,
  fkLanguage: "EN",
  state: 1,
  email: "",
  password: "",
  confPassword: "",
};

export const validationSchema = (t) => {
  return Yup.object({
    name: Yup.string().required(t("fields.name.errors.required")),
    fkPtitle: Yup.number()
      .integer(t("fields.title.errors.integer"))
      .min(1, t("fields.title.errors.required"))
      .required(t("fields.title.errors.required"))
      .typeError(t("fields.title.errors.typeError")),
    nif: Yup.string().notRequired().nullable(),
    fkType: Yup.string().notRequired(),
    fkLanguage: Yup.string().required(t("fields.fkLanguage.errors.required")),
    state: Yup.number().required(t("fields.state.errors.required")),
    mobile: Yup.string()
      .numberPhone(t("fields.mobile.errors.numberPhone"))
      .nullable()
      .notRequired(),
    phone: Yup.string()
      .numberPhone(t("fields.phone.errors.numberPhone"))
      .nullable()
      .notRequired(),
    email: Yup.string()
      .email(t("fields.email.errors.email"))
      .required(t("fields.email.errors.required"))
      .nullable(),
    password: Yup.string()
      .required(t("fields.password.errors.required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[0-9]).{6,}$/,
        t("fields.password.errors.match")
      ),
    confPassword: Yup.string()
      .required(t("fields.confPassword.errors.required"))
      .oneOf(
        [Yup.ref("password"), null],
        t("fields.confPassword.errors.oneOf")
      ),
  });
};
