import { useTranslation } from "react-i18next";
import { Pin2GiveListItem } from "../../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveList from "../../../../components/Pin2GiveList/Pin2GiveList.Container";

import { useImagesReferences } from "../useImagesReferences";

const References = () => {
  //!--------------------------------------------!\\
  //!---------------useTranslation---------------!\\
  //!--------------------------------------------!\\
  const { t } = useTranslation(["pm/imagesReferences"]);

  //!--------------------------------------------!\\
  //!--------------------data--------------------!\\
  //!--------------------------------------------!\\
  const { references, isUpdated, orderList, overlayConfirm } =
    useImagesReferences();

  //!--------------------------------------------!\\
  //!-----------------handleClick----------------!\\
  //!--------------------------------------------!\\
  const handleClick = (fkReference) => {
    if (isUpdated(orderList.data, orderList?.init)) {
      return references.setSelected(fkReference);
    }

    return overlayConfirm.handleShowConfirm(fkReference);
  };

  return (
    <Pin2GiveList
      headerProps={{
        title: t("referencesList.title"),
        headerButton: {},
      }}
    >
      {references?.data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          type="text"
          title={e?.sreference}
          selected={e?.reference === references.selected}
          tooltip={e?.reference}
          onClick={() => handleClick(e?.reference)}
        />
      ))}
    </Pin2GiveList>
  );
};

export default References;
