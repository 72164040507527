import { Outlet } from "react-router-dom";
import React from "react";
import {
  Circle,
  Circles,
  LayoutUserLogin as Container,
} from "./Styles/LoginLayout";
import OnlyPublicRoute from "./routes/OnlyPublicRoute";

const LoginLayout = () => {
  return (
    <OnlyPublicRoute>
      <Container>
        <Circles>
          {[...Array(10)].map((e, index) => (
            <Circle key={index} />
          ))}
        </Circles>
        <Outlet />
      </Container>
    </OnlyPublicRoute>
  );
};

export default LoginLayout;
