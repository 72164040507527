import { Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import "./Tabs.css";

const Tabs = (props) => {
  const { tabslist } = props;
  return (
    <Nav className="app-tabs justify-content-center  justify-content-sm-start border-bottom-1 border-bottom-sm-0 ">
      {tabslist.map((e, index) => (
        <Nav.Item key={index} className="mb-3 md-md-0">
          <Link
            href={`#${e?.href}`}
            to={e?.href}
            activeClass="active"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={e?.offset}
            duration={50}
          >
            {e?.title}
          </Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};
export default Tabs;
