import styled from "styled-components";

export const Subtitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
`;

export const Subtitle2 = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #a1a1a1;
`;

export const Paragraph = styled.p`
  text-align: justify;
`;
