/* eslint-disable react-hooks/exhaustive-deps */

//components and styles
import { Container, Row, Col, Spinner } from "react-bootstrap";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { CardStore } from "../../../components/Cards/Cards";
import BackButton from "../../../components/BackButton/BackButton";

//react-router
import { useNavigate, useParams } from "react-router-dom";

//react
import { useEffect, useState } from "react";

//i18n
import { useTranslation } from "react-i18next";

//others
import lodash from "lodash";
import { useServices } from "../../../services/useServices";

const SubCategories = () => {
  //useState
  const initObject = { data: null, loading: true };
  const [search, setSearch] = useState();
  const [searchData, setSearchData] = useState(initObject);
  const [data, setData] = useState(initObject);
  const { productsWebEndpoints, getLanguage } = useServices();

  //react-router
  const navigate = useNavigate();
  const {
    name: dname,
    idCategorie,
    idSubCategorie,
    idSubSubCategorie,
  } = useParams();
  const name = decodeURIComponent(dname);

  //get language for endpoint
  const { i18n, t } = useTranslation();

  //onClickCard
  const onClickCard = (e) => {
    if (idSubCategorie === "-1") {
      navigate("/store/" + e?.name + "/" + idCategorie + "/" + e?.id + "/-1");
    } else {
      navigate(
        "/store/" +
          encodeURIComponent(e?.name) +
          "/" +
          idCategorie +
          "/" +
          idSubCategorie +
          "/" +
          e?.id
      );
    }
  };

  useEffect(() => {
    setData(initObject);
    productsWebEndpoints
      .wgetnsubcategories(
        idCategorie,
        idSubCategorie,
        idSubSubCategorie,
        localStorage.getItem("country_code"),
        getLanguage()
      )
      .then((response) => {
        //console.log(response.data);
        setData({
          data: response?.data,
          loading: false,
          status: response?.status,
        });
      })
      .catch((error) => {
        setData({ ...data, subcategories: { data: null, loading: false } });
        console.log("ERROR SUBCATEGORIES ", error.response.status);
      });
  }, [idCategorie, idSubCategorie, idSubSubCategorie, i18n.language]);

  useEffect(() => {
    if (!lodash.isEmpty(search)) {
      productsWebEndpoints
        .wfindProducts(
          search,
          getLanguage(),
          localStorage.getItem("country_code")
        )
        .then((response) => {
          //console.log("response", response?.status);
          setSearchData({
            data: response?.data,
            loading: false,
            status: response?.status,
          });
        })
        .catch(() => setData({ data: null, loading: false }));
    }
  }, [search, i18n.language]);

  return (
    <div className="app-sub-categories">
      <Container fluid className="bg-white py-4 sticky-top">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs="12"
              sm="10"
              md="8"
              lg="8"
              className="mb-3 mb-lg-0 d-flex justify-content-center justify-content-lg-start"
            >
              <BackButton />
            </Col>
            <Col xs="12" xl="4" sm="10" md="8" lg="4" className="mb-3 mb-lg-0">
              <SearchInput
                handleChange={(event) => setSearch(event.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        {!lodash.isEmpty(search) ? (
          <>
            <Row className="">
              <Col xs="12" className="text-left mt-3 mb-3">
                <span className="app-sub-title">
                  {t("Search.ResultsFor") + " " + search}:
                </span>
              </Col>
            </Row>
            <Row id="Search" className="justify-content-center px-lg-5">
              {searchData?.loading ? (
                <Col xs="12" className="text-center">
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                </Col>
              ) : lodash.isEmpty(searchData?.data) ? (
                <Col xs="12">
                  {lodash.isEqual(searchData?.status, 204)
                    ? t("Store.EmptyLanguage.data")
                    : t("Store.Empty.Search")}
                </Col>
              ) : (
                searchData?.data.map((e, index) => (
                  <Col
                    xs="9"
                    sm="6"
                    md="5"
                    lg="4"
                    xl="3"
                    key={index}
                    className="mb-5 px-4"
                  >
                    <CardStore
                      title={e?.designation}
                      image={e?.fk_default_image}
                      price={e?.price}
                      symbol={e?.symbol}
                      onClick={() =>
                        navigate("/store/products/details/" + e?.id_product)
                      }
                    />
                  </Col>
                ))
              )}
            </Row>
          </>
        ) : (
          <Row className="justify-content-center justify-content-lg-start">
            <Col xs="12" className="text-left mt-3 mb-3">
              <span
                className="app-sub-title"
                dangerouslySetInnerHTML={{ __html: name }}
              />
            </Col>
            {data?.loading ? (
              <Col xs="12" className="text-center">
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
              </Col>
            ) : lodash.isEmpty(data?.data) ? (
              <Col xs="12">
                {lodash.isEqual(data?.status, 204) ? (
                  t("Store.EmptyLanguage.data")
                ) : (
                  <>{t("Store.Empty.Subcategories") + " " + name}</>
                )}
              </Col>
            ) : (
              data?.data.map((e) => (
                <Col
                  xs="9"
                  sm="6"
                  md="5"
                  lg="4"
                  xl="3"
                  key={e?.id ? e?.id : e?.id_product}
                  className="mb-5 px-4"
                >
                  {e?.type === 1 ? (
                    <CardStore
                      title={e?.designation}
                      price={e?.price}
                      symbol={e?.symbol}
                      image={e?.fk_default_image}
                      onClick={() =>
                        navigate("/store/products/details/" + e?.id_product)
                      }
                    />
                  ) : (
                    <CardStore
                      title={e?.name}
                      image={e?.path}
                      onClick={() => onClickCard(e)}
                    />
                  )}
                </Col>
              ))
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default SubCategories;
