import React, { useContext } from "react";
import { productContext } from "./Provider";

// import { Container } from './styles';

function useProductsContext() {
  const context = useContext(productContext);
  return context;
}

export default useProductsContext;
