import axios from "axios";

export const AuthenticationEndpoints = (initPath) => {
  const PATH = `${initPath}/securitybackendendpoint`;

  const getTitles = async () => axios.get(`${PATH}/getAllTitlePersons`);

  const register = async (data) => axios.post(`${PATH}/create_or_update`, data);
  //("create_or_update")->1 success -> 0 error

  const getPublicKey = async () => await axios.get(`${PATH}/getPublicKey`);

  const resendEmail = async (email) =>
    axios.get(`${PATH}/resendemail?email=${email}`);
  //return 1 -> sucesso
  //return 0 ->

  const login = async (hash) =>
    axios.post(`${PATH}/login_backend`, "", {
      headers: { Authorization: `Basic ${hash}` },
    });

  const getProfileData = async (data) =>
    await axios.post(`${PATH}/getProfileData`, data);

  const changePassword = async (data) =>
    await axios.post(`${PATH}/changePassword`, data);

  const recoveryPassword = async (email) =>
    await axios.put(`${PATH}/recoveryPassword`, email, {
      headers: {
        "Content-Type": "text/plain",
      },
    });

  const confirmationrecoveryPassword = async (data) =>
    await axios.put(`${PATH}/confirmationrecoveryPassword`, data);

  return {
    getTitles,
    register,
    getPublicKey,
    resendEmail,
    login,
    getProfileData,
    changePassword,
    recoveryPassword,
    confirmationrecoveryPassword,
  };
};
