import React from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useProductsContext from "../../../../contexts/ProductsManagement/Products/useProductsContext";
import { useServices } from "../../../../services/useServices";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";

// import { Container } from './styles';

function Compositions() {
  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* products
  const { products, forms } = useProductsContext();

  //* services
  const { productCompositionsEndpoints } = useServices();

  //* navigate
  const navigate = useNavigate();

  //* translation
  const { t } = useTranslation(["pm/products", "pm/overlayConfirm"]);

  //!-------------------------------------------------------!\\
  //!----------------Compositions by Product----------------!\\
  //!-------------------------------------------------------!\\
  //* obter todas as composições afetas ao produto
  const { data: prodCompositions } = useQuery(
    ["getProdCompositions", products.selected],
    () =>
      productCompositionsEndpoints.getAllCompositionsBySupplier(
        products.selected?.idProduct
      ),
    {
      enabled: products.selected !== null && products.selected.idProduct !== -1,
    }
  );

  const onClickView = (e) =>
    navigate(
      `/productsmanagement/compositions/${products.selected?.idProduct}/${e}`
    );

  return (
    <Accordion.Item className="pin2give-accordion-button" eventKey="2">
      <Accordion.Header>{t("compositions.title")}</Accordion.Header>
      <Accordion.Body className="pb-0 text-end">
        {lodash.isEmpty(prodCompositions?.data) ? (
          <div className="text-start pb-3">{t("compositions.empty")}</div>
        ) : (
          <Table responsive className="mb-0">
            <thead className="text-center">
              <tr>
                <th>{t("compositions.fields.id")}</th>
                <th>{t("compositions.fields.title")}</th>
                <th>{t("compositions.fields.productcode")}</th>
                <th>{t("compositions.fields.barcode")}</th>
                <th>{t("compositions.fields.actions")}</th>
              </tr>
            </thead>
            <tbody className="text-center align-middle">
              {prodCompositions?.data.slice(0, 5).map((e, index) => (
                <tr key={index}>
                  <td>{e?.idComposition}</td>
                  <td>{e?.stitle}</td>
                  <td>{e?.sproductcode || t("compositions.fields.empty")}</td>
                  <td>{e?.barcode || t("compositions.fields.empty")}</td>
                  <td>
                    <Button
                      disabled={forms.editable}
                      variant="link"
                      size="sm"
                      onClick={() => onClickView(e.idComposition || -1)}
                    >
                      <FaEye />
                    </Button>
                  </td>
                </tr>
              ))}
              {prodCompositions?.data.length > 5 ? (
                <tr className="border-white">
                  <td colSpan="5">
                    <Button
                      variant="pin2give-link"
                      onClick={() => onClickView(-1)}
                    >
                      {t("compositions.viewMore")}
                    </Button>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Compositions;
