import lodash, { omit } from "lodash";

export const staticValues = [
  {
    label: "Perfumaria",
    id: 18,
    subCategories: [
      {
        label: "Cosméticos",
        id: 75,
        subsubCategories: [],
      },
    ],
  },
  {
    label: "Moda Adulto",
    id: 50,
    subCategories: [
      {
        label: "Homem",
        id: 123,
        subsubCategories: [
          { label: "Roupa", id: 7 },
          { label: "Acessórios", id: 13 },
          { label: "Sapatos", id: 11 },
        ],
      },
      {
        label: "Senhora",
        id: 124,
        subsubCategories: [
          { label: "Roupa", id: 14 },
          { label: "Acessórios", id: 20 },
          { label: "Sapatos", id: 18 },
        ],
      },
    ],
  },
];

export const getCategories = (data = []) => {
  let categories = [];

  //* iterar sobre as categorias
  data.forEach((e) => {
    const x = e;

    //* adicionar objeto as categorias
    categories.push(omit(x, "list"));
  });

  //* obter categorias
  return categories ?? null;
};

export const isInResult = (key, value, result = []) => {
  const a = result?.find((e) => lodash.isEqual([key, value], e));

  if (a === undefined) return false;

  return true;
};

export const getSubCategories = (data = [], fkCategory) => {
  //* obter objeto onde o id da categoria é
  //* igual ao id passado como parametro
  const category = data.find((e) => e.id === fkCategory);

  //* se não existir categoria retorna null
  if (category === undefined) return null;

  //* se existir categoria é necessário interar sobre
  //* a key "subcategories" e remover as "subsubcategories"
  //* associadas. (a key não é necessária)
  let subCategories = [];
  category?.list?.forEach((e) => {
    const x = e;

    //* adicionar objeto á variável subCategories
    subCategories.push(omit(x, "list"));
  });

  //* obter subCategorias
  return subCategories ?? null;
};

export const getSubSubCategories = (data = [], fkCategory, fkSubCategory) => {
  //* obter objeto onde o id da categoria é
  //* igual ao id passado como parametro
  const category = data.find((e) => e.id === fkCategory);

  //* se não existir categoria retorna null
  if (category === undefined) return null;

  //* obter objeto onde o id da subcategoria é
  //* igual ao id passado como parametro (fkSubCategory)
  const subCategory = category.list.find((e) => e.id === fkSubCategory);

  //* se não existir subcategoria retorna null
  if (subCategory === undefined) return null;

  //* se existir subCategoria retorna as mesmas
  return subCategory.list ?? null;
};

export const getCheck = (key, value, result = [], data = []) => {
  let category = -1;
  let subCategory = -1;
  let subSubCategory = -1;

  //* verificar se a chave valor pedida
  //* existe no resultado
  const existInResult = result.find((e) => lodash.isEqual(e, [key, value]));

  //* se não existir no resultado retorna false (não está checkado)
  if (existInResult === undefined) return false;

  data.forEach((e) => {
    //* interar sobre categorias
    category = e?.id;

    if (key === 2) {
      e?.subCategories?.forEach((i) => {
        //* iterar sobre subcategorias
        subCategory = i?.id;

        if (key === 3) {
          i?.subsubCategories?.forEach((j) => {
            //* iterar sobre subsubcategorias
            subSubCategory = j?.id;
          });
        }
      });
    }

    if (lodash.isEqual([1, e?.id], [key, value])) return true;
  });
};

/**
 * * New Category Object
 * @param {number} fkCategory
 * @param {string} sigla_country
 * @param {number} fkProduct
 */
export const newCategoryObject = (fkCategory, sigla_country, fkProduct) => ({
  type: 0,
  sigla_country: sigla_country,
  productVsCategoriesPK: {
    fkCategory: fkCategory,
    fkProduct: fkProduct,
    fkSubcategory: 0,
    fkSubsubcategory: 0,
  },
});

/**
 * * New Subcategory Object
 * @param {number} fkCategory
 * @param {number} fkSubCategory
 * @param {string} sigla_country
 * @param {number} fkProduct
 */
export const newSubCategoryObject = (
  fkCategory,
  fkSubCategory,
  sigla_country,
  fkProduct
) => ({
  type: 1,
  sigla_country: sigla_country,
  productVsCategoriesPK: {
    fkCategory: fkCategory,
    fkProduct: fkProduct,
    fkSubcategory: fkSubCategory,
    fkSubsubcategory: 0,
  },
});

/**
 * * New Subcategory Object
 * @param {number} fkCategory
 * @param {number} fkSubCategory
 * @param {number} fkSubSubCategory
 * @param {string} sigla_country
 * @param {number} fkProduct
 */
export const newSubSubCategoryObject = (
  fkCategory,
  fkSubCategory,
  fkSubSubCategory,
  sigla_country,
  fkProduct
) => ({
  type: 2,
  sigla_country: sigla_country,
  productVsCategoriesPK: {
    fkCategory: fkCategory,
    fkProduct: fkProduct,
    fkSubcategory: fkSubCategory,
    fkSubsubcategory: fkSubSubCategory,
  },
});
