import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import useProductsContext from "../../../../contexts/ProductsManagement/Products/useProductsContext";
import Products from "../Products";

function Form() {
  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/products", "countries"]);

  //* navigate
  const navigate = useNavigate();

  //* role
  const { role } = useAuth();

  //* use products context
  const { producers, images, forms } = useProductsContext();
  const formik = forms.product;

  const [info, setInfo] = useState(false);

  useEffect(() => {
    if (
      (!formik.values?.dimage || formik.values?.dimage < 0) &&
      !forms.editable
    ) {
      setInfo(true);
    } else {
      setInfo(false);
    }
  }, [formik.values, forms.editable]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12">
            <Alert
              show={info}
              onClose={() => setInfo(false)}
              variant="info"
              dismissible
            >
              {t("fields.dimage.info")}
            </Alert>
          </Col>
          {
            //!----------------------------------------------------!\\
            //!------------------------sTitle----------------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.stitle.label")}
              inputProps={{
                readOnly: !forms.editable,
                value: formik.values?.stitle ?? "",
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
                autoComplete: "off",
                name: "stitle",
              }}
              error={formik.errors?.stitle}
            />
          </Col>
          {
            //!----------------------------------------------------!\\
            //!-----------------------Producer---------------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Select
              label={t("fields.fkProducer.label")}
              error={formik.errors?.fkProducer}
              inputProps={{
                name: "fkProducer",
                disabled: !forms.editable,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values?.fkProducer,
              }}
              secondaryText={
                <Button
                  variant="pin2give-link"
                  size="sm"
                  onClick={() => navigate("/productsmanagement/producer")}
                >
                  {t("fields.fkProducer.button")}
                </Button>
              }
            >
              <option value="-1">{t("fields.fkProducer.placeholder")}</option>
              {[...(producers || [])].map((x, index) => (
                <option value={`${x?.idProducer}`} key={index}>
                  {x?.designation}
                </option>
              ))}
            </Pin2GiveForm.Select>
          </Col>
          {
            //!----------------------------------------------------!\\
            //!---------------------Observations-------------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.observations.label")}
              inputProps={{
                readOnly: !forms.editable,
                value: formik.values?.observations ?? "",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                autoComplete: "off",
                name: "observations",
                as: "textarea",
                rows: 4,
              }}
              error={formik.errors?.ovservations}
            />
          </Col>
          {
            //!----------------------------------------------------!\\
            //!---------------------SpecialCares-------------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.specialcares.label")}
              inputProps={{
                readOnly: !forms.editable,
                value: formik.values?.specialcares ?? "",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                autoComplete: "off",
                name: "specialcares",
                as: "textarea",
                rows: 4,
              }}
              error={formik.errors?.specialcares}
            />
          </Col>
          {
            //!----------------------------------------------------!\\
            //!------------------SpecificRegulation----------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.specificregulation.label")}
              inputProps={{
                readOnly: !forms.editable,
                value: formik.values?.specificregulation ?? "",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                autoComplete: "off",
                name: "specificregulation",
                as: "textarea",
                rows: 4,
              }}
              error={formik.errors?.specificregulation}
            />
          </Col>
          <Col xs="12">Condições de Elegibilidade</Col>
          {
            //!----------------------------------------------------!\\
            //!---------------------Text Area 1--------------------!\\
            //!----------------------------------------------------!\\
          }
          {role === 4 && (
            <Col xs="12" lg="6">
              <Pin2GiveForm.Input
                label={"Produto - User/Avatar"}
                inputProps={{
                  readOnly: !forms.editable,
                  value: formik.values?.str1 ?? "",
                  autoComplete: "off",
                  onChange: formik.handleChange,
                  name: "str1",
                }}
              />
            </Col>
          )}
          {
            //!----------------------------------------------------!\\
            //!---------------------Text Area 2--------------------!\\
            //!----------------------------------------------------!\\
          }
          {role === 4 && (
            <Col xs="12" lg="6">
              <Pin2GiveForm.Input
                label={"Produto - Evento"}
                inputProps={{
                  readOnly: !forms.editable,
                  value: formik.values?.str2 ?? "",
                  autoComplete: "off",
                  name: "str2",
                  onChange: formik.handleChange,
                }}
              />
            </Col>
          )}
          {
            //!----------------------------------------------------!\\
            //!---------------------DefaultImage-------------------!\\
            //!----------------------------------------------------!\\
          }
          {Products.selected?.idProduct !== -1 && (
            <Col xs="12">
              <Pin2GiveForm.ImagePicker
                label={t("fields.dimage.label")}
                editable={forms.editable}
                list={[...(images || [])].map((e, index) => ({
                  key: index,
                  id: e?.id,
                  value: e?.imageBase64,
                  selected: formik?.values?.dimage === e?.id,
                  handleSelect: () =>
                    formik.setFieldValue("dimage", Number(e?.id)),
                }))}
                emptyListLabel={t("fields.dimage.emptyListLabel")}
                isUndefinedLabel={t("fields.dimage.na")}
                errorLabel={formik?.errors?.dimage}
              />
            </Col>
          )}
        </Row>
      </form>
    </Container>
  );
}

export default Form;
