import { Button, Spinner } from "react-bootstrap";
import { Tooltip, IconButton } from "@mui/material";
import { FaHome, FaEye, FaEyeSlash } from "react-icons/fa";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useState } from "react";
import Pin2GiveForm from "../../components/Pin2GiveForm/Pin2GiveForm";

import { JSEncrypt } from "jsencrypt";

import { encryptPublicLong } from "@lsqswl/rsaencrypt";
import { useTranslation } from "react-i18next";
import DropdownLang from "../../components/DropdownLang/DropdownLang";
import {
  Brand,
  Container,
  ExternalLink,
  Form,
  Header,
  SocialContent,
} from "./login.styles";
import Logo from "../../assets/images/logo.png";
import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  //!-------------------------------------------!\\
  //!---------------TYPE PASSWORD---------------!\\
  //!-------------------------------------------!\\
  const [typePassword, setTypePassword] = useState("password");
  //*Handle Password
  const handlePassword = () => {
    if (typePassword === "password") {
      setTypePassword("text");
    } else {
      setTypePassword("password");
    }
  };

  //!--------------------------------------------!\\
  //!---------------useTranslation---------------!\\
  //!--------------------------------------------!\\
  const { t } = useTranslation(["login"]);

  //!--------------------------------------------!\\
  //!-----------------AUTH CONTEXT---------------!\\
  //!--------------------------------------------!\\
  const { publicKey, authentication } = useAuth();

  //!--------------------------------------------!\\
  //!-------------REACT ROUTER DOM---------------!\\
  //!--------------------------------------------!\\
  const navigate = useNavigate();

  //!--------------------------------------------!\\
  //!-------------Forgot Password----------------!\\
  //!--------------------------------------------!\\
  const [fPassword, setFPassword] = useState(false);
  const closeFPassword = () => setFPassword(false);

  //!--------------------------------------------!\\
  //!-------------------FORMIK-------------------!\\
  //!--------------------------------------------!\\
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("fields.email.errors.email"))
        .required(t("fields.email.errors.required")),
      password: Yup.string().required(t("fields.password.errors.required")),
    }),

    onSubmit: (values) => {
      //* obter email e password
      const { email, password } = values;

      //* instaciar a biblioteca de encriptação
      //* e definir a public key
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);

      //* encryptação da password
      const encryptPassword = encrypt.encrypt(password);

      //* construir a hash
      //* é uma string construida da seguinte forma:
      //* 0;;email;;passwordEncriptada
      const x = encryptPublicLong(`0;;${email};;${encryptPassword}`, publicKey);
      return authentication.login(x);
    },
  });

  return (
    <Container>
      <Header>
        <Tooltip title="Home" placement="right">
          <IconButton onClick={() => navigate("/")}>
            <FaHome />
          </IconButton>
        </Tooltip>

        <div>
          <DropdownLang />
        </div>
      </Header>
      <Brand.Container>
        <Brand.Logo src={Logo} alt="logo" />
        <Brand.Title>{t("title")}</Brand.Title>
      </Brand.Container>
      <Form onSubmit={formik.handleSubmit}>
        <Pin2GiveForm.Input
          label={t("fields.email.label")}
          inputProps={{
            name: "email",
            autoComplete: "off",
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            value: formik.values.email,
            placeholder: t("fields.email.label"),
          }}
          error={formik.errors.email}
        />
        <Pin2GiveForm.Input
          label={t("fields.password.label")}
          inputProps={{
            type: typePassword,
            name: "password",
            placeholder: t("fields.password.label"),
            autoComplete: "off",
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            value: formik.values.password,
            help: (
              <Button variant="mini-password-button" onClick={handlePassword}>
                {typePassword === "password" ? <FaEye /> : <FaEyeSlash />}
              </Button>
            ),
          }}
          error={formik.errors.password}
        />
        <Button
          variant="link"
          onClick={() => navigate("/user/recoverpassword")}
        >
          {t("recoverpassword")}
        </Button>
        <div className="d-flex align-items-center mt-4">
          <Button
            className="w-100"
            variant="outline-secondary"
            onClick={() => navigate("/user/register")}
          >
            {t("buttons.register")}
          </Button>
          <Button type="submit" className="ms-3 w-100" variant="pin2give">
            {authentication.isLoadingLogin ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t("buttons.login")
            )}
          </Button>
        </div>
      </Form>
      <ForgotPassword visible={fPassword} handleClose={closeFPassword} />
      <SocialContent>
        <ExternalLink
          href="https://www.facebook.com/pin2give"
          aria-label="facebook"
        >
          <FacebookRounded />
        </ExternalLink>
        <ExternalLink
          href="https://www.instagram.com/pin2give/"
          aria-label="instagram"
        >
          <Instagram />
        </ExternalLink>
        <ExternalLink
          href="https://www.linkedin.com/company/pin2give/"
          aria-label="linkedin"
        >
          <LinkedIn />
        </ExternalLink>
        <ExternalLink
          href="https://www.pinterest.pt/pin2give_/_created/"
          aria-label="pinterest"
        >
          <Pinterest />
        </ExternalLink>
        <ExternalLink href="https://twitter.com/Pin2Give" aria-label="twitter">
          <Twitter />
        </ExternalLink>
      </SocialContent>
    </Container>
  );
};

export default Login;
