import lodash from "lodash";

/**
 * !-------------------------------------------!
 * !---------------Format Values---------------!
 * !-------------------------------------------!
 * @param {array} seasonCrosstable
 * @returns {array} lista de listas pro código de produto
 * @description este método formata o objeto enviado no post
 */
export const formatValues = (seasonCrosstable = []) => {
  //* x = seasonCrossTable
  let x = [...seasonCrosstable];

  //* ids dos produtos
  let ids = [];

  //* array retornado
  let newArray = [];

  //* iterar sobre a seasonCrossTable
  x.forEach((e) => {
    //* procurar se existe o id do produto no array de ids
    const z = ids.find((y) => y === e?.seasonCrosstablePK?.fkProduct);

    //* se não existir o id do produto será adicionado a lista de ids
    if (z === undefined) ids.push(e?.seasonCrosstablePK?.fkProduct);
  });

  //* interar sobre a lista de ids
  ids.forEach((e) => {
    //* obter a lista de objetos com o fkProduct igual ao elemento da lista
    const z = x.filter((y) => y.seasonCrosstablePK?.fkProduct === e);

    //* so é adicionado ao novo array se z existir
    if (!lodash.isEmpty(z)) newArray.push(z);
  });

  return newArray;
};
