import * as Yup from "yup";

export const validationSchema = (t) => {
  return Yup.object().shape({
    stitle: Yup.string()
      .max(200, t("fields.stitle.errors.max"))
      .required(t("fields.stitle.errors.required"))
      .typeError(t("fields.stitle.errors.typeError")),
    barcode: Yup.string()
      .max(25, t("fields.barcode.errors.max"))
      .typeError(t("fields.barcode.errors.typeError")),
    sproductcode: Yup.string()
      .max(50, t("fields.sproductcode.errors.max"))
      .typeError(t("fields.sproductcode.errors.typeError")),
    fkShippingScale: Yup.number()
      .integer(t("fields.shippingscale.errors.integer"))
      .moreThan(0, t("fields.shippingscale.errors.required"))
      .required(t("fields.shippingscale.errors.required"))
      .typeError(t("fields.shippingscale.errors.typeError")),
    height: Yup.number()
      .required(t("fields.height.errors.required"))
      .integer(t("fields.height.errors.integer"))
      .typeError(t("fields.height.errors.typeError")),
    width: Yup.number()
      .required(t("fields.width.errors.required"))
      .integer(t("fields.width.errors.integer"))
      .typeError(t("fields.width.errors.typeError")),
    length: Yup.number()
      .required(t("fields.lenght.errors.required"))
      .integer(t("fields.lenght.errors.integer"))
      .typeError(t("fields.lenght.errors.typeError")),
    weight: Yup.number()
      .required(t("fields.weight.errors.required"))
      .integer(t("fields.weight.errors.integer"))
      .typeError(t("fields.weight.errors.typeError")),
    dimage: Yup.number()
      .integer(t("fields.dimage.errors.required"))
      .when("idComposition", {
        is: (idComposition) => idComposition !== -1,
        then: Yup.number()
          .integer(t("fields.dimage.errors.required"))
          .required(t("fields.dimage.errors.required"))
          .min(1, t("fields.dimage.errors.required"))
          .typeError(t("fields.dimage.errors.required")),
      })
      .typeError(t("fields.dimage.errors.required")),
    preparationtime: Yup.number()
      .integer(t("fields.preparationtime.errors.integer"))
      .min(0, t("fields.preparationtime.errors.min")),
  });
};
