import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import useSuppliers from "../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import Header from "../Header";
import Loading from "../Loading";
import OverlayConfirm from "../OverlayConfirm";
import Cards from "./_cards/Cards";
import Empty from "./Empty";
import FormAddress from "./_forms/FormAddress";
import FormProtocol from "./_forms/FormProtocol";
import FormSupplier from "./_forms/FormSupplier";
import List from "./List";
import Title from "./Title";
import lodash, { isEmpty, isEqual, omit } from "lodash";
import { validationSchema } from "../../../_yup/ProductsManagement/ProductsManagement";
import Dashboard from "./Dashboard";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

// import { Container } from './styles';

function Suppliers() {
  //!-------------------------------------------------!\\
  //!---------------------hooks-----------------------!\\
  //!-------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/suppliers", "overlayConfirm"]);

  //* suppliers
  const { suppliers, forms, protocol, overlayConfirm, initValues, ref } =
    useSuppliers();

  //* use authentication
  const { role } = useAuth();

  useEffect(() => {
    if (!isEmpty(suppliers.selected)) {
      ref.current.scrollIntoView();
    }
  }, [suppliers.selected]);

  return (
    <Container>
      {role === 4 && (
        <>
          <Row>
            <Col xs="12">
              <div className="app-title my-5">Dashboard</div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs="12" className="">
              <Dashboard />
            </Col>
          </Row>
        </>
      )}
      <div ref={ref} />
      <Header center={{ title: t("title") }} />
      {suppliers.isLoading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initValues}
          onSubmit={(values) => {
            const initProtocol = protocol.data ?? {};

            if (
              isEqual(
                omit(initProtocol, ["dateSubmission"]),
                omit(values.protocol, ["dateSubmission"])
              )
            ) {
              forms.handleSubmit.mutate({
                ...values,
                supplier: { ...values.supplier, state: 0 },
              });
            } else {
              forms.handleSubmit.mutate({
                ...values,
                supplier: { ...values.supplier, state: 0 },
                protocol: {
                  ...values.protocol,
                  enabled: 3,
                  dateSubmission: "",
                },
              });
            }
          }}
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={validationSchema(t)}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            handleBlur,
            setValues,
            validateField,
          }) => {
            return (
              <Row>
                <Col xs="12" lg="3">
                  <List
                    isUpdated={() => {
                      return lodash.isEqual(initValues, {
                        ...values,
                        protocol: {
                          ...values.protocol,
                          dcomission: Number(values.protocol.dcomission),
                        },
                      });
                    }}
                  />
                </Col>
                <Col xs="12" lg="9">
                  {!suppliers.selected ? (
                    <Empty />
                  ) : (
                    <Container fluid>
                      <Row className="mb-5">
                        <Col xs="12">
                          <Title
                            title={suppliers.selected?.company || t("list.new")}
                            editable={forms.editable}
                            onClick={() => forms.setEditable(true)}
                            onSuspend={() =>
                              forms.handleSubmit.mutate({
                                ...values,
                                supplier: { ...values.supplier, state: 4 },
                              })
                            }
                            onActive={() =>
                              forms.handleSubmit.mutate({
                                ...values,
                                supplier: { ...values.supplier, state: 3 },
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        {protocol.isLoading ? (
                          <>
                            <div className="h5 fw-bold text-center">
                              {t("loadings.protocol")}
                            </div>
                            <div className="text-center">
                              <Spinner animation="grow" variant="pin2give" />
                              <Spinner animation="grow" variant="pin2give" />
                              <Spinner animation="grow" variant="pin2give" />
                              <Spinner animation="grow" variant="pin2give" />
                            </div>
                          </>
                        ) : (
                          <>
                            {!forms.editable && (
                              <Col xs="12">
                                <Cards />
                              </Col>
                            )}
                            <Col xs="12">
                              <Form>
                                <Col xs="12">
                                  <FormSupplier
                                    values={values?.supplier}
                                    errors={errors?.supplier ?? {}}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    validateField={validateField}
                                  />
                                </Col>
                                <Col xs="12">
                                  <FormAddress
                                    values={values?.addresses}
                                    errors={errors?.addresses ?? {}}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                  />
                                </Col>
                                <Col xs="12">
                                  <FormProtocol
                                    values={values?.protocol}
                                    errors={errors?.protocol ?? {}}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    setValues={(n) =>
                                      setValues({ ...values, protocol: n })
                                    }
                                  />
                                </Col>
                                {forms.editable && (
                                  <Col xs="12" className="text-end mt-5 d-flex">
                                    <Button
                                      className="ms-auto me-2"
                                      variant="secondary"
                                      onClick={() => {
                                        if (
                                          suppliers?.selected?.idSupplier !== -1
                                        ) {
                                          setValues({
                                            ...values,
                                            supplier: suppliers.selected,
                                            protocol: protocol?.data,
                                          });
                                        } else {
                                          suppliers.setSelected(
                                            suppliers?.data[0]
                                          );
                                        }

                                        forms.setEditable(false);
                                      }}
                                      type="button"
                                    >
                                      {t("buttons.cancel")}
                                    </Button>
                                    <Button
                                      variant="success"
                                      className="d-flex align-items-center"
                                      type="submit"
                                    >
                                      {forms.isLoading ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <>
                                          <FaSave className="me-2" />{" "}
                                          {t("buttons.save")}
                                        </>
                                      )}
                                    </Button>
                                  </Col>
                                )}
                              </Form>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Container>
                  )}
                </Col>
              </Row>
            );
          }}
        </Formik>
      )}
      <OverlayConfirm
        show={overlayConfirm.show}
        handleCloseConfirm={overlayConfirm.handleCloseConfirm}
        title={t("suppliers.title", { ns: "pm/overlayConfirm" })}
        content={t("suppliers.content", { ns: "pm/overlayConfirm" })}
        onSelect={() => {
          suppliers.setSelected(overlayConfirm.newSelect);
          overlayConfirm.handleCloseConfirm();
          forms.setEditable(false);
        }}
      />
    </Container>
  );
}

export default Suppliers;
