import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import lodash, { isEmpty } from "lodash";
import Form from "./_forms/Form";

//? FORMIK
import { useFormik } from "formik";

import Header from "../Header";

//? COMPONENTS
import OverlayConfirm from "../OverlayConfirm";
import Loading from "../Loading";
import Idiomatics from "./_forms/Idiomatics";

//? I18NEXT
import { useTranslation } from "react-i18next";

//? REACT-ALERT
import { useAlert } from "react-alert";

//? REACT-QUERY
import { useMutation, useQuery } from "react-query";

//? ACTIONS
import {
  newProducer,
  formatProducer,
  convertProducerLanguagesToForm,
  convertFormToProducerLanguages,
} from "./_actions";
import {
  validationSchema,
  validationSchemaLanguages,
} from "../../../_yup/ProductsManagement/Producers";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";
import ProducerList from "./_lists/ProducerList";
import EmptyLabel from "./_components/EmptyLabel";
import FormTitle from "./_components/FormTitle";
import BottomButtons from "./_components/BottomButtons";

//?------------- PAGE-------------------?\\
const Producer = () => {
  //!--------------------------------------------------------!\\
  //!-------------------------hooks--------------------------!\\
  //!--------------------------------------------------------!\\
  //* supplier data
  const { fkSupplier } = usePM();

  //*services
  const { producersEndpoints, producerLanguagesEndpoints } = useServices();

  //* translation
  const { t } = useTranslation(["pm/producers", "pm/overlayConfirm"]);

  //* alert
  const alert = useAlert();

  //!--------------------------------------------------------!\\
  //!-------------------------States-------------------------!\\
  //!--------------------------------------------------------!\\
  //* editable
  //* é utilizado no formulário para alterar
  //*a possibilidade de escrever
  const [editable, setEditable] = useState(false);

  //* é utilizado quando o utilizador tenta alterar o
  //* producer selecionado quando foram alterados dados.
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: {},
  });
  const handleCloseConfirm = () => setShowConfirm(false);

  //* selected
  //* onde fica armazenado o objeto selecionado
  const [selected, setSelected] = useState(null);

  //* é onde fica armazenado as linguagens do produtor selecionado
  //* as linguagens armazenadas são recebidas em array e são convertidas para objeto
  //* para ser utilizado no formulário
  const [producerLang, setProducerLang] = useState(null);

  //!--------------------------------------------------------!\\
  //!-----------------------Producers------------------------!\\
  //!--------------------------------------------------------!\\
  //* endpoint para ir buscar os producers;
  //* o método é executado quando é alterado o id do supplier.
  //* o id do supplier tem de ser diferente de -1 senão o método não é executado
  const {
    data,
    isLoading,
    refetch: refetchProducers,
  } = useQuery(
    ["getProducers", fkSupplier],
    () => producersEndpoints.getAllProducersBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!--------------------------------------------------------!\\
  //!-------------------ProducerLanguages--------------------!\\
  //!--------------------------------------------------------!\\
  //* endpoint para ir buscar as idiomaticas
  //* o método é executado quando é alterado o producer selecionado
  //* e quando o producer selecionado não é nulo
  const { isLoading: isGplLoading, refetch: refetchProducerLang } = useQuery(
    ["getProducerLanguages", selected],
    () =>
      producerLanguagesEndpoints.getAllLanguagesByProducer(
        selected?.idProducer
      ),
    {
      enabled: selected !== null,
      onSuccess: (response) => {
        //* converter as idiomaticas em objeto e armazenar na variável producerLang
        return setProducerLang(convertProducerLanguagesToForm(response.data));
      },
    }
  );

  //!--------------------------------------------------------!\\
  //!------------------------Creates-------------------------!\\
  //!--------------------------------------------------------!\\
  //* create producer language
  //* é utilizado para guardar/editar as idiomáticas
  //* as idiomáticas são guardadas individualmente
  const { mutate, isLoading: isPlLoading } = useMutation((prodLang) => {
    return producerLanguagesEndpoints.create_or_update(prodLang);
  });

  //* create producer
  //* é utilizado para guardar os dados do producer
  //* se o producer for guardado/editado com sucesso,
  //* de seguida serão guardadas as idiomaticas introduzidas
  const mutation = useMutation(
    (np) => {
      return producersEndpoints.create_or_update(np);
    },
    {
      onSuccess: (data) => {
        //* verificar se a resposta não veio vazia
        if (!lodash.isEmpty(data?.data)) {
          //* se a resposta contem dados
          //* será feita a submissão das idiomáticas
          formikLanguages.submitForm().then((form) => {
            //* mostra mensagem a dizer que o producer foi introduzido com
            //* successo
            alert.success(t("alerts.success.submit.message"));

            //* pl vai guardar as idiomáticas convertidas do objeto utilizado no formulário
            //* para array
            const pl = convertFormToProducerLanguages(
              form,
              data.data.idProducer
            );

            //* utilizar o forEach para submeter as idiomaticas uma a uma
            //* para as idiomaticas serem inseridas
            //* é necessário haver descrição e designação para cada uma delas
            [...pl].forEach((e) => {
              if (
                !lodash.isEmpty(e?.description) &&
                !lodash.isEmpty(e?.designation)
              ) {
                //* criar idiomática
                mutate(e, {
                  //* este método (onSuccess) só é executado no último item do forEach
                  //* (efeitos da biblioteca)
                  onSuccess: () => {
                    //* quando a ultima idiomática é introduzida
                    //* o objeto introduzido ficará automaticamente selecionado por defeito
                    //* e o formulário ficará bloqueado
                    setSelected(data.data);
                    setEditable(false);

                    //* esta mensagem aparece quando a última idiomática é introduzida com successo
                    //* mostra o alerta a dizer que todas as idiomáticas foram introduzidas com sucesso
                    alert.success(t("alerts.success.submitLanguage.message"));
                  },
                  //* este método é sempre executado caso dê erro, caso dê successo
                  //* (é sempre executado em segundo lugar, ou seja, depois do método onSuccess ou onError)
                  onSettled: () => {
                    refetchProducers();
                  },
                });
              }
            });
          });
        }
      },
      onSettled: () => {
        refetchProducers().then(() => refetchProducerLang());
      },
    }
  );

  //!--------------------------------------------------------!\\
  //!--------------------------forms-------------------------!\\
  //!--------------------------------------------------------!\\
  //* form producer
  const formikProducer = useFormik({
    initialValues: selected ?? newProducer,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => mutation.mutate(formatProducer(values, fkSupplier)),
  });

  //* formLanguages
  const formikLanguages = useFormik({
    initialValues: producerLang ?? convertProducerLanguagesToForm([]),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: validationSchemaLanguages(t),
    enableReinitialize: true,
    onSubmit: (values) => values,
  });

  //!--------------------------------------------------------!\\
  //!-----------------------useEffects-----------------------!\\
  //!--------------------------------------------------------!\\
  //* este método é utilizado após os dados dos producers serem carregados.
  //* se houver dados, o produtor pré-selecionado será o primeiro objeto do array
  useEffect(() => {
    if (data?.data) {
      //* o objeto retorna toda a lista de producers e é necessário
      //* obter só os producers para o fkSupplier = fkSupplier do objeto
      const x = data?.data?.filter((e) => e?.fk_supplier === fkSupplier) ?? [];

      //* se o array for vazio retorna null
      if (isEmpty(x)) {
        return setSelected(null);
      }

      if (lodash.isEmpty(x) || !x) {
        return setSelected(null);
      } else {
        return setSelected(x[0]);
      }
    }
  }, [data?.data, fkSupplier]);

  return (
    <div className="app-products-management-producer">
      <Container>
        {
          //!--------------------------------------------------------!\\
          //!-------------------------Header-------------------------!\\
          //!--------------------------------------------------------!\\
        }
        <Header
          left={{ backButton: true, menuButton: true }}
          center={{ title: t("title") }}
        />
        {isLoading ? (
          <Loading />
        ) : (
          <Row className={`h-100 ${!selected ? "align-items-center" : ""}`}>
            {
              //!--------------------------------------------------------!\\
              //!---------------------Pin2Give List----------------------!\\
              //!--------------------------------------------------------!\\
            }
            <Col xs="12" lg="3" className="mb-5">
              <ProducerList
                setSelected={setSelected}
                setEditable={setEditable}
                data={data?.data || []}
                selected={selected}
                producerLang={producerLang}
                formikProducer={formikProducer}
                formikLanguages={formikLanguages}
                setShowConfirm
              />
            </Col>
            <Col xs="12" lg="9" className="position-relative">
              {!selected ? (
                <>
                  {
                    //!--------------------------------------------------------!\\
                    //!----------------------Empty Label-----------------------!\\
                    //!--------------------------------------------------------!\\
                  }
                  <EmptyLabel />
                </>
              ) : (
                <Container fluid>
                  {
                    //!--------------------------------------------------------!\\
                    //!----------------------Form Title------------------------!\\
                    //!--------------------------------------------------------!\\
                  }
                  <Row className="mb-5">
                    <Col xs="12">
                      <FormTitle
                        selected={selected}
                        editable={editable}
                        setEditable={setEditable}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      {
                        //!--------------------------------------------------------!\\
                        //!--------------------Producer Form-----------------------!\\
                        //!--------------------------------------------------------!\\
                      }
                      <Form
                        fields={{
                          selected,
                        }}
                        formik={formikProducer}
                        editable={editable}
                        setEditable={setEditable}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      {
                        //!--------------------------------------------------------!\\
                        //!-------------------Form Idiomatics----------------------!\\
                        //!--------------------------------------------------------!\\
                      }
                      <Idiomatics
                        formik={formikLanguages}
                        editable={editable}
                        isLoading={isGplLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Container>
                        <Row>
                          {
                            //!--------------------------------------------------------!\\
                            //!-------------------Bottom Buttons-----------------------!\\
                            //!--------------------------------------------------------!\\
                          }
                          <Col xs="12" className="text-end mt-5">
                            <BottomButtons
                              editable={editable}
                              formikProducer={formikProducer}
                              formikLanguages={formikLanguages}
                              mutation={mutation}
                              isPlLoading={isPlLoading}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        )}
      </Container>
      <OverlayConfirm
        show={showConfirm.show}
        title={t("producers.title", { ns: "pm/overlayConfirm" })}
        content={t("producers.content", { ns: "pm/overlayConfirm" })}
        onSelect={() => {
          setSelected(showConfirm.newSelect);
          handleCloseConfirm();
          setEditable(false);
        }}
        handleCloseConfirm={handleCloseConfirm}
      />
    </div>
  );
};

export default Producer;
