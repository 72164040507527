import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Header";
import InvoiceTable from "./InvoiceTable";
import Saft from "./Saft";
// import { Container } from './styles';

function InvoiceManagement() {
  return (
    <Container className="py-5">
      <Header center={{ title: "Invoice Management" }} />
      <Row>
        <Col xs="12">
          <Saft />
        </Col>
        <Col xs="12">
          <InvoiceTable />
        </Col>
      </Row>
    </Container>
  );
}

export default InvoiceManagement;
