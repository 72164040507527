import lodash from "lodash";

export const newComposition = {
  fkProduct: -1,
  idComposition: -1,
  fkShippingScale: -1,
  barcode: "",
  sproductcode: "",
  stitle: "",
  preparationtime: 0,
  tconsignment: 0,
  state: 0,
  length: 0,
  height: 0,
  width: 0,
  weight: 0,
  stock: 0,
  dimage: 0,
};

export const formatValues = (values) => {
  return {
    fkProduct: Number(values?.fkProduct) ?? 0,
    idComposition: Number(values?.idComposition) ?? 0,
    fkShippingScale: Number(values?.fkShippingScale) ?? 0,
    barcode: values?.barcode ?? "-",
    sproductcode: values?.sproductcode ?? "-",
    stitle: values?.stitle,
    preparationtime: Number(values?.preparationtime) ?? 0,
    tconsignment: Number(values?.tconsignment) ?? 0,
    state: Number(values?.state) ?? 0,
    cstate: Number(values?.cstate) ?? 0,
    cdate: values?.cdate,
    length: Number(values?.length) ?? 0,
    height: Number(values?.height) ?? 0,
    width: Number(values?.width) ?? 0,
    weight: Number(values.weight) ?? 0,
    stock: Number(values?.stock) ?? 0,
    dimage: Number(values?.dimage) ?? 0,
  };
};

export const isInList = (list, obj) => {
  const x = list.find(({ productCompositionReferencePK }) =>
    lodash.isEqual(productCompositionReferencePK, obj)
  );

  if (x !== undefined) return true;
  return false;
};

export function getSteps(t, fkProduct) {
  return [
    { label: t("steps.products"), path: "/productsmanagement/products" },
    {
      label: t("steps.categories"),
      path: `/productsmanagement/productcategories/${fkProduct}`,
    },
    {
      label: t("steps.compositions"),
      path: null,
    },
    {
      label: t("steps.compositionReferences"),
      path: `/productsmanagement/compositionsreferences/${fkProduct}`,
    },
  ];
}
