import "./Carousel.css";
import sliderImg1 from "../../assets/images/carousel/slider1.jpg";
import sliderImg2 from "../../assets/images/carousel/slider2.png";
import sliderImg3 from "../../assets/images/carousel/slider3.png";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { CarouselHome, CarouselProducts } from "./Carousel.styles";

const HomeCarousel = () => {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <CarouselHome.Container
      activeIndex={index}
      onSelect={handleSelect}
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon"
          data-testid="home-carousel-next-icon"
        />
      }
    >
      <Carousel.Item>
        <CarouselHome.Item.Image src={sliderImg1} alt="" />
        <CarouselHome.Item.Caption>
          <h1>{t("Carousel.Slider1.Title")}</h1>
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: t("Carousel.Slider1.Content"),
            }}
          />
        </CarouselHome.Item.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselHome.Item.Image src={sliderImg2} alt="" />
        <CarouselHome.Item.Caption>
          <h1>{t("Carousel.Slider2.Title")}</h1>
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: t("Carousel.Slider2.Content"),
            }}
          />
        </CarouselHome.Item.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselHome.Item.Image src={sliderImg3} alt="" />

        <CarouselHome.Item.Caption>
          <h1 className="mb-5">{t("Carousel.Slider3.Title")}</h1>
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: t("Carousel.Slider3.Content"),
            }}
          />
        </CarouselHome.Item.Caption>
      </Carousel.Item>
    </CarouselHome.Container>
  );
};

const ProductCarousel = (props) => {
  const [index, setIndex] = useState(0);
  const { images } = props;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <CarouselProducts.Container
      activeIndex={index}
      onSelect={handleSelect}
      nextIcon={
        <CarouselProducts.Icon>
          <FiArrowRightCircle />
        </CarouselProducts.Icon>
      }
      prevIcon={
        <CarouselProducts.Icon>
          <FiArrowLeftCircle />
        </CarouselProducts.Icon>
      }
    >
      {images.map((e, index) => (
        <Carousel.Item key={index}>
          <CarouselProducts.Item.BackGround />
          <CarouselProducts.Item.Caption>
            <img src={e} alt={index} />
          </CarouselProducts.Item.Caption>
        </Carousel.Item>
      ))}
    </CarouselProducts.Container>
  );
};

export { HomeCarousel, ProductCarousel };
