//? REACT-ICONS
import { FaCamera } from "react-icons/fa";

//? IMAGES
import NotFoundImg from "../../assets/images/notfound.png";

//!----------------------------------------------------------------------!\\
//!--------------------------PIN2GIVEIMAGEINPUT--------------------------!\\
//!----------------------------------------------------------------------!\\
const ImageUpload = ({ label, inputProps, error }) => {
  //* UPLOAD IMAGES
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        inputProps.onChange(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <label htmlFor="upload-logo">
      <div className="supplier-form-logo">
        <img
          src={inputProps.value !== "" ? inputProps.value : NotFoundImg}
          alt=""
        />
        <div
          className={`upload-logo-button-info ${
            inputProps.disabled ? "d-none" : ""
          }`}
        >
          <FaCamera />
        </div>
      </div>
      <input
        onChange={onSelectFile}
        id="upload-logo"
        className="d-none"
        type="file"
        accept="image/jpg, image/jpeg"
        disabled={inputProps.disabled}
      />
    </label>
  );
};

export default ImageUpload;
