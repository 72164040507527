/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductsCategories from "../../../pages/ProductsManagement/ProductsCategories/ProductsCategories";
import { getCategories, getSubCategories, getSubSubCategories } from "./utils";
import lodash from "lodash";
import { useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

export const productContactsContext = createContext();

const PCProvider = () => {
  //!-----------------------------------------!\\
  //!-----------------Params------------------!\\
  //!-----------------------------------------!\\
  const { fkProduct } = useParams();

  //!-----------------------------------------!\\
  //!----------------Navigate-----------------!\\
  //!-----------------------------------------!\\
  const navigate = useNavigate();

  //!-----------------------------------------!\\
  //!----------------Services-----------------!\\
  //!-----------------------------------------!\\
  const { productsEndpoints, getLanguage } = useServices();

  //!-----------------------------------------!\\
  //!------------------Alert------------------!\\
  //!-----------------------------------------!\\
  const alert = useAlert();

  //!-----------------------------------------!\\
  //!--------------translation----------------!\\
  //!-----------------------------------------!\\
  const { t } = useTranslation(["pm/productsCategories"]);

  //!-----------------------------------------!\\
  //!------------------RESULT-----------------!\\
  //!-----------------------------------------!\\
  //* será a variável enviada no post
  const [result, setResult] = useState();

  //* será a variável que não é atualizada perante
  //* as atualizações efetuadas
  const { data: initResult, refetch: refetchResult } = useQuery(
    ["getProductCategoriesByProductAndCountry", fkProduct],
    () =>
      productsEndpoints.getProductCategoriesByProductAndCountry(
        fkProduct,
        localStorage.getItem("country_code")
      ),
    {
      onSuccess: (res) => {
        setResult(res.data);
      },
      onError: () => {
        alert.error(<>{t("alerts.error.getResultList")}</>);
      },
      refetchOnWindowFocus: false,
    }
  );

  //!-----------------------------------------!\\
  //!----------------Categorias---------------!\\
  //!-----------------------------------------!\\
  //* useState
  const [cSelected, setCSelected] = useState(-1);
  const { data: categoriesData } = useQuery(
    "getAllInformationCategories",
    () => productsEndpoints.getAllInformationCategories(getLanguage()),
    {
      onError: () => alert.error(<>{t("alerts.error.getCategoriesList")}</>),
      refetchOnWindowFocus: false,
    }
  );

  //!-----------------------------------------!\\
  //!---------------SubCategorias-------------!\\
  //!-----------------------------------------!\\
  const [sCSelected, setSCSelected] = useState(-1);

  //!-----------------------------------------!\\
  //!--------------SubSubCategorias-----------!\\
  //!-----------------------------------------!\\
  //* useState
  const [sSCSelected, setSSCSelected] = useState(-1);

  //!-----------------------------------------!\\
  //!------------------Methods----------------!\\
  //!-----------------------------------------!\\
  const isUpdated = lodash.isEqual(result, initResult?.data);

  //!-----------------------------------------!\\
  //!----------------UseEffects---------------!\\
  //!-----------------------------------------!\\
  //* se não houver produto retorna para a página especificada
  //* se houver obter as categorias
  useEffect(() => {
    if (fkProduct <= 0) {
      return navigate("/productsmanagement");
    }
  }, [fkProduct]);

  return (
    <productContactsContext.Provider
      value={{
        categories: {
          data: getCategories(categoriesData?.data),
          selected: cSelected,
          setSelected: setCSelected,
        },
        subCategories: {
          data: getSubCategories(categoriesData?.data, cSelected),
          selected: sCSelected,
          setSelected: setSCSelected,
        },
        subSubCategories: {
          data: getSubSubCategories(
            categoriesData?.data,
            cSelected,
            sCSelected
          ),
          selected: sSCSelected,
          setSelected: setSSCSelected,
        },
        result: {
          data: result,
          setResult,
          init: initResult?.data,
          isUpdated,
          refetchResult,
        },
        fkProduct: Number(fkProduct),
      }}
    >
      <ProductsCategories />
    </productContactsContext.Provider>
  );
};

export default PCProvider;
