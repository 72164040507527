import styled, { keyframes } from "styled-components";

const arrowAnimationKF = keyframes`
  0% {
    transform: translateY(-3rem);
  }
  50% {
    transform: translateY(3rem);
  }
  100% {
    transform: translateY(-3rem);
  }
`;

export const SaveButton = styled.button`
  border: 0;
  padding: 0.5rem 3rem;
  border-radius: 4px;
  background-color: #4a934a;
  color: white;
  font-weight: bold;
  border-bottom: 5px solid #376e37;
  margin-bottom: 5px;
  &:hover {
    background-color: #408140;
    color: white;
  }
  &:active {
    transform: translateY(5px);
    border-bottom: 0px solid white;
  }
`;

export const InputFileLabel = styled.label`
  background-color: #a20013;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: 200ms linear;
  &:hover {
    background-color: #72000d;
    transition: 200ms linear;
  }
  & input {
    display: none;
  }
`;

export const AnimationArrow = styled.div`
  text-align: center;
  width: 100%;
  & svg {
    animation: ${arrowAnimationKF} 2s ease-in-out infinite;
    color: #333;
  }
`;
