import { Info } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { P, Popover } from "./Element.styles";

function Element({ body }) {
  const popover = (
    <Popover>
      <Popover.Header as="h3">Ajuda</Popover.Header>
      <Popover.Body as="p">{body}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      overlay={popover}
    >
      <IconButton size="small" color="primary" aria-label="Page Info">
        <Info />
      </IconButton>
    </OverlayTrigger>
  );
}

export default Element;
