import styled from "styled-components";
import { Table as BTable } from "react-bootstrap";

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  padding: 1rem 0.5rem;
`;

export const Table = {
  Container: styled(BTable)`
    margin-bottom: 0;
  `,
  THead: styled.thead`
    border-bottom: white;
    color: #a20013;
  `,
  TBody: styled.tbody``,
  Tr: styled.tr`
    vertical-align: middle;
  `,
  Th: styled.th``,
  Td: styled.td`
    vertical-align: middle;
  `,
  Input: styled.input`
    width: 100%;
    border: 1px solid #c0c1c2;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    color: #333;

    &:focus {
      outline-color: rgba(162, 0, 19, 0.3);
    }
  `,
  Select: styled.select`
    width: 100%;
    border: 1px solid #c0c1c2;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    color: #333;
    background-color: white;

    &:focus {
      outline-color: rgba(162, 0, 19, 0.3);
    }
  `,
};

export const ItemSubtitle = styled.div`
  font-weight: 500;
  color: #333;
  font-size: 1.1rem;
`;
