import lodash from "lodash";
import { formatFormArray } from "./form";
import { getProductsChecked } from "./products";

/**
 * !-----------------------------------------------------------------!
 * !---------------Get seasonCrossTable By Composition---------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @returns {array} retorna array de objetos da seasonCrosstable para o produto e composição pretendida
 * @description este método auxilia outros métodos e reduz a duplicação de código
 */
export function getSeasonCrossTableByComposition(
  seasonCrossTable,
  fkProduct,
  fkComposition
) {
  //* retorna um array de objetos da seasonCrossTable para o produto e composição
  //* pretendida
  return seasonCrossTable.filter(
    ({ seasonCrosstablePK: y }) =>
      y.fkProduct === fkProduct && y.fkComposition === fkComposition
  );
}

/**
 * !-----------------------------------------------------------------!
 * !-----------------Get seasonCrossTable By Country-----------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @param {integer} fkCountry
 * @returns {array} retorna array de objetos da seasonCrosstable para o produto, composição e país pretendido
 * @description este método auxilia outros métodos e reduz a duplicação de código
 */
export function getSeasonCrossTableByCountry(
  seasonCrossTable,
  fkProduct,
  fkComposition,
  fkCountry
) {
  //* retorna um array de objetos da seasonCrossTable para o produto, composição e país
  //* pretendido
  return seasonCrossTable.filter(
    ({ seasonCrosstablePK: y }) =>
      y?.fkProduct === fkProduct &&
      y?.fkComposition === fkComposition &&
      y?.fkCountry === fkCountry
  );
}

/**
 * !-----------------------------------------------------------------!
 * !--------------------Handle Enable Composition--------------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @returns {array} seasonCrossTable com os enables atualizados
 * @description este método é utilizado no onClick do enable da composição
 */
export function handleEnableComposition(
  seasonCrossTable = [],
  fkProduct,
  fkComposition
) {
  //* obter os dados da seasonCrossTable para a composição pretendida
  const sctByComposition = getSeasonCrossTableByComposition(
    seasonCrossTable,
    fkProduct,
    fkComposition
  );

  if (lodash.isEmpty(sctByComposition)) return [];

  //* se estiver enable, alterar os registos para disabled (type = 0)
  if (
    checkEnableComposition(seasonCrossTable, fkProduct, fkComposition) === 1 ||
    checkEnableComposition(seasonCrossTable, fkProduct, fkComposition) === 2
  ) {
    sctByComposition.forEach((e) => {
      e.enabled = 5;
    });
  } else {
    sctByComposition.forEach((e) => {
      e.enabled = 2;
    });
  }

  //* variável que irá ser retornada
  const result = [];

  //* iterar sobre a seasonCrossTable
  seasonCrossTable.forEach((e) => {
    const y = e?.seasonCrosstablePK;

    //* obter o index em que as chaves são iguais
    const index = sctByComposition.indexOf(({ seasonCrosstablePK: z }) =>
      lodash.isEqual(y, z)
    );

    //* se houver index (index !== -1)
    //* adicionar o valor ao result a partir da sctByComposition
    if (index !== -1) {
      result.push(sctByComposition[index]);
    } else {
      //* se não existir index
      //* adicionar o valor a partir da seasonCrossTable
      result.push(e);
    }
  });

  return result;
}

/**
 * !-----------------------------------------------------------------!
 * !---------------------------Handle Post---------------------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @returns {array} seasonCrossTable com os enables atualizados
 * @description este método é utilizado no onClick do enable da composição
 */
export function handlePostComposition(
  seasonCrossTable = [],
  fkProduct,
  fkComposition,
  enabledValue
) {
  //* obter os dados da seasonCrossTable para a composição pretendida
  const sctByComposition = getSeasonCrossTableByComposition(
    seasonCrossTable,
    fkProduct,
    fkComposition
  );

  //* se não encontrar a composição retorna array vazio
  if (lodash.isEmpty(sctByComposition)) return [];

  //* alterar o enabled para o valor passado como parâmetro
  sctByComposition.forEach((e) => {
    e.enabled = enabledValue;
  });

  //* variável que irá ser retornada
  const result = [];

  //* iterar sobre a seasonCrossTable
  seasonCrossTable.forEach((e) => {
    const y = e?.seasonCrosstablePK;

    //* obter o index em que as chaves são iguais
    const index = sctByComposition.indexOf(({ seasonCrosstablePK: z }) =>
      lodash.isEqual(y, z)
    );

    //* se houver index (index !== -1)
    //* adicionar o valor ao result a partir da sctByComposition
    if (index !== -1) {
      result.push(sctByComposition[index]);
    } else {
      //* se não existir index
      //* adicionar o valor a partir da seasonCrossTable
      result.push(e);
    }
  });

  return result;
}

/**
 * !-----------------------------------------------------------------!
 * !---------------------Check Enable Composition--------------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @returns {boolean} retorna true se os valores (enabled) estiverem a 1
 * @return {boolean} retorna false se pelo menos um valor estiver a 0
 */
export function checkEnableComposition(
  seasonCrossTable,
  fkProduct,
  fkComposition
) {
  //* obter os dados da seasonCrossTable para a composição pretendida
  const sctByComposition = getSeasonCrossTableByComposition(
    seasonCrossTable,
    fkProduct,
    fkComposition
  );

  //* se não encontrar a composição retorna 0
  if (lodash.isEmpty(sctByComposition)) return 0;

  //* basta ir ao primeiro registo e obter o valor do enabled
  return sctByComposition[0].enabled;
}

export function createOrUpdate(
  seasonCrossTable = [],
  form = {},
  fkProduct,
  fkComposition,
  fkCountry
) {
  //* 1-remover todos os dados para o produto, composição e país selecionado
  //* esta linha de  código é para evitar a repetição de dados
  const dataWithoutCountry = removeSCTValuesByCountry(
    seasonCrossTable,
    fkProduct,
    fkComposition,
    fkCountry,
    undefined,
    "create"
  );

  //* 2-adicionar os dados atualizados
  //* converter o formulário para array
  const formArray = formatFormArray(form, true);

  //* retorna o array com os novos dados adicionados
  return [...dataWithoutCountry, ...formArray];
}

/**
 * !-----------------------------------------------------------------------!
 * !---------------Remove SeasonCrossTable Values By Country---------------!
 * !-----------------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @param {integer} fkCountry
 * @returns {array} retorna a seasonCrossTable sem o país pretendido
 */
export function removeSCTValuesByCountry(
  seasonCrossTable = [],
  fkProduct,
  fkComposition,
  fkCountry,
  fkSeason,
  type = "remove"
) {
  //* array onde serão guardados os valores
  let result = [];

  //* iterar sobre os valores da seasonsCrossTable
  seasonCrossTable.forEach((e) => {
    const y = e?.seasonCrosstablePK;

    //* encontrar dados diferentes do país pretendido
    if (
      !(y?.fkProduct === fkProduct,
      y?.fkComposition === fkComposition && y?.fkCountry === fkCountry)
    ) {
      //* se encontrar, adiciona o objeto
      result.push(
        y?.fkProduct === fkProduct && y?.fkComposition === fkComposition
          ? { ...e, enabled: 1 }
          : e
      );
    }
  });

  //* se este método for utilizado no intuito de remover,
  //* verifica se o produto a que foram tirados dados, ainda tem dados.
  //* se não houver dados para o produto pretendido, o produto serão removido
  if (!getProductsChecked(fkProduct, result) && type === "remove") {
    return removeSCTValuesByProduct(result, fkProduct, fkSeason);
  }

  //* se houver para o produto pretendido,
  //* só será removido os países pretendidos
  return result;
}

/**
 * !---------------------------------------------------------------------------!
 * !---------------Remove SeasonCrossTable Values By Composition---------------!
 * !---------------------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @param {integer} fkSeason
 * @returns {array} retorna array sem a composição que será removida
 */
export function removeSCTValuesByComposition(
  seasonCrossTable = [],
  fkProduct,
  fkComposition,
  fkSeason
) {
  //* array onde serão guardados os valores
  let result = [];

  //* iterar sobre os valores da seasonsCrossTable
  seasonCrossTable.forEach((e) => {
    const y = e?.seasonCrosstablePK;

    //* encontrar dados diferentes da composição pretendida
    if (!(y?.fkCountry === fkProduct, y?.fkComposition === fkComposition)) {
      result.push(e);
    }
  });

  //* verifica se o produto a que foram tirados dados, ainda tem dados.
  //* se não houver dados para o produto pretendido, o produto serão removido
  if (!getProductsChecked(fkProduct, result)) {
    return removeSCTValuesByProduct(result, fkProduct, fkSeason);
  }

  //* se houver dados, a composição será removida
  return result;
}

/**
 *
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkSeason
 * @returns {array} Retorna a seasonCrosstable sem o produto que se pretende remover, juntamente com um objeto específico
 * @description para remover um produto é necessário adicionar um objeto semelhante aos objetos da seasonCrossTable com as seguintes chaves:
 * @object {..., seasonCrosstablePK: { fkComposition: -1, fkCountry: 0, fkProduct: fkProduct, fkReference: "-", fkSeason: fkSeason }}
 */
export function removeSCTValuesByProduct(
  seasonCrossTable = [],
  fkProduct,
  fkSeason
) {
  //* array onde serão guardados os valores
  let result = [];

  //* iterar sobre os valores da seasonsCrossTable
  seasonCrossTable.forEach((e) => {
    const y = e?.seasonCrosstablePK;

    //* encontrar dados diferentes da composição pretendida
    if (!(y.fkProduct !== fkProduct)) {
      result.push(e);
    }
  });

  //* retorna a seasonCrossTable sem o produto pretendido
  //*juntamente com o objeto para remover o produto
  return [
    ...result,
    {
      discount: 0,
      enabled: 0,
      oldref: "",
      price: 0,
      profit: 0,
      pvp: 0,
      seasonCrosstablePK: {
        fkComposition: -1,
        fkCountry: 0,
        fkProduct: fkProduct,
        fkReference: "-",
        fkSeason: fkSeason,
      },
    },
  ];
}
