import { Add, Remove } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { isEmpty, values } from "lodash";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { usePM } from "../../../../../contexts/PMProvider/usePM";
import { useServices } from "../../../../../services/useServices";
import {
  formatDeleteObject,
  initialValues,
  rData,
  validationSchema,
} from "./_utils";
// import { Container } from './styles';

function FormCaracterization(props) {
  //* props
  const { countries, seasons, properties, fkProduct } = props;

  //!--------------------------------------------------------------------!\\
  //!-------------------------------hooks--------------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* services
  const { propertyEndpoints, getLanguage } = useServices();

  //* supplier data
  const { fkSupplier } = usePM();

  //* translation
  const { t } = useTranslation(["pm/products"]);

  //!--------------------------------------------------------------------!\\
  //!--------------------------create / remove---------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* add property
  const addProperty = useMutation(
    (values) => {
      return propertyEndpoints.addProductProperty(values);
    },
    {
      onSuccess: () => {
        propertiesByProduct.refetch();
        caracterizationForm.resetForm();
      },
    }
  );

  //* remove property
  const removeProperty = useMutation(
    (values) =>
      propertyEndpoints.removeProductProperty(formatDeleteObject(values)),
    {
      onSuccess: () => propertiesByProduct.refetch(),
    }
  );

  //!--------------------------------------------------------------------!\\
  //!-----------------------------relevance------------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* caracterization form
  const caracterizationForm = useFormik({
    initialValues: initialValues(fkProduct),
    enableReinitialize: true,
    onSubmit: addProperty.mutate,
    validationSchema: validationSchema,
  });

  //!--------------------------------------------------------------------!\\
  //!--------------------------------data--------------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* property values
  const propertyValues = useQuery(
    [
      "getPropertyValuesP",
      caracterizationForm.values.productPropertyPK.fkProperty,
    ],
    () =>
      propertyEndpoints.getPropertyValues(
        caracterizationForm.values.productPropertyPK.fkProperty,
        fkSupplier,
        String(getLanguage()).toUpperCase()
      ),
    {
      enabled: caracterizationForm.values.productPropertyPK.fkProperty !== -1,
    }
  );

  //* properties by product
  const propertiesByProduct = useQuery(
    ["getPropertiesByProduct", fkProduct],
    () =>
      propertyEndpoints.getAllPropertysByProduct(
        fkSupplier,
        getLanguage(),
        fkProduct
      ),
    {
      enabled: fkProduct !== -1,
    }
  );

  //* is disabled property
  const isDisabledProperty =
    caracterizationForm.values.productPropertyPK.fk_season === -1 ||
    caracterizationForm.values.productPropertyPK.fkCountry === -1;

  //!--------------------------------------------------------------------!\\
  //!------------------------------methods-------------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* onChange Season
  const handleChangeSeason = (e) =>
    caracterizationForm.setFieldValue("productPropertyPK.fk_season", e?.value);

  //* value season
  const valueSeason =
    seasons.find(
      (e) => e.value === caracterizationForm.values.productPropertyPK.fk_season
    ) ?? null;

  //* onChange country
  const handleChangeCountry = (e) =>
    caracterizationForm.setFieldValue("productPropertyPK.fkCountry", e?.value);

  //* value season
  const valueCountry =
    countries.find(
      (e) => e.value === caracterizationForm.values.productPropertyPK.fkCountry
    ) || null;

  //* handle change property
  const handleChangeProperty = (e) =>
    caracterizationForm.setFieldValue("productPropertyPK.fkProperty", e?.value);

  //* property value
  const propertyValue =
    properties.find(
      (e) => e.value === caracterizationForm.values.productPropertyPK.fkProperty
    ) || null;

  //* optionsPropertyValues
  const optionsPropertyValues = [...(propertyValues.data?.data || [])].map(
    (e) => ({
      label: e[String(getLanguage()).toLowerCase()],
      value: e?.idPropertyValue,
    })
  );

  //* handle change property value
  const handleChangePropertyValue = (e) =>
    caracterizationForm.setFieldValue(
      "productPropertyPK.fkPropertyValue",
      e?.value
    );

  //* isOptionDisabledPropertyValue
  const isOptionDisabledPropertyValue = (e) => {
    const value = e.value;

    const pbp = propertiesByProduct.data?.data || [];
    const property = caracterizationForm.values.productPropertyPK.fkProperty;

    const exist = pbp.find(
      (x) => x.fk_property === property && x.fk_property_value === value
    );

    if (exist !== undefined) return true;

    return false;
  };

  //*
  const valuePropertyValue =
    [...(propertyValues.data?.data || [])]
      .map((e) => ({
        label: e[String(getLanguage()).toLowerCase()],
        value: e?.idPropertyValue,
      }))
      .find(
        (e) =>
          e.value ===
          caracterizationForm.values.productPropertyPK.fkPropertyValue
      ) || null;

  return (
    <>
      <Form as="tr" onSubmit={caracterizationForm.handleSubmit}>
        <td>
          {
            //!--------------------------------------------------------------------!\\
            //!-------------------------------Season-------------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Select
            aria-invalid="true"
            menuPortalTarget={document.body}
            options={seasons}
            controlShouldRenderValue
            onChange={handleChangeSeason}
            value={valueSeason}
          />
        </td>
        <td>
          {
            //!--------------------------------------------------------------------!\\
            //!-------------------------------Country------------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Select
            menuPortalTarget={document.body}
            options={countries}
            onChange={handleChangeCountry}
            value={valueCountry}
          />
        </td>
        <td>
          {
            //!--------------------------------------------------------------------!\\
            //!-------------------------------property-----------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Select
            menuPortalTarget={document.body}
            options={properties}
            isDisabled={isDisabledProperty}
            onChange={handleChangeProperty}
            value={propertyValue}
          />
        </td>
        <td>
          {
            //!--------------------------------------------------------------------!\\
            //!--------------------------properties values-------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Select
            menuPortalTarget={document.body}
            options={optionsPropertyValues}
            isDisabled={
              caracterizationForm.values.productPropertyPK.fkProperty === -1
            }
            onChange={handleChangePropertyValue}
            isOptionDisabled={isOptionDisabledPropertyValue}
            value={valuePropertyValue}
          />
        </td>
        <td>
          {
            //!--------------------------------------------------------------------!\\
            //!-----------------------------relevance------------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Select
            menuPortalTarget={document.body}
            options={rData}
            onChange={(e) =>
              caracterizationForm.setFieldValue("relevance", e?.value)
            }
            value={
              rData.find(
                (e) => e.value === caracterizationForm.values.relevance
              ) || null
            }
          />
        </td>
        <td className="text-center">
          {
            //!--------------------------------------------------------------------!\\
            //!-----------------------------Add button-----------------------------!\\
            //!--------------------------------------------------------------------!\\
          }
          <Tooltip title={t("caracterization.buttons.add")} placement="right">
            <IconButton
              color="success"
              onClick={caracterizationForm.submitForm}
              size="small"
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        </td>
      </Form>
      {[...(propertiesByProduct.data?.data || [])].map((e) => (
        <tr>
          <td>{e?.name_season}</td>
          <td>{e?.country}</td>
          <td>{e?.name_property}</td>
          <td>{e?.name_property_value}</td>
          <td className="text-center">{e?.relevance}</td>
          <td className="text-center">
            <Tooltip
              title={t("caracterization.buttons.remove")}
              placement="right"
            >
              <IconButton
                color="error"
                size="small"
                onClick={() => removeProperty.mutate(e)}
              >
                <Remove fontSize="small" />
              </IconButton>
            </Tooltip>
          </td>
        </tr>
      ))}
      {isEmpty(propertiesByProduct?.data?.data) && (
        <tr className="text-center">
          <td colSpan="6">{t("caracterization.form.empty")}</td>
        </tr>
      )}
    </>
  );
}

export default FormCaracterization;
