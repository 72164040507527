//? YUP
import * as Yup from "yup";

//?------------------------------------------------------------?\\
//?----------------------validationSchema----------------------?\\
//?------------------------------------------------------------?\\
export const validationSchema = (t, role) => {
  return Yup.object().shape({
    reference: Yup.string().notRequired(),
    fkSupplier: Yup.string().required(),
    sreference: Yup.string().notRequired(),
    barcode: Yup.string().notRequired(),
    warranty: Yup.number()
      .integer(t("fields.warranty.errors.integer"))
      .required(t("fields.warranty.errors.required"))
      .min(1, t("fields.warranty.errors.moreThan0"))
      .typeError(t("fields.warranty.errors.typeError")),
    stock: Yup.number()
      .integer(t("fields.stock.errors.integer"))
      .required(t("fields.stock.errors.required"))
      .min(1, t("fields.stock.errors.moreThan0"))
      .typeError(t("fields.stock.errors.typeError")),
    vat: Yup.number()
      .required(t("fields.vat.errors.required"))
      .min(0, t("fields.vat.errors.percentage"))
      .max(100, t("fields.vat.errors.percentage"))
      .typeError(t("fields.vat.errors.typeError")),

    dprofit: Yup.number()
      .required(t("fields.dprofit.errors.required"))
      .min(
        role === 4 ? 0 : 5,
        role === 4
          ? t("fields.dprofit.errors.percentage2")
          : t("fields.dprofit.errors.percentage")
      )
      .max(
        100,
        role === 4
          ? t("fields.dprofit.errors.percentage2")
          : t("fields.dprofit.errors.percentage")
      )
      .typeError(t("fields.dprofit.errors.typeError")),
    pattern: Yup.string().notRequired(),
    price: Yup.number()
      .required(t("fields.price.errors.required"))
      .test("is-decimal", t("fields.price.errors.isDecimal"), (value) =>
        (value + "").match(/^\d+(\.\d{0,2})?$/)
      )
      .typeError(t("fields.price.errors.typeError")),
    color: Yup.mixed().notRequired(),
    images: Yup.array().notRequired(),
    descriptionInvoice: Yup.string().notRequired(),
    fkScale: Yup.number(),
    fkScaleValue: Yup.string()
      .when("fkScale", {
        is: (x) => Number(x) !== 0,
        then: Yup.string().required(t("fields.scaleValue.errors.required")),
      })
      .typeError(t("fields.scaleValue.errors.typeError")),
    descScale: Yup.string().nullable(),
  });
};
