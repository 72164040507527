import axios from "axios";

export function ProductsWebEndpoint(initPath) {
  const PATH = `${initPath}/v1.0/productswebendpoint`;

  const wgetCategorys = async (fkLanguage) =>
    await axios.get(`${PATH}/wgetCategorys/${fkLanguage}`);

  const wgetnsubcategories = async (
    fkCategory,
    fkSubcategory,
    fkSubsubcategory,
    fkCountryCode,
    fkLanguage
  ) =>
    await axios.get(
      `${PATH}/wgetnsubcategorys/${fkLanguage}/${fkCategory}/${fkSubcategory}/${fkSubsubcategory}/${fkCountryCode}`
    );

  const wdetailsproduct = async (fkProduct, fkLanguage, fkCountryCode) =>
    await axios.get(
      `${PATH}/wdetailsproduct/${fkProduct}/${fkLanguage}/${fkCountryCode}`
    );

  const wfindProducts = async (textFilter, fkLanguage, fkCountryCode) =>
    await axios.get(
      `${PATH}/wfindProducts/${fkLanguage}/${fkCountryCode}/?text_filter=${textFilter}`
    );

  const wshopstorebysupplier = async (fkSupplier, fkLanguage, fkCountryCode) =>
    axios.get(
      `${PATH}/wshopstorebysupplier/${fkLanguage}/${fkSupplier}/${fkCountryCode}`
    );

  const wsuppliersInformation = async () =>
    axios.get(`${PATH}/wsuppliersInformation`);

  return {
    wgetCategorys,
    wgetnsubcategories,
    wfindProducts,
    wshopstorebysupplier,
    wsuppliersInformation,
    wdetailsproduct,
  };
}
