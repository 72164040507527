import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { encryptPublicLong } from "@lsqswl/rsaencrypt";
import { useServices } from "../../../../services/useServices";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function IntroducePassword({ token }) {
  //!-------------------------------------------------------!\\
  //!-------------------------Hooks-------------------------!\\
  //!-------------------------------------------------------!\\
  //* use authentication
  const { publicKey } = useAuth();

  //* navigate
  const navigate = useNavigate();

  //* alert
  const alert = useAlert();

  //* services
  const { authenticationEndpoints } = useServices();

  //* use translation
  const { t } = useTranslation(["recoverPassword"]);

  //!-------------------------------------------------------!\\
  //!-------------------------states------------------------!\\
  //!-------------------------------------------------------!\\
  //* é utilizado para alterar o typo da caixa de texto entre text<->password
  const [typeInput, setTypeInput] = useState("password");

  //!-------------------------------------------------------!\\
  //!-------------------------methods-----------------------!\\
  //!-------------------------------------------------------!\\
  //* é a validação do formulário
  const schema = Yup.object({
    password: Yup.string()
      .required(t("fields.password.errors.required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[0-9]).{6,}$/,
        t("fields.password.errors.match")
      ),
    confPassword: Yup.string()
      .required(t("fields.confPassword.errors.required"))
      .oneOf(
        [Yup.ref("password"), null],
        t("fields.confPassword.errors.oneOf")
      ),
  });

  //!-------------------------------------------------------!\\
  //!-------------------------services----------------------!\\
  //!-------------------------------------------------------!\\
  //* serviço para enviar os dados
  const confirmationrecoveryPassword = useMutation(
    (data) => authenticationEndpoints.confirmationrecoveryPassword(data),
    {
      onSuccess: (response) => {
        //* se a responsta for 0
        //* a password ou o token não é válida.
        if (response?.data === 0) {
          //* aparece o alerta e volta para a parte inicial (voltar a introduzir email)
          alert.error(t("alerts.submitError"));
          return navigate("/user/recoverpassword");
        }

        //* se não houver erro
        //* o utilizador vai para o login
        return navigate("/user/login");
      },
    }
  );

  //!-------------------------------------------------------!\\
  //!---------------------------form------------------------!\\
  //!-------------------------------------------------------!\\
  const formik = useFormik({
    initialValues: { passowrd: "", confPassword: "" },
    enableReinitialize: false,
    validationSchema: schema,
    validateOnMount: false,
    onSubmit: (values) => {
      //* obter a password
      const { password } = values;

      //* construir a hash da password
      const x = encryptPublicLong(password, publicKey);
      return confirmationrecoveryPassword.mutate({
        token: token,
        cypher_password: x,
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Pin2GiveForm.Input
          label={t("fields.password.label")}
          inputProps={{
            onChange: formik.handleChange,
            type: typeInput,
            value: formik.values.password || "",
            name: "password",
            help: (
              <div
                className="d-flex text-secondary"
                onClick={() =>
                  setTypeInput((e) => (e === "text" ? "password" : "text"))
                }
              >
                {typeInput === "text" ? <FaEyeSlash /> : <FaEye />}
              </div>
            ),
          }}
          error={formik.errors.password}
        />
        <Pin2GiveForm.Input
          label={t("fields.confPassword.label")}
          inputProps={{
            onChange: formik.handleChange,
            type: typeInput,
            value: formik.values.confPassword || "",
            name: "confPassword",
          }}
          error={formik.errors.confPassword}
        />
        <div className="text-end">
          <Button size="sm" variant="success" type="submit">
            {t("buttons.recover")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default IntroducePassword;
