import { P2GLHeader } from "./Pin2GiveList.styles";
import { Tooltip, IconButton } from "@mui/material";
import { FaPlus } from "react-icons/fa";

const Pin2GiveListHeader = ({ title, headerButton }) => {
  return (
    <P2GLHeader.Container>
      <P2GLHeader.Content>
        <div>{title}</div>
        {headerButton.onClick && (
          <P2GLHeader.ButtonContainer>
            <Tooltip placement="right" title={headerButton.title}>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={headerButton.onClick}
              >
                <FaPlus size={18} />
              </IconButton>
            </Tooltip>
          </P2GLHeader.ButtonContainer>
        )}
      </P2GLHeader.Content>
    </P2GLHeader.Container>
  );
};

export default Pin2GiveListHeader;
