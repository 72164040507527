//? REACT-BOOTSTRAP
import { Form } from "react-bootstrap";

//!-----------------------------------------------------------------!\\
//!--------------------------PIN2GIVECHECK--------------------------!\\
//!-----------------------------------------------------------------!\\
const Check = ({ label, checkProps, error }) => {
  return (
    <Form.Group className="mb-4">
      <Form.Label>{label}</Form.Label>
      <Form.Check {...checkProps} isInvalid={error} />
      <Form.Control.Feedback type={error ? "invalid" : "valid"}>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Check;
