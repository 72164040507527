import styled, { css } from "styled-components";
import { Row, Table as TableB } from "react-bootstrap";
export const Tr = styled.tr`
  vertical-align: middle;
`;

export const Filters = {
  Container: styled(Row)`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 4px;
    padding: 1rem 0.5rem;
  `,
};

export const Table = {
  Container: styled.div`
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 4px;
    padding: 1rem;
  `,
  Table: styled(TableB)``,
  Tr: styled.tr`
    vertical-align: middle;
  `,
  Td: styled.td`
    border-bottom: 0;
    ${({ centered }) =>
      centered &&
      css`
        text-align: center;
      `};
  `,
  THead: styled.thead`
    color: #a20013;
  `,
  TBody: styled.tbody``,
  Th: styled.th`
    ${({ centered }) =>
      centered &&
      css`
        text-align: center;
      `}
  `,
};
