import { FaSearch } from "react-icons/fa";
import styled, { css } from "styled-components";

export const Search = {
  Container: styled.div`
    background-color: white;
    display: flex;
    border-radius: 50px;
    padding: 0.3rem 1.5rem;
    align-items: center;
    border: 2px solid;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    ${({ color }) => css`
      border-color: color !important;
    `}
  `,
  Input: styled.input`
    border: none;
    outline: none;
  `,
  Icon: styled.div`
    color: #333;
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 0.3rem;
  `,
};
