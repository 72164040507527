/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col, Button } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import "./Support.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Tabs from "../../components/Tabs/Tabs";
import ButtonScroll from "../../components/ButtonScroll/ButtonScroll";
import lodash from "lodash";
import { useServices } from "../../services/useServices";
const Contacts = () => {
  const { t } = useTranslation();
  const { pin2giveWebEndpoints, getLanguage } = useServices();
  const [FaqUsers, setFaqUsers] = useState(null);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  useEffect(() => {
    document.title = t("MainTitles.Support");

    pin2giveWebEndpoints
      .wgetFAQs(getLanguage(), 0)
      .then((response) => {
        setFaqUsers(response?.data);
      })
      .catch((error) => console.log("error on obtain faqs user", error));
  }, []);

  const getTabs = () => {
    if (!lodash.isEmpty(FaqUsers)) {
      return [
        {
          href: "hc",
          title: t("MainTitles.HelpCenter"),
          offset: -200,
        },
        {
          href: "faqs",
          title: "FAQ's",
          offset: -200,
        },
        {
          href: "contacts",
          title: t("MainTitles.Contacts"),
          offset: -200,
        },
      ];
    } else {
      return [
        {
          href: "hc",
          title: t("MainTitles.HelpCenter"),
          offset: -200,
        },
        {
          href: "contacts",
          title: t("MainTitles.Contacts"),
          offset: -200,
        },
      ];
    }
  };
  return (
    <div className="app-contacts">
      <div className="d-none">contacts</div>
      <Container fluid className="sticky-top">
        <Row>
          <Col xs="12">
            <Container className="py-2 py-md-4 bg-white">
              <Row>
                <Col xs="12">
                  <Tabs tabslist={getTabs()} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center" id="hc">
          <Col xs="12" md="8" className="text-center mt-5 mb-3">
            <span className="app-title">{t("MainTitles.HelpCenter")}</span>
          </Col>
          <Col
            xs="12"
            md="12"
            className="text-center mb-5 d-flex justify-content-center mt-3"
          >
            <div className="contacts-list-container">
              <span className="contacts-content-icon">
                <div>
                  <FaEnvelope />
                </div>
              </span>
              <span className="contacts-content-labels">
                <h5>{t("Contacts.Email")}</h5>
                <p>support@pin2give.com</p>
              </span>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5" id="faqs">
          <Col xs="12" className="mb-5">
            <Container fluid className="m-0 p-0">
              {lodash.isEmpty(FaqUsers) ? (
                <></>
              ) : (
                <>
                  <Row className="justify-content-center">
                    <Col xs="12" md="8" className="text-center mt-5 mb-3">
                      <span className="app-title">FAQ's</span>
                    </Col>
                  </Row>
                  {FaqUsers.map((e, index) => (
                    <Row className="justify-content-center" key={index}>
                      <Col xs="12" md="8" className="mb-3">
                        <div className="app-sub-title">{e?.title}</div>
                      </Col>
                      <Col xs="12" md="8" className="text-justify mb-5">
                        <p>{e?.question}</p>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Container>
          </Col>
        </Row>
        <Row className="justify-content-center " id="contacts">
          <Col xs="12" md="8" className="text-center mt-5 mb-3">
            <span className="app-title">{t("MainTitles.Contacts")}</span>
          </Col>
          <Col xs="12" md="8" className="text-center mb-5">
            <p>{t("Contacts.p")}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" lg="6">
            <ul className="contacts-list">
              <li>
                <div className="contacts-list-container">
                  <span className="contacts-content-icon">
                    <div>
                      <FaMapMarkerAlt />
                    </div>
                  </span>
                  <span className="contacts-content-labels">
                    <h5>{t("Contacts.Address")}</h5>
                    <p>
                      Brigantia EcoPark <br />
                      Av. Cidade de Leon, 506
                      <br />
                      5301-35 Bragança
                      <br /> Portugal
                    </p>
                  </span>
                </div>
              </li>
              <li>
                <div className="contacts-list-container">
                  <span className="contacts-content-icon">
                    <div>
                      <FaPhoneAlt />
                    </div>
                  </span>
                  <span className="contacts-content-labels">
                    <h5>{t("Contacts.Phone")}</h5>
                    <p>+351 273 310 382</p>
                  </span>
                </div>
              </li>
              <li>
                <div className="contacts-list-container">
                  <span className="contacts-content-icon">
                    <div>
                      <FaEnvelope />
                    </div>
                  </span>
                  <span className="contacts-content-labels">
                    <h5>{t("Contacts.Email")}</h5>
                    <p>geral@pin2give.com</p>
                  </span>
                </div>
              </li>
            </ul>
          </Col>
          <Col xs="12" lg="6" className="contacts-map">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              center={{
                lat: 41.81937716677108,
                lng: -6.749111208015103,
              }}
              zoom={16}
            ></GoogleMapReact>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="12" className="text-center">
            <Button
              variant="link"
              target="_blank"
              href="https://www.linkedin.com/company/pin2give"
            >
              <FaLinkedinIn size={20} />
            </Button>
            <Button
              variant="link"
              href="https://www.facebook.com/pages/category/Product-Service/Pin2Give-110308421125098/"
              target="_blank"
            >
              <FaFacebookF size={20} />
            </Button>
            <Button
              variant="link"
              href="https://www.instagram.com/pin2give/"
              target="_blank"
            >
              <FaInstagram size={20} />
            </Button>
          </Col>
        </Row>
      </Container>
      <ButtonScroll />
    </div>
  );
};

export default Contacts;
