import { IconButton, Tooltip } from "@mui/material";
import { node } from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Data from "./Data";
import Safts from "./Stafts";

// import { Container } from './styles';

function Profile() {
  const { t } = useTranslation(["profile"]);
  const navigate = useNavigate();
  return (
    <Container className="mt-5">
      <Row>
        <Col xs="12">
          <div className="app-title text-center d-flex align-items-center">
            <Tooltip
              title={t("buttons.back")}
              placement="bottom"
              className="me-3"
            >
              <IconButton
                edge="end"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaArrowLeft />
              </IconButton>
            </Tooltip>
            <div className="mx-auto">{t("title")}</div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs="8">
          <Data />
        </Col>
      </Row>
      {process.env.NODE_ENV === "development" && (
        <Row className="mt-5">
          <Col xs="12">
            <Safts />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Profile;
