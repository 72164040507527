import { Tab, Tabs } from "react-bootstrap";
import styled, { css } from "styled-components";

export const Titles = {
  Secondary: styled.div`
    font-weight: bold;
    font-size: 1.4rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #ccc;
  `,
  Label: styled.div`
    padding: 1rem;
    font-weight: bold;
  `,
};

export const SaftsTabs = {
  Tabs: styled(Tabs)`
    ${({ variant }) =>
      variant === "pills" &&
      css`
        & .active {
          background-color: #a20013 !important;
        }
        & button {
          color: #a20013 !important;

          &.active {
            color: white !important;
          }

          &.disabled {
            color: #ccc !important;
          }
        }
      `}
  `,
  Tab: styled(Tab)``,
};
