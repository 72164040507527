import * as Yup from "yup";

export const validationSchema = (t) => {
  return Yup.object().shape({
    title: Yup.string()
      .max(65, t("fields.title.errors.max"))
      .required(t("fields.title.errors.required")),
    observations: Yup.string().max(500, t("fields.observations.max")),
    dpublication: Yup.string().required(
      t("fields.dpublication.errors.required")
    ),
    depublication: Yup.string(),
  });
};
