import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { useAlert } from "react-alert";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useProductCategories } from "../../../contexts/ProductsManagement/ProductsCategories/useProductCategories";
import { useServices } from "../../../services/useServices";
import Header from "../Header";
import Categories from "./Categories";
import SubCategories from "./SubCategories";
import SubSubCategories from "./SubSubCategories";

// import { Container } from './styles';

function ProductsCategories() {
  const { result, fkProduct } = useProductCategories();
  const { productsEndpoints } = useServices();
  const navigate = useNavigate();
  const { t } = useTranslation(["pm/productsCategories"]);

  //!-----------------------!\\
  //!---------STEPS---------!\\
  //!-----------------------!\\
  const steps = [
    { label: t("steps.products"), path: "/productsmanagement/products" },
    {
      label: t("steps.categories"),
      path: null,
    },
    {
      label: t("steps.compositions"),
      path: `/productsmanagement/compositions/${fkProduct}/-`,
    },
    {
      label: t("steps.compositionReferences"),
      path: `/productsmanagement/compositionsreferences/${fkProduct}`,
    },
  ];

  const alert = useAlert();

  const { mutate: handleSubmit } = useMutation(
    (data) => productsEndpoints.createCategoriesforProduct(data),
    {
      onSuccess: () => {
        result.refetchResult();
        alert.success(<>{t("alerts.success.submitResult")}</>);
      },
      onError: () => {
        alert.error(<>{t("alerts.error.submitResult")}</>);
      },
    }
  );

  return (
    <Container className="mt-5">
      <Header
        left={{ backButton: true, menuButton: true }}
        center={{ title: t("title") }}
      />
      {
        //!------------------------------------------------!\\
        //!-----------------STEP INDICATOR-----------------!\\
        //!------------------------------------------------!\\
      }
      <Row className="mb-5">
        <Col xs="12">
          <Stepper activeStep={1}>
            {steps.map(({ label, path }) => (
              <Step
                key={label}
                onClick={() => (path ? navigate(path) : undefined)}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="3">
          <Categories />
        </Col>
        <Col xs="3">
          <SubCategories />
        </Col>
        <Col xs="3">
          <SubSubCategories />
        </Col>
      </Row>
      <Row className="mt-4 ">
        {!result?.isUpdated ? (
          <Col xs="12" className=" mt-5 text-end d-flex align-items-center">
            <Button
              variant="secondary"
              className="d-flex align-items-center ms-auto me-2"
              onClick={() => result.setResult(result.init)}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              variant="success"
              className="d-flex align-items-center "
              onClick={() => handleSubmit(result?.data)}
            >
              <FaSave className="me-2 d-flex align-items-center" />{" "}
              {t("buttons.save")}
            </Button>
          </Col>
        ) : (
          <>
            <Col xs="12" className="text-end mt-5 d-flex align-items-center">
              {
                //!-------------------------------------------!\\
                //!----------------COMPOSITIONS---------------!\\
                //!-------------------------------------------!\\
              }
              <Button
                variant="secondary"
                className="d-flex align-items-center me-auto"
                onClick={() => navigate(`/productsmanagement/products`)}
              >
                <FaArrowLeft className="me-2" /> {t("buttons.products")}
              </Button>
              <Button
                variant="pin2give"
                className="d-flex align-items-center ms-auto"
                onClick={() =>
                  navigate(`/productsmanagement/compositions/${fkProduct}/-`)
                }
              >
                {t("buttons.compositions")} <FaArrowRight className="ms-2" />
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}

export default ProductsCategories;
