/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Container, Row, Col } from "react-bootstrap";
import "./Policy.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ButtonScrollLink from "../../components/ButtonScroll/ButtonScroll";
import { useNavigate, useLocation } from "react-router-dom";
import { useServices } from "../../services/useServices";

const Policy = () => {
  const { t } = useTranslation(["privacyPolicy"]);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState(null);
  const { pin2giveWebEndpoints, getLanguage } = useServices();

  useEffect(() => {
    document.title = t("title");
    pin2giveWebEndpoints
      .getStrHtmlPolicie(getLanguage())
      .then((response) => setPolicy(response.data));
  }, []);

  useEffect(() => {
    const status = localStorage.getItem("privacy_status");

    if (status === "accepted") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  return (
    <div className="app-policy">
      <div className="d-none">policy</div>
      <Container className="mt-3">
        <Row>
          <Col xs="12">
            <div dangerouslySetInnerHTML={{ __html: policy }} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs="12" className={`text-center ${show ? "" : "d-none"}`}>
            <button
              className="policy-banner-button me-3"
              onClick={() => {
                localStorage.setItem("privacy_status", "accept_not");
                navigate("/");
              }}
            >
              {t("buttons.da")}
            </button>
            <button
              className="policy-banner-button"
              onClick={() => {
                localStorage.setItem("privacy_status", "accepted");
                navigate("/");
              }}
            >
              {t("buttons.a")}
            </button>
          </Col>
        </Row>
      </Container>

      <ButtonScrollLink href="links" />
    </div>
  );
};

export default Policy;
