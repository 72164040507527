/* eslint-disable react-hooks/exhaustive-deps */
//-----------------------------------\\
//---------------REACT---------------\\
//-----------------------------------\\
import React, { memo, useCallback } from "react";

//-------------------------------------\\
//---------------I18NEXT---------------\\
//-------------------------------------\\
import { useTranslation } from "react-i18next";

//----------------------------------------------\\
//---------------REACT-ROUTER-DOM---------------\\
//----------------------------------------------\\
import { useNavigate } from "react-router-dom";

//------------------------------------------\\
//---------------PIN2GIVELIST---------------\\
//------------------------------------------\\
import {
  Pin2GiveListItem,
  Pin2GiveList,
} from "../../../../components/Pin2GiveList/Pin2GiveList";

//----------------------------------------------\\
//---------------SEASONCROSSTABLE---------------\\
//----------------------------------------------\\
import { useSeasonCrossTable } from "../useSeasonCrossTable";
import { getCompositionChecked } from "../_actions/compositions";

function Compositions() {
  //!----------------------------------------!\\
  //!----------------navigate----------------!\\
  //!----------------------------------------!\\
  const navigate = useNavigate();

  //!-------------------------------------------!\\
  //!----------------translation----------------!\\
  //!-------------------------------------------!\\
  const { t } = useTranslation(["pm/sct"]);

  //!------------------------------------!\\
  //!----------------data----------------!\\
  //!------------------------------------!\\
  //* context
  const { products, compositions, sct, references, countries } =
    useSeasonCrossTable();

  //!------------------------------------------------------!\\
  //!----------------handle add composition----------------!\\
  //!------------------------------------------------------!\\
  const handleAddCompositions = () =>
    navigate("/productsmanagement/compositions/" + products.selected + "/-");

  //!--------------------------------------------!\\
  //!----------------handle close----------------!\\
  //!--------------------------------------------!\\
  const handleClick = useCallback(
    (fkComposition) => {
      references.setSelected("");
      countries.setSelected(-1);
      compositions.setSelected(fkComposition);
    },
    [compositions.selected]
  );

  const isChecked = useCallback(
    (fkComposition) =>
      getCompositionChecked(sct, products.selected, fkComposition),
    [compositions]
  );

  return (
    <Pin2GiveList
      headerProps={{
        title: t("compositions.title"),
        headerButton:
          products.selected !== -1
            ? {
                onClick: handleAddCompositions,
                title: t("compositions.buttonTooltip"),
              }
            : {},
      }}
    >
      {compositions.data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          check={isChecked(e?.idComposition)}
          selected={e?.idComposition === compositions.selected}
          checked={isChecked(e?.idComposition)}
          onClick={() => handleClick(e?.idComposition)}
          title={e?.stitle}
          checkDisabled
        />
      ))}
    </Pin2GiveList>
  );
}

export default memo(Compositions);
