import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import Pin2GiveForm from "../../components/Pin2GiveForm/Pin2GiveForm";
import { useServices } from "../../services/useServices";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import JSEncrypt from "jsencrypt";
import { encryptPublicLong } from "@lsqswl/rsaencrypt";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
// import { Container } from './styles';

function ChangePasswordOverlay({ handleClose, show }) {
  const { authenticationEndpoints } = useServices();
  const { publicKey } = useAuth();
  const alert = useAlert();
  const { t } = useTranslation(["profile"]);

  const { mutate: handleSubmit } = useMutation(
    (data) => {
      //* obter email e password
      console.log(data);
      console.log(publicKey);

      const { oldPassword, newPassword } = data;

      //* instaciar a biblioteca de encriptação
      //* e definir a public key
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);

      //* encryptação da password antiga
      const encryptOldPassword = encrypt.encrypt(oldPassword);

      //* encrypt nova password
      const encryptNewPassword = encrypt.encrypt(newPassword);

      //* construir a hash
      const x = encryptPublicLong(
        `${encryptOldPassword};;${encryptNewPassword}`,
        publicKey
      );
      return authenticationEndpoints.changePassword(x);
    },
    {
      onSuccess: (res) => {
        if (res.data === 1) {
          alert.success(t("alerts.changePasswordS"));
          formik.handleReset();
        } else {
          alert.error(t("alerts.changePasswordE"));
          formik.handleReset();
        }
        handleClose();
      },
    }
  );

  const formik = useFormik({
    initialValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(
        t("fields.currentPassword.errors.required")
      ),
      newPassword: Yup.string()
        .required(t("fields.newPassword.errors.required"))
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[0-9]).{6,}$/,
          t("fields.newPassword.errors.match")
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        t("fields.confirmPassword.errors.match")
      ),
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("buttons.changePassword")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs="6">
                <Pin2GiveForm.Input
                  label={t("fields.currentPassword.label")}
                  inputProps={{
                    type: "password",
                    name: "oldPassword",
                    value: formik.values?.oldPassword,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                  }}
                  error={formik.errors?.oldPassword}
                />
              </Col>
              <Col xs="6">
                <Pin2GiveForm.Input
                  label={t("fields.newPassword.label")}
                  inputProps={{
                    type: "password",
                    name: "newPassword",
                    value: formik.values?.newPassword,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                  }}
                  error={formik.errors?.newPassword}
                />
              </Col>
              <Col xs="12">
                <Pin2GiveForm.Input
                  label={t("fields.confirmPassword.label")}
                  inputProps={{
                    type: "password",
                    name: "confirmPassword",
                    value: formik.values?.confirmPassword,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                  }}
                  error={formik.errors?.confirmPassword}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            {t("buttons.cancel")}
          </Button>
          <Button type="submit" variant="pin2give">
            {t("buttons.change")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ChangePasswordOverlay;
