import Pin2GiveListHeader from "./Pin2GiveList.Header";
import { useTranslation } from "react-i18next";
import { P2GLContainer, Search } from "./Pin2GiveList.styles";

const Pin2GiveList = ({
  children,
  headerProps,
  sticky,
  search,
  handleSearch,
}) => {
  const { t } = useTranslation(["pin2givelist"]);
  return (
    <P2GLContainer.Container sticky>
      <Pin2GiveListHeader {...headerProps} />
      {search && (
        <Search.Container>
          <Search.Form.Control
            placeholder="Search..."
            onChange={handleSearch}
          />
        </Search.Container>
      )}
      <P2GLContainer.Content>
        {children ?? <div className="fw-bold text-center">{t("empty")}</div>}
      </P2GLContainer.Content>
    </P2GLContainer.Container>
  );
};

export default Pin2GiveList;
