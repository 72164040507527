export const interceptRequestPrivate = (isPrivateRoute, token) => {
  return {
    resolve: function (config) {
      if (isPrivateRoute(config)) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
      return config;
    },
    reject: function (error) {
      return Promise.reject(error);
    },
  };
};

export const interceptRequestPublic = () => {
  return {
    resolve: function (config) {
      if (!config.url.includes("/login_backend")) {
        delete config.headers["Authorization"];
      }
      return config;
    },
    reject: function (error) {
      return Promise.reject(error);
    },
  };
};

export const interceptResponse = (isPrivateRoute, token, setShow) => {
  return {
    resolve: function (response) {
      return response;
    },
    reject: function (error) {
      const { config } = error;

      //esta condição resolve o erro quando é feito o login e os dados estão
      //incorretos, aparece o modal.
      if (config?.url && config?.url.includes("login_backend")) {
        setShow(false);
      } else if (isPrivateRoute(config) && token) {
        setShow(true);
      }

      return Promise.reject(error);
    },
  };
};
