import React from "react";
import { Button } from "react-bootstrap";
import { P } from "../Element.styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function Seasons() {
  const navigate = useNavigate();
  const { t } = useTranslation(["informationPages/seasons"]);
  return (
    <>
      <P dangerouslySetInnerHTML={{ __html: t("body") }} />
      <div className="align-middle">
        <span>{t("buttons.sectionLabel")}</span>{" "}
        <Button
          variant="link"
          size="sm"
          onClick={() => navigate("/productsmanagement/seasons")}
        >
          {t("buttons.seasonCrossTable")}
        </Button>
      </div>
    </>
  );
}

export default Seasons;
