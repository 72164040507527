import axios from "axios";

export const ReferencesEndpoints = (initPath) => {
  const PATH = `${initPath}/referencesendpoint`;

  const getAllReferencesBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllReferencesBySupplier/${fkSupplier}`);

  const getAllImagesBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllImagesBySupplier/${fkSupplier}`);

  const getAllImagesByReference = async (fkReference) =>
    await axios.get(`${PATH}/getAllImagesByReference/${fkReference}`);

  const create_or_update_reference_image = async (data) =>
    await axios.put(`${PATH}/create_or_update_reference_image`, data);

  const getAllScalesBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllScalesBySupplier/${fkSupplier}`);

  const getAllScaleValuesByScale = async (fkScale) =>
    await axios.get(`${PATH}/getAllScaleValuesByScale/${fkScale}`);

  const create_or_update = async (data) =>
    await axios.post(`${PATH}/create_or_update`, data);

  const managementImage = async (data) =>
    await axios.post(`${PATH}/management_image`, data);

  const getAllImagesByIdProduct = async (fkProduct) =>
    await axios.get(`${PATH}/getAllImagesByIdProduct/${fkProduct}`);

  return {
    getAllReferencesBySupplier,
    getAllImagesBySupplier,
    getAllImagesByReference,
    create_or_update_reference_image,
    getAllScalesBySupplier,
    getAllScaleValuesByScale,
    create_or_update,
    managementImage,
    getAllImagesByIdProduct,
  };
};
