import React from "react";
import { useProductCategories } from "../../../contexts/ProductsManagement/ProductsCategories/useProductCategories";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";
import { newCategoryObject } from "../../../contexts/ProductsManagement/ProductsCategories/utils";
import { useTranslation } from "react-i18next";

function Categories() {
  //!-------------------------------------------------------------!\\
  //!---------------------------Contexts--------------------------!\\
  //!-------------------------------------------------------------!\\
  const {
    categories: dCategories, //* {data: [], selected: -1, setSelected: function}
    subCategories, //* {data: [], selected: -1, setSelected: function}
    subSubCategories, //* {data: [], selected: -1, setSelected: function}
    result, //* {data: [], init: [], setResult: function}
    fkProduct,
  } = useProductCategories();
  const { t } = useTranslation("pm/productsCategories");

  //!-------------------------------------------------------------!\\
  //!---------------------------Methods---------------------------!\\
  //!-------------------------------------------------------------!\\
  /**
   * * handle select
   * @param {number} fkCategory
   * @returns {void}
   */
  const handleSelect = (fkCategory) => {
    //* quando selecionar uma categoria
    //* os select da subCategorie e subSubCategorie
    //* será removido

    subCategories.setSelected(-1);
    subSubCategories.setSelected(-1);
    dCategories.setSelected(fkCategory);
  };

  /**
   * * Get Check
   * @param {number} fkCategory
   * @returns {boolean} false if not exist in result
   * @returns {boolean} true if exist in result
   */
  const getCheck = (fkCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe algum registo
    //* com o id fkCategory
    //* pretendido
    const isInRes = res?.find(
      (e) => e?.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se for undefined (não existe) retorna false
    if (isInRes === undefined) return false;

    return true;
  };

  /**
   * * Check Disabled
   * @param {number} fkCategory
   * @returns {boolean} false se for diferente de 0 em result
   * @returns {boolean} false se for igual a 0 em result
   * @returns {boolean} se não encontrar nenhum registo em result
   */
  const checkDisabled = (fkCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe algum registo
    //* com o id fkCategory
    //* pretendido
    const isInRes = res?.find(
      (e) => e?.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não encontrar nenhum registo (undefined) retorna false
    if (isInRes === undefined) return false;

    //* se existir e o campo "productVsCategoriesPK.fkSubcategory" for 0
    //* returna false (é possível utilizar)
    if (isInRes?.productVsCategoriesPK?.fkSubcategory === 0) return false;

    //* se for diferente de 0 retorna true (não é possível utilizar)
    return true;
  };

  /**
   * * Add Check
   * @param {number} fkCategory
   * @returns {boolean} fasle se não for possível submeter a categoria
   * @returns {boolean} true se for possível submeter a categoria
   */
  const addCheck = (fkCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe algum registo
    //* com o id fkCategory
    //* pretendido
    const isInRes = res?.find(
      (e) => e?.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se existir categoria em result retorna false
    //* não é possível adicionar a mesma categoria duas vezes
    if (isInRes !== undefined) return false;

    //* se não existir categoria
    //* terá de ser adicionada a categoria pretendida ao result
    const country_code = localStorage.getItem("country_code");
    const newObject = newCategoryObject(fkCategory, country_code, fkProduct);
    console.log(newObject);
    result.setResult((e) => [...e, newObject]);
    return true;
  };

  /**
   * * Remove Check
   * @param {number} fkCategory
   * @returns {boolean} true se for possível remover
   * @returns {boolean} false se não for possível remover
   */
  const removeCheck = (fkCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe algum registo
    //* com o id fkCategory
    //* pretendido
    const isInRes = res?.find(
      (e) => e?.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não existir retorna false
    //* não é possível remover uma categoria que já não existe
    if (isInRes === undefined) return false;

    //* se houver categoria pretendida, será removida
    const newRes = res.filter(
      ({ productVsCategoriesPK: pk }) => pk.fkCategory !== fkCategory
    );
    console.log(newRes);
    result.setResult(newRes);
    return true;
  };

  /**
   * * Handle Check
   * @param {number} fkCategory
   * @returns {boolean} false se não for possível adicionar ou remover
   * @returns {voi} true se for possível adicionar ou remover
   */
  const handleCheck = (fkCategory) => {
    //* verificar se a categoria tem check
    const isCheck = getCheck(fkCategory);

    //* se tiver check, a categoria será removida
    if (isCheck) {
      console.log("remove");
      return removeCheck(fkCategory);
    }

    //* se não tiver check, a categoria será adicionada
    console.log("add");
    return addCheck(fkCategory);
  };

  return (
    <Pin2GiveList
      headerProps={{
        title: t("lists.categories.label"),
        headerButton: {},
      }}
    >
      {dCategories?.data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          title={e?.name}
          check
          onCheck={() => handleCheck(e?.id)}
          checkDisabled={checkDisabled(e?.id)}
          onClick={() => handleSelect(e?.id)}
          checked={getCheck(e?.id)}
          selected={e?.id === dCategories.selected}
        />
      ))}
    </Pin2GiveList>
  );
}

export default Categories;
