import lodash from "lodash";

export const formatRegisterValues = (values, language, password) => {
  let data = {
    fktitle: Number(values.fkPtitle),
    name: values.name,
    nif: values.nif ?? "",
    cc: values.cc ?? "",
    email: values.email,
    mobile: values.mobile,
    phone: values.phone,
    nib: values.nib,
    fk_type: 2,
    state: 1,
    fkLanguage: `${language}`.toUpperCase(),
    password: password,
  };

  if (lodash.isEmpty(data.cc)) delete data.cc;
  if (lodash.isEmpty(data.nif)) delete data.nif;
  if (lodash.isEmpty(data.nib)) delete data.nib;

  return data;
};

export const encodeRole = (role) => {
  return btoa(role);
};
