import "./ButtonScroll.css";
import { FaArrowUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

const ButtonScrollLink = () => {
  return (
    <ScrollToTop
      smooth
      component={<FaArrowUp />}
      className="app-button-scroll"
    />
  );
};

export default ButtonScrollLink;
