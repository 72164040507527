//? REACT-BOOTSTRAP
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";

//? COMPONENTS
import { CardStore } from "../../../components/Cards/Cards";
import { ProductCarousel } from "../../../components/Carousel/Carousel";

//? REACT-I18NEXT
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import lodash from "lodash";
import PhonePreview from "../../../components/PhonePreview/PhonePreview";
import NotFoundImg from "../../../assets/images/notfound.png";
import { useParams } from "react-router-dom";
import { useServices } from "../../../services/useServices";

//?-----------------PAGE----------------------
const Preview = () => {
  //* i18next
  const { t } = useTranslation(["pm/preview"]);
  const { productsEndpoints, getLanguage } = useServices();

  const { fkProduct } = useParams();

  //* endpoints
  const { data: productDetails } = useQuery(
    ["getProductDetails", fkProduct],
    () =>
      productsEndpoints.detailsproduct(
        fkProduct,
        getLanguage(),
        localStorage.getItem("country_code")
      ),
    {
      enabled: fkProduct !== undefined,
    }
  );

  //* render
  return (
    <Modal
      show={true}
      fullscreen
      className="app-productsmanagement-preview"
      onHide={() => window.close()}
    >
      <Modal.Header closeButton>
        <Modal.Title className=" align-self-center ms-auto">
          <div className="app-title  mx-auto">{t("title")}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lodash.isEmpty(productDetails?.data) ? (
          <Alert variant="danger">
            <p>{t("alert.1")}</p>
            <p>
              <b>{t("alert.2")} </b> {t("alert.3")}
            </p>
          </Alert>
        ) : (
          <Container>
            {
              //!-----------------------------------------!\\
              //!----------------DETAILS------------------!\\
              //!-----------------------------------------!\\
            }
            <Row className="mb-5">
              <Col xs="12" className="mb-5">
                <div className="app-sub-title text-center">{t("details")}</div>
              </Col>
              <Col xs="10" sm="8" md="6" lg="5" xl="4" className="mb-5">
                {
                  //* ProductsCarousel *\\
                }
                <ProductCarousel
                  images={
                    lodash.isEmpty(productDetails?.data?.product_photos)
                      ? [NotFoundImg]
                      : productDetails?.data?.product_photos
                  }
                />
              </Col>
              <Col xs="12" md="10" lg="7" xl="8">
                <Container fluid className="d-flex flex-column">
                  <Row className="justify-content-between flex-column-reverse flex-lg-row">
                    <Col
                      xs="12"
                      lg="9"
                      className="mb-3 overflow-hidden flex-column d-flex"
                    >
                      <span className="app-title ">
                        {productDetails?.data?.title_product}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p
                        className="productdetails-description"
                        dangerouslySetInnerHTML={{
                          __html: productDetails?.data?.description_product,
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            {
              //!----------------------------------------!\\
              //!----------------CARDSTORE---------------!\\
              //!----------------------------------------!\\
            }
            <Row className="mb-5 justify-content-center">
              <Col xs="12">
                <div className="app-sub-title mb-3 text-center">
                  {t("card")}
                </div>
              </Col>
              <Col xs="10" sm="6" md="5" lg="4" xl="3" className="mb-5 px-4">
                <CardStore
                  title={productDetails?.data?.title_product}
                  image={productDetails?.data?.product_photos[0] || NotFoundImg}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs="12" className="text-center mb-3">
                <div className="app-sub-title mb-3">{t("mobilePreview")}</div>
              </Col>
              <Col
                xs="12"
                sm="6"
                className="d-flex justify-content-center mb-3"
              >
                <PhonePreview
                  title={productDetails?.data?.title_product}
                  image={productDetails?.data?.product_photos[0] || NotFoundImg}
                />
              </Col>
              <Col
                xs="12"
                sm="6"
                className="d-flex justify-content-center mb-3"
              >
                <PhonePreview
                  type="details"
                  title={productDetails?.data?.title_product}
                  image={productDetails?.data?.product_photos[0] || NotFoundImg}
                  description={productDetails?.data?.description_product}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Preview;
