import { Popover as PopoverB } from "react-bootstrap";
import styled from "styled-components";

export const Popover = styled(PopoverB)`
  max-width: 500px !important;
  & .popover-body {
    max-height: 400px !important;
    overflow-y: auto;
  }
`;

export const P = styled.p`
  margin-top: 0.8rem;
`;
