import "./Footer.css";
const Footer = () => {
  return (
    <footer className="app-footer">
      &#169; Copyright <a href="/">pin2give</a>. All rights reserved.
    </footer>
  );
};

export default Footer;
