import * as Yup from "yup";
/*
export const validationSchema = () =>
  Yup.object().shape({
    seasonCrosstablePK: Yup.object({
      fkCountry: Yup.number()
        .required("Country is required!")
        .min(0, "Country is required!"),
    }),
    discount: Yup.number()
      .required("Discount is required!")
      .moreThan(-1, "Discount must be between 0 and 100")
      .lessThan(101, "Discount must be between 0 and 100"),
    pvp: Yup.number()
      .required("pvp is required")
      .test("is-decimal", "Incorrect decimal value!", (value) =>
        (value + "").match(/^\d+(\.\d{0,2})?$/)
      )
      .typeError("Price must be number!"),
    vat: Yup.number()
      .moreThan(-1, "VAT must be between 0 and 100")
      .lessThan(101, "VAT must be between 0 and 100"),
    profit: Yup.number()
      .moreThan(-1, "VAT must be between 0 and 100")
      .lessThan(101, "VAT must be between 0 and 100"),
  });*/

export const validationSchema = (references = [], t) => {
  const object = {};

  references.forEach((e, index) => {
    object[`form${index}`] = Yup.object({
      discount: Yup.number()
        .required("Discount is required!")
        .min(0, "Discount must be between 0 and 100")
        .max(99, "Discount must be between 0 and 100"),
      pvp: Yup.number()
        .required("pvp is required")
        .test("is-decimal", "Incorrect decimal value!", (value) =>
          (value + "").match(/^\d+(\.\d{0,2})?$/)
        )
        .typeError("Price must be number!"),
      profit: Yup.number()
        .min(0, "VAT must be between 0 and 100")
        .max(99, "VAT must be between 0 and 100"),
      images: Yup.array()
        .required("Images is Required")
        .min(1, "Images is Required!"),
    });
  });

  return Yup.object().shape(object);
};
