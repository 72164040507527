import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { useServices } from "../../../../services/useServices";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function IntroduceEmail() {
  //!-------------------------------------------------------!\\
  //!-------------------------hooks-------------------------!\\
  //!-------------------------------------------------------!\\
  //* services
  const { authenticationEndpoints } = useServices();

  //* translations
  const { t } = useTranslation(["recoverPassword"]);

  //!-------------------------------------------------------!\\
  //!------------------------states-------------------------!\\
  //!-------------------------------------------------------!\\
  //* email
  const [email, setEmail] = useState("");

  //* valid
  const [valid, setValid] = useState(false);

  //* navigate
  const navigate = useNavigate();

  //!-------------------------------------------------------!\\
  //!------------------------methods------------------------!\\
  //!-------------------------------------------------------!\\
  //* handle change email
  const handleChange = (e) => {
    const value = e.currentTarget.value;
    setEmail(value);
  };

  //* validation schema
  const validation = Yup.string()
    .required(t("fields.email.errors.required"))
    .email(t("fields.email.errors.email"));

  //!-------------------------------------------------------!\\
  //!------------------------services-----------------------!\\
  //!-------------------------------------------------------!\\
  const sendEmail = useMutation(
    (email) => authenticationEndpoints.recoveryPassword(email),
    {
      onSuccess: () => navigate("/user/recoverpassword?type=1"),
    }
  );

  //!-------------------------------------------------------!\\
  //!-----------------------useEffects----------------------!\\
  //!-------------------------------------------------------!\\
  //* quando o email é alterado
  //* é feita a validação do email
  useEffect(() => {
    validation
      .validate(email)
      .then(() => setValid(""))
      .catch((error) => setValid(error?.message || ""));
  }, [email]);

  return (
    <div>
      <Pin2GiveForm.Input
        label={t("fields.email.label")}
        inputProps={{ onChange: handleChange, value: email || "" }}
        error={valid}
      />
      <div className="text-end">
        <Button
          variant="success"
          size="sm"
          onClick={() => sendEmail.mutate(email)}
          disabled={!isEmpty(valid)}
        >
          {t("buttons.next")}
        </Button>
      </div>
    </div>
  );
}

export default IntroduceEmail;
