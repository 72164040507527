//--------------------------------------------------\\
//-----------------------REACT----------------------\\
//--------------------------------------------------\\
import { createContext, useEffect, useState } from "react";

//--------------------------------------------------\\
//--------------------REACT-QUERY-------------------\\
//--------------------------------------------------\\
import { useMutation, useQuery } from "react-query";

//--------------------------------------------------\\
//----------------------LODASH----------------------\\
//--------------------------------------------------\\
import lodash from "lodash";

//--------------------------------------------------\\
//--------------------COMPONENTS--------------------\\
//--------------------------------------------------\\
import Loading from "../Loading";
import ProtocolError from "./ProtocolError";
import ModalRemove from "./ModalRemove";
import Products from "./_lists/Products";
import Compositions from "./_lists/Compositions";
import Seasons from "./_lists/Seasons";
import Header from "../Header";

//--------------------------------------------------\\
//----------------------I18NEXT---------------------\\
//--------------------------------------------------\\
import { useTranslation } from "react-i18next";

//--------------------------------------------------\\
//----------------------ACTIONS---------------------\\
//--------------------------------------------------\\
import { formatValues } from "./_actions/_actions";
import { getReferencesByCompositionList } from "./_actions/references";

//--------------------------------------------------\\
//------------------REACT-BOOTSTRAP-----------------\\
//--------------------------------------------------\\
import { Col, Container, Row } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useServices } from "../../../services/useServices";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { useLocation, useNavigate } from "react-router-dom";
import SeasonForm from "./_forms/SeasonForm";
import CountryList from "./_lists/CountryList";
import CompositionPreview from "./CompositionPreview";
import { getSeasonCrossTableByCountry } from "./_actions/seasonCrossTable";
import OverlayConfirm from "../OverlayConfirm";

//*------------------------------------------------*\\
//*------------------CREATECONTEXT-----------------*\\
//*------------------------------------------------*\\
export const sctContext = createContext({});

//*------------------------------------------------*\\
//*----------------SEASONCROSSTABLE----------------*\\
//*------------------------------------------------*\\
const SeasonCrossTable = () => {
  //!-------------------------------------------------!\\
  //!-----------------useTransalition-----------------!\\
  //!-------------------------------------------------!\\
  const { t } = useTranslation(["pm/sct"]);

  //!-------------------------------------------------!\\
  //!--------------------useAlert---------------------!\\
  //!-------------------------------------------------!\\
  const alert = useAlert();

  //!-------------------------------------------------!\\
  //!---------------------contexts--------------------!\\
  //!-------------------------------------------------!\\
  //* productsManagement context
  const { fkSupplier, protocol } = usePM();

  //* services
  const {
    productsEndpoints,
    productCompositionsEndpoints,
    referencesEndpoints,
    seasonsEndpoints,
    seasonCrossTableEndpoints,
    getLanguage,
  } = useServices();

  //* authentication context
  const { role } = useAuth();

  //* navigate
  const navigate = useNavigate();

  //* location
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  //!-------------------------------------------------!\\
  //!-------------------init values-------------------!\\
  //!-------------------------------------------------!\\
  //* modal remove
  const initModalRemove = {
    show: false,
    onRemove: undefined,
    word: "",
  };

  //!-------------------------------------------------!\\
  //!--------------------useStates--------------------!\\
  //!-------------------------------------------------!\\
  //* modals
  const [remove, setRemove] = useState(initModalRemove);

  //* seasons
  const [sSelected, setSSelected] = useState(-1);

  //* products
  const [pSelected, setPSelected] = useState(-1);

  //* compositions
  const [cSelected, setCSelected] = useState(-1);

  //* references
  const [rSelected, setRSelected] = useState("");

  //* references by composition
  const [aRefs, setARefs] = useState([]);

  //* countries
  const [CountrySelected, setCountrySelected] = useState(-1);

  //* overlay confirm
  const [confirm, setConfirm] = useState({
    show: false,
    v2: true,
    title: "",
    content: "<p></p>",
    onClick: () => {},
  });
  const handleCloseConfirm = () => setConfirm((e) => ({ ...e, show: false }));

  //!-------------------------------------------------!\\
  //!-------------------MODAL REMOVE------------------!\\
  //!-------------------------------------------------!\\
  const handleRemove = () => setRemove({ ...remove, show: !remove.show });

  //!-------------------------------------------------!\\
  //!---------------------SEASONS---------------------!\\
  //!-------------------------------------------------!\\
  //* get Seasons
  const { isLoading, data: seasons } = useQuery(
    ["getSeasons", fkSupplier],
    () => seasonsEndpoints.getAllSeasonBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1 || (role !== 2 && protocol?.type_ !== 1),
      onSuccess: (response) => {
        //? obter o id da season que
        //? fica selecionado por default
        if (
          (lodash.isEmpty(response.data) &&
            protocol?.type_ === 1 &&
            role === 2) ||
          (lodash.isEmpty(response.data) && role === 4)
        ) {
          alert.error(t("alerts.errors.redirect"));
          return navigate("/productsmanagement/createseasons");
        }

        if (queryParams.get("fkse") !== null) {
          const object = response.data.find(
            (e) => e.idSeason === Number(queryParams.get("fkse"))
          );
          if (object !== undefined) {
            setSSelected(object?.idSeason);
          } else {
            setSSelected(response.data[0].idSeason);
          }
        } else {
          setSSelected(response.data[0]?.idSeason);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  //!-------------------------------------------------!\\
  //!---------------------PRODUCTS--------------------!\\
  //!-------------------------------------------------!\\
  //* get Products
  const { data: products } = useQuery(
    ["getProductsApprove", fkSupplier],
    () => productsEndpoints.getAllProductApproveBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1 || (role !== 2 && protocol.type_ !== 1),
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const fkp = queryParams.get("fkp");

        if (fkp !== null && fkp !== undefined) {
          const object = (response.data || []).find(
            (e) => e.idProduct === Number(fkp)
          );

          if (object !== undefined) {
            setPSelected(object.idProduct);
          }
        }
      },
    }
  );

  //!-------------------------------------------------!\\
  //!-------------------COMPOSITIONS------------------!\\
  //!-------------------------------------------------!\\
  //* get Compositions
  const { data: compositions } = useQuery(
    ["getCompositions", pSelected],
    () =>
      productCompositionsEndpoints.getAllCompositionsWithReferencesByProduct(
        pSelected
      ),
    {
      enabled: pSelected !== -1,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const fkc = queryParams.get("fkc");

        if (fkc !== null || fkc !== undefined) {
          const object = (response.data || []).find(
            (e) => e.idComposition === Number(fkc)
          );

          if (object !== undefined) {
            setCSelected(object.idComposition);
          }
        }
      },
    }
  );

  //!-------------------------------------------------!\\
  //!--------------------REFERENCES-------------------!\\
  //!-------------------------------------------------!\\
  //* get references
  const { data: references } = useQuery(
    ["getReferences", fkSupplier],
    () => referencesEndpoints.getAllReferencesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!---------------------------------------------------------!\\
  //!----------------Compositions & References----------------!\\
  //!---------------------------------------------------------!\\
  const [rLength, setRLength] = useState({ length: null, index: null });

  //* get references by composition
  useQuery(
    ["getCompositionReferences", cSelected],
    () => productCompositionsEndpoints.getAllReferencesByComposition(cSelected),
    {
      enabled: cSelected !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (response) => {
        setARefs(
          getReferencesByCompositionList(response.data, references?.data)
        );
        setRLength({ length: response.data?.length, index: 0 });
      },
    }
  );

  //!---------------------------------------------------------!\\
  //!-----------Compositions & References & Images------------!\\
  //!---------------------------------------------------------!\\
  const compRefImgs = useQuery(
    ["getImagesReferenceByComposition", cSelected],
    () =>
      seasonCrossTableEndpoints.getAllImagesByComposition(
        fkSupplier,
        cSelected
      ),
    {
      enabled: cSelected !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const [imagesByReference, setImagesByReferences] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  useEffect(() => {
    if (rLength.index !== null && rLength.index < rLength.length) {
      setLoadingImages(true);
      referencesEndpoints
        .getAllImagesByReference(aRefs[rLength.index]?.reference)
        .then((response) => {
          setImagesByReferences((e) => [
            ...(rLength.index === 0 ? [] : e),
            ...response.data,
          ]);
          setRLength((e) => ({ ...e, index: e.index + 1 }));

          if (rLength.index === rLength.length - 1) setLoadingImages(false);
        });
    }
  }, [rLength, aRefs]);

  //!------------------------------------------------!\\
  //!--------------------countries-------------------!\\
  //!------------------------------------------------!\\
  //* endpoint
  const { data: countries } = useQuery("getCountries", () =>
    seasonCrossTableEndpoints.getAllCountries(getLanguage())
  );

  //!------------------------------------------------!\\
  //!-----------------Get SCT IMAGES-----------------!\\
  //!------------------------------------------------!\\
  const sctImages = useQuery(
    ["getSCTImages", CountrySelected],
    () =>
      seasonCrossTableEndpoints.getAllSeasonCrosstableImage(
        fkSupplier,
        cSelected,
        CountrySelected,
        sSelected
      ),
    {
      enabled: CountrySelected !== -1,
    }
  );

  //!------------------------------------------------!\\
  //!----------------SEASONCROSSTABLE----------------!\\
  //!------------------------------------------------!\\
  //* get season crosstable
  const { data: seasonCrossTable, refetch: refetchSCT } = useQuery(
    ["getSeasonCrossTable", sSelected],
    () => seasonCrossTableEndpoints.getAllSeasonCrosstableBySeason(sSelected),
    {
      enabled: fkSupplier !== -1 && sSelected !== undefined && sSelected !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!------------------------------------!\\
  //!---------------IMAGES---------------!\\
  //!------------------------------------!\\
  //const [images, setImages] = useState([]);
  const supplierImages = useQuery(
    ["getSupplierImages", fkSupplier],
    () => referencesEndpoints.getAllImagesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!-------------------------------------------------!\\
  //!----------------------CREATES--------------------!\\
  //!-------------------------------------------------!\\
  //* create seasoncrosstable image reference
  const createImage = useMutation((values) =>
    seasonCrossTableEndpoints.create_season_crosstable_image(values)
  );

  //* delete seasonCrossTable image reference
  const deleteImage = useMutation((values) =>
    seasonCrossTableEndpoints.delete_season_crosstable_image(values)
  );

  //* handle Submit
  const { mutate: saveObject } = useMutation(
    (values) => {
      return seasonCrossTableEndpoints.create_or_update(formatValues(values));
    },
    {
      onSuccess: (response, variables) => {
        //* só serão adicionados dados à seasonCrossTableImage
        //* se o buttão save for clicado

        //* verificar se isSave === true
        const isSave = variables.find((e) => e.isSave === true);
        if (isSave !== undefined) {
          //* obter a seasoncrosstable enviada para um determinado país
          const sctUpdated = getSeasonCrossTableByCountry(
            variables,
            pSelected,
            cSelected,
            CountrySelected
          );
          //* obter a lista de imagens da seasonCrossTable que vem no serviço
          const dSctImages = sctImages.data?.data ?? [];

          //* construir o objeto de seasonCrossTable image
          let sctImage = [];

          //* construir o objeto de seasonCrossTable image com os ids que serão removidos
          let sctImageRemove = [];

          //* interar sobre a lista de referências que foram inseridos para um país
          sctUpdated.forEach((e) => {
            const pk = e.seasonCrosstablePK;

            //* lista dos ids das imagens atualizados
            const images = e.images ?? [];

            //* iterar sobre a lista de imagens
            images.forEach((i) => {
              //* adicionar os objetos que serão enviados a sctImages
              //* se o objeto existir em dSctImages, o mesmo não será enviado

              const exist = dSctImages.find((scti) => {
                const a = scti.seasonCrosstableImagePK;
                return (
                  a.image === i.image &&
                  pk.fkReference === a.fkReference &&
                  i.enabled === scti.enabled
                );
              });

              if (exist === undefined) {
                sctImage.push({
                  seasonCrosstableImagePK: {
                    fkSeason: pk.fkSeason,
                    fkCountry: pk.fkCountry,
                    fkProduct: pk.fkProduct,
                    fkComposition: pk.fkComposition,
                    fkReference: i.reference,
                    image: i.image,
                  },
                  dimage: 0,
                  enabled: i.enabled,
                });
              }
            });

            //* iterar sobre a lista de seasonCrossTableImage que vem no serviço
            dSctImages.forEach((e) => {
              if (e.seasonCrosstableImagePK.fkReference === pk.fkReference) {
                //* se o idimage do objeto não houver na lista de images
                //* o objeto será removido
                const exist = images.find(
                  (i) => i.image === e.seasonCrosstableImagePK.image
                );

                if (exist === undefined) {
                  sctImageRemove.push(e);
                }
              }
            });
          });

          //* adicionar valores à seasonCrosstableImages
          sctImage.forEach((e) => {
            createImage.mutate(e, {
              onSettled: () => {
                refetchSCT();
                sctImages.refetch();
              },
            });
          });

          //* remover valores da seasonCrossTable image
          sctImageRemove.forEach((e) =>
            deleteImage.mutate(e, {
              onSettled: () => {
                sctImages.refetch();
              },
            })
          );
        }

        refetchSCT();

        //setRLength((e) => ({ ...e, index: 0 }));
        //refetchCR();
        return alert.success(t("alerts.success.submit.content"));
      },
    }
  );

  //!---------------------------------------------------!\\
  //!-------------------PROTOCOL ERROR------------------!\\
  //!---------------------------------------------------!\\
  if (protocol?.type_ === 0 && role === 2) {
    return <ProtocolError />;
  }

  //!-----------------------------------------------!\\
  //!-------------------COMPONENTS------------------!\\
  //!-----------------------------------------------!\\
  return (
    <sctContext.Provider
      value={{
        seasons: {
          data: seasons?.data,
          selected: sSelected,
          setSelected: setSSelected,
        },
        products: {
          data: products?.data,
          selected: pSelected,
          setSelected: setPSelected,
        },
        compositions: {
          data: compositions?.data,
          selected: cSelected,
          setSelected: setCSelected,
        },
        references: {
          selected: rSelected,
          setSelected: setRSelected,
          setARefs: setARefs,
          data: aRefs,
        },
        countries: {
          data: countries?.data ?? null,
          selected: CountrySelected,
          setSelected: setCountrySelected,
        },
        sctImages: {
          ...sctImages,
          data: sctImages.data?.data,
          initImages: imagesByReference,
          supplierImages: supplierImages?.data?.data,
          loadingImages: loadingImages,
        },
        modalRemove: {
          setRemove,
          remove,
        },
        sct: seasonCrossTable?.data ?? [],
        saveObject: saveObject,
        confirm: {
          ...confirm,
          set: setConfirm,
        },
        referenceImages: {
          data: compRefImgs.data?.data,
        },
      }}
    >
      <Container className="mt-5">
        <Header
          left={{ menuButton: true, backButton: true }}
          center={{ title: t("title") }}
          infoKey="seasonCrossTable"
        />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col xs="12" md="3" className="mb-5">
                <Seasons />
              </Col>
              <Col xs="12" md="3" className="mb-5">
                <Products />
              </Col>
              <Col xs="12" md="3" className="mb-5">
                <Compositions />
              </Col>
              <Col xs="12" md="3" className="mb-5">
                <CountryList />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" md={CountrySelected === -1 ? "12" : "3"}>
                <CompositionPreview />
              </Col>
              <Col xs="9">
                <SeasonForm />
              </Col>
            </Row>
          </>
        )}
      </Container>
      <ModalRemove
        show={remove.show}
        handleRemove={handleRemove}
        title={remove?.title}
        onRemove={remove.onRemove}
        content={remove?.content}
      />
      <OverlayConfirm
        show={confirm.show}
        v2={confirm.v2}
        title={confirm.title}
        content={confirm.content}
        primaryButtonLabel={confirm.primaryButtonLabel}
        handleCloseConfirm={handleCloseConfirm}
        onSelect={confirm.onClick}
      />
    </sctContext.Provider>
  );
};

export default SeasonCrossTable;
