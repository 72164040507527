/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InformartionPage from "../../../../components/InformartionPage/InformationPage";
import useSuppliers from "../../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import lodash from "lodash";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import { FaEuroSign, FaPercentage } from "react-icons/fa";
import { newProtocol } from "../_actions/_data";

function FormProtocol({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  setValues,
}) {
  //!-------------------------------------------------!\\
  //!----------------------hooks----------------------!\\
  //!-------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/suppliers"]);

  //* supplier
  const { forms, protocol, suppliers } = useSuppliers();

  //* auth
  const { role } = useAuth();

  //!-------------------------------------------------!\\
  //!----------------------states---------------------!\\
  //!-------------------------------------------------!\\
  //* editable
  const [editable, setEditable] = useState(false);

  //* show by type protocol
  const [showByFkProtocol, setShowByFkProtocol] = useState(false);

  //!-------------------------------------------------!\\
  //!---------------------methods---------------------!\\
  //!-------------------------------------------------!\\
  const getBadge = (enabled) => {
    switch (enabled) {
      case 0:
        return (
          <Badge pill bg="danger">
            Rejeitado
          </Badge>
        );
      case 1:
        return (
          <Badge pill bg="success">
            Aprovado
          </Badge>
        );
      case 2:
        return (
          <Badge pill bg="warning" text="dark">
            Pendente
          </Badge>
        );
      default:
        return (
          <Badge pill bg="secondary">
            Criado/editado
          </Badge>
        );
    }
  };

  const getButtonsEnabled = (enabled) => {
    switch (enabled) {
      case 1:
        return (
          !editable && (
            <Button
              variant="primary"
              size="sm"
              className="ms-auto"
              onClick={() => {
                setValues(newProtocol(suppliers.selected.idSupplier ?? -1));
                forms.setEditable(true);
              }}
            >
              Novo Protocolo
            </Button>
          )
        );
      case 2:
        return (
          role === 4 &&
          !editable && (
            <div className="ms-auto">
              <Button
                size="sm"
                className="me-2"
                variant="danger"
                onClick={() => forms.handleEnabledProtocol(values, 0)}
              >
                Rejeitar
              </Button>
              <Button
                size="sm"
                variant="success"
                onClick={() => forms.handleEnabledProtocol(values, 1)}
              >
                Aprovar
              </Button>
            </div>
          )
        );
      case 3:
        return (
          !editable && (
            <Button
              size="sm"
              variant="warning"
              className="ms-auto"
              onClick={() => forms.handleEnabledProtocol(values, 2)}
            >
              Submeter para revisão
            </Button>
          )
        );
      default:
        return <></>;
    }
  };

  //!-------------------------------------------------!\\
  //!--------------------useEffects-------------------!\\
  //!-------------------------------------------------!\\
  useEffect(() => {
    if (values.enabled !== 1 && forms.editable === true) {
      return setEditable(true);
    }
    return setEditable(false);
  }, [values.enabled, forms.editable]);

  useEffect(() => {
    const fkProtocol = values?.type_;

    if (fkProtocol === 1) {
      return setShowByFkProtocol(true);
    }

    return setShowByFkProtocol(false);
  }, [values.type_]);

  return (
    <Container fluid className="mt-5">
      <Row>
        <Col xs="12">
          <div className="d-flex  border-bottom pb-2  mb-4 align-items-center">
            <h4 className="app-sub-title m-0">
              {t("protocol.title")} <InformartionPage infoKey="priceProfit" />
            </h4>
            {getBadge(values.enabled)}
            {getButtonsEnabled(values.enabled)}
          </div>
        </Col>
      </Row>
      {lodash.isEmpty(protocol.data) && !editable ? (
        <Row>
          <Col xs="12">{t("protocol.na")}</Col>
        </Row>
      ) : (
        <Row>
          <Col xs="12" className="mb-4">
            <Form.Label>{t("protocol.fields.protocol.label")}</Form.Label>
            <Form.Check
              type="radio"
              name="protocol.type_"
              value="1"
              label="Preço Fixo"
              id="fkProtocol-precoFixo"
              disabled={!editable}
              checked={Number(values?.type_) === 0}
              onChange={() => setFieldValue("protocol.type_", 0)}
            />
            <Form.Check
              type="radio"
              name="protocol.type_"
              value="2"
              checked={Number(values?.type_) === 1}
              label="Margem Fixa"
              onChange={() => setFieldValue("protocol.type_", 1)}
              id="fkProtocol-margemFixa"
              disabled={!editable}
            />
          </Col>
          {showByFkProtocol ? (
            <Col xs="12" md="2">
              <Pin2GiveForm.Input
                label={t("protocol.fields.dcomission.label")}
                inputProps={{
                  name: "protocol.dcomission",
                  help: (
                    <div className="text-muted ">
                      <FaPercentage />
                    </div>
                  ),
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values?.dcomission,
                  readOnly: !editable,
                }}
                error={errors?.dcomission}
              />
            </Col>
          ) : (
            <></>
          )}
          <Col xs="12" md="5">
            <Pin2GiveForm.Input
              label={t("protocol.fields.dpreparationTime.label")}
              inputProps={{
                name: "protocol.dpreparationTime",
                onChange: handleChange,
                onBlur: handleBlur,
                value: values?.dpreparationTime ?? 1,
                help: (
                  <div className="text-muted fw-bold">
                    {t("protocol.fields.dpreparationTime.days")}
                  </div>
                ),
                readOnly: !editable,
              }}
              error={errors?.dpreparationTime}
            />
          </Col>
          <Col xs="12" md="5">
            <Pin2GiveForm.Input
              label={t("protocol.fields.wrapprice.label")}
              inputProps={{
                name: "protocol.wrapprice",
                onChange: handleChange,
                onBlur: handleBlur,
                value: values?.wrapprice ?? 0,
                help: (
                  <div className="text-muted">
                    <FaEuroSign />
                  </div>
                ),
                readOnly: !editable,
              }}
              error={errors?.wrapprice}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default FormProtocol;
