//? REACT-BOOTSTRAP
import { Form } from "react-bootstrap";

//? PHONE INPUT
import PhoneInput from "react-phone-number-input";

//!----------------------------------------------------------------------!\\
//!--------------------------PIN2GIVEPHONEINPUT--------------------------!\\
//!----------------------------------------------------------------------!\\
export const MyPhoneInput = ({ label, inputProps, error }) => {
  return (
    <Form.Group className="mb-4">
      <Form.Label>{label}</Form.Label>
      <PhoneInput
        className={`form-control  ${error ? "is-invalid" : ""}`}
        {...inputProps}
      />
      <Form.Control.Feedback type={error ? "invalid" : "valid"}>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default MyPhoneInput;
