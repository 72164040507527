/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../PMProvider/usePM";
import {
  convertFormToProductLanguages,
  convertProductLanguagesToForm,
  formatProduct,
  newProduct,
  useSteps,
} from "./utils";
import lodash, { omit } from "lodash";
import { Alert } from "react-bootstrap";
import { useFormik } from "formik";
import {
  validationSchema,
  validationSchemaLanguages,
} from "../../../_yup/ProductsManagement/Products";
import Products from "../../../pages/ProductsManagement/Products/Products";
import { useAuth } from "../../AuthProvider/useAuth";
import { useLocation } from "react-router-dom";

export const productContext = createContext({});

function ProductsProvider() {
  //!----------------------------------------------------!\\
  //!----------------------contexts----------------------!\\
  //!----------------------------------------------------!\\
  //* product management
  const { fkSupplier } = usePM();

  //* auth
  const { role } = useAuth();

  //* endpoints
  const {
    productsEndpoints,
    referencesEndpoints,
    productLanguagesEndpoints,
    producersEndpoints,
    propertyEndpoints,
  } = useServices();

  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/products", "pm/overlayConfirm"]);

  //* alert
  const alert = useAlert();

  //* location
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  //!----------------------------------------------------!\\
  //!----------------------editable----------------------!\\
  //!----------------------------------------------------!\\
  const [editable, setEditable] = useState(false);

  //!----------------------------------------------------!\\
  //!----------------------producers---------------------!\\
  //!----------------------------------------------------!\\
  const { data: producers } = useQuery(["getProducers", fkSupplier], () =>
    producersEndpoints.getAllProducersBySupplier(fkSupplier)
  );

  //!----------------------------------------------------!\\
  //!----------------------products----------------------!\\
  //!----------------------------------------------------!\\
  //* selected product
  const [selected, setSelected] = useState(null);

  const products = useQuery(
    ["getProducts", fkSupplier],
    () => productsEndpoints.getAllProductsBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!----------------------------------------------------!\\
  //!------------------productLanguages------------------!\\
  //!----------------------------------------------------!\\
  const [productsLanguages, setProductsLanguages] = useState(null);

  const initProductsLanguages = useQuery(
    ["getProductLanguages", selected],
    () =>
      productLanguagesEndpoints.getAllLanguagesByProduct(selected?.idProduct),
    {
      enabled: selected !== null,
      onSuccess: (response) => {
        return setProductsLanguages(
          convertProductLanguagesToForm(response.data)
        );
      },
    }
  );

  //!----------------------------------------------------!\\
  //!---------------------priorities---------------------!\\
  //!----------------------------------------------------!\\
  const getPropertyOperatorPin2GiveByProduct = useQuery(
    ["getPropertyOperatorPin2GiveByProduct", selected],
    () =>
      propertyEndpoints.getPropertyOperatorPin2GiveByProduct(
        selected?.idProduct
      ),
    {
      enabled: role === 4 && selected !== null,
    }
  );

  const addPropertiesOperator = useMutation(
    (data) => propertyEndpoints.addPropertyOperatorPin2Give(data),
    {
      onSuccess: (response) => {
        if (response.data === 1) {
          return alert.error("Erro a processar a expressão!");
        }

        if (response.data === 2) {
          return alert.error("Erro de leitura!");
        }
      },
      onSettled: getPropertyOperatorPin2GiveByProduct.refetch,
    }
  );

  //!----------------------------------------------------!\\
  //!-----------------------images-----------------------!\\
  //!----------------------------------------------------!\\
  const { data: images } = useQuery(
    ["getSupplierImages", selected],
    () => referencesEndpoints.getAllImagesByIdProduct(selected?.idProduct),
    {
      enabled: selected !== null,
    }
  );

  //!----------------------------------------------------!\\
  //!--------------------handleSubmit--------------------!\\
  //!----------------------------------------------------!\\
  //* create product language
  const createProductLanguage = useMutation((prodLang) => {
    return productLanguagesEndpoints.create_or_update(prodLang);
  });

  //* create product
  const createProduct = useMutation(
    (np) => {
      delete np.fkProtocol;
      return productsEndpoints.create_or_update(np);
    },
    {
      onSuccess: (data, variables) => {
        if (!lodash.isEmpty(data?.data)) {
          formikLanguages.submitForm().then((form) => {
            alert.success(
              <>
                <Alert.Heading>
                  {t("alerts.success.submit.title")}
                </Alert.Heading>
                <p>{t("alerts.success.submit.message")}</p>
              </>
            );

            if (role === 4) {
              //* se for role 4 altera os dados
              const abc = {
                fk_product: data.data.idProduct,
                cexpr_pua: variables.str1 || "",
                cexpr_pe: variables.str2 || "",
              };

              addPropertiesOperator.mutate(abc);
            }

            //* adicionar idiomaticas ao produto
            const pl = convertFormToProductLanguages(form, data.data.idProduct);

            [...pl].forEach((e) => {
              createProductLanguage.mutate(e, {
                onSuccess: () => {
                  setSelected(data?.data);
                  setEditable(false);
                  alert.success(
                    <>
                      <Alert.Heading>
                        {t("alerts.success.submitLang.title")}
                      </Alert.Heading>
                      <p>{t("alerts.success.submitLang.message")}</p>
                    </>
                  );
                },
                onSettled: () => {
                  products.refetch();
                },
              });
            });
          });
        }
      },
      onSettled: () => {
        products.refetch().then(() => initProductsLanguages.refetch());
      },
    }
  );

  //* handle submit
  const handleSubmit = () => {
    formikProduct.validateForm().then((responseProduct) =>
      formikLanguages.validateForm().then((responseLang) => {
        if (lodash.isEmpty(responseProduct) && lodash.isEmpty(responseLang)) {
          formikProduct.submitForm();
        }
      })
    );
  };

  //!----------------------------------------------------!\\
  //!-------------------confirmOverlay-------------------!\\
  //!----------------------------------------------------!\\
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: () => {},
  });
  const handleCloseConfirm = () => setShowConfirm(false);

  //!----------------------------------------------------!\\
  //!------------------------steps-----------------------!\\
  //!----------------------------------------------------!\\
  const steps = useSteps(t, selected?.idProduct ?? null);

  //!----------------------------------------------------!\\
  //!-------------------Products Form--------------------!\\
  //!----------------------------------------------------!\\
  const formikProduct = useFormik({
    initialValues: {
      ...selected,
      ...(role === 4
        ? {
            str1:
              getPropertyOperatorPin2GiveByProduct.data?.data?.cexpr_pua || "",
            str2:
              getPropertyOperatorPin2GiveByProduct.data?.data?.cexpr_pe || "",
          }
        : {}),
    } ?? { ...newProduct, ...(role === 4 ? { str1: "", str2: "" } : {}) },
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      if (values.state === 0 || values.state === 4) {
        createProduct.mutate(
          formatProduct({ ...values, state: 0 }, fkSupplier)
        );
      } else {
        setShowConfirm((e) => ({
          ...e,
          show: true,
          v2: true,
          primaryButtonLabel: t("alerts.editProduct.primaryButtonLabel"),
          newSelect: () => {
            createProduct.mutate(
              formatProduct({ ...values, state: 0 }, fkSupplier)
            );
            setShowConfirm((e) => ({ ...e, show: false }));
          },
          title: t("alerts.editProduct.title"),
          content: t("alerts.editProduct.content"),
        }));
      }
    },
  });

  //!----------------------------------------------------!\\
  //!--------------Product Languages Form----------------!\\
  //!----------------------------------------------------!\\
  const formikLanguages = useFormik({
    initialValues: productsLanguages ?? convertProductLanguagesToForm([]),
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
    validationSchema: validationSchemaLanguages(t),
    onSubmit: (values) => values,
  });

  //!----------------------------------------------------!\\
  //!---------------------isUpdated----------------------!\\
  //!----------------------------------------------------!\\
  const isUpdated = (obj1, obj2) => {
    return lodash.isEqual(
      omit(obj1, ["str1", "str2"]),
      omit(obj2, ["str1", "str2"])
    );
  };

  //!----------------------------------------------------!\\
  //!---------------------useEffects---------------------!\\
  //!----------------------------------------------------!\\
  /*useEffect(() => {
    if (lodash.isEmpty(products?.data?.data) || !products?.data?.data) {
      return setSelected(null);
    } else {
      if (!selected) {
        return setSelected(products?.data?.data[0]);
      }
    }
  }, [products?.data?.data]);*/
  useEffect(() => {
    const data = products?.data?.data;
    const fkp = queryParams.get("fkp");

    if (lodash.isEmpty(data) || !data) {
      return setSelected(null);
    } else {
      if (fkp !== null) {
        const x = data.findIndex((e) => e.idProduct === Number(fkp));

        if (x !== -1) return setSelected(data[x]);
      } else if (lodash.isEmpty(selected)) {
        return setSelected(data[0]);
      }
    }
  }, [location.pathname, products?.data?.data, queryParams.get("fkp")]);

  return (
    <productContext.Provider
      value={{
        initProductsLanguages,
        products: {
          ...products,
          selected,
          setSelected,
          newProduct,
          data: products?.data?.data,
        },
        steps,
        forms: {
          setEditable,
          product: formikProduct,
          productLanguages: formikLanguages,
          editable,
          isUpdated: () =>
            isUpdated(
              { ...selected, state: 0 },
              {
                ...formikProduct.values,
                state: 0,
              }
            ),
          handleSubmit,
          createProduct,
          createProductLanguage,
          cancel: () => {
            formikProduct.setValues(selected);
            formikLanguages.setValues(productsLanguages);
            if (selected?.idProduct === -1) {
              setSelected(products?.data?.data[0] ?? {});
            }
            setEditable(false);
          },
        },
        overlayConfirm: {
          setShowConfirm,
          showConfirm,
          handleCloseConfirm,
        },
        producers: producers?.data ?? null,
        images: images?.data ?? null,
      }}
    >
      <Products />
    </productContext.Provider>
  );
}

export default ProductsProvider;
