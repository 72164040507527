import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Pin2GiveListItem } from "../../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveList from "../../../../components/Pin2GiveList/Pin2GiveList.Container";
import { useImagesReferences } from "../useImagesReferences";
import {
  addObject,
  handleEnabledImage,
  isEnabled,
  ordernate,
  replalceEnabled,
  updateDImage,
} from "../_actions/_actions";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Images = () => {
  //!--------------------------------------------!\\
  //!--------------------hooks-------------------!\\
  //!--------------------------------------------!\\
  //* navigate
  const navigate = useNavigate();

  //* translation
  const { t } = useTranslation(["pm/imagesReferences"]);

  //* images References
  const {
    images,
    referenceImages,
    references,
    orderList,
    handleSubmit,
    dImage,
  } = useImagesReferences();

  //* auth
  const { role } = useAuth();

  //!--------------------------------------------!\\
  //!-------------------methods------------------!\\
  //!--------------------------------------------!\\
  //* handle check
  const handleCheck = (fkImage) => {
    const { getChecked, removeCheck, addCheck } = referenceImages;

    if (getChecked(fkImage)) return removeCheck(references.selected, fkImage);

    return addCheck(addObject(references.selected, fkImage, 0));
  };

  const handleClick = (e) => {
    const new0 = ordernate(e);
    handleSubmit(updateDImage(new0, dImage.data));
  };

  return (
    <Pin2GiveList
      headerProps={{
        title: t("imagesList.title"),
        headerButton: {
          onClick: () => navigate("/productsmanagement/imagesupload"),
          title: t("imagesList.button"),
        },
      }}
    >
      {[...(images?.data || [])].map((e, index) => (
        <Pin2GiveListItem
          check
          onCheck={() => handleCheck(e?.id)}
          key={index}
          type="img"
          secondaryComponent={
            role === 4 ? (
              referenceImages.getChecked(e?.id) && (
                <Button
                  size="sm"
                  variant="secondary"
                  className="ms-3 rounded-circle"
                  onClick={() => {
                    const enabled = handleEnabledImage(
                      orderList.data,
                      e?.id,
                      references.selected
                    );

                    return handleClick(
                      replalceEnabled(
                        referenceImages.data,
                        e?.id,
                        references.selected,
                        enabled
                      )
                    );
                    /*referenceImages.setReferencesImages(
                    replalceEnabled(
                      referenceImages.data,
                      e?.id,
                      references.selected,
                      enabled
                    )
                  );*/
                  }}
                >
                  {isEnabled(orderList.data, e?.id, references.selected) ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )}
                </Button>
              )
            ) : (
              <></>
            )
          }
          checked={referenceImages.getChecked(e?.id)}
          src={e?.imageBase64}
        />
      ))}
    </Pin2GiveList>
  );
};

export default Images;
