import i18next from "i18next";

export const getLanguage = () => {
  const language = i18next.language;
  switch (language) {
    case "en":
      return "EN";
    case "pt-PT":
      return "PT";
    case "it":
      return "IT";
    case "fr":
      return "FR";
    case "es":
      return "ES";
    case "de":
      return "DE";
    default:
      return "EN";
  }
};

export const isPrivateRoute = (config) => {
  if (
    config.url.includes("productsendpoint") ||
    config.url.includes("productlanguageendpoint") ||
    config.url.includes("producerendpoint") ||
    config.url.includes("producerlanguageendpoint") ||
    config.url.includes("productscompositionendpoint") ||
    config.url.includes("referencesendpoint") ||
    config.url.includes("seasonendpoint") ||
    config.url.includes("seasoncrosstableendpoint") ||
    config.url.includes("supplierendpoint") ||
    config.url.includes("getAllSupplierByPerson") ||
    config.url.includes("invoicesendpoind") ||
    config.url.includes("getProfileData") ||
    config.url.includes("changePassword") ||
    config.url.includes("dashboardendpoint") ||
    (process.env.NODE_ENV === "development" &&
      config.url.includes("invoicesendpoint")) ||
    config.url.includes("propertyendpoint") ||
    config.url.includes("ordersendpoint")
  ) {
    return true;
  }

  return false;
};
