import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import useSuppliers from "../../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import { useServices } from "../../../../services/useServices";

// import { Container } from './styles';

function FormSupplier({
  values,
  handleChange,
  errors,
  setFieldValue,
  handleBlur,
}) {
  //!-------------------------------------------------!\\
  //!----------------------hooks----------------------!\\
  //!-------------------------------------------------!\\
  //* services
  const { supplierEndpoints, getLanguage } = useServices();

  //* suppliers
  const { forms } = useSuppliers();
  const { editable } = forms;

  //* translations
  const { t } = useTranslation(["pm/suppliers"]);

  //!-------------------------------------------------!\\
  //!------------------typesSupplier------------------!\\
  //!-------------------------------------------------!\\
  const { isLoading: isLoadingTypes, data: types } = useQuery(
    "typesSupplier",
    supplierEndpoints.getTypesSupplier
  );

  return (
    <Container fluid>
      <Row>
        {
          //!------------------------------------------------!\\
          //!----------------------LOGO----------------------!\\
          //!------------------------------------------------!\\
        }
        <Col xs="12" className="text-center mb-3">
          <Pin2GiveForm.ImageUpload
            inputProps={{
              value: values?.str_logo || "",
              onChange: (value) => setFieldValue("supplier.str_logo", value),
              disabled: !editable,
            }}
          />
        </Col>
      </Row>
      <Row>
        {
          //!----------------------------!\\
          //!------------NOME------------!\\
          //!----------------------------!\\
        }
        <Col xs="12" md="8">
          <Pin2GiveForm.Input
            label={t("fields.company.label")}
            inputProps={{
              name: "supplier.company",
              value: values?.company ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.company}
          />
        </Col>
        {
          //!----------------------------------!\\
          //!------------Short nome------------!\\
          //!----------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.shortname.label")}
            inputProps={{
              name: "supplier.shortname",
              value: values?.shortname ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.shortname}
          />
        </Col>
        {
          //!----------------------------------!\\
          //!------------Short nome------------!\\
          //!----------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Select
            label={t("fields.fkStype.label")}
            inputProps={{
              name: "supplier.fkStype",
              value: values?.fkStype ?? "-1",
              onChange: handleChange,
              onBlur: handleBlur,
              disabled: !editable,
              autoComplete: "off",
            }}
            error={errors?.fkStype}
          >
            <option value="-1">
              {isLoadingTypes ? "Loading..." : t("fields.fkStype.choose")}
            </option>
            {types?.data.map((e, index) => (
              <option key={index} value={e?.idStype}>
                {e[`${getLanguage().toLowerCase()}`]}
              </option>
            ))}
          </Pin2GiveForm.Select>
        </Col>
        {
          //!---------------------------!\\
          //!------------NIF------------!\\
          //!---------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.nif.label")}
            inputProps={{
              name: "supplier.nif",
              value: values?.nif ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.nif}
          />
        </Col>
        {
          //!---------------------------!\\
          //!------------NIB------------!\\
          //!---------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.nib.label")}
            inputProps={{
              name: "supplier.nib",
              value: values?.nib ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.nib}
          />
        </Col>
        {
          //!---------------------------!\\
          //!------------url------------!\\
          //!---------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.url.label")}
            inputProps={{
              name: "supplier.url",
              value: values?.url ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.url}
          />
        </Col>
        {
          //!-----------------------------!\\
          //!------------EMAIL------------!\\
          //!-----------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.email.label")}
            inputProps={{
              name: "supplier.email",
              value: values?.email ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.email}
          />
        </Col>
        {
          //!-----------------------------!\\
          //!------------EMAIL------------!\\
          //!-----------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Input
            label={t("fields.email2.label")}
            inputProps={{
              name: "supplier.email2",
              value: values?.email2 ?? "",
              onChange: handleChange,
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
            }}
            error={errors?.email2}
          />
        </Col>
        {
          //!-----------------------------------!\\
          //!------------PhoneNumber------------!\\
          //!-----------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.PhoneInput
            label={t("fields.mobile.label")}
            inputProps={{
              name: "supplier.mobile",
              value: values?.mobile ?? "",
              onChange: (e) => setFieldValue("supplier.mobile", e),
              onBlur: handleBlur,
              readOnly: !editable,
              autoComplete: "off",
              placeholder: "+351 000 000 000",
            }}
            error={errors?.mobile}
          />
        </Col>
        {
          //!---------------------------------!\\
          //!------------Telephone------------!\\
          //!---------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.PhoneInput
            label={t("fields.phone.label")}
            inputProps={{
              name: "supplier.phone",
              value: values?.phone ?? "",
              onChange: (e) => setFieldValue("supplier.phone", e),
              onBlur: handleBlur,
              readOnly: !editable,
              placeholder: "+351 000 000 000",
            }}
            error={errors?.phone}
          />
        </Col>
        {
          //!---------------------------------!\\
          //!------------VATPAYER------------!\\
          //!---------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Check
            label={t("fields.vat_payer.label")}
            checkProps={{
              type: "switch",
              label: values?.vatpayer === 0 ? "Off" : "On",
              name: "supplier.vatpayer",
              id: "supplier.vatpayer",
              checked: values?.vatpayer ?? false,
              onChange: (e) => {
                if (e.target.checked) {
                  setFieldValue("supplier.vatpayer", 1);
                } else {
                  setFieldValue("supplier.vatpayer", 0);
                }
              },
              onBlur: handleBlur,
              disabled: !editable,
            }}
            error={errors?.vatpayer}
          />
        </Col>
        {
          //!------------------------------!\\
          //!------------wrapup------------!\\
          //!------------------------------!\\
        }
        <Col xs="12" md="4">
          <Pin2GiveForm.Check
            label={t("fields.wrapup.label")}
            checkProps={{
              type: "switch",
              label: values?.wrapup === 0 ? "Off" : "On",
              name: "supplier.wrapup",
              id: "wrapup",
              checked: values?.wrapup ?? false,
              onChange: (e) => {
                if (e.target.checked) {
                  setFieldValue("supplier.wrapup", 1);
                } else {
                  setFieldValue("supplier.wrapup", 0);
                }
              },
              onBlur: handleBlur,
              disabled: !editable,
            }}
            error={errors?.wrapup}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default FormSupplier;
