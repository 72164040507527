import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Pin2GiveForm from "../../components/Pin2GiveForm/Pin2GiveForm";
import * as Yup from "yup";

// import { Container } from './styles';

function ForgotPassword({ visible, handleClose }) {
  const [errors, setErrors] = useState("");
  const [email, setEmail] = useState("");
  const validator = Yup.string()
    .required("Email is Required!")
    .email("Email is invalid!");

  return (
    <Modal show={visible} backdrop="static">
      <Modal.Header>Forgot Password</Modal.Header>
      <Modal.Body>
        <Pin2GiveForm.Input
          label="Introduza o Email"
          inputProps={{
            placeholder: "example@email.com",
            onChange: (e) => {
              const x = e.target.value;
              validator
                .validate(x)
                .then(() => setErrors(""))
                .catch((error) => setErrors(error.message));
              setEmail(x);
            },
            value: email,
            name: "email",
            autoComplete: "off",
          }}
          error={errors || ""}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          Voltar
        </Button>
        <Button variant="pin2give" size="sm">
          Recover Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ForgotPassword;
