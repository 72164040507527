/**
 * !--------------------------------------------------!
 * !---------------Get Products Checked---------------!
 * !--------------------------------------------------!
 * @param {integer} idProduct
 * @param {array} seasonCrossTable
 * @returns true se o produto existir na seasonCrossTable
 * @returns false se o produto não exisitir na seasonCrossTable
 */
export const getProductsChecked = (idProduct, seasonCrossTable) => {
  const x = seasonCrossTable?.find(
    ({ seasonCrosstablePK }) => seasonCrosstablePK.fkProduct === idProduct
  );

  if (x !== undefined) return true;

  return false;
};

/**
 * !--------------------------------------------------!
 * !-----------------Get Product Title----------------!
 * !--------------------------------------------------!
 * @param {array} productsList lista de produtos
 * @param {*} fkProduct id de um produto
 * @returns {string} retorna o nome de um produto (stitle)
 */
export function getProductTitle(productsList, fkProduct) {
  const data = productsList || [];

  const object = data.find((e) => e.idProduct === fkProduct);

  if (object !== undefined) return object.stitle;

  return "";
}
