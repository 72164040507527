/* eslint-disable react/prop-types */
//? REACT-BOOTSTRAP
import { Row, Col, Container } from "react-bootstrap";

//? REACT-ICONS
import { FaBarcode, FaEuroSign, FaPercentage } from "react-icons/fa";

//? i18Next
import { useTranslation } from "react-i18next";

//? PIN2GIVE
import Pin2GiveForm from "../../../components/Pin2GiveForm/Pin2GiveForm";

import React from "react";

//? --------------------------FORM-----------------------------
const Form = ({ editable, formik, values, selected }) => {
  const { t } = useTranslation(["pm/references"]);
  return (
    <Container fluid>
      <Row>
        {
          //!----------------------------------!\\
          //!----------------PvP---------------!\\
          //!----------------------------------!\\
        }
        <Col xs="12" lg="3">
          <Pin2GiveForm.Input
            label={t("fields.dpvp.label")}
            inputProps={{
              name: "dpvp",
              readOnly: true,
              value: editable
                ? Number(values?.dpvp).toFixed(2) ?? 0
                : selected?.dpvp.toFixed(2) ?? 0,
              autoComplete: "off",
              help: <FaEuroSign className="text-muted" />,
            }}
            infoKey="priceSale"
            error={formik.errors?.dpvp}
          />
        </Col>
        {
          //!----------------------------------------!\\
          //!----------------REFERENCE---------------!\\
          //!----------------------------------------!\\
        }
        <Col xs="12" lg="9">
          <Pin2GiveForm.Input
            label={t("fields.reference.label")}
            inputProps={{
              name: "reference",
              value: values?.reference ?? "",
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </Col>
        {
          //!----------------------------------------!\\
          //!----------------Reference---------------!\\
          //!----------------------------------------!\\
        }
        <Col xs="12" lg="8">
          <Pin2GiveForm.Input
            label={t("fields.sreference.label")}
            inputProps={{
              readOnly: !editable,
              autoComplete: "off",
              name: "sreference",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("sreference"),
              value: values?.sreference ?? "",
            }}
            error={formik.errors?.sreference}
          />
        </Col>

        {
          //!--------------------------------------!\\
          //!----------------BARCODE---------------!\\
          //!--------------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.barcode.label")}
            inputProps={{
              name: "barcode",
              autoComplete: "off",
              readOnly: !editable,
              value: values?.barcode ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("barcode"),
              help: <FaBarcode className="text-muted" />,
            }}
            error={formik.errors?.barcode}
          />
        </Col>
        {
          //!------------------------------------!\\
          //!----------------STOCK---------------!\\
          //!------------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.stock.label")}
            inputProps={{
              name: "stock",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.stock ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("stock"),
            }}
            error={formik.errors?.stock}
          />
        </Col>
        {
          //!---------------------------------------!\\
          //!----------------GARANTIA---------------!\\
          //!---------------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.warranty.label")}
            inputProps={{
              name: "warranty",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.warranty ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("warranty"),
            }}
            error={formik.errors?.warranty}
          />
        </Col>
        {
          //!-------------------------------------!\\
          //!----------------PROFIT---------------!\\
          //!-------------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.dprofit.label")}
            inputProps={{
              name: "dprofit",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.dprofit ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("dprofit"),
              help: <FaPercentage className="text-muted" />,
            }}
            error={formik.errors?.dprofit}
          />
        </Col>
        {
          //!----------------------------------!\\
          //!----------------IVA---------------!\\
          //!----------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.vat.label")}
            inputProps={{
              name: "vat",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.vat ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("vat"),
              help: <FaPercentage className="text-muted" />,
            }}
            error={formik.errors?.vat}
          />
        </Col>
        {
          //!------------------------------------!\\
          //!----------------PRICE---------------!\\
          //!------------------------------------!\\
        }
        <Col xs="12" lg="4">
          <Pin2GiveForm.Input
            label={t("fields.price.label")}
            inputProps={{
              name: "price",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.price ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("price"),
              help: <FaEuroSign className="text-muted" />,
            }}
            error={formik.errors?.price}
            infoKey="price"
          />
        </Col>

        {
          //!--------------------------------------------------!\\
          //!----------------DESCRIPITON INVOICE---------------!\\
          //!--------------------------------------------------!\\
        }
        <Col xs="12">
          <Pin2GiveForm.Input
            label={t("fields.descriptioninvoice.label")}
            inputProps={{
              name: "descriptionInvoice",
              as: "textarea",
              rows: "4",
              readOnly: !editable,
              autoComplete: "off",
              value: values?.descriptionInvoice ?? "",
              onChange: formik.handleChange,
              onBlur: () => formik.validateField("descriptionInvoice"),
            }}
            error={formik.errors?.descriptionInvoice}
            infoKey={"descriptionInvoice"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Form;
