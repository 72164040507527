import styled, { css } from "styled-components";

export const Container = styled.div`
  margin-bottom: 2rem;
`;
export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 0.3rem;
`;

export const HorizontalScroll = styled.div`
  min-width: 100% !important;
  white-space: nowrap;
  display: flex;
  overflow: auto;
`;

export const Item = {
  Container: styled.div`
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid white;
    width: 50px;
    height: 50px;
    position: relative;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:hover {
      border-color: #a20013;
    }
    ${({ selected }) =>
      !selected &&
      css`
        cursor: pointer;
      `}
    ${({ selected }) =>
      selected &&
      css`
        border-color: #a20013;
      `}
  `,
  Image: styled.img`
    object-fit: "container";
    width: 100%;
    height: 100%;
  `,
  SecondaryButton: styled.button`
    position: absolute;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
    border: 2px solid #a20013;
    background-color: white;
    color: #333;
    top: -0.3rem;
    right: -0.3rem;
    &:hover {
      background-color: #a20013;
      color: #fff;
    }
  `,
};
