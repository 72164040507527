//? REACT-BOOTSTRAP
import { Row, Col, Container, Button, Spinner, Alert } from "react-bootstrap";

//? REACT-ICONS
import { FaArrowLeft, FaEdit, FaSave } from "react-icons/fa";

//? LODASH
import lodash from "lodash";

//? REACT ALERT
import { useAlert } from "react-alert";

//? REACT
import React, { useState, useEffect } from "react";

//? REACT-ROUTER-DOM
import { useNavigate, useParams } from "react-router-dom";

//? UI-MATERIAL
import { Stepper, Step, StepLabel } from "@mui/material";

//? FORMIK
import { useFormik } from "formik";
import { validationSchema } from "../../../_yup/ProductsManagement/References";

//? COMPONENTS
import UploadPattern from "../../../components/UploadPattern/UploadPattern";
import Form from "./Form";
import Header from "../Header";
import Scale from "./Scale";
import OverlayConfirm from "../OverlayConfirm";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveColorPicker from "../../../components/Pin2GiveColorPicker/Pin2GiveColorPicker";

//? I18NEXT
import { useTranslation } from "react-i18next";
import Loading from "../Loading";

import { useMutation, useQuery } from "react-query";
import { newReference, formatObject, updatePvp } from "./_actions";
import { useServices } from "../../../services/useServices";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { usePM } from "../../../contexts/PMProvider/usePM";

//?----------------PAGE----------------?\\
const References = () => {
  //!---------------------------!\\
  //!---------useParams---------!\\
  //!---------------------------!\\
  const { fkReference } = useParams();

  //!----------------------------!\\
  //!---------useContext---------!\\
  //!----------------------------!\\
  const { fkSupplier } = usePM();
  const { role } = useAuth();
  const { referencesEndpoints } = useServices();

  //!--------------------------!\\
  //!---------useAlert---------!\\
  //!--------------------------!\\
  const alert = useAlert();

  //!-----------------------------!\\
  //!---------useNavigate---------!\\
  //!-----------------------------!\\
  const navigate = useNavigate();

  //!--------------------------------!\\
  //!---------useTranslation---------!\\
  //!--------------------------------!\\
  const { t } = useTranslation(["pm/references", "pm/overlayConfirm"]);

  //!-----------------------!\\
  //!---------STEPS---------!\\
  //!-----------------------!\\
  const steps = [
    { label: t("Steps.s1"), path: null },
    { label: t("Steps.s3"), path: "/productsmanagement/imagesreferences" },
  ];

  //!--------------------------!\\
  //!---------editable---------!\\
  //!--------------------------!\\
  const [editable, setEditable] = useState(false);

  //!------------------------------------!\\
  //!---------------SCALES---------------!\\
  //!------------------------------------!\\
  const { data: scales, refetch: refetchScales } = useQuery(
    ["getAllScalesBySupplier", fkSupplier],
    () => referencesEndpoints.getAllScalesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!----------------------------!\\
  //!---------references---------!\\
  //!----------------------------!\\
  const [selected, setSelected] = useState(null);
  const {
    data: references,
    refetch: refetchReferences,
    isLoading: isLoadingReferences,
  } = useQuery(
    ["getReferences", fkSupplier],
    () => referencesEndpoints.getAllReferencesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!-------------------------------!\\
  //!---------confirm Modal---------!\\
  //!-------------------------------!\\
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: {},
  });
  const handleCloseConfirm = () => setShowConfirm(false);

  //!------------------------!\\
  //!---------formik---------!\\
  //!------------------------!\\
  const formik = useFormik({
    initialValues: selected ?? newReference,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: validationSchema(t, role),
    onSubmit: (values) => {
      createReference(values);
    },
  });

  //!----------------------------!\\
  //!---------useEffects---------!\\
  //!----------------------------!\\

  //* atualizar pvp quando o
  //* price, vat e dprofit for alterado
  useEffect(() => {
    formik.setFieldValue(
      "dpvp",
      updatePvp(
        formik.values?.price,
        formik.values?.dprofit,
        formik.values?.vat
      )
    );
  }, [formik.values?.price, formik.values?.vat, formik?.values?.dprofit]);

  useEffect(() => {
    if (lodash.isEmpty(references?.data) || !references?.data) {
      return setSelected(null);
    } else {
      if (fkReference !== "-") {
        const x = references?.data?.findIndex(
          (e) => e.reference === fkReference
        );

        if (x !== -1) return setSelected(references?.data[x]);
      } else if (!selected) {
        return setSelected(references?.data[0]);
      }
    }
  }, [references?.data, fkReference]);

  //!------------------------------!\\
  //!---------handleSubmit---------!\\
  //!------------------------------!\\
  const { mutate: createReference, isLoading: isLoadingButton } = useMutation(
    (values) => {
      return referencesEndpoints.create_or_update(
        formatObject(values, fkSupplier)
      );
    },
    {
      onSuccess: (response) => {
        if (!lodash.isEmpty(response.data)) {
          setSelected(response.data);
          setEditable(false);
          alert.success(
            <>
              <Alert.Heading>{t("alerts.success.title")}</Alert.Heading>
              <p>
                <strong>{response?.data?.sreference}</strong>
                {t("alerts.success.message")}
              </p>
            </>
          );
        }
      },
      onError: (error) =>
        alert.error(
          <>
            <Alert.Heading>ERROR {error.status ?? 500}!</Alert.Heading>
            <p>{error?.message}</p>
          </>
        ),
      onSettled: () => {
        refetchReferences();
        refetchScales();
      },
    }
  );

  const handleEnabled = () => {
    const x = {
      ...selected,
      enabled: selected.enabled === 0 ? 1 : 0,
      dpvp: updatePvp(selected?.price, selected?.dprofit, selected?.vat),
    };
    createReference(x);
  };

  //!------------------------!\\
  //!---------render---------!\\
  //!------------------------!\\
  return (
    <div className="app-references">
      <Container>
        {
          //!----------------------------------------!\\
          //!-----------------HEADER-----------------!\\
          //!----------------------------------------!\\
        }
        <Header
          left={{ backButton: true, menuButton: true }}
          center={{ title: t("title") }}
          infoKey="reference"
        />
        {
          //!------------------------------------------------!\\
          //!-----------------STEP INDICATOR-----------------!\\
          //!------------------------------------------------!\\
        }
        <Row className="mb-5">
          <Col xs="12">
            <Stepper activeStep={0}>
              {steps.map(({ label, path }) => (
                <Step
                  key={label}
                  onClick={() => (path ? navigate(path) : undefined)}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>
        {
          //!-----------------------------------------!\\
          //!-----------------Loading-----------------!\\
          //!-----------------------------------------!\\
        }
        {isLoadingReferences ? (
          <Loading />
        ) : (
          <Row
            className={`h-100 ${
              lodash.isEmpty(selected) ? "align-items-center" : ""
            }`}
          >
            {
              //!---------------------------------------------!\\
              //!-----------------SECTIONLIST-----------------!\\
              //!---------------------------------------------!\\
            }
            <Col xs="12" lg="3" className="mb-5 ">
              <div className="sticky-top">
                <Pin2GiveList
                  headerProps={{
                    title: t("list.title"),
                    headerButton: {
                      onClick: () => {
                        setSelected(newReference);
                        setEditable(true);
                      },
                      title: t("list.buttonTooltip"),
                    },
                  }}
                >
                  {references?.data?.map((e, index) => (
                    <Pin2GiveListItem
                      key={index}
                      id={e?.reference}
                      type="text"
                      title={
                        <span
                          className={
                            e?.enabled === 1 &&
                            e?.reference !== selected?.reference
                              ? "text-success fw-bold"
                              : ""
                          }
                        >
                          {e?.sreference}
                        </span>
                      }
                      onClick={() => {
                        const x = {
                          ...(formik.values ?? {}),
                          dpvp: Number(formik.values.dpvp).toFixed(1),
                        };
                        delete x.isnewsv;
                        delete x.descScale;
                        const y = {
                          ...(selected ?? {}),
                          dpvp: Number(selected?.dpvp).toFixed(1),
                        };
                        delete y.isnewsv;
                        delete x.dpvp;
                        delete y.dpvp;
                        if (lodash.isEqual(x, y)) {
                          setSelected(e);
                          setEditable(false);
                        } else {
                          setShowConfirm({ show: true, newSelect: e });
                        }
                      }}
                      selected={e?.reference === selected?.reference}
                      tooltip={e?.reference}
                    />
                  ))}
                  {lodash.isEqual(selected, newReference) ? (
                    <Pin2GiveListItem
                      selected
                      type="text"
                      title={t("list.newRefTitle")}
                    />
                  ) : (
                    <></>
                  )}
                </Pin2GiveList>
                {editable ? (
                  <></>
                ) : (
                  <div className="text-center">
                    <Button
                      className="mt-5"
                      variant="secondary"
                      onClick={() =>
                        navigate("/productsmanagement/imagesreferences")
                      }
                    >
                      {t("buttons.imagesReferences")}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col xs="12" lg="9" className="h-100">
              <form onSubmit={formik.handleSubmit}>
                {
                  //!--------------------------------------------!\\
                  //!-----------------EMPTY LIST-----------------!\\
                  //!--------------------------------------------!\\

                  lodash.isEmpty(selected) ? (
                    <div className="h-100 d-flex flex-column align-items-center empty-active-reference">
                      <FaArrowLeft size={30} />
                      <div className="empty-active-reference">
                        {t("list.buttonTooltip")}
                      </div>
                    </div>
                  ) : (
                    <Container fluid className="active-reference-container">
                      <Row className="mb-5">
                        <Col xs="12">
                          <div className="app-sub-title w-100 container-fluid">
                            <Row>
                              <Col xs="12">
                                <div className="border-bottom pb-2 d-flex justify-content-between">
                                  <div>
                                    {selected?.sreference ||
                                      t("list.newRefTitle")}
                                  </div>
                                  <div>
                                    {!editable ? (
                                      <Row>
                                        <Col xs="12" className="text-end">
                                          {role === 4 && (
                                            <Button
                                              className="me-2"
                                              size="sm"
                                              variant={`${
                                                selected?.enabled === 0
                                                  ? "secondary"
                                                  : "success"
                                              }`}
                                              onClick={handleEnabled}
                                            >
                                              {selected?.enabled === 0
                                                ? "Disabled"
                                                : "Enabled"}
                                            </Button>
                                          )}
                                          {selected?.enabled !== 0 || (
                                            <Button
                                              size="sm"
                                              className="rounded-circle"
                                              variant="warning"
                                              onClick={() => setEditable(true)}
                                            >
                                              <FaEdit />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      {
                        //!--------------------------------------!\\
                        //!-----------------FORM-----------------!\\
                        //!--------------------------------------!\\
                      }
                      <Row>
                        <Col xs="12">
                          <Form
                            editable={editable}
                            formik={formik}
                            values={formik.values}
                            updatePvP={updatePvp}
                            selected={selected}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {
                          //!----------------------------------------------!\\
                          //!-----------------COLOR PICKER-----------------!\\
                          //!----------------------------------------------!\\
                        }
                        {formik.values.pattern === 0 && (
                          <Col xs="12" className="mt-5">
                            <Container>
                              <Row>
                                <Col xs="12">
                                  <h4 className="app-sub-title border-bottom pb-2 ">
                                    {t("fields.color.label")}
                                  </h4>
                                </Col>
                                <Col xs="12">
                                  <Pin2GiveColorPicker
                                    initColors={[
                                      "#0000ff",
                                      "#ff0000",
                                      "#00ff00",
                                      "#800080",
                                      "#000000",
                                      "#ffa500",
                                      "#ffff00",
                                      "#b8860b",
                                      "#ffc0cb",
                                    ]}
                                    activeColor={formik.values?.color}
                                    onClick={formik.setFieldValue}
                                    editable={editable}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        )}
                        {
                          //!---------------------------------------!\\
                          //!-----------------SCALE-----------------!\\
                          //!---------------------------------------!\\
                        }
                        <Col xs="12" className="mt-5 mb-4">
                          <Container>
                            <Row>
                              <Col xs="12">
                                <h4 className="app-sub-title border-bottom pb-2 mb-4">
                                  {t("fields.scale.label")}
                                </h4>
                              </Col>

                              <Scale
                                formik={formik}
                                editable={editable}
                                values={formik.values}
                                selected={selected}
                                fkSupplier={fkSupplier}
                                scales={scales}
                              />
                            </Row>
                          </Container>
                        </Col>
                        {
                          //!------------------------------------------------!\\
                          //!-----------------UPLOAD PATTERN-----------------!\\
                          //!------------------------------------------------!\\
                        }
                        {lodash.isEmpty(formik.values.color) && (
                          <Col xs="12">
                            <Container>
                              <Row className="mt-5">
                                <Col xs="12">
                                  <h4 className="app-sub-title border-bottom pb-2 mb-3">
                                    {t("fields.pattern.label")}
                                  </h4>
                                </Col>
                                <Col xs="12">
                                  <UploadPattern
                                    value={formik.values?.patternBase64}
                                    formik={formik}
                                    editable={editable}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        )}
                        {editable ? (
                          <Col
                            xs="12"
                            className="my-5 d-flex align-items-center"
                          >
                            <Button
                              className="ms-auto me-2"
                              variant="secondary"
                              type="button"
                              onClick={() => {
                                if (formik.values?.reference !== "") {
                                  formik.setValues(selected);
                                } else {
                                  setSelected(references?.data[0] ?? null);
                                }
                                return setEditable(false);
                              }}
                            >
                              {t("buttons.cancel")}
                            </Button>
                            <Button
                              variant="success"
                              type="submit"
                              className="d-flex align-items-center"
                            >
                              {isLoadingButton ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                />
                              ) : (
                                <>
                                  <FaSave className="me-2" />{" "}
                                  {t("buttons.save")}
                                </>
                              )}
                            </Button>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Container>
                  )
                }
              </form>
            </Col>
          </Row>
        )}
      </Container>
      <OverlayConfirm
        title={t("references.title", { ns: "pm/overlayConfirm" })}
        content={t("references.content", { ns: "pm/overlayConfirm" })}
        show={showConfirm.show}
        handleCloseConfirm={handleCloseConfirm}
        onSelect={() => {
          setSelected(showConfirm.newSelect);
          handleCloseConfirm();
          setEditable();
        }}
      />
    </div>
  );
};

export default References;
