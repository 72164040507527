//? REACT-BOOTSTRAP
import { Box } from "@mui/material";
import { Form, InputGroup } from "react-bootstrap";

//!-----------------------------------------------------------------!\\
//!--------------------------PIN2GIVEINPUT--------------------------!\\
//!-----------------------------------------------------------------!\\
export const Select = ({
  label,
  inputProps,
  error,
  children,
  secondaryText,
}) => {
  return (
    <Form.Group className="mb-4">
      <Form.Label>
        {label} <Box sx={{ width: "24px", height: "10px" }} />
      </Form.Label>
      {inputProps.help ? (
        <InputGroup>
          <Form.Select isInvalid={error} {...inputProps}>
            {children}
          </Form.Select>
          <InputGroup.Text>{inputProps.help}</InputGroup.Text>
        </InputGroup>
      ) : (
        <Form.Select isInvalid={error} {...inputProps}>
          {children}
        </Form.Select>
      )}

      <Form.Text className="text-danger">{error}</Form.Text>
      {secondaryText ? <Form.Text>{secondaryText}</Form.Text> : <></>}
    </Form.Group>
  );
};

export default Select;
