import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

//* se o sort for 0, é ordenado de A-Z
//* se for 1, é ordernado de Z-A
//* se for undefined ou -1, n é ordenado
export const handleSort = (sort, nKey) => {
  const sortKey = sort?.key;

  if (sortKey === nKey) {
    return sort?.sort === 1
      ? undefined
      : sort?.sort === 0
      ? { key: nKey, sort: 1 }
      : { key: nKey, sort: 0 };
  }

  return { key: nKey, sort: 0 };
};

export function getSortIcon(sort, nKey) {
  if (sort.key === nKey) {
    return sort.sort === 0 ? <TiArrowSortedUp /> : <TiArrowSortedDown />;
  }

  return <></>;
}
