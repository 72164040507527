/* eslint-disable react-hooks/exhaustive-deps */
//? REACT
import React, { memo, useCallback, useEffect, useState } from "react";

//? REACT-BOOTSTRAP
import { Button, Col, Row, Spinner } from "react-bootstrap";

//? STYLES
import { Container, FormTitle, FormTitle2, PathLabel } from "./Forms.styles";

//? PIN2GIVEFORM
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";

//? HOOKS
import { useSeasonCrossTable } from "../useSeasonCrossTable";
import { useServices } from "../../../../services/useServices";
import { usePM } from "../../../../contexts/PMProvider/usePM";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";

//? FORMIK
import { Form, Formik } from "formik";
import { validationSchema } from "../../../../_yup/ProductsManagement/seasonCrossTable";

//? ACITONS
import {
  createImageData,
  findIdInArray,
  formatFormObject,
  isUpdatedForm,
  updatePricePurchase,
} from "../_actions/form";
import { getCompositionTitle } from "../_actions/compositions";
import { getCountryChecked, getCountryTitle } from "../_actions/countries";
import {
  checkEnableComposition,
  createOrUpdate,
  removeSCTValuesByCountry,
} from "../_actions/seasonCrossTable";
import { getProductTitle } from "../_actions/products";
import { getSeasonTitle } from "../_actions/seasons";

//? REACT-ICONS
import { FaEye, FaEyeSlash, FaTrashAlt } from "react-icons/fa";

//? LODASH
import { isEmpty, isEqual } from "lodash";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

function SeasonForm() {
  //!---------------------------------------------------!\\
  //!------------------------Hooks----------------------!\\
  //!---------------------------------------------------!\\
  //* season cross table
  const {
    references,
    countries,
    products,
    compositions,
    seasons,
    sct,
    saveObject,
    sctImages,
    modalRemove,
    confirm,
    referenceImages,
  } = useSeasonCrossTable();

  //* authentication
  const { role } = useAuth();

  //* services
  const { getLanguage } = useServices();

  //* use products management
  const { protocol } = usePM();

  //* use translation
  const { t } = useTranslation(["pm/sct"]);

  //!---------------------------------------------------!\\
  //!----------------------constants--------------------!\\
  //!---------------------------------------------------!\\
  //* initial values
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    setInitialValues(
      formatFormObject(
        references?.data,
        sct,
        seasons.selected,
        products.selected,
        compositions.selected,
        countries.selected,
        protocol,
        sctImages.data ?? [],
        referenceImages.data ?? []
      )
    );
  }, [sctImages.initImages, sctImages.data]);

  const getImageLink = (id) => {
    const supplierImages = sctImages.supplierImages ?? [];

    const data = supplierImages.find((e) => e.id === id);

    if (data === undefined) return "";

    return data.imageBase64;
  };

  //!---------------------------------------------------!\\
  //!----------------------Callbacks--------------------!\\
  //!---------------------------------------------------!\\
  //* is updated
  const isUpdated = useCallback(
    (values) => {
      return isUpdatedForm(
        sct,
        products.selected,
        compositions.selected,
        countries.selected,
        values,
        sctImages.data || []
      );
    },
    [
      sct,
      products.selected,
      compositions.selected,
      countries.selected,
      countries.selected,
      sctImages.data,
    ]
  );

  //* verificar se o país selecionado
  //* existe na seasonCrossTable
  const isChecked = useCallback(
    () =>
      getCountryChecked(
        sct,
        products.selected,
        compositions.selected,
        countries.selected
      ),
    [sct, products.selected, compositions.selected, countries.selected]
  );

  //* season Title
  const seasonTitle = useCallback(
    () => getSeasonTitle(seasons?.data || [], seasons?.selected),
    [seasons?.selected, seasons?.data]
  );

  //* product title
  const productTitle = useCallback(
    () => getProductTitle(products?.data || [], products?.selected),
    [products?.data, products?.selected]
  );

  //* composition title
  const compositionTitle = useCallback(
    () => getCompositionTitle(compositions?.data, compositions.selected),
    [compositions?.data, compositions?.selected]
  );

  //* get images
  const getImages = (reference) =>
    createImageData([], referenceImages.data ?? [], reference);

  //!---------------------------------------------------!\\
  //!-----------------------events----------------------!\\
  //!---------------------------------------------------!\\
  //* submit form
  const submitForm = (values) => {
    const newSCT = createOrUpdate(
      sct,
      values,
      products.selected,
      compositions.selected,
      countries.selected
    );
    const isEnabled = checkEnableComposition(
      sct,
      products.selected,
      compositions.selected
    );

    if (isEnabled === 5 || isEnabled === 4) {
      confirm.set((e) => ({
        ...e,
        show: true,
        onClick: () => {
          saveObject(newSCT);
          confirm.set((e) => ({ ...e, show: false }));
        },
        primaryButtonLabel: t(
          "compositionPreview.alerts.editComposition.primaryButtonLabel"
        ),
        title: t("compositionPreview.alerts.editComposition.title"),
        content: t("compositionPreview.alerts.editComposition.content"),
      }));
    } else {
      saveObject(newSCT);
    }
  };

  //* remove country
  const removeCountry = () => {
    return modalRemove.setRemove((e) => ({
      ...e,
      show: true,
      onRemove: () =>
        saveObject(
          removeSCTValuesByCountry(
            sct,
            products.selected,
            compositions.selected,
            countries.selected,
            seasons.selected
          )
        ),
      title: t("compositionPreview.alerts.removeCountry.title"),
      content: t("compositionPreview.alerts.removeCountry.content"),
    }));
  };

  //* handleSelect Image
  const handleSelectImage = (values, index, id, setFieldValue) => {
    let array = isEmpty(values[`form${index}`].images)
      ? []
      : values[`form${index}`].images;

    if (findIdInArray(array, id)) {
      array = array.filter(
        (x) =>
          !isEqual(
            { image: x.image, reference: x.reference },
            { image: id.image, reference: id.reference }
          )
      );
    } else {
      array.push(id);
    }

    return setFieldValue(`form${index}.images`, array);
  };

  //!---------------------------------------------------!\\
  //!-----------------------render----------------------!\\
  //!---------------------------------------------------!\\
  //* esconder formulário quando não houver país selecionado
  if (countries.selected === -1 || initialValues === null) {
    return <></>;
  }

  //* formulário
  return (
    <Container className="mh-100">
      {sctImages.loadingImages ? (
        <Row>
          <Col xs="12">
            <div className="h5 fw-bold text-center">A obter imagens...</div>
            <div className="text-center">
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center">
                <PathLabel>
                  {`${seasonTitle()} > ${productTitle()} > ${compositionTitle()}`}
                </PathLabel>
                {isChecked() && (
                  <Button
                    variant="danger"
                    className="ms-auto"
                    size="sm"
                    onClick={removeCountry}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormTitle2>
                {getCountryTitle(
                  countries?.data,
                  countries?.selected,
                  getLanguage
                )}
              </FormTitle2>
            </Col>
          </Row>
          <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={submitForm}
            enableReinitialize
            validationSchema={validationSchema(references?.data)}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setValues,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {references?.data?.map((e, index) => (
                    <Row key={index} className="mb-5">
                      <Col xs="12">
                        <FormTitle exist={values[`form${index}`]?.exist}>
                          {e?.reference}
                        </FormTitle>
                      </Col>
                      <Col xs="2">
                        <Pin2GiveForm.Input
                          label={t("fields.vat.label")}
                          inputProps={{
                            value: values[`form${index}`]?.vat,
                            readOnly: true,
                          }}
                        />
                      </Col>
                      <Col xs="2">
                        <Pin2GiveForm.Input
                          label={t("fields.profit.label")}
                          inputProps={{
                            value: values[`form${index}`]?.profit,
                            name: `form${index}.profit`,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            readOnly: role === 2,
                          }}
                          error={errors[`form${index}`]?.profit}
                        />
                      </Col>
                      <Col xs="3">
                        <Pin2GiveForm.Input
                          label={t("fields.pvp.label")}
                          infoKey="priceSale"
                          inputProps={{
                            value: values[`form${index}`]?.pvp,
                            name: `form${index}.pvp`,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            readOnly: role === 2 && protocol.type_ === 0,
                          }}
                          error={errors[`form${index}`]?.pvp}
                        />
                      </Col>
                      <Col xs="3">
                        <Pin2GiveForm.Input
                          label={t("fields.price.label")}
                          infoKey="price"
                          inputProps={{
                            value: updatePricePurchase(
                              values[`form${index}`]?.pvp,
                              values[`form${index}`]?.profit
                            ).toFixed(2),
                            readOnly: true,
                          }}
                        />
                      </Col>
                      <Col xs="2">
                        <Pin2GiveForm.Input
                          label={t("fields.discount.label")}
                          inputProps={{
                            value: values[`form${index}`]?.discount,
                            name: `form${index}.discount`,
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          error={errors[`form${index}`]?.discount}
                        />
                      </Col>
                      <Col xs="12">
                        <Pin2GiveForm.ImagePicker
                          label="Images"
                          list={getImages(e.reference)?.map((x) => ({
                            id: x.reference,
                            secondaryButton: true,
                            secondaryButtonLabel:
                              [...(values[`form${index}`]?.images ?? [])].find(
                                (i) => i.image === x.image
                              )?.enabled === 1 ? (
                                <FaEye />
                              ) : (
                                <FaEyeSlash />
                              ),
                            value: x.url,
                            selected: findIdInArray(
                              values[`form${index}`]?.images,
                              x
                            ),
                            handleSelect: () => {
                              handleSelectImage(
                                values,
                                index,
                                x,
                                setFieldValue
                              );
                            },
                            handleEnabled: (z) => {
                              //* este método é para poder utilizar um onclick dentro de um onclick
                              z.stopPropagation();

                              //* encontrar o objeto em images
                              const object = values[
                                `form${index}`
                              ]?.images.find((i) => i.image === x.image);

                              //* sabe para qual valor o enabled vai ser alterado
                              const nEnabled = object.enabled === 1 ? 0 : 1;

                              //* result
                              const images = [
                                ...values[`form${index}`]?.images,
                              ];
                              for (let i = 0; i < images.length; i++) {
                                if (
                                  images[i].reference === x.reference &&
                                  images[i].image === x.image
                                ) {
                                  return setFieldValue(
                                    `form${index}.images[${i}].enabled`,
                                    nEnabled
                                  );
                                }
                              }
                            },
                          }))}
                          editable
                          errorLabel={errors[`form${index}`]?.images}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    {!isUpdated(values) && (
                      <Col
                        xs="12"
                        className="d-flex align-items-center justify-content-end"
                      >
                        {isChecked() && (
                          <Button
                            type="button"
                            variant="secondary"
                            className="ms-auto me-2"
                            onClick={() => setValues(initialValues)}
                          >
                            {t("buttons.cancel")}
                          </Button>
                        )}
                        <Button type="submit" variant="success">
                          {t("buttons.save")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Container>
  );
}

export default memo(SeasonForm);
