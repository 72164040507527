/**
 * !---------------------------------------------------------------!
 * !---------------Get References By Composition Lis---------------!
 * !---------------------------------------------------------------!
 * @param {array} compRefs
 * @param {array} references
 * @returns {array} retorna um array com as referências para a composição selecionada
 */
export function getReferencesByCompositionList(compRefs, references) {
  //* lista de todas as referências
  const x = references ?? [];

  //* lista de todos os id's das referências por composição
  const y = [...compRefs];

  //* este é o resultado que será retornado
  let z = [];

  //* iterar sobre a lista de referências
  x.forEach((e) => {
    //* para a referências atual {e.reference}
    //* será verificado se existe na lista de referências por composição
    y.forEach((i) => {
      if (i?.productCompositionReferencePK.reference === e?.reference) {
        //* se existir adiciona ao resultado
        z.push(e);
      }
    });
  });

  return z;
}
