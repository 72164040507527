import React from "react";
import { FaSearch } from "react-icons/fa";
import { Search as DSearch } from "../_styles/search.styles";

// import { Container } from './styles';

function Search(props) {
  return (
    <DSearch.Container color={props.color}>
      <DSearch.Input {...props} />
      <DSearch.Icon>
        <FaSearch />
      </DSearch.Icon>
    </DSearch.Container>
  );
}

export default Search;
