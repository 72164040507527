/**
 * !--------------------------------------------------------------------!
 * !-----------------------------Add Object-----------------------------!
 * !--------------------------------------------------------------------!
 * @param {string} reference reference p2g
 * @param {integer} imageId
 * @param {integer} order new order
 * @returns {object} retorna o novo objeto que será enviado no serviço
 */
export const addObject = (reference, imageId, order) => {
  return {
    referenceImagePK: {
      reference: reference,
      image: imageId,
    },
    iorder: order,
    description: "-",
    dproduct: 0,
    dcomposition: 0,
    dreference: 0,
    enabled: 1,
    cstate: 0,
    cdate: 0,
  };
};

/**
 * !--------------------------------------------------------------------!
 * !------------------------------Ordenate------------------------------!
 * !--------------------------------------------------------------------!
 * @param {array} list obter a lista imageReference
 * @returns {array} retorna a lista de imagens ordenada
 */
export const ordernate = (list) => {
  let x = list;
  x?.forEach((e, index) => {
    e.iorder = index + 1;
  });
  return x;
};

/**
 * !--------------------------------------------------------------------!
 * !----------------------------UpdateDImage----------------------------!
 * !--------------------------------------------------------------------!
 * @param {array} list lista referenceImages
 * @param {*} dimageObject objeto referenceImagePK que contem a dImage
 * @returns {array} retorna array com a imagem default atualizada
 * @description este método percorre a lista de referenceImages e altera o dimage de todas os objetos
 */
export const updateDImage = (list, dimageObject) => {
  let y = [];
  [...list].forEach((e) => {
    let z = e;
    if (e?.referenceImagePK === dimageObject?.referenceImagePK) {
      z.dproduct = 0;
      z.dcomposition = 0;
      z.dreference = 1;
    } else {
      z.dproduct = 0;
      z.dcomposition = 0;
      z.dreference = 0;
    }
    y.push(z);
  });

  return y;
};

/**
 * !--------------------------------------------------------------------!
 * !-------------------------handleEnabledImage-------------------------!
 * !--------------------------------------------------------------------!
 * @param {array} referenceImages
 * @param {integer} fkImage
 * @param {string} fkReference
 * @returns {integer} retorna o valor para o qual será alterado o valor do enabled
 */
export function handleEnabledImage(referenceImages = [], fkImage, fkReference) {
  //* obter o objeto com a pk para o fkImage, fkReference
  //* passado como parâmetro
  const object = referenceImages.find(
    ({ referenceImagePK: pk }) =>
      pk.reference === fkReference && pk.image === fkImage
  );

  //* so será feito o handle se o ojeto for encontrado
  if (object !== undefined) {
    //* obter o enabled atual do objeto
    const enabled = object?.enabled ?? 0;

    //* se o enable for 0, passar para 1
    //* se foor 1, passar para 0
    if (enabled === 0) {
      return 1;
    }

    return 0;
  }

  return 0;
}

/**
 *
 * @param {array} referenceImages
 * @param {integer} fkImage
 * @param {integer} fkReference
 * @returns {boolean} true se o enabled for 1
 * @returns {boolean} false se o enabled for 0
 */
export function isEnabled(referenceImages = [], fkImage, fkReference) {
  //* obter o objeto com a pk para o fkImage, fkReference
  //* passado como parâmetro
  const object = referenceImages.find(
    ({ referenceImagePK: pk }) =>
      pk.reference === fkReference && pk.image === fkImage
  );

  //* se não encontrar o objeto retorna false
  if (object === undefined) return false;

  //* obter o enabled
  const enabled = object?.enabled ?? 0;

  //* se o enabled for 0, então não está visível
  //* retorna false
  if (enabled === 0) return false;

  //* se o enabled for 1, entãoo está visível
  //* retorna true
  return true;
}

export function replalceEnabled(
  referenceImages = [],
  fkImage,
  fkReference,
  enabled
) {
  const result = referenceImages;

  //* obter o index do objeto
  const index = referenceImages.findIndex(
    ({ referenceImagePK: pk }) =>
      pk.reference === fkReference && pk.image === fkImage
  );

  //* substituir o enabled pelo valor pretendido
  result[index] = { ...result[index], enabled: enabled };
  console.log(result);
  return result;
}
