//? REACT-BOOTSTRAP
import { Form, InputGroup } from "react-bootstrap";

//? REACT
import { useRef } from "react";
import InformartionPage from "../InformartionPage/InformationPage";
import { Box } from "@mui/system";

//!-----------------------------------------------------------------!\\
//!--------------------------PIN2GIVEINPUT--------------------------!\\
//!-----------------------------------------------------------------!\\
export const Input = ({
  label,
  inputProps,
  error,
  children,
  secondaryText,
  infoKey,
}) => {
  const inputRef = useRef(null);
  return (
    <Form.Group className="mb-4">
      <Form.Label>
        {label}{" "}
        {infoKey ? (
          <InformartionPage infoKey={infoKey} />
        ) : (
          <Box sx={{ width: "24px", height: "10px" }} />
        )}
      </Form.Label>
      {inputProps.help ? (
        <InputGroup>
          <Form.Control ref={inputRef} isInvalid={error} {...inputProps} />
          <InputGroup.Text>{inputProps.help}</InputGroup.Text>
        </InputGroup>
      ) : (
        <Form.Control ref={inputRef} isInvalid={error} {...inputProps} />
      )}
      {children}
      {secondaryText ? <Form.Text>{secondaryText}</Form.Text> : <></>}
      <Form.Text className="text-danger">{error}</Form.Text>
    </Form.Group>
  );
};

export default Input;
