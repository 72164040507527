import { Form } from "react-bootstrap";
import { FaLock, FaLockOpen, FaTrashAlt } from "react-icons/fa";
import { P2GLItem, P2GLItemImage } from "./Pin2GiveList.styles";

export const Pin2GiveListItemImg = ({
  check,
  checkBoxRef,
  id,
  onCheck,
  checked,
  src,
  checkDisabled,
  title,
  hoverableImage = false,
  onRemove,
  handleEnabled,
  enabled,
  secondaryComponent,
}) => {
  if (check) {
    return (
      <P2GLItem.Item>
        <Form.Check
          ref={checkBoxRef}
          disabled={checkDisabled}
          type="checkbox"
          id={`pin2givelist-item${id}`}
          onChange={(e) => {
            e.preventDefault();
            onCheck();
          }}
          checked={checked}
        />
        {secondaryComponent}
        <P2GLItemImage.Image src={src} alt={title} />
      </P2GLItem.Item>
    );
  }

  return (
    <P2GLItem.Item>
      <P2GLItemImage.Image
        hoverableImage={hoverableImage}
        left
        src={src}
        alt={title}
      />
      {handleEnabled && (
        <P2GLItemImage.RemoveButton
          onClick={onRemove}
          variant={enabled ? "danger" : "primary"}
          size="sm"
        >
          {enabled ? <FaLock /> : <FaLockOpen />}
        </P2GLItemImage.RemoveButton>
      )}
      {onRemove && (
        <P2GLItemImage.RemoveButton
          onClick={onRemove}
          variant="pin2give"
          size="sm"
        >
          <FaTrashAlt />
        </P2GLItemImage.RemoveButton>
      )}
    </P2GLItem.Item>
  );
};
