import axios from "axios";

export const SeasonsEndpoints = (initPath) => {
  const PATH = `${initPath}/seasonendpoint`;

  const create_or_update = async (data) =>
    await axios.post(`${PATH}/create_or_update`, data);

  const getAllSeasonBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllSeasonBySupplier/${fkSupplier}`);

  const remove = async (data) => await axios.put(`${PATH}/remove`, data);

  return { create_or_update, getAllSeasonBySupplier, remove };
};
