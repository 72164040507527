import "./App.css";
import "./forms.css";
import React, { Suspense } from "react";
import Routes from "./layout/routes/Routes";
import AuthProvider from "./contexts/AuthProvider/authContext";
import ServicesProvider from "./services/Provider";
function App() {
  return (
    <Suspense fallback={null}>
      <ServicesProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ServicesProvider>
    </Suspense>
  );
}

export default App;
