export const _quillModules = (editable) => {
  const toolbar = editable
    ? [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "link"],
      ]
    : false;

  return {
    toolbar: toolbar,
  };
};
