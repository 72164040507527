import axios from "axios";

export const ProductsEndpoints = (initPath) => {
  const PATH = `${initPath}/productsendpoint`;

  //!----------------------------------------------------------!\\
  //!---------------------------GETS---------------------------!\\
  //!----------------------------------------------------------!\\
  const getAllProductsBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllProductsBySupplier/${fkSupplier}`);

  const getAllProtocols = async () =>
    await axios.get(`${PATH}/getAllProtocols`);

  const detailsproduct = async (fkProduct, language, countryCode) =>
    await axios.get(
      `${PATH}/detailsproduct/${fkProduct}/${language}/${countryCode}`
    );

  const getAllInformationCategories = async (fkLanguage) =>
    await axios.get(`${PATH}/getAllInformationCategories/${fkLanguage}`);

  const getProductCategoriesByProductAndCountry = async (
    fkProduct,
    fkCountry
  ) =>
    await axios.get(
      `${PATH}/getProductCategoriesByProductAndCountry/${fkProduct}/${fkCountry}`
    );

  const getAllImagesByIdProduct = async (fkProduct) =>
    await axios.get(`${PATH}/getAllImagesByIdProduct/${fkProduct}`);

  const getAllProductApproveBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllProductApproveBySupplier/${fkSupplier}`);

  //!----------------------------------------------------------!\\
  //!--------------------------POSTS---------------------------!\\
  //!----------------------------------------------------------!\\
  const create_or_update = async (data) =>
    await axios.post(`${PATH}/create_or_update`, data);

  //!----------------------------------------------------------!\\
  //!---------------------------PUT----------------------------!\\
  //!----------------------------------------------------------!\\
  const createCategoriesforProduct = async (data) =>
    await axios.put(`${PATH}/createCategoriesforProduct`, data);

  return {
    getAllProductsBySupplier,
    getAllProtocols,
    detailsproduct,
    create_or_update,
    getAllInformationCategories,
    getProductCategoriesByProductAndCountry,
    createCategoriesforProduct,
    getAllImagesByIdProduct,
    getAllProductApproveBySupplier,
  };
};

export const ProductLanguagesEndpoints = (initPath) => {
  const PATH = `${initPath}/productlanguageendpoint`;

  const create_or_update = async (data) =>
    axios.post(`${PATH}/create_or_update`, data);

  const getAllLanguagesByProduct = async (fkProduct) =>
    axios.get(`${PATH}/getAllLanguagesByProduct/${fkProduct}`);
  return {
    create_or_update,
    getAllLanguagesByProduct,
  };
};
