import lodash from "lodash";
import { getSeasonCrossTableByCountry } from "./seasonCrossTable";

/**
 * !---------------------------------------------------------------!
 * !---------------Get SeasonCrossTable By Reference---------------!
 * !---------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkCountry
 * @param {integer} fkComposition
 * @param {string} fkReference
 * @return undefined se o objeto não existir
 * @return objeto da seasonCrossTable
 * @description Este método retorna o objeto que será apresentado no formulário
 */
export function getSCTObjectByReference(
  seasonCrossTable = [],
  fkProduct,
  fkComposition,
  fkCountry,
  fkReference
) {
  //* obter o objeto com os id's pretendidos
  const object = seasonCrossTable.find(
    ({ seasonCrosstablePK: y }) =>
      y.fkProduct === fkProduct &&
      y.fkComposition === fkComposition &&
      y.fkCountry === fkCountry &&
      y.fkReference === fkReference
  );

  //* se o objeto não existir retorna undefined
  return object;
}

/**
 * !------------------------------------------------------------------!
 * !-----Get SeasonCrossTable By Product, Composition and Country-----!
 * !------------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @param {integer} fkCountry
 * @returns undefined se o array for vazio
 * @returns array com objetos da seasonCrossTable
 */
export function getSCTByProductCompositionCountry(
  seasonCrossTable = [],
  fkProduct,
  fkComposition,
  fkCountry
) {
  //* obter valores da seasonCrossTable
  //* para os id's pretendidos
  const data = seasonCrossTable.filter(
    ({ seasonCrosstablePK: y }) =>
      y.fkProduct === fkProduct &&
      y.fkComposition === fkComposition &&
      y.fkCountry === fkCountry
  );

  //* se o array for vazio retorna undefined
  if (lodash.isEmpty(data)) return undefined;

  return data;
}

/**
 * !------------------------------------------------!
 * !---------------Format Form Object---------------!
 * !------------------------------------------------!
 * @param {array} references array
 * @param {array} seasonCrossTable array
 * @param {integer} fkSeason integer
 * @param {integer} fkProduct integer
 * @param {integer} fkComposition integer
 * @param {integer} fkCountry integer
 * @param {object} protocol object
 * @return {object} retorna um objecto com serão os dados inicias do formulário
 * @description este método converte o array das referências em um único objeto
 * para ser utilizado no formulário. Se existe valores na seasonCrossTable referentes a uma referência, os mesmos serão utilizados,
 * senão, por defeito os valor vêm do objeto da referência
 */
export function formatFormObject(
  references = [], //! o que vem no serviço das referências associadas a uma composição
  seasonCrossTable = [],
  fkSeason = -1,
  fkProduct = -1,
  fkComposition = -1,
  fkCountry = -1,
  protocol = {},
  sctImages = [], //! o que vem no serviço sctImages
  dImages = [] //! o que vem no serviço imagesRefs
) {
  //* objeto que será retornado
  const object = {};

  //* iterar sobre as referências para construir o objeto
  references.forEach((e, index) => {
    //* obter os dados da seasonCrossTable
    //* referentes a esta referência.
    const data = getSCTObjectByReference(
      seasonCrossTable,
      fkProduct,
      fkComposition,
      fkCountry,
      e?.reference
    );

    //* se não houver dados referentes a esta referência na seasonCrossTable
    //* o profit depende do protocolo escolhido
    //* se o protocolo escolhido for margem fixa, o valor por defeito será o valor escolhido no protocolo (dcomission)
    //* se o protocolo escolhido for preço fixo, o valor por defeito setá o valor escolhido na referência (dprofit)
    const profit = data?.profit
      ? data?.profit
      : protocol?.type_ === 0
      ? e?.dprofit
      : protocol?.dcomission;

    //* se não houver dados referentes a esta referência na seasonCrossTable
    //* o valor por defeito do pvp será o valor escolhido na referência (dpvp)
    const pvp = data?.pvp ?? e?.dpvp ?? 0;

    //* construção do objeto
    //* as chaves do objeto são compostas por "form" + o indice do forEach
    //* ex.: { form1: {}, form2: {}, form3: {}, ...}
    object[`form${index}`] = {
      seasonCrosstablePK: {
        fkReference: e?.reference,
        fkSeason,
        fkProduct,
        fkComposition,
        fkCountry,
      },
      discount: 0,
      enabled: data?.enabled ?? 1,
      pvp: pvp.toFixed(2),
      price: updatePricePurchase(pvp, profit) ?? 0,
      profit: profit,
      vat: e?.vat,
      exist: !lodash.isEmpty(data),
      images: createImageData(sctImages, dImages, e?.reference),
    };
  });

  return object;
}

export function createImageData(sctImage = [], dImages = [], reference = "") {
  //* result é o array que será retornado
  const result = [];

  //* se não houver dados da seasonCrossTable
  //* por defeito serão apresentadas as imagens default
  if (lodash.isEmpty(sctImage)) {
    //* obter as imagens da referência pretendida
    const imagesByReference = [...(dImages || [])]?.filter(
      ({ referenceImagePK: pk }) => pk?.reference === reference
    );
    // console.log("ibr" + reference, imagesByReference);

    //* adicionar ao array os objetos com o seguinte formato {enabled, image, reference, url}
    imagesByReference.forEach((a) => {
      const pk = a.referenceImagePK;

      result.push({
        enabled: a?.enabled,
        image: pk?.image,
        reference: pk?.reference,
        url: a?.url,
      });
    });
  } else {
    sctImage.forEach((a) => {
      const pk = a.seasonCrosstableImagePK;
      if (pk.fkReference === reference) {
        result.push({
          enabled: a?.enabled,
          image: pk?.image,
          reference: pk?.fkReference,
          url: a?.url_image,
        });
      }
    });
  }

  return result;
}

/**
 * !-----------------------------------------------!
 * !---------------Format Form Array---------------!
 * !-----------------------------------------------!
 * @param {object} form
 * @returns {array} array com novos dados da seasonCrossTable
 * @description este método é utilizado para para converter o objeto em array para ser enviado no post
 */
export function formatFormArray(form = {}, isSave = false) {
  //* obter as keys do objeto do formulário
  //* keus = ["form1", "form2", "form3"]
  const keys = Object.keys(form);
  const data = [];

  //* interar sobre as keys existentes e colocar alguns
  //* dados com o mesmo formato para conseguir comparar os objetos
  keys.forEach((e) => {
    data.push({
      ...form[e],
      pvp: Number(form[e].pvp),
      price: Number(
        updatePricePurchase(Number(form[e].pvp), Number(form[e].profit))
      ),
      enabled: 1,
      isSave: isSave,
    });
  });

  //* retorna o array com os novos dados
  return data;
}

/**
 * !---------------------------------------------------!
 * !---------------Update Price Purchase---------------!
 * !---------------------------------------------------!
 * @param {number} priceSale
 * @param {number} profit
 * @returns {number} retorna o price purchase com base no priceSale e no profit
 * @info pvp = priceSale; price = pricePurchase
 * @description este método é utilizado apenas para visualização no
 * formulário e é o valor que será introduzido na seasonCrossTable.
 * @equation pricePurchase = priceSale * (1 - (profit / 100))
 */
export function updatePricePurchase(priceSale = 0, profit = 0) {
  //* se o priceSale não for válido é substituido por 0
  const dPriceSale = lodash.isNaN(Number(priceSale))
    ? 0
    : Number(priceSale) ?? 0;

  //* se o profit não for válido é substituido por 0
  //* passar a % para decimal
  const dProfit = lodash.isNaN(Number(profit)) ? 0 : Number(profit) / 100 ?? 0;

  return Number((dPriceSale * (1 - dProfit)).toFixed(4));
}

/**
 * !---------------------------------------------------!
 * !------------------is updated form------------------!
 * !---------------------------------------------------!
 * @param {array} sct seasonCrossTable
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @param {integer} fkCountry
 * @param {object} values form values
 * @returns {boolean} true se o formulário estiver atualizado
 * @returns {boolean} false se o formulário não estiver atualizado
 */
export function isUpdatedForm(
  sct,
  fkProduct,
  fkComposition,
  fkCountry,
  values,
  sctImage,
  initImages
) {
  const sctByCountry = getSeasonCrossTableByCountry(
    sct,
    fkProduct,
    fkComposition,
    fkCountry
  );

  const a = [];

  const b = [];

  sctByCountry.forEach((e) => {
    a.push(
      lodash.omit(
        {
          ...e,
          pvp: Number(Number(e.pvp).toFixed(2)),
          oldref: "",
          discount: Number(e.discount),
          price: Number(e?.price).toFixed(2),
          images: [
            ...(createImageData(
              sctImage || [],
              [],
              e.seasonCrosstablePK.fkReference
            ) || []),
          ].sort(function (a, b) {
            return a - b;
          }),
        },
        ["exist", "vat", "enabled", "oldref", "enabled", "isSave"]
      )
    );
  });

  formatFormArray(values).forEach((e) => {
    b.push(
      lodash.omit(
        {
          ...e,
          price: Number(e.price).toFixed(2),
          pvp: Number(Number(e.pvp).toFixed(2)),
          profit: Number(e.profit),
          discount: Number(e.discount),
          images: [...(e?.images || [])].sort(function (a, b) {
            return a - b;
          }),
        },
        ["exist", "vat", "enabled", "oldref", "enabled", "isSave"]
      )
    );
  });

  /*console.log("a", a);
  console.log("b", b);*/

  return lodash.isEqual(a, b);
}

/**
 *
 * @param {*} array
 * @param {*} id
 * @returns
 * @description este método é utilizado na seleção de imagens para as referências
 */
export function findIdInArray(array = [], id) {
  const exist = array.find((e) => e.image === id.image);

  if (exist === undefined) return false;

  return true;
}
