import React from "react";
import { useTranslation } from "react-i18next";
import { P } from "../Element.styles";

function Price() {
  const { t } = useTranslation(["informationPages/price"]);
  return (
    <>
      <P dangerouslySetInnerHTML={{ __html: t("body") }} />
    </>
  );
}

export default Price;
