import axios from "axios";

export const ProducersEndpoints = (initPath) => {
  const PATH = `${initPath}/producerendpoint`;

  const create_or_update = async (data) =>
    axios.post(`${PATH}/create_or_update`, data);

  const getAllProducersBySupplier = async (fkSupplier) =>
    axios.get(`${PATH}/getAllProducersBySupplier/${fkSupplier}`);

  const getAllProducersType = async () =>
    await axios.get(`${PATH}/getAllProducersType`);

  return {
    create_or_update,
    getAllProducersBySupplier,
    getAllProducersType,
  };
};

export const ProducerLanguagesEndpoints = (initPath) => {
  const PATH = `${initPath}/producerlanguageendpoint`;

  const create_or_update = async (data) =>
    axios.post(`${PATH}/create_or_update`, data);

  const getAllLanguagesByProducer = async (fkProducer) =>
    axios.get(`${PATH}/getAllLanguagesByProducer/${fkProducer}`);

  return {
    create_or_update,
    getAllLanguagesByProducer,
  };
};
