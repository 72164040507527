import axios from "axios";

export const SeasonCrossTableEndpoints = (initPath) => {
  const PATH = `${initPath}/seasoncrosstableendpoint`;

  const create_or_update = async (data) =>
    await axios.put(`${PATH}/create_or_update`, data);

  const getAllSeasonCrosstableBySeason = async (fkSeason) =>
    await axios.get(`${PATH}/getAllSeasonCrosstableBySeason/${fkSeason}`);

  const getAllCountries = async (fkLanguage) =>
    await axios.get(`${PATH}/getAllCountries/${fkLanguage}`);

  const create_season_crosstable_image = async (data) =>
    await axios.put(`${PATH}/create_season_crosstable_image`, data);

  const delete_season_crosstable_image = async (data) =>
    await axios.put(`${PATH}/delete_season_crosstable_image`, data);

  const getAllImagesByComposition = async (fkSupplier, fkComposition) =>
    await axios.get(
      `${PATH}/getAllImagesByComposition/${fkSupplier}/${fkComposition}`
    );

  const getAllSeasonCrosstableImage = async (
    fkSupplier,
    fkComposition,
    fkCountry,
    fkSeason
  ) =>
    await axios.get(
      `${PATH}/getAllSeasonCrosstableImage/${fkSupplier}/${fkComposition}/${fkCountry}/${fkSeason}`
    );

  return {
    create_or_update,
    getAllSeasonCrosstableBySeason,
    getAllCountries,
    create_season_crosstable_image,
    delete_season_crosstable_image,
    getAllSeasonCrosstableImage,
    getAllImagesByComposition,
  };
};
