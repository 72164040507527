import axios from "axios";

export function DashboardEndpoints(initPath) {
  const PATH = `${initPath}/dashboardendpoint`;

  const getDataDashboard = async (type) =>
    await axios.get(`${PATH}/getDataDashboard/${type}`);

  const getValuesDashboard = async () =>
    await axios.get(`${PATH}/getValuesDashboard`);

  return {
    getDataDashboard,
    getValuesDashboard,
  };
}
