/* eslint-disable react/prop-types */
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import "./responsive.css";
import "react-quill/dist/quill.snow.css";
import "react-phone-number-input/style.css";
//i18n
import "./i18n";
import { Alert } from "react-bootstrap";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  containerStyle: {
    zIndex: 999,
  },
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const AlertTemplate = ({ options, message, close }) => (
  <Alert
    style={{ width: "25rem" }}
    className="m-1"
    onClose={() => close()}
    variant={
      options.type === "info"
        ? "info"
        : options.type === "success"
        ? "success"
        : options.type === "error"
        ? "danger"
        : "secondary"
    }
  >
    {message}
  </Alert>
);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
