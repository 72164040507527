//? REACT-BOOTSTRAP
import { Row, Col, Container } from "react-bootstrap";

//? LODASH
import lodash from "lodash";

//? I18NEXT
import { useTranslation } from "react-i18next";

//? PIN2GIVE FORM
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";

import { useQuery } from "react-query";
import { useServices } from "../../../../services/useServices";

const Form = ({ formik, editable }) => {
  //*i18Next
  const { t } = useTranslation(["pm/producers", "countries"]);

  const { producersEndpoints, getLanguage, seasonCrossTableEndpoints } =
    useServices();

  //!----------------------------------------!\\
  //!----------------COUNTRIES---------------!\\
  //!----------------------------------------!\\
  const { data: countries } = useQuery("getCountries", () =>
    seasonCrossTableEndpoints.getAllCountries(getLanguage())
  );

  //!----------------------------------------!\\
  //!----------------PRODUCERS---------------!\\
  //!----------------------------------------!\\
  const { data: ptype } = useQuery("ptype", () =>
    producersEndpoints.getAllProducersType()
  );

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          {
            //!------------------------------------------!\\
            //!----------------DESIGNATION---------------!\\
            //!------------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.designation.label")}
              inputProps={{
                name: "designation",
                value: formik.values?.designation ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                readOnly: !editable,
              }}
              error={formik.errors.designation}
            />
          </Col>
          {
            //!------------------------------------!\\
            //!----------------EMAIL---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.email.label")}
              inputProps={{
                name: "email",
                value: lodash.isEmpty(formik.values?.email)
                  ? ""
                  : formik.values?.email,
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                readOnly: !editable,
              }}
              error={formik.errors.email}
            />
          </Col>
          {
            //!-----------------------------------!\\
            //!----------------LINK---------------!\\
            //!-----------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Input
              label={t("fields.url.label")}
              inputProps={{
                name: "url",
                value: formik.values?.url ?? "asdasdasd",
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                readOnly: !editable,
              }}
              error={formik?.errors?.url}
            />
          </Col>
          {
            //!-----------------------------------!\\
            //!----------------TYPE---------------!\\
            //!-----------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Select
              label={t("fields.fkPtype.label")}
              inputProps={{
                name: "fkPtype",
                value: formik.values?.fkPtype ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                disabled: !editable,
              }}
              error={formik?.errors?.fkPtype}
            >
              <option value="0">{t("fields.fkPtype.placeholder")}</option>
              {ptype?.data.map((e, index) => (
                <option key={index} value={e?.idPtype}>
                  {e[`${getLanguage().toLowerCase()}`]}
                </option>
              ))}
            </Pin2GiveForm.Select>
          </Col>
          {
            //!--------------------------------------!\\
            //!----------------COUNTRY---------------!\\
            //!--------------------------------------!\\
          }
          <Col xs="12" lg="6">
            <Pin2GiveForm.Select
              label={t("fields.fkCountry.label")}
              inputProps={{
                name: "fkCountry",
                value: formik.values?.fkCountry ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                disabled: !editable,
              }}
              error={formik?.errors?.fkCountry}
            >
              <option value="-1">{t("fields.fkCountry.placeholder")}</option>
              {countries?.data.map((e, index) => (
                <option key={index} value={e?.idCountry}>
                  {e[`${getLanguage().toLowerCase()}`]}
                </option>
              ))}
            </Pin2GiveForm.Select>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default Form;
