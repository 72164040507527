import { Alert, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaBox, FaFileInvoice, FaWind } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { CardMenu } from "../ProductsManagement.style";

const ProtocolError = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["pm/suppliers"]);
  return (
    <div>
      <Container className="mt-5">
        <Header
          left={{ menuButton: true, backButton: true }}
          center={{ title: t("title") }}
          infoKey="seasonCrossTable"
        />
        <Row className="mt-5">
          <Col xs="12">
            <Alert variant="danger">
              <h5 className="text-center">{t("protocolError")}</h5>
            </Alert>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xs="12" md="4" className="mb-5">
            <CardMenu.Container
              onClick={() => {
                navigate("/productsmanagement/products");
              }}
            >
              <CardMenu.Icon>
                <FaBox />
              </CardMenu.Icon>
              <h5>{t("cards.products")}</h5>
            </CardMenu.Container>
          </Col>
          <Col xs="12" md="4" className="mb-5">
            <CardMenu.Container
              onClick={() => {
                navigate("/productsmanagement/references/-");
              }}
            >
              <CardMenu.Icon>
                <FaFileInvoice />
              </CardMenu.Icon>
              <h5>{t("cards.references")}</h5>
            </CardMenu.Container>
          </Col>
          <Col xs="12" md="4" className="mb-5">
            <CardMenu.Container
              onClick={() => {
                navigate("/productsmanagement/createseasons");
              }}
            >
              <CardMenu.Icon>
                <FaWind />
              </CardMenu.Icon>
              <h5>{t("cards.createSeasons")}</h5>
            </CardMenu.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProtocolError;
