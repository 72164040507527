import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Composition from "./Contents/composition";
import DescriptionInvoice from "./Contents/descriptionInvoice";
import Price from "./Contents/price";
import PriceProfit from "./Contents/priceProfit";
import PriceSale from "./Contents/priceSale";
import Produtos from "./Contents/produtos";
import Pvp from "./Contents/pvp";
import References from "./Contents/references";
import SeasonCrossTable from "./Contents/seasonCrossTable";
import Seasons from "./Contents/temporada";
import Element from "./Element";

function InformartionPage({ infoKey, adicionalItem }) {
  const [body, setBody] = useState(<>No Content</>);

  useEffect(() => {
    switch (infoKey) {
      case "reference":
        setBody(<References />);
        break;
      case "descriptionInvoice":
        setBody(<DescriptionInvoice />);
        break;
      case "composition":
        setBody(<Composition />);
        break;
      case "products":
        setBody(<Produtos adicionalItem={adicionalItem} />);
        break;
      case "pvp":
        setBody(<Pvp />);
        break;
      case "priceSale":
        setBody(<PriceSale />);
        break;
      case "price":
        setBody(<Price />);
        break;
      case "priceProfit":
        setBody(<PriceProfit />);
        break;
      case "seasons":
        setBody(<Seasons />);
        break;
      case "seasonCrossTable":
        setBody(<SeasonCrossTable />);
        break;
      default:
        setBody(<>No Content</>);
    }
  }, [infoKey, adicionalItem]);

  return <Element body={body} />;

  // quando utilizar, substiruir o componente <></>
  // por o componente <Element/>
}

export default InformartionPage;
