import axios from "axios";

export const InvoicesEndpoints = (initPath) => {
  const endpoint = `${initPath}/invoicesendpoint`;

  //!----------------------------------------------------------!\\
  //!---------------------------GETS---------------------------!\\
  //!----------------------------------------------------------!\\
  const downloadDocumentInvoice = async (typeDoc, invoiceCode) =>
    await axios.get(
      `${endpoint}/downloadDocumentInvoice/${typeDoc}/?invoice_code=${invoiceCode}`,
      {
        responseType: "blob",
      }
    );

  const referencesSales = async (fkSupplier) =>
    await axios.get(`${endpoint}/referencesSales/${fkSupplier}`);

  const getreferencesForCreditNoteByInvoice = async (fkSupplier, fkInvoice) =>
    await axios.get(
      `${endpoint}/getreferencesForCreditNoteByInvoice/${fkSupplier}/?id_invoice=${fkInvoice}`
    );

  const returnFileSaft = async (fkSupplier, year, bmonth, emonth) =>
    await axios.get(
      `${endpoint}/returnfileSaft/${fkSupplier}/${year}/${bmonth}/${emonth}`
    );

  //!----------------------------------------------------------!\\
  //!--------------------------POSTS---------------------------!\\
  //!----------------------------------------------------------!\\
  const returnInvoices = async (data) =>
    await axios.post(`${endpoint}/returnInvoices`, data, {
      headers: {
        Accept: "*/*",
      },
    });

  const NewCreditNote = async (data) =>
    axios.post(`${endpoint}/NewCreditNote`, data);

  //!----------------------------------------------------------!\\
  //!---------------------------PUTS---------------------------!\\
  //!----------------------------------------------------------!\\
  const generateCreditNote = async (fkSupplier) =>
    await axios.put(`${endpoint}/generateCreditNote/${fkSupplier}`);

  return {
    returnInvoices,
    downloadDocumentInvoice,
    returnFileSaft,
    generateCreditNote,
    referencesSales,
    getreferencesForCreditNoteByInvoice,
    NewCreditNote,
  };
};
