//? YUP
import * as Yup from "yup";

export const validationSchema = (t) =>
  Yup.object().shape({
    designation: Yup.string()
      .max(64, t("fields.designation.errors.max"))
      .required(t("fields.designation.errors.required")),
    email: Yup.string().email(t("fields.email.errors.email")).nullable(),
    url: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      t("fields.url.errors.url")
    ),
    fkPtype: Yup.number()
      .integer(t("fields.fkPtype.errors.integer"))
      .min(1, t("fields.fkPtype.errors.min"))
      .required(t("fields.fkPtype.errors.required"))
      .typeError(t("fields.fkPtype.errors.typeError")),
    fkCountry: Yup.number()
      .integer(t("fields.fkCountry.errors.integer"))
      .min(0, t("fields.fkCountry.errors.min"))
      .required(t("fields.fkCountry.errors.required"))
      .typeError(t("fields.fkCountry.errors.typeError")),
  });

export const validationSchemaLanguages = (t) =>
  Yup.object().shape({
    EN: Yup.object().shape({
      designation: Yup.string(),
      description: Yup.string(),
    }),
    PT: Yup.object().shape({
      designation: Yup.string().required(
        t("fields.designationLG.errors.required")
      ),
      description: Yup.string().required(
        t("fields.descriptionLG.errors.required")
      ),
    }),
    ES: Yup.object().shape({
      designation: Yup.string(),
      description: Yup.string(),
    }),
    DE: Yup.object().shape({
      designation: Yup.string(),
      description: Yup.string(),
    }),
    FR: Yup.object().shape({
      designation: Yup.string(),
      description: Yup.string(),
    }),
    IT: Yup.object().shape({
      designation: Yup.string(),
      description: Yup.string(),
    }),
  });
