import {
  Alert,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownLang from "../../../components/DropdownLang/DropdownLang";
import { Brand, Container, Header } from "../login.styles";
import Logo from "../../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import IntroduceEmail from "./_steps/IntroduceEmail";
import IntroducePassword from "./_steps/IntorducePassword";
import { Button } from "react-bootstrap";

// import { Container } from './styles';

function RecoverPassword() {
  //!-------------------------------------------------------!\\
  //!---------------------------hooks-----------------------!\\
  //!-------------------------------------------------------!\\
  //* navigate
  const navigate = useNavigate();

  //* translation
  const { t } = useTranslation(["recoverPassword"]);

  //* location
  const location = useLocation();

  //!-------------------------------------------------------!\\
  //!---------------------------state-----------------------!\\
  //!-------------------------------------------------------!\\
  //* é onde ficará armazenado o token
  //* introduzido no query paremeter
  const [token, setToken] = useState("");

  //* o type diferencia os diferentes passos
  //* 0 -> introduzir email
  //* 1 -> verificar email
  //* 2 -> introduzir password
  const [type, setType] = useState(0);

  //!-------------------------------------------------------!\\
  //!---------------------------params----------------------!\\
  //!-------------------------------------------------------!\\
  //* searchParams
  const SearchParams = new URLSearchParams(location.search);

  //* type
  const dtype = SearchParams.get("type");

  //* tokens
  const dToken = SearchParams.get("token");

  //!-------------------------------------------------------!\\
  //!---------------------------steps-----------------------!\\
  //!-------------------------------------------------------!\\
  const steps = [
    {
      label: t("steps.s1"),
      Component: () => <IntroduceEmail nextStep={() => {}} />,
    },
    {
      label: t("steps.s2"),
      Component: () => (
        <>
          <Alert severity="warning">{t("alerts.verifyEmail")}</Alert>
          <div className="text-end mt-3">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => navigate("/user/recoverpassword?type=0")}
            >
              {t("buttons.back")}
            </Button>
            <Button
              size="sm"
              variant="pin2give"
              className="ms-2"
              onClick={() => navigate("/user/login")}
            >
              {t("buttons.jrp")}
            </Button>
          </div>
        </>
      ),
    },
    {
      label: t("steps.s3"),
      Component: () => <IntroducePassword token={token} />,
    },
  ];

  //!-------------------------------------------------------!\\
  //!------------------------useEffects---------------------!\\
  //!-------------------------------------------------------!\\
  //* é utilizado quando o type e o token passado
  //* no query parameter é alterado
  useEffect(() => {
    //* se o type for null -> altera para 0;
    //* se o type não for null-> converte para
    //* número e adiciona à variável
    if (dtype !== null) {
      setType(Number(dtype));
    } else {
      setType(0);
    }

    //* se o token for null -> altera para string vazia
    //* se o token não for null -> adiciona o token à variável
    if (dToken !== null) {
      setToken(dToken);
    } else {
      setToken("");
    }
  }, [dtype, dToken]);

  return (
    <Container>
      <Header>
        <Tooltip title="Home" placement="right">
          <IconButton onClick={() => navigate("/")}>
            <FaHome />
          </IconButton>
        </Tooltip>
        <div>
          <DropdownLang />
        </div>
      </Header>
      <Brand.Container>
        <Brand.Logo src={Logo} alt="logo" />
        <Brand.Title>{t("title")}</Brand.Title>
        <Stepper
          orientation="vertical"
          activeStep={type}
          sx={{ width: "100%", mt: 5 }}
        >
          {steps.map((e, index) => (
            <Step key={index}>
              <StepLabel>{e.label}</StepLabel>
              <StepContent>
                <e.Component />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Brand.Container>
    </Container>
  );
}

export default RecoverPassword;
