//components && styles
import { Container, Row, Col, Spinner } from "react-bootstrap";
import BackButton from "../../../components/BackButton/BackButton";
import { CardStore } from "../../../components/Cards/Cards";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ProviderTooltip from "../../../components/ProviderTooltip/ProviderTooltip";

//hooks, libraries & endpoints
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import lodash from "lodash";
import { useServices } from "../../../services/useServices";

const Products = () => {
  const navigate = useNavigate();
  const { provider } = useParams();
  const { t, i18n } = useTranslation();
  const { productsWebEndpoints, getLanguage } = useServices();

  const [providerProducts, setProviderProducts] = useState({
    data: null,
    loading: true,
  });
  const [searchData, setSearchData] = useState({ data: null, loading: true });
  const [search, setSearch] = useState("");

  //get data
  useEffect(() => {
    productsWebEndpoints
      .wshopstorebysupplier(
        provider,
        getLanguage(),
        localStorage.getItem("country_code")
      )
      .then((response) => {
        //console.log(response?.data);
        setProviderProducts({
          data: response?.data,
          loading: false,
          status: response?.status,
        });
      })
      .catch((error) => {
        //console.log("Erro ao obter dados do supplier", error);
        setProviderProducts({ data: null, loading: false });
      });
  }, [provider, i18n.language]);

  useEffect(() => {
    if (!lodash.isEmpty(search)) {
      productsWebEndpoints
        .wfindProducts(
          search,
          getLanguage(),
          localStorage.getItem("country_code")
        )
        .then((response) =>
          setSearchData({
            data: response?.data,
            loading: false,
            status: response?.status,
          })
        )
        .catch(() => setSearchData({ data: null, loading: false }));
    }
  }, [search, i18n.language]);

  return (
    <div className="app-store-products">
      <Container>
        <Row className="justify-content-between mt-5 sticky-top bg-white py-3">
          <Col
            xs="2"
            md="2"
            lg="1"
            className="justify-content-center d-flex align-items-center"
          >
            <BackButton />
          </Col>
          <Col xs="10" md="6" lg="4">
            <SearchInput
              handleChange={(event) => setSearch(event.target.value)}
            />
          </Col>
        </Row>
        {!lodash.isEmpty(search) ? (
          <>
            <Row className="">
              <Col xs="12" className="text-left mt-3 mb-3">
                <span className="app-sub-title">
                  {t("Search.ResultsFor") + " " + search}:
                </span>
              </Col>
            </Row>
            <Row id="Search" className="justify-content-center px-lg-5">
              {searchData?.loading ? (
                <Col xs="12" className="text-center">
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                </Col>
              ) : lodash.isEmpty(searchData?.data) ? (
                <Col xs="12">
                  {lodash.isEqual(searchData?.status, 204)
                    ? t("Store.EmptyLanguage.data")
                    : t("Store.Empty.Search")}
                </Col>
              ) : (
                searchData?.data.map((e, index) => (
                  <Col
                    xs="9"
                    sm="6"
                    md="5"
                    lg="4"
                    xl="3"
                    key={index}
                    className="mb-5 px-4"
                  >
                    <CardStore
                      title={e?.designation}
                      image={e?.fk_default_image}
                      price={e?.price}
                      symbol={e?.symbol}
                      onClick={() =>
                        navigate("/store/products/details/" + e?.id_product)
                      }
                    />
                  </Col>
                ))
              )}
            </Row>
          </>
        ) : (
          <Row className="justify-content-center justify-content-lg-start">
            <Col xs="12" className="text-left mt-3 mb-3">
              <span className="app-sub-title">
                <span>
                  {providerProducts?.data?.title}
                  <span>
                    <ProviderTooltip
                      icon={<BsFillInfoCircleFill size={20} />}
                      title={providerProducts?.data?.title}
                      description={providerProducts?.data?.description}
                    />
                  </span>
                </span>
                <h4 className="p-0 m-0">{t("Store.products")}</h4>
              </span>
            </Col>
            {providerProducts?.loading ? (
              <Col xs="12" className="text-center">
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
              </Col>
            ) : lodash.isEmpty(providerProducts?.data?.lprodutos) ? (
              <Col xs="12">
                {lodash.isEqual(providerProducts?.status, 204)
                  ? t("Store.EmptyLanguage.data")
                  : t("Store.Empty.Supplier")}
              </Col>
            ) : (
              providerProducts?.data?.lprodutos.map((e, index) => (
                <Col
                  xs="9"
                  sm="6"
                  md="5"
                  lg="4"
                  xl="3"
                  key={index}
                  className="mb-5  px-5 px-sm-3  "
                >
                  <CardStore
                    image={e?.fk_default_image}
                    title={e?.designation}
                    price={e?.price}
                    key={e}
                    onClick={() =>
                      navigate("/store/products/details/" + e?.id_product)
                    }
                  />
                </Col>
              ))
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Products;
