import "./ButtonProductDetails.css";
import { FiShoppingCart } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const ButtonWhite = (props) => {
  const { icon } = props;
  return (
    <button {...props} className="app-button-white">
      {icon}
    </button>
  );
};

const ButtonCart = (props) => {
  const { t } = useTranslation();
  return (
    <button {...props} className="app-button-cart">
      <span className="app-button-cart-icon">
        <FiShoppingCart />
      </span>
      <span className="app-button-cart-label">{t("Store.buy")}</span>
    </button>
  );
};

const ButtonColorPicker = (props) => {
  const { active, colorref } = props;

  return (
    <button
      {...props}
      className={
        active ? "app-button-color-picker active" : "app-button-color-picker"
      }
    >
      <div className="color-ref" style={{ backgroundColor: colorref }} />
    </button>
  );
};

const ButtonImagePicker = (props) => {
  const { active, imagesrc } = props;
  return (
    <button
      {...props}
      className={
        active ? "app-button-image-picker active" : "app-button-image-picker"
      }
    >
      <div className="image-ref">
        <img src={imagesrc} alt={imagesrc} />
      </div>
    </button>
  );
};

export { ButtonWhite, ButtonCart, ButtonColorPicker, ButtonImagePicker };
