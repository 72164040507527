import React from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import useSuppliers from "../../../contexts/ProductsManagement/Suppliers/useSuppliers";

// import { Container } from './styles';

function Title({ title, onClick, editable, onActive, onSuspend }) {
  const { suppliers, protocol } = useSuppliers();
  const { role } = useAuth();
  const { t } = useTranslation(["pm/suppliers"]);

  const getBadge = (state) => {
    switch (state) {
      case 1:
        return (
          <Badge pill bg="primary" size="sm">
            {t("badges.pending")}
          </Badge>
        );
      case 3:
        return (
          <Badge pill bg="success" size="sm">
            {t("badges.approved")}
          </Badge>
        );
      case 4:
        return (
          <Badge pill bg="secondary" size="sm">
            {t("badges.suspended")}
          </Badge>
        );
      case 5:
        return (
          <Badge pill bg="info" text="dark" size="sm">
            {t("badges.archived")}
          </Badge>
        );
      default:
        return (
          <Badge pill bg="secondary" size="sm">
            {t("badges.created")}
          </Badge>
        );
    }
  };

  const getButtonsStates = (state) => {
    switch (state) {
      case 3:
        return (
          role === 4 && (
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={onSuspend}
            >
              {t("buttons.suspend")}
            </Button>
          )
        );

      default:
        return (
          protocol?.data?.enabled === 1 &&
          role === 4 && (
            <Button
              variant="success"
              size="sm"
              className="me-2"
              onClick={onActive}
            >
              {t("buttons.approve")}
            </Button>
          )
        );
    }
  };
  return (
    <div className="app-sub-title w-100 container-fluid">
      <Col xs="12">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {title}{" "}
            <div className="h6 m-0 ms-2">
              {getBadge(suppliers.selected.state)}
            </div>
          </div>

          <div>
            <Row>
              <Col xs="12" className="text-end">
                {getButtonsStates(suppliers.selected.state)}
                {!editable && (
                  <Button
                    size="sm"
                    className="rounded-circle"
                    variant="warning"
                    onClick={onClick}
                  >
                    <FaEdit />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </div>
  );
}

export default Title;
