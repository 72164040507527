//? REACT
import React, { useState } from "react";

//? REACT-BOOTSTRAP
import { Container, Row, Col, Button } from "react-bootstrap";

//? REACT-ICONS
import { FaArrowUp } from "react-icons/fa";

//? REACT-ROUTER-DOM
import { useNavigate } from "react-router-dom";

//? LODASH
import lodash from "lodash";

//? COMPONENTS
import Header from "../Header";
import Pin2GiveForm from "../../../components/Pin2GiveForm/Pin2GiveForm";

//? CONFIG ENDPOINTS
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";
import OverlayConfirm from "../OverlayConfirm";
import ImagesList from "./_lists/ImagesList";
import CropImage from "./_cropper/CropImage";
import { ImagePreview } from "./_cropper/styles";
import { AnimationArrow, InputFileLabel, SaveButton } from "./styles";

//?---------------PAGE---------------?\\
const UploadImages = () => {
  //!-----------------------------------------------------!\\
  //!------------------------hooks------------------------!\\
  //!-----------------------------------------------------!\\
  //* supplier data
  const { fkSupplier } = usePM();

  //* services
  const { referencesEndpoints } = useServices();

  //* navigate
  const navigate = useNavigate();

  //* alert
  const alert = useAlert();

  //* translation
  const { t } = useTranslation(["pm/uploadimages"]);

  //!-----------------------------------------------------!\\
  //!------------------------states-----------------------!\\
  //!-----------------------------------------------------!\\
  //* newImage (base64 da nova imagem)
  const [newImage, setNewImage] = useState("");

  //* nome da imagem
  const [name, setName] = useState("");

  //* validação do nome da imagem
  const [erros, setErrors] = useState();

  //* cropper (onde fica guardado o base64 da imagem cortada)
  const [cropper, setCropper] = useState();

  //* overlay utilizado no remover
  const [overlayConfirm, setOverlayConfirm] = useState({
    title: "",
    content: "",
    v2: "true",
    primaryButtonLabel: "",
    event: () => {},
    show: false,
  });

  const handleCloseOverlay = () =>
    setOverlayConfirm((e) => ({ ...e, show: false }));

  //!-----------------------------------------------------!\\
  //!------------------------methods----------------------!\\
  //!-----------------------------------------------------!\\
  //* handleChange image
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setNewImage(reader.result);
      setName(files[0].name.replace(/\.[^/.]+$/, "").substring(0, 50));
    };
    reader.readAsDataURL(files[0]);
  };

  //* obter o base64 da imagem cortada
  const getCropData = () => {
    return cropper.getCroppedCanvas().toDataURL();
  };

  //* save button click
  const saveButtonClick = () => {
    //* se não houver nome, aparece erro e não regista imagem
    if (lodash.isEmpty(name)) {
      setErrors(t("field.error"));
    } else {
      //* se não houver erro
      //* a imagem é introduzida
      const data = {
        fkSupplier: fkSupplier,
        id: -1,
        nameFile: name,
        imageBase64: getCropData(),
        isRemove: 0,
      };
      handleSubmit(data);
      setErrors("");
    }
  };

  //!-----------------------------------------------------!\\
  //!------------------------requests---------------------!\\
  //!-----------------------------------------------------!\\
  //* images
  const { data: images, refetch: refetchImages } = useQuery(
    ["getSupplierImages", fkSupplier],
    () => referencesEndpoints.getAllImagesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //* submit
  const { mutate: handleSubmit } = useMutation(
    (data) => {
      return referencesEndpoints.managementImage(data);
    },
    {
      onSuccess: (response, variables) => {
        const { data } = response;

        //* se os dados inviados for uma remoção (isRemove === 1)
        //* e se o isRemove que vem na resposta for 2
        //* é porque deu erro ao introduzir o ojeto
        //* normalmente dá erro porque a imagem está a ser utilizada
        if (variables.isRemove === 1) {
          if (data?.isRemove === 2) {
            return alert.error(t("alerts.error.remove"));
          } else {
            return alert.success(t("alerts.success.remove"));
          }
        }
        setNewImage("");

        alert.success(
          data.isRemove === 1
            ? t("alerts.success.remove.message")
            : t("alerts.success.save.message")
        );
      },
      onSettled: () => {
        refetchImages();
      },
    }
  );

  return (
    <Container>
      {
        //!-----------------------------------------------------!\\
        //!-------------------------Header----------------------!\\
        //!-----------------------------------------------------!\\
      }
      <Header
        left={{ backButton: true, menuButton: true }}
        center={{ title: t("header") }}
      />
      <Row>
        {
          //!-----------------------------------------------------!\\
          //!----------------------Pin2GiveList-------------------!\\
          //!-----------------------------------------------------!\\
        }
        <Col xs="12" lg="3" className="mb-5 text-center">
          <ImagesList
            images={lodash.isEmpty(images?.data) ? [] : images?.data}
            overlayConfirm={{
              set: setOverlayConfirm,
              handleClose: handleCloseOverlay,
            }}
            handleSubmit={handleSubmit}
          />
          <Button
            variant="secondary"
            className="mt-5"
            onClick={() => navigate("/productsmanagement")}
          >
            {t("buttons.back")}
          </Button>
        </Col>
        {
          //!-----------------------------------------------------!\\
          //!-------------------------Content---------------------!\\
          //!-----------------------------------------------------!\\
        }
        <Col xs="12" lg="9" className="h-100">
          <Container fluid className="active-reference-container">
            <Row className="justify-content-center">
              {
                //!-----------------------------------------------------!\\
                //!----------------------Upload Button------------------!\\
                //!-----------------------------------------------------!\\
              }
              <Col xs="12" className="mb-3 text-center">
                <InputFileLabel>
                  <input
                    id="inputFile-imagesReferences"
                    type="file"
                    onChange={onChange}
                    accept="image/jpeg, image/png"
                  />
                  {t("buttons.ui")}
                </InputFileLabel>
              </Col>

              {
                //!-----------------------------------------------------!\\
                //!----------------------Arrow Animated-----------------!\\
                //!-----------------------------------------------------!\\
                lodash.isEmpty(newImage) ? (
                  <Col className=" mt-5">
                    <AnimationArrow>
                      <FaArrowUp size={30} />
                    </AnimationArrow>
                  </Col>
                ) : (
                  <>
                    {
                      //!-----------------------------------------------------!\\
                      //!---------------------------Name----------------------!\\
                      //!-----------------------------------------------------!\\
                    }
                    <Col xs="7" className="align-self-center mt-5">
                      <Pin2GiveForm.Input
                        label="Image Name"
                        inputProps={{
                          value: name,
                          onChange: (e) => setName(e.target.value),
                          maxLength: "50",
                        }}
                        error={erros}
                      />
                    </Col>
                    {
                      //!-----------------------------------------------------!\\
                      //!------------------------Crop Image-------------------!\\
                      //!-----------------------------------------------------!\\
                    }
                    <Col xs="12" lg="6">
                      <Container>
                        <Row>
                          <Col xs="12">
                            <div className="mb-3 text-center text-muted h5">
                              {t("cropImage")}
                            </div>
                          </Col>
                          <Col
                            xs="12"
                            className="d-flex justify-content-center mb-5"
                          >
                            <CropImage
                              setCropper={setCropper}
                              newImage={newImage}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    {
                      //!-----------------------------------------------------!\\
                      //!-----------------------Preview Image-----------------!\\
                      //!-----------------------------------------------------!\\
                    }
                    <Col xs="12" lg="6">
                      <Container>
                        <Row>
                          <Col xs="12">
                            <div className="mb-3 text-center text-muted h5">
                              {t("previewImage")}
                            </div>
                          </Col>
                          <Col
                            xs="12"
                            className="d-flex justify-content-center"
                          >
                            <ImagePreview className="img-preview" />
                          </Col>
                          <Col xs="12" className="mt-4 text-center">
                            <SaveButton onClick={saveButtonClick}>
                              {t("buttons.save")}
                            </SaveButton>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </>
                )
              }
            </Row>
          </Container>
        </Col>
      </Row>
      <OverlayConfirm
        title={overlayConfirm.title}
        content={overlayConfirm.content}
        show={overlayConfirm.show}
        handleCloseConfirm={handleCloseOverlay}
        onSelect={overlayConfirm.event}
        v2={overlayConfirm.v2}
        primaryButtonLabel={overlayConfirm.primaryButtonLabel}
      />
    </Container>
  );
};

export default UploadImages;
