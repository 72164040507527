//? REACT-BOOTSTRAP
import { Col, Container, Row, Button, Alert, Spinner } from "react-bootstrap";

//? REACT-ICONS
import { FaBarcode, FaSave } from "react-icons/fa";

//? I18Next
import { useTranslation } from "react-i18next";
import Pin2GiveForm from "../../../components/Pin2GiveForm/Pin2GiveForm";
import { useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import References from "./References";
import { useEffect, useState } from "react";

//?-------------------------FORM------------------------------?\\
const Form = (props) => {
  //!---------------------------------------------!\\
  //!--------------------Data---------------------!\\
  //!---------------------------------------------!\\
  //* props
  const {
    fields,
    fkProduct,
    formik,
    editable,
    fkSupplier,
    loadingCreate,
    handleCancel,
  } = props;

  //* services
  const { productCompositionsEndpoints, referencesEndpoints } = useServices();

  //* useTranslation
  const { t } = useTranslation(["pm/compositions"]);

  //!---------------------------------------------!\\
  //!--------------------alert--------------------!\\
  //!---------------------------------------------!\\
  const [info, setInfo] = useState(false);

  //!---------------------------------------------!\\
  //!--------------Shipping Scales----------------!\\
  //!---------------------------------------------!\\
  const { data: shippingScales } = useQuery(
    "getShippingScales",
    productCompositionsEndpoints.getAllShippingScales
  );

  //!---------------------------------------------!\\
  //!-------------------Images--------------------!\\
  //!---------------------------------------------!\\
  const { data: images } = useQuery(
    ["getSupplierImages", formik.values?.fkProduct],
    () => referencesEndpoints.getAllImagesByIdProduct(fkProduct),
    {
      enabled: fkSupplier !== -1,
    }
  );

  useEffect(
    (e) => {
      if ((!formik.values?.dimage || formik.values?.dimage < 0) && !editable) {
        setInfo(true);
      } else {
        setInfo(false);
      }
    },
    [formik.values, editable]
  );

  return (
    <Container fluid>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12">
            <Alert
              show={info}
              onClose={() => setInfo(false)}
              variant="info"
              dismissible
            >
              {t("fields.dimage.info")}
            </Alert>
          </Col>
          {
            //!------------------------------------!\\
            //!----------------TITLE---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="3">
            <Pin2GiveForm.Input
              label={t("fields.stock.label")}
              inputProps={{
                name: "stock",
                readOnly: true,
                value: formik.values?.stock ?? "0",
              }}
            />
          </Col>
          {
            //!------------------------------------!\\
            //!----------------TITLE---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="9">
            <Pin2GiveForm.Input
              label={t("fields.stitle.label")}
              inputProps={{
                name: "stitle",
                readOnly: !editable,
                value: formik.values?.stitle ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
              }}
              error={formik.errors?.stitle}
            />
          </Col>
          {
            //!--------------------------------------!\\
            //!----------------BARCODE---------------!\\
            //!--------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.barcode.label")}
              inputProps={{
                name: "barcode",
                readOnly: !editable,
                value: formik.values?.barcode ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                help: <FaBarcode className="text-muted" />,
              }}
              error={formik.errors?.barcode}
            />
          </Col>
          {
            //!-------------------------------------------!\\
            //!----------------PRODUCT CODE---------------!\\
            //!-------------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.sproductcode.label")}
              inputProps={{
                name: "sproductcode",
                readOnly: !editable,
                value: formik.values?.sproductcode ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
              }}
              error={formik.errors?.sproductcode}
            />
          </Col>
          {
            //!---------------------------------------------!\\
            //!----------------SHIPPING SCALE---------------!\\
            //!---------------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Select
              label={t("fields.shippingscale.label")}
              inputProps={{
                disabled: !editable,
                name: "fkShippingScale",
                value: formik.values?.fkShippingScale ?? 0,
                onChange: formik.handleChange,
                help: <div className="text-muted">kg</div>,
              }}
              error={formik.errors.fkShippingScale}
            >
              <option value="-1">{t("fields.shippingscale.select")}</option>
              {!shippingScales?.data ? (
                <></>
              ) : (
                shippingScales?.data.map((e, index) => (
                  <option key={index} value={e?.idScale}>
                    {e?.description}
                  </option>
                ))
              )}
            </Pin2GiveForm.Select>
          </Col>
          {
            //!-------------------------------------!\\
            //!-----------Preparation Time----------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.preparationtime.label")}
              inputProps={{
                name: "preparationtime",
                readOnly: !editable,
                value: formik.values?.preparationtime ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                help: (
                  <div className="text-muted">
                    {t("fields.preparationtime.help")}
                  </div>
                ),
              }}
              error={formik.errors?.preparationtime}
            />
          </Col>
          {
            //!-------------------------------------!\\
            //!----------------HEIGHT---------------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.height.label")}
              inputProps={{
                name: "height",
                readOnly: !editable,
                value: formik.values?.height ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                help: <div className="text-muted">cm</div>,
              }}
              error={formik.errors?.height}
            />
          </Col>
          {
            //!------------------------------------!\\
            //!----------------WIDTH---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.width.label")}
              inputProps={{
                name: "width",
                readOnly: !editable,
                value: formik.values?.width ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                help: <div className="text-muted">cm</div>,
              }}
              error={formik.errors?.width}
            />
          </Col>
          {
            //!-------------------------------------!\\
            //!----------------LENGHT---------------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.lenght.label")}
              inputProps={{
                name: "length",
                readOnly: !editable,
                value: formik.values?.length ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                help: <div className="text-muted">cm</div>,
              }}
              error={formik.errors?.length}
            />
          </Col>
          {
            //!-------------------------------------!\\
            //!----------------WEIGHT---------------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.weight.label")}
              inputProps={{
                name: "weight",
                readOnly: !editable,
                value: formik.values?.weight ?? "",
                autoComplete: "off",
                onChange: formik.handleChange,
                help: <div className="text-muted">kg</div>,
              }}
              error={formik.errors?.weight}
            />
          </Col>
          {
            //!-------------------------------------!\\
            //!----------------DImage---------------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12">
            <Pin2GiveForm.ImagePicker
              label={t("fields.dimage.label")}
              editable={editable}
              list={[...(images?.data || [])].map((e) => ({
                id: e?.id,
                value: e?.imageBase64,
                selected: formik?.values?.dimage === e?.id,
                handleSelect: () =>
                  formik.setFieldValue("dimage", Number(e?.id)),
              }))}
              emptyListLabel={t("fields.dimage.emptyListLabel")}
              isUndefinedLabel={t("fields.dimage.na")}
              errorLabel={formik?.errors?.dimage}
            />
          </Col>
          {
            //!-----------------------------------------------------!\\
            //!--------------REFERENCES BY COMPOSITION--------------!\\
            //!-----------------------------------------------------!\\
          }

          <Col xs="12">
            {!editable && (
              <References
                editable={editable}
                fkComposition={fields?.idComposition}
                fkProduct={fkProduct}
              />
            )}
          </Col>
          {
            //!------------------------------------------!\\
            //!----------------SAVE BUTTON---------------!\\
            //!------------------------------------------!\\
            editable && (
              <Col xs="12" className="mt-5 d-flex">
                <Button
                  variant="secondary"
                  className="d-flex align-items-center ms-auto"
                  type="button"
                  onClick={handleCancel}
                >
                  {t("buttons.cancel")}
                </Button>
                <Button
                  variant="success"
                  className="d-flex align-items-center ms-2"
                  type="submit"
                >
                  {loadingCreate ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <>
                      <FaSave className="me-2" /> {t("buttons.save")}
                    </>
                  )}
                </Button>
              </Col>
            )
          }
        </Row>
      </form>
    </Container>
  );
};

export default Form;
