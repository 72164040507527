import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";

const OverlayConfirm = ({
  show,
  onSelect,
  handleCloseConfirm,
  content,
  title,
  remove,
  v2,
  primaryButtonLabel,
  secondaryButtonLabel,
}) => {
  const { t } = useTranslation(["pm/overlayConfirm"]);
  return (
    <Modal show={show} onHide={handleCloseConfirm} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <FaExclamationCircle className="me-2 text-warning" /> {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs="12" dangerouslySetInnerHTML={{ __html: content }} />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="pin2give-link"
          className="text-secondary"
          onClick={handleCloseConfirm}
        >
          {t("buttons.back")}
        </Button>
        <Button variant="pin2give" size="sm" onClick={onSelect}>
          {v2
            ? primaryButtonLabel ?? ""
            : remove
            ? t("buttons.remove")
            : t("buttons.change")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OverlayConfirm;
