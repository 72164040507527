import "./ProviderTooltip.css";
import { OverlayTrigger, Popover, Button, Row, Col } from "react-bootstrap";
import { IoPricetagOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const ProviderTooltip = (props) => {
  const { t } = useTranslation();
  const { title, viewMore, onClickViewMore, description, icon } = props;
  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      key={"bottom"}
      placement={"bottom"}
      overlay={
        <Popover
          id={`popover-positioned-$bottom`}
          className="provider-tooltip shadow"
          data-testid="provider-visible"
        >
          <Popover.Header as="div" className="container">
            <Row>
              <Col xs="9">
                {viewMore ? (
                  <>
                    {t("Store.details.provider.providedBy")}
                    <br />
                  </>
                ) : (
                  <></>
                )}
                <strong>{title}</strong>
              </Col>
              {viewMore ? (
                <Col xs="3">
                  <Button size="sm" variant="link" onClick={onClickViewMore}>
                    {t("Store.details.provider.viewProducts")}
                    <div className="d-none">view more</div>
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Popover.Header>
          <Popover.Body>
            <p
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant={viewMore ? "secondary" : "link"}
        size="sm"
        className="shadow-none"
        data-testid="provider-button"
      >
        <div className="d-flex align-items-center ">
          {viewMore ? (
            <>
              <IoPricetagOutline size={18} className="me-2" /> {title}
            </>
          ) : (
            icon
          )}
        </div>
      </Button>
    </OverlayTrigger>
  );
};

export default ProviderTooltip;
