import lodash from "lodash";

export const newReference = {
  reference: "",
  fkSupplier: -1,
  sreference: "",
  barcode: "",
  price: "",
  dprofit: 22,
  dpvp: 0,
  color: null,
  fkScale: 0,
  fkScaleValue: "",
  pattern: 0,
  warranty: 24,
  stock: 1,
  enabled: 0,
  descriptionInvoice: "",
  vat: 23,
  isnewsv: 0,
  descScale: "",
  patternBase64: "",
};

export const formatObject = (values, fkSupplier) => {
  let data = values;
  data.sreference = lodash.isEmpty(data.sreference) ? "-" : data.sreference;
  data.barcode = lodash.isEmpty(data.barcode) ? "-" : data.barcode;
  data.color = lodash.isEmpty(data.color)
    ? null
    : data.color.charAt(0) === "#"
    ? data.color.substring(1)
    : data.color;
  data.descriptionInvoice = lodash.isEmpty(data.descriptionInvoice)
    ? "-"
    : data.descriptionInvoice;
  data.warranty = Number(data.warranty);
  data.dprofit = Number(data.dprofit);
  data.vat = Number(data.vat);
  data.price = Number(data.price);
  data.stock = Number(data.stock);
  data.isnewsv = lodash.isNaN(Number(data.isnewsv)) ? 0 : Number(data.isnewsv);
  data.fkScale = Number(data.fkScale);
  data.descScale = lodash.isEmpty(data.descScale) ? null : data.descScale;
  data.fkSupplier = fkSupplier;

  if (lodash.isEmpty(data.descScale) && lodash.isEmpty(data.fkScaleValue)) {
    data.isnewsv = 0;
  }

  return data;
};

export const updatePvp = (price, profit, vat) => {
  /*
        price / (1-(profit/100))
    */
  const dprice = Number(price) || 0;
  const dprofit = Number(profit) / 100 || 0;
  const dvat = Number(vat) || 0;

  const y = Number((dprice / (1 - dprofit)).toFixed(4));

  return !lodash.isNaN(y) ? y : 0;
};
