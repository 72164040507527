import { isEmpty } from "lodash";
import { getLanguage } from "../../../../services/_utils";
import {
  getSeasonCrossTableByComposition,
  getSeasonCrossTableByCountry,
} from "./seasonCrossTable";

/**
 * !-----------------------------------------------------------------!
 * !-----------------------Get Country Checked-----------------------!
 * !-----------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} idProduct
 * @param {integer} idComposition
 * @param {integer} idCountry
 * @returns {boolean} true se o país existir na seasonCrossTable
 * @returns {boolean} false se o país não existir na seasonCrossTable
 * @description este método é utilizado na lista de países para saber se o check está ativo ou não!
 */
export function getCountryChecked(
  seasonCrossTable,
  idProduct,
  idComposition,
  idCountry
) {
  //* procurar na seasoncrosstable se existe algum registo
  //* com o id do produto, id da composição e id do país pretendido
  const x = getSeasonCrossTableByCountry(
    seasonCrossTable,
    idProduct,
    idComposition,
    idCountry
  );

  //* se não encontrar nenhum
  //* registo retorna false
  if (!isEmpty(x)) return true;

  //* se encontrar retorna true
  return false;
}

/**
 * !-----------------------------------------------------------------!
 * !------------------------Get Country Title------------------------!
 * !-----------------------------------------------------------------!
 * @param {array} countries lista de países
 * @param {integer} fkCountry id de um país específico
 * @param {function} getLanguage linguagem usada no portal
 * @returns {string} retorna o nome do país traduzido
 */
export function getCountryTitle(countries, fkCountry, getLanguage) {
  //* lista de países
  const data = countries || [];

  //* encontrar o objeto pretendido
  const object = data.find((e) => e.idCountry === fkCountry);

  //* se existir retorna o país
  if (object !== undefined) {
    return object[`${getLanguage().toLowerCase()}`];
  }

  //* se não existir retorna string vazia
  return "";
}

/**
 * !------------------------------------------------------------!
 * !---------------Get Country List By Compositon---------------!
 * !------------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {array} countries
 * @param {integer} fkProduct
 * @param {integer} fkComposition
 * @returns {array} retorna um array com o formato [{idCountry, name}]
 * @description este método retorna uma lista de países com base na composição selecionada
 */
export function getCountryListByComposition(
  seasonCrossTable = [],
  countries = [],
  fkProduct,
  fkComposition
) {
  //* obter dados da seasoncrosstable por composição
  const sctByComposition = getSeasonCrossTableByComposition(
    seasonCrossTable,
    fkProduct,
    fkComposition
  );
  //* neste array vão ser guardados todos os ids dos
  //* países que existem na seasonCrossTable para a composição escolhida
  let ids = [];

  //* iterar sobre sctByComposition para encontrar os ids
  sctByComposition.forEach((e) => {
    const y = e.seasonCrosstablePK;
    ids.push(y.fkCountry);
  });

  //* remover os ids duplicados
  ids = ids.filter((e, index) => ids.indexOf(e) === index);

  //* iterar sobre os id's e construir um array de objetos
  //* com o formato: [ {idCountry:1, name:"Country Name"}]
  const result = [];

  ids.forEach((e) => {
    result.push({
      idCountry: e,
      name: getCountryTitle(countries, e, getLanguage),
    });
  });

  return result;
}
