const cropperConfig = {
  zoomTo: 0.5,
  initialAspectRatio: 1 / 1,
  preview: ".img-preview",
  aspectRatio: 1 / 1,
  viewMode: 1,
  minCropBoxHeight: 10,
  minCropBoxWidth: 10,
  background: false,
  responsive: true,
  autoCropArea: 1,
  checkOrientation: false,
  guides: true,
  className: "crop-image",
};

export default cropperConfig;
