import { isEqual, omit } from "lodash";
import React, { useCallback } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import useSuppliers from "../../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import { useServices } from "../../../../services/useServices";

// import { Container } from './styles';

function FormAddress({
  values,
  handleChange,
  handleBlur,
  errors,
  setFieldValue,
}) {
  const keys = ["invoicingAddress", "collectionAddress"];

  //!-------------------------------------------------!\\
  //!---------------------hooks-----------------------!\\
  //!-------------------------------------------------!\\
  //* suppliers
  const { forms, supplierAddresses } = useSuppliers();
  //* services
  const { seasonCrossTableEndpoints, getLanguage } = useServices();

  //* translation
  const { t } = useTranslation(["pm/suppliers", "overlayConfirm"]);

  //!-------------------------------------------------!\\
  //!-------------------countries---------------------!\\
  //!-------------------------------------------------!\\
  const { data: countries } = useQuery("getCountries", () =>
    seasonCrossTableEndpoints.getAllCountries(getLanguage())
  );

  const isEqualPreviousAddress = useCallback(
    () =>
      isEqual(
        omit(values.invoicingAddress, ["idAddress"]),
        omit(values.collectionAddress, ["idAddress"])
      ),
    [values]
  );

  const onClickPreviousAddress = () => {
    console.log("addr");

    return setFieldValue("addresses.collectionAddress", {
      ...values.invoicingAddress,
      idAddress: values.collectionAddress.idAddress,
    });
  };

  const onClickOriginalAddress = () =>
    setFieldValue(
      "addresses.collectionAddress",
      supplierAddresses.data[1] || {}
    );

  return (
    <Col xs="12" className="mt-4">
      {keys.map((e, index) => (
        <Container fluid key={index} className="mb-5">
          <Row>
            <Col xs="12">
              <div className=" border-bottom pb-2 mb-3 d-flex align-items-center">
                <div className="app-sub-title">
                  {e === "invoicingAddress"
                    ? "Morada de Faturação"
                    : "Morada de Recolha"}
                </div>
                {e !== "invoicingAddress" && (
                  <div className="ms-auto">
                    <Form.Group>
                      <Form.Check
                        reverse="true"
                        disabled={!forms.editable}
                        type="switch"
                        label="Utilizar morada anterior"
                        id="moradaAnt"
                        checked={isEqualPreviousAddress()}
                        onChange={() => {
                          if (!isEqualPreviousAddress()) {
                            return onClickPreviousAddress();
                          }

                          return onClickOriginalAddress();
                        }}
                      />
                    </Form.Group>
                  </div>
                )}
              </div>
            </Col>
            <Col xs="12" md="4">
              <Pin2GiveForm.Select
                label={t("addresses.fields.fkCountry.label")}
                inputProps={{
                  name: `addresses.${e}.fkCountry`,
                  value: values[`${e}`]?.fkCountry,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  disabled: !forms.editable,
                }}
                error={errors[`${e}`]?.fkCountry}
              >
                <option value="-1">
                  {t("addresses.fields.fkCountry.choose")}
                </option>
                {countries?.data?.map((e, index) => (
                  <option key={index} value={e?.idCountry}>
                    {e[`${getLanguage().toLowerCase()}`]}
                  </option>
                ))}
              </Pin2GiveForm.Select>
            </Col>
            <Col xs="12" md="4">
              <Pin2GiveForm.Input
                label={t("addresses.fields.city.label")}
                inputProps={{
                  name: `addresses.${e}.city`,
                  value: values[`${e}`]?.city,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  readOnly: !forms.editable,
                }}
                error={errors[`${e}`]?.city}
              />
            </Col>
            <Col xs="12" md="4">
              <Pin2GiveForm.Input
                label={t("addresses.fields.postalCode.label")}
                inputProps={{
                  name: `addresses.${e}.postalCode`,
                  value: values[`${e}`]?.postalCode,
                  onChange: handleChange,
                  readOnly: !forms.editable,
                  onBlur: handleBlur,
                }}
                error={errors[`${e}`]?.postalCode}
              />
            </Col>
            <Col xs="12" md="6">
              <Pin2GiveForm.Input
                label={t("addresses.fields.street1.label")}
                inputProps={{
                  name: `addresses.${e}.street1`,
                  value: values[`${e}`]?.street1,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  readOnly: !forms.editable,
                }}
                error={errors[`${e}`]?.street1}
              />
            </Col>
            <Col xs="12" md="6">
              <Pin2GiveForm.Input
                label={t("addresses.fields.street2.label")}
                inputProps={{
                  name: `addresses.${e}.street2`,
                  value: values[`${e}`]?.street2,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  readOnly: !forms.editable,
                }}
                error={errors[`${e}`]?.street2}
              />
            </Col>
            <Col xs="12">
              <Pin2GiveForm.Input
                label={t("addresses.fields.designation.label")}
                inputProps={{
                  name: `addresses.${e}.designation`,
                  value: values[`${e}`]?.designation,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  readOnly: !forms.editable,
                }}
                error={errors[`${e}`]?.designation}
              />
            </Col>
          </Row>
        </Container>
      ))}
    </Col>
  );
}

export default FormAddress;
