/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, createRef, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { useAuth } from "../../AuthProvider/useAuth";
import { usePM } from "../../PMProvider/usePM";
import lodash from "lodash";
import {
  convertSupplierAddressesToForm,
  createFormObject,
  formatSupplierAddressObject,
  formatSupplierObject,
  formatSupplierProtocolObject,
  initialValues,
  newSupplier,
} from "./utils";
import Suppliers from "../../../pages/ProductsManagement/Suppliers/Suppliers";
import { isNumberPhone } from "../../../_yup/methods";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

export const suppliersContext = createContext();

function SuppliersProvider() {
  //* esta referência é utilizada para fazer scroll quando é escolhido
  //* um protocolo do supplier ou um supplier no dashboard
  const scrollToSuppliers = createRef();
  //!-------------------------------------------------!\\
  //!----------------------hooks----------------------!\\
  //!-------------------------------------------------!\\

  //* alert
  const alert = useAlert();

  //* translation
  const { t } = useTranslation(["pm/suppliers", "overlayConfirm"]);

  //* services
  const { supplierEndpoints } = useServices();

  //* useAuth
  const { auth } = useAuth();

  //* use productsmanagement
  const { setPMprotocol } = usePM();

  //* use location
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  //!-------------------------------------------------!\\
  //!---------------------states----------------------!\\
  //!-------------------------------------------------!\\
  //* overlay confirm
  const [showConfirm, setShowConfirm] = useState(initialValues.overlayConfirm);

  //* supplier selected
  const [selected, setSelected] = useState(null);

  //* editable
  const [editable, setEditable] = useState(false);

  //!-------------------------------------------------!\\
  //!-----------------overlay confirm-----------------!\\
  //!-------------------------------------------------!\\
  //* handle close
  const handleCloseConfirm = () => setShowConfirm(false);

  //!-------------------------------------------------!\\
  //!--------------------suppliers--------------------!\\
  //!-------------------------------------------------!\\
  const suppliers = useQuery(
    ["suppliers", auth.token],
    () => supplierEndpoints.getAllSupplierByPerson(auth.token),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!-------------------------------------------------!\\
  //!--------------------addresses--------------------!\\
  //!-------------------------------------------------!\\
  const { data: supplierAddresses, refetch: refetchSA } = useQuery(
    ["getSupplierAddresses", selected],
    () => supplierEndpoints.getAddressByIdSup(selected.idSupplier),
    {
      enabled: selected !== null && selected?.idSupplier !== -1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!-------------------------------------------------!\\
  //!--------------------protocol---------------------!\\
  //!-------------------------------------------------!\\
  const protocol = useQuery(
    ["getSupplierConditions", selected],
    () => supplierEndpoints.getSupplierConditions(selected.idSupplier),
    {
      enabled: selected !== null && selected?.idSupplier !== -1,
      onSuccess: (res) =>
        setPMprotocol(lodash.isEmpty(res.data) ? null : res.data),
      onError: () => setPMprotocol(null),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  //!-------------------------------------------------!\\
  //!--------------------creates----------------------!\\
  //!-------------------------------------------------!\\
  //* create protocol
  const { mutate: createSupplierProtocol, isLoading: isLoadingSP } =
    useMutation(
      (values) => {
        return supplierEndpoints.setSupplierConditions(values);
      },
      {
        onSettled: () => protocol.refetch(),
        onSuccess: () => {
          alert.success(t("alerts.success.submitProtocol.message"));
        },
      }
    );

  const { mutate: createSupplierAddress, isLoading: isLoadinSA } = useMutation(
    (values) => {
      return supplierEndpoints.create_or_update_address(values);
    }
  );

  //* enable protocol
  const handleEnabledProtocol = (protocolData, enabledV) => {
    const x = { ...protocolData, enabled: enabledV };

    createSupplierProtocol(
      formatSupplierProtocolObject(x, selected.idSupplier)
    );
  };

  //!-------------------------------------------------!\\
  //!------------------handleSubmit-------------------!\\
  //!-------------------------------------------------!\\
  const handleSubmit = useMutation(
    (values) => {
      return supplierEndpoints.create_or_update(
        formatSupplierObject(values.supplier)
      );
    },
    {
      onSuccess: (response, variables) => {
        const fkSupplier = response?.data?.idSupplier ?? -1;
        if (fkSupplier !== -1) {
          //* create protocol
          createSupplierProtocol(
            formatSupplierProtocolObject(variables.protocol, fkSupplier)
          );

          //* create addressess (invoicind and collection)
          ["collectionAddress", "invoicingAddress"].forEach((e) => {
            const dAddress = {
              ...variables.addresses[`${e}`],
              email: variables.supplier.email,
              phone: variables.supplier.phone,
              nif: variables.supplier.nif,
            };
            createSupplierAddress(
              formatSupplierAddressObject(
                dAddress,
                fkSupplier,
                e === "collectionAddress" ? 1 : 0
              ),
              {
                onSuccess: () =>
                  alert.success(t("alerts.success.submitAddress")),
                onSettled: () => refetchSA(),
              }
            );
          });
        }
        alert.success(t("alerts.success.submit.message"));
        setSelected(response.data);
        return setEditable(false);
      },
      onSettled: () => {
        suppliers.refetch();
        protocol.refetch();
        refetchSA();
      },
    }
  );

  //!-------------------------------------------------!\\
  //!---------------------forms-----------------------!\\
  //!-------------------------------------------------!\\
  //* methods
  Yup.addMethod(Yup.string, "numberPhone", isNumberPhone);

  //!-------------------------------------------------!\\
  //!-------------------useEffects--------------------!\\
  //!-------------------------------------------------!\\
  useEffect(() => {
    const data = suppliers?.data?.data;
    const dfks = queryParams.get("dfks");
    if (lodash.isEmpty(data) || !data) {
      return setSelected(null);
    } else {
      if (dfks !== null) {
        const x = data.findIndex((e) => e.idSupplier === Number(dfks));
        if (x !== -1) return setSelected(data[x]);
      } else if (lodash.isEmpty(selected)) {
        return setSelected(data[0]);
      }
    }
  }, [location.pathname, suppliers?.data?.data, queryParams.get("dfks")]);

  return (
    <suppliersContext.Provider
      value={{
        suppliers: {
          ...suppliers,
          data: suppliers?.data?.data ?? null,
          selected: selected,
          setSelected: setSelected,
          newSupplier: newSupplier,
        },
        protocol: { ...protocol, data: protocol?.data?.data ?? null },
        forms: {
          editable,
          setEditable,
          isLoading: handleSubmit.isLoading || isLoadingSP || isLoadinSA,
          handleSubmit: handleSubmit,
          handleEnabledProtocol,
          // addresses: formikAddresses,
        },
        overlayConfirm: {
          show: showConfirm.show,
          handleCloseConfirm,
          newSelect: showConfirm.newSelect,
          setShowConfirm,
        },
        initValues: createFormObject(
          selected || null,
          lodash.isEmpty(protocol?.data?.data) ? null : protocol?.data?.data,
          lodash.isEmpty(supplierAddresses?.data)
            ? null
            : convertSupplierAddressesToForm(supplierAddresses?.data)
        ),
        supplierAddresses: {
          ...supplierAddresses,
          data: supplierAddresses?.data || null,
        },
        ref: scrollToSuppliers,
      }}
    >
      <Suppliers />
    </suppliersContext.Provider>
  );
}

export default SuppliersProvider;
