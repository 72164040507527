import styled, { css } from "styled-components";
import { Container as DContainer } from "react-bootstrap";

export const FormContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
`;

export const FormTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.3rem;
  ${({ exist }) =>
    exist &&
    css`
      color: #a20013;
    `}
`;

export const Container = styled(DContainer)`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  border-radius: 4px;
  padding: 2rem !important;
  min-height: 100% !important;
`;

export const FormTitle2 = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
`;

export const PathLabel = styled.div`
  color: #aeadad;
  margin-bottom: 1.5rem;
`;
