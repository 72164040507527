import axios from "axios";

export const ProductCompositionsEndpoint = (initPath) => {
  const PATH = `${initPath}/productscompositionendpoint`;

  const create_or_update = async (data) =>
    await axios.post(`${PATH}/create_or_update`, data);

  const getAllCompositionsBySupplier = async (fkProduct) =>
    await axios.get(`${PATH}/getAllCompositionsBySupplier/${fkProduct}`);

  const getAllCompositionsWithReferencesByProduct = async (fkProduct) =>
    await axios.get(
      `${PATH}/getAllCompositionsWithReferenceByProduct/${fkProduct}`
    );

  const getAllShippingScales = async () =>
    await axios.get(`${PATH}/getAllShippingScales`);

  const getAllReferencesByComposition = async (fkComposition) =>
    await axios.get(`${PATH}/getAllReferencesByComposition/${fkComposition}`);

  const create_or_update_composition_reference = async (data) =>
    await axios.put(
      `${PATH}/create_or_update_productcompositionreference`,
      data
    );

  return {
    create_or_update,
    getAllCompositionsBySupplier,
    getAllShippingScales,
    getAllReferencesByComposition,
    create_or_update_composition_reference,
    getAllCompositionsWithReferencesByProduct,
  };
};
