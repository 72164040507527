import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pin2GiveListItem } from "../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveList from "../../../components/Pin2GiveList/Pin2GiveList.Container";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import useSuppliers from "../../../contexts/ProductsManagement/Suppliers/useSuppliers";
import lodash from "lodash";

function List({ isUpdated }) {
  //!-------------------------------------------------!\\
  //!----------------------hooks----------------------!\\
  //!-------------------------------------------------!\\
  //* suppliers
  const { suppliers, forms, overlayConfirm } = useSuppliers();

  //* use auth  context
  const { role } = useAuth();

  //* translations
  const { t } = useTranslation(["pm/suppliers", "overlayConfirm"]);

  //!-------------------------------------------------!\\
  //!----------------------search---------------------!\\
  //!-------------------------------------------------!\\
  const [search, setSearch] = useState({ text: "", data: null });

  const handleSearch = (e) => {
    const value = e.target.value;

    const filter = suppliers?.data.filter(
      (e) =>
        String(e?.company)
          .toLowerCase()
          .search(String(value).toLocaleLowerCase()) !== -1
    );

    return setSearch({ text: value, data: filter });
  };

  //!-------------------------------------------------!\\
  //!----------------------methods--------------------!\\
  //!-------------------------------------------------!\\
  //* handle click
  const handleClick = (supplier) => {
    if (isUpdated()) {
      suppliers.setSelected(supplier);
      forms.setEditable(false);
    } else {
      overlayConfirm.setShowConfirm({ show: true, newSelect: supplier });
    }
  };

  return (
    <Pin2GiveList
      headerProps={{
        title: t("list.title"),
        headerButton: {
          onClick: () => {
            suppliers.setSelected(suppliers.newSupplier);
            forms.setEditable(true);
          },
          title: t("list.buttonTooltip"),
        },
      }}
      search={role === 4}
      handleSearch={handleSearch}
    >
      {search.data
        ? search.data?.map((e, index) => (
            <Pin2GiveListItem
              key={index}
              title={e?.company}
              onClick={() => handleClick(e)}
              selected={e?.idSupplier === suppliers.selected?.idSupplier}
            />
          ))
        : suppliers.data?.map((e, index) => (
            <Pin2GiveListItem
              key={index}
              title={
                <div
                  className={
                    e?.state === 3 &&
                    e?.idSupplier !== suppliers.selected?.idSupplier
                      ? "text-success"
                      : ""
                  }
                >
                  {e?.company}
                </div>
              }
              onClick={() => handleClick(e)}
              selected={e?.idSupplier === suppliers.selected?.idSupplier}
            />
          ))}
      {lodash.isEqual(suppliers.selected, suppliers.newSupplier) && (
        <Pin2GiveListItem selected type="text" title={t("list.new")} />
      )}
    </Pin2GiveList>
  );
}

export default List;
