//? YUP
import * as Yup from "yup";

//? Initial Fields
export const initialValues = {
  logo: "",
  name: "",
  shortname: "",
  nif: "",
  nib: "",
  url: "",
  email: "",
  email2: "",
  mobile: "",
  phone: "",
  vat_payer: "",
  idSupplier: -1,
  fkStype: 0,
  company: "-",
  fkAddressPicking: 0,
  fkAddressTax: 0,
  dateAccounts: "0",
  credit: 1.0,
  promotionalLevel: 0,
  state: 0,
  wrapup: 0,
};

//? Validation Schema
export const validationSchema = (t) =>
  Yup.object({
    supplier: Yup.object({
      logo: Yup.string(),
      company: Yup.string().required(t("fields.company.errors.required")),
      shortname: Yup.string().required(t("fields.shortname.errors.required")),
      nif: Yup.number()
        .required(t("fields.nif.errors.required"))
        .integer(t("fields.nif.errors.integer")),
      nib: Yup.string().required(t("fields.nib.errors.required")),
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        t("fields.url.errors.url")
      ),
      email: Yup.string()
        .email(t("fields.email.errors.email"))
        .required(t("fields.email.errors.required")),
      email2: Yup.string().email(t("fields.email2.errors.required")),
      phone: Yup.string()
        .required(t("fields.phone.errors.required"))
        .numberPhone(t("fields.phone.errors.numberPhone")),
      mobile: Yup.string()
        .required(t("fields.mobile.errors.required"))
        .numberPhone(t("fields.mobile.errors.numberPhone")),
      vat_payer: Yup.boolean(),
      fkStype: Yup.number().moreThan(-1, t("fields.fkStype.errors.moreThan")),
    }),
    protocol: Yup.object().shape({
      fkSupplier: Yup.number().moreThan(-1, "").required(),
      dcomission: Yup.number()
        .when("type_", {
          is: (type) => type === 1,
          then: Yup.number().required(
            t("protocol.fields.dcomission.errors.required")
          ),
        })
        .moreThan(-1, t("protocol.fields.dcomission.errors.moreThan"))
        .lessThan(101, t("protocol.fields.dcomission.errors.lessThan"))
        .typeError(t("protocol.fields.dcomission.errors.typeError")),
      dpreparationTime: Yup.number()
        .integer()
        .required(t("protocol.fields.dprepatationTime.errors.required"))
        .typeError(t("protocol.fields.dprepatationTime.errors.typeError")),
      wrapprice: Yup.number()
        .required(t("protocol.fields.wrapprice.errors.required"))
        .test(
          "is-decimal",
          t("protocol.fields.wrapprice.errors.test"),
          (value) => (value + "").match(/^\d+(\.\d{0,2})?$/)
        )
        .typeError(t("protocol.fields.wrapprice.errors.typeError")),
    }),
    addresses: Yup.object({
      collectionAddress: addressSchema(t),
      invoicingAddress: addressSchema(t),
    }),
  });

function addressSchema(t) {
  return Yup.object().shape({
    fkCountry: Yup.number()
      .integer(t("addresses.fields.fkCountry.errors.integer"))
      .required(t("addresses.fields.fkCountry.errors.required"))
      .min(0, t("addresses.fields.fkCountry.errors.required"))
      .typeError(t("addresses.fields.fkCountry.errors.integer")),
    city: Yup.string().required(t("addresses.fields.city.errors.required")),
    postalCode: Yup.string().required(
      t("addresses.fields.postalCode.errors.required")
    ),
    street1: Yup.string().required(
      t("addresses.fields.street1.errors.required")
    ),
    street2: Yup.string().required(
      t("addresses.fields.street2.errors.required")
    ),
  });
}

//? ONSUBMIT
export const onSubmit = (values) => {
  console.log("values", values);
};
