/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from "react";
import { Pin2GiveListItem } from "../../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveList from "../../../../components/Pin2GiveList/Pin2GiveList.Container";
import { useServices } from "../../../../services/useServices";
import { useSeasonCrossTable } from "../useSeasonCrossTable";
import { getCountryChecked } from "../_actions/countries";
import lodash from "lodash";

// import { Container } from './styles';

function CountryList() {
  //!-------------------------------------------------!\\
  //!-----------------------HOOKS---------------------!\\
  //!-------------------------------------------------!\\
  //* services
  const { getLanguage } = useServices();

  //* seasonCrossTable
  const { countries, compositions, sct, products } = useSeasonCrossTable();
  const [search, setSearch] = useState({ text: "", data: null });

  //!-------------------------------------------------!\\
  //!-----------------------events--------------------!\\
  //!-------------------------------------------------!\\
  //* handle Search
  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;

      const filter = countries?.data?.filter((e) => {
        return (
          String(e[`${getLanguage().toLowerCase()}`].toLowerCase()).search(
            String(value).toLowerCase()
          ) !== -1
        );
      });

      return setSearch({ text: value, data: filter });
    },
    [search]
  );

  //* handle select
  const handleSelect = useCallback(
    (fkCountry) => {
      countries.setSelected(fkCountry);
    },
    [countries.selected]
  );

  //* isChecked
  const isChecked = useCallback(
    (idCountry) =>
      getCountryChecked(
        sct,
        products.selected,
        compositions.selected,
        idCountry
      ),
    [countries]
  );

  //* ordenar a lista de paises port check e por ordem alfabetica
  const sortableListCountry = (
    type = "country",
    countries = [],
    search = []
  ) => {
    switch (type) {
      case "search":
        return search
          .sort(
            (a, b) =>
              a[`${getLanguage().toLowerCase()}`] >
              b[`${getLanguage().toLowerCase()}`]
          )
          .sort((a, b) => isChecked(a.idCountry) < isChecked(b.idCountry));
      default:
        return countries
          .sort((a, b) =>
            a[`${getLanguage().toLowerCase()}`] <
            b[`${getLanguage().toLowerCase()}`]
              ? -1
              : 1
          )
          .sort((a, b) =>
            isChecked(a.idCountry) > isChecked(b.idCountry) ? -1 : 1
          );
    }
  };

  return (
    <Pin2GiveList
      headerProps={{
        title: "Countries",
        headerButton: {},
      }}
      search
      handleSearch={handleSearch}
    >
      {compositions.selected !== -1 ? (
        sortableListCountry(
          lodash.isEmpty(search?.text) ? "country" : "search",
          countries?.data || undefined,
          search?.data || undefined
        ).map((e, index) => (
          <Pin2GiveListItem
            key={index}
            type="text"
            check={isChecked(e?.idCountry)}
            checked={isChecked(e?.idCountry)}
            checkDisabled
            title={e[`${getLanguage().toLowerCase()}`]}
            onClick={() => handleSelect(e?.idCountry)}
            selected={countries.selected === e?.idCountry}
          />
        ))
      ) : (
        <></>
      )}
    </Pin2GiveList>
  );
}

export default memo(CountryList);
