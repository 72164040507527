//? REACT-BOOTSTRAP
import { Offcanvas } from "react-bootstrap";

//? REACT-ROUTER-DOM
import { NavLink, useNavigate } from "react-router-dom";

//? COMPONENTS
import DownloadButton from "../../components/DownloadButton/DownloadButton";
import DropdownLang from "../../components/DropdownLang/DropdownLang";

//? REACT-ICONS
import {
  FaHome,
  FaStore,
  FaUserTie,
  FaShieldAlt,
  FaQuestion,
  FaBox,
  FaSignInAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

//? I18NEXT
import { useTranslation } from "react-i18next";

//? COMPONENT
const Canvas = ({ visible, handleClose, Logo, setVisible }) => {
  const { t } = useTranslation();

  const { auth, authentication } = useAuth();

  return (
    <Offcanvas show={visible} onHide={handleClose} placement="start">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <a className="header-top-brand" href="/">
            <img src={Logo} alt="" />
            <span>Pin2Give</span>
          </a>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-menu">
        <ul>
          <li>
            <NavLink
              activeclassname="active"
              to="/"
              data-testid="link-home"
              onClick={() => setVisible(false)}
            >
              <span className="icon">
                <FaHome />
              </span>
              <span className="link">{t("MainTitles.Home")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeclassname="active"
              to="/store"
              onClick={() => setVisible(false)}
            >
              <span className="icon">
                <FaStore />
              </span>
              <span className="link">{t("MainTitles.Store")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeclassname="active"
              to="/partners"
              onClick={() => setVisible(false)}
            >
              <span className="icon">
                <FaUserTie />
              </span>
              <span className="link">{t("MainTitles.BusinessPartners")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeclassname="active"
              to="/policy"
              data-testid="link-policy"
              onClick={() => setVisible(false)}
            >
              <span className="icon">
                <FaShieldAlt />
              </span>
              <span className="link">{t("MainTitles.PrivacyPolicy")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeclassname="active"
              to="/support"
              onClick={() => setVisible(false)}
            >
              <span className="icon">
                <FaQuestion />
              </span>
              <span className="link">{t("MainTitles.Support")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeclassname="active" to="/productsmanagement">
              <span className="icon">
                <FaBox />
              </span>
              <span className="link">{t("MainTitles.ProductManagement")}</span>
            </NavLink>
          </li>
          {auth?.token ? (
            <li>
              <NavLink
                activeclassname=""
                className={"bg-white text-dark"}
                to=""
                onClick={() => {
                  authentication.logout();
                  handleClose();
                }}
              >
                <span className="icon">
                  <FaSignOutAlt />
                </span>
                <span className="link">Logout</span>
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink activeclassname="active" to="/user/login">
                <span className="icon">
                  <FaSignInAlt />
                </span>
                <span className="link">Login</span>
              </NavLink>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center justify-content-around w-100">
          <DownloadButton placement="top" />
          <DropdownLang toggle={() => setVisible(false)} />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Canvas;
