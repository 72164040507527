/* eslint-disable react/prop-types */
import { Fab, Paper } from "@mui/material";
import styled from "styled-components";
import React from "react";

export const Container = ({ children, register = false }) => (
  <Paper
    elevation={10}
    sx={{
      overflowY: "auto",
      width: register ? "60rem" : "30rem",
      maxHeight: { xs: "100%", md: "45rem" },
      padding: { xs: "2rem", md: "4rem" },
      zIndex: 1,
    }}
  >
    {children}
  </Paper>
);

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Brand = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    align-items: center;
  `,
  Logo: styled.img`
    width: 7rem;
    height: 7rem;
  `,
  Title: styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    color: #a20013;
  `,
  Subtitle: styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    color: #333;
  `,
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 1rem;
`;

export const SocialContent = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3.5rem;
  position: absolute;
  right: 2rem;
  top: 0rem;
  @media (min-width: 1200px) {
    display: flex;
  } ;
`;

export const ExternalLink = ({ children, href, ariaLabel }) => (
  <Fab
    href={href}
    target="_blank"
    sx={{ backgroundColor: "white", color: "#0d6efd", mb: 3 }}
    aria-label={ariaLabel}
  >
    {children}
  </Fab>
);
