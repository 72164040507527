//? REACT
import React, { useState, useEffect } from "react";

//? REACT-BOOTSTRAP
import { Button, Col, Container, Row } from "react-bootstrap";

//? REACT-ICONS
import { FaArrowLeft, FaSave } from "react-icons/fa";

//? REACT-ROUTER-DOM
import { useNavigate, useParams } from "react-router-dom";

//? LODASH
import lodash, { isEmpty } from "lodash";

//? OVERLAY
import OverlayConfirm from "../OverlayConfirm";

//? LOADING
import Loading from "../Loading";

//? i18Next
import { useTranslation } from "react-i18next";

//? HEADER
import Header from "../Header";

import { createObject } from "./_actions";
import { useAlert } from "react-alert";
import { useMutation, useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { Step, StepLabel, Stepper } from "@mui/material";
import CompositionList from "./_lists/CompositionsList";
import ImagesList from "./_lists/ImagesList";

const CompositionsReferences = () => {
  //!-------------------------------------------------------!\\
  //!-------------------------hooks-------------------------!\\
  //!-------------------------------------------------------!\\
  //* params
  const { fkProduct } = useParams();

  //* supplier data
  const { fkSupplier } = usePM();

  //* services
  const { productCompositionsEndpoints, referencesEndpoints } = useServices();

  //* navigate
  const navigate = useNavigate();

  //* alert
  const alert = useAlert();

  //* translations
  const { t } = useTranslation([
    "pm/compositionReferences",
    "pm/overlayConfirm",
  ]);

  //!-------------------------------------------------------!\\
  //!-------------------------states------------------------!\\
  //!-------------------------------------------------------!\\
  //* selected
  const [selected, setSelected] = useState(null);

  //* overlay confirm
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: {},
  });
  const handleCloseConfirm = () => setShowConfirm(false);

  //* references by composition
  const [compositionReferences, setCompositionReferences] = useState([]);

  //!-------------------------------------------------------!\\
  //!-------------------------steps-------------------------!\\
  //!-------------------------------------------------------!\\
  const steps = [
    { label: t("steps.products"), path: "/productsmanagement/products" },
    {
      label: t("steps.categories"),
      path: `/productsmanagement/productcategories/${fkProduct}`,
    },
    {
      label: t("steps.compositions"),
      path: `/productsmanagement/compositions/${fkProduct}/-`,
    },
    {
      label: t("steps.compositionReferences"),
      path: `/productsmanagement/compositionsreferences/${fkProduct}`,
    },
  ];

  //!-------------------------------------------------------!\\
  //!----------------------compositions---------------------!\\
  //!-------------------------------------------------------!\\
  const { data: compositions, isLoading: isLoadingCompositions } = useQuery(
    ["getCompositions", fkProduct],
    () => productCompositionsEndpoints.getAllCompositionsBySupplier(fkProduct),
    {
      enabled: fkSupplier !== -1,
      onSuccess: (res) => {
        setSelected(res.data[0]?.idComposition);
        if (isEmpty(res.data)) {
          navigate(`/productsmanagement/compositions/${fkProduct}/-`);
          alert.error("Tem de inserir pelo menos uma composição!");
        }
      },
    }
  );

  //!-------------------------------------------------------!\\
  //!-----------------------references----------------------!\\
  //!-------------------------------------------------------!\\
  const {
    data: references,
    refetch: refetchReferences,
    isLoading: isLoadingReferences,
  } = useQuery(
    ["getReferences", fkSupplier],
    () => referencesEndpoints.getAllReferencesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
      onSuccess: (response) => {
        if (isEmpty(response.data)) {
          navigate(`/productsmanagement/references/-`);
          alert.error("Tem de inserir pelo menos uma referência!");
        }
      },
    }
  );

  //!-------------------------------------------------------!\\
  //!-----------------composition references----------------!\\
  //!-------------------------------------------------------!\\
  const {
    data: initCR,
    refetch: refetchCR,
    isLoading: isLoadingCR,
  } = useQuery(
    ["getCompositionReferences", selected],
    () => productCompositionsEndpoints.getAllReferencesByComposition(selected),
    {
      enabled: selected !== null && selected !== -1 && selected !== undefined,
      onSuccess: (res) => {
        setCompositionReferences([...res.data]);
      },
    }
  );

  //!-------------------------------------------------------!\\
  //!-------------------------methods-----------------------!\\
  //!-------------------------------------------------------!\\
  //* remove check
  const remove = (idComposition, reference) => {
    let cr = compositionReferences;
    cr = cr.filter(
      ({ productCompositionReferencePK }) =>
        productCompositionReferencePK?.fkComposition === idComposition &&
        productCompositionReferencePK?.reference !== reference
    );
    setCompositionReferences(cr);
    refetchReferences();
  };

  //* change Quantity
  const handleChangeQuantity = (e, reference) => {
    const value = e.target.value;
    let x = 1;

    if (!lodash.isEmpty(value)) {
      if (!lodash.isNaN(Number(value))) {
        if (Number(value) > 0) {
          x = Number(value);
        }
      }
    } else {
      x = 1;
    }

    let cr = [...compositionReferences];

    let y = cr.findIndex(
      (e) => e?.productCompositionReferencePK?.reference === reference
    );

    if (y !== -1) {
      cr[y] = { ...cr[y], quantity: x };

      setCompositionReferences(cr);
      refetchReferences();
    }
  };

  //* get Quantity
  const getQuantity = (reference) => {
    const x = compositionReferences.find(
      ({ productCompositionReferencePK }) =>
        productCompositionReferencePK.reference === reference
    );

    if (x !== undefined && x !== 0) {
      return Number(x.quantity);
    }

    return 0;
  };

  //* isUpdated
  const idUpdated = (initList, updatedList) => {
    return lodash.isEqual(initList, updatedList);
  };

  //* add check
  const add = (obj) => {
    const data = [...compositionReferences, obj];
    setCompositionReferences(data);
    let cr = data;

    let y = cr.findIndex(
      (e) =>
        e?.productCompositionReferencePK?.reference ===
        obj?.productCompositionReferencePK?.reference
    );

    if (y !== -1) {
      cr[y] = { ...cr[y], quantity: 1 };

      setCompositionReferences(cr);
      refetchReferences();
    }
  };

  //* verify if is checked
  const getChecked = (reference) => {
    const x = compositionReferences.find(
      (e) => e?.productCompositionReferencePK.reference === reference
    );

    if (x !== undefined) return true;

    return false;
  };

  //!-------------------------------------------------------!\\
  //!----------------------handleSubmit---------------------!\\
  //!-------------------------------------------------------!\\
  const { mutate: handleSubmit } = useMutation(
    (data) => {
      let x = [...data];
      if (lodash.isEmpty(x)) {
        x.push({
          productCompositionReferencePK: {
            fkProduct: -1,
            fkComposition: Number(selected),
            reference: "-",
          },
          quantity: 0,
        });
      }
      return productCompositionsEndpoints.create_or_update_composition_reference(
        x
      );
    },
    {
      onSuccess: () => {
        alert.success(t("alerts.success.submit.message"));
      },
      onSettled: () => refetchCR(),
    }
  );

  //!-------------------------------------------------------!\\
  //!-----------------------useEffects----------------------!\\
  //!-------------------------------------------------------!\\
  //* initial
  useEffect(() => {
    if (fkSupplier === -1) {
      navigate("/productsmanagement");
    }
  }, [fkSupplier, navigate]);

  return (
    <Container className="mt-5">
      <Header
        left={{ menuButton: true, backButton: true }}
        center={{
          title: t("title"),
        }}
      />
      {
        //!------------------------------------------------!\\
        //!-----------------STEP INDICATOR-----------------!\\
        //!------------------------------------------------!\\
      }
      <Row className="mb-5">
        <Col xs="12">
          <Stepper activeStep={3}>
            {steps.map(({ label, path }) => (
              <Step
                onClick={() => (path ? navigate(path) : undefined)}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Col>
      </Row>
      {isLoadingCompositions ? (
        <Loading />
      ) : (
        <Row className="mt-5 justify-content-center">
          <Col xs="12" md="6" lg="3" className="mb-5">
            <CompositionList
              fkProduct={fkProduct}
              isUpdated={idUpdated}
              data={compositions?.data ?? []}
              compositionReferences={compositionReferences}
              initCR={initCR}
              setSelected={setSelected}
              setShowConfirm={setShowConfirm}
              selected={selected}
            />
          </Col>
          <Col xs="12" lg="6" md="6" className="mb-5">
            <ImagesList
              data={references?.data ?? []}
              getQuantity={getQuantity}
              getChecked={getChecked}
              handleChangeQuantity={handleChangeQuantity}
              selected={selected}
              remove={remove}
              add={add}
              createObject={createObject}
              fkProduct={fkProduct}
            />
          </Col>
          <Col xs="12" className="mt-5 text-end d-flex align-items-center">
            {!idUpdated(initCR?.data, compositionReferences) &&
            isLoadingCR === false &&
            isLoadingCompositions === false &&
            isLoadingReferences === false ? (
              <>
                <Button
                  variant="secondary"
                  className="ms-auto me-2"
                  onClick={() => {
                    setCompositionReferences(initCR?.data);
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
                <Button
                  variant="success"
                  className="d-flex align-items-center"
                  onClick={() => {
                    //console.log(compositionReferences);
                    handleSubmit(compositionReferences);
                  }}
                >
                  <FaSave className="me-2" /> {t("buttons.save")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="secondary"
                  className="me-auto"
                  onClick={() =>
                    navigate(
                      "/productsmanagement/compositions/" + fkProduct + "/-"
                    )
                  }
                >
                  <FaArrowLeft className="me-2" />
                  {t("buttons.compositions")}
                </Button>
                <Button
                  variant="pin2give"
                  onClick={() => navigate("/productsmanagement")}
                >
                  {t("buttons.suppliers")}
                </Button>
              </>
            )}
          </Col>
        </Row>
      )}
      <OverlayConfirm
        word="referencia"
        show={showConfirm.show}
        handleCloseConfirm={handleCloseConfirm}
        onSelect={() => {
          setSelected(showConfirm.newSelect);
          handleCloseConfirm();
        }}
        title={t("compositionReferences.title", { ns: "pm/overlayConfirm" })}
        content={t("compositionReferences.content", {
          ns: "pm/overlayConfirm",
        })}
      />
    </Container>
  );
};

export default CompositionsReferences;
