import { Row, Container, Col } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const PlSkeleton = () => {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <Skeleton variant="text" height={50} />
        </Col>
        <Col xs="12">
          <Skeleton variant="text" height={50} />
        </Col>
      </Row>
    </Container>
  );
};

export default PlSkeleton;
