//? REACT-BOOTSTRAP
import { Form, Col } from "react-bootstrap";

//? i18Next
import { useTranslation } from "react-i18next";

import CreatableSelect from "react-select/creatable";

//? LODASH
import lodash from "lodash";

import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useServices } from "../../../services/useServices";

const Scale = ({ editable, formik, selected, scales }) => {
  const selectRef = useRef(false);
  const [selectedScale, setSelectedScale] = useState("");
  const { referencesEndpoints } = useServices();

  //!--------------------------------------------!\\
  //!---------------useTranslation---------------!\\
  //!--------------------------------------------!\\
  const { t } = useTranslation(["pm/references"]);

  //!----------------------------------------------!\\
  //!---------------Values by scales---------------!\\
  //!----------------------------------------------!\\
  const { data: scaleValues, isLoading: isLoadingScaleValues } = useQuery(
    ["getAllScaleValuesByScale", formik.values.fkScale],
    () => referencesEndpoints.getAllScaleValuesByScale(formik.values.fkScale),
    {
      enabled: !lodash.isEmpty(selectedScale),
    }
  );

  useEffect(() => {
    //? quando existe fkScale altera o value do react-select
    if (!lodash.isEmpty(scales)) {
      //console.log(scales);
      //console.log(selected.fkScale);
      const find = scales?.data.find((e) => e.idScale === selected.fkScale);
      //console.log(find);
      if (find !== undefined) {
        setSelectedScale({ value: find.idScale, label: find.en });
      } else {
        setSelectedScale(null);
      }
    }
  }, [selected.fkScale, scales]);

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("isnewsv", 0);
    }
  }, [editable]);

  const scaleValueEditable = () => {
    if (editable) {
      if (lodash.isEmpty(selectedScale)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  if (!editable && selected.fkScale === 0) {
    return (
      <>
        <Col xs="12">Sem escala</Col>
      </>
    );
  }

  return (
    <>
      <Col xs="12" sm="6">
        <Form.Group>
          <Form.Label>{t("fields.scale.label")}</Form.Label>
          <CreatableSelect
            isDisabled={!editable}
            isClearable
            ref={selectRef}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("isnewsv", 0);
                formik.setFieldValue("fkScale", 0);
                formik.setFieldValue("fkScaleValue", "");

                setSelectedScale(e);
              } else {
                //console.log(e?.idScale);
                formik.setFieldValue("fkScale", e?.idScale);
                if (editable) formik.setFieldValue("fkScaleValue", "");
                setSelectedScale(e);
              }
            }}
            options={scales?.data?.map(({ en, idScale }) => ({
              value: idScale,
              label: en,
              idScale,
            }))}
            formatCreateLabel={(e) => `Create New ${e}`}
            onCreateOption={(e) => {
              formik.setFieldValue("descScale", e);
              formik.setFieldValue("isnewsv", 1);
              formik.setFieldValue("fkScale", -1);
              setSelectedScale({ label: e, value: Math.random() });
            }}
            value={selectedScale}
          />
        </Form.Group>
      </Col>
      <Col xs="12" sm="6">
        <Form.Group>
          <Form.Label>{t("fields.scaleValue.label")}</Form.Label>
          <CreatableSelect
            options={scaleValues?.data?.map(({ scaleValuesPK }) => ({
              value: scaleValuesPK.scaleValue,
              label: scaleValuesPK.scaleValue,
            }))}
            isDisabled={scaleValueEditable()}
            isClearable
            placeholder="Select..."
            label
            isLoading={isLoadingScaleValues}
            onChange={(e) => {
              if (e?.__isNew__) {
                formik.setFieldValue("isnewsv", 1);
              } else {
                formik.setFieldValue("isnewsv", 0);
              }
              return formik.setFieldValue("fkScaleValue", e?.value);
            }}
            value={{
              value:
                formik.values.fkScale === 0
                  ? "Select scale..."
                  : formik.values.fkScaleValue || "Select...",
              label:
                formik.values.fkScale === 0
                  ? "Select scale..."
                  : formik.values.fkScaleValue || "Select...",
            }}
          />
          <Form.Text className="text-danger">
            {formik.errors?.fkScaleValue}
          </Form.Text>
        </Form.Group>
      </Col>
    </>
  );
};

export default Scale;
