import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";

function ImagesList({
  data,
  getQuantity,
  getChecked,
  handleChangeQuantity,
  selected,
  remove,
  add,
  createObject,
  fkProduct,
}) {
  //!-------------------------------------------------------!\\
  //!--------------------------hooks------------------------!\\
  //!-------------------------------------------------------!\\
  //* navigate
  const navigate = useNavigate();

  //* translation
  const { t } = useTranslation([
    "pm/compositionReferences",
    "pm/overlayConfirm",
  ]);

  //!-------------------------------------------------------!\\
  //!-------------------------options-----------------------!\\
  //!-------------------------------------------------------!\\
  //* header Props
  const headerProps = {
    title: t("lists.references.title"),
    headerButton: {
      onClick: () => navigate("/productsmanagement/references/-"),
      title: t("lists.references.button"),
    },
  };

  //!-------------------------------------------------------!\\
  //!--------------------------events-----------------------!\\
  //!-------------------------------------------------------!\\
  //* handleCheck
  const handleCheck = (e) => {
    //* verificar se a referência tem check
    if (getChecked(e?.reference)) {
      //* se tiver check -> remove o check
      remove(Number(selected), e?.reference);
    } else {
      //* senão tiver check -> adicionar o check
      add(createObject(Number(fkProduct), Number(selected), e?.reference));
    }
  };

  return (
    <Pin2GiveList headerProps={headerProps}>
      <Pin2GiveListItem
        title={t("lists.references.subtitle1")}
        input={
          <div className="text-end pe-4">{t("lists.references.subtitle2")}</div>
        }
        tooltip={t("lists.references.subtitle3")}
      />
      {data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          type="text"
          title={e?.sreference}
          check
          input={
            <Form.Control
              value={getQuantity(e?.reference)}
              readOnly={!getChecked(e?.reference)}
              onChange={(event) => handleChangeQuantity(event, e?.reference)}
            />
          }
          tooltip={e?.reference}
          checked={getChecked(e?.reference)}
          onCheck={() => handleCheck(e)}
        />
      ))}
    </Pin2GiveList>
  );
}

export default ImagesList;
