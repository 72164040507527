/* eslint-disable react-hooks/exhaustive-deps */
//-------------------------------------\\
//---------------I18NEXT---------------\\
//-------------------------------------\\
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

//----------------------------------------------\\
//---------------REACT-ROUTER-DOM---------------\\
//----------------------------------------------\\
import { useNavigate } from "react-router-dom";

//------------------------------------------\\
//---------------PIN2GIVELIST---------------\\
//------------------------------------------\\
import {
  Pin2GiveListItem,
  Pin2GiveList,
} from "../../../../components/Pin2GiveList/Pin2GiveList.jsx";

//----------------------------------------------\\
//---------------SEASONCROSSTABLE---------------\\
//----------------------------------------------\\
import { useSeasonCrossTable } from "../useSeasonCrossTable.jsx";
import { getProductsChecked } from "../_actions/products.js";

const Products = () => {
  //!----------------------------------------!\\
  //!----------------navigate----------------!\\
  //!----------------------------------------!\\
  const navigate = useNavigate();

  //!---------------------------------------------!\\
  //!----------------useTranlation----------------!\\
  //!---------------------------------------------!\\
  const { t } = useTranslation(["pm/sct"]);

  //!------------------------------------!\\
  //!----------------data----------------!\\
  //!------------------------------------!\\
  //* season cross table
  const { products, sct, compositions, references, seasons, countries } =
    useSeasonCrossTable();

  //!--------------------------------------------!\\
  //!----------------handle click----------------!\\
  //!--------------------------------------------!\\
  const handleClick = useCallback(
    (idProduct) => {
      compositions.setSelected(-1);
      references.setSelected("");
      countries.setSelected(-1);
      references.setARefs([]);
      return products.setSelected(Number(idProduct));
    },
    [products.selected]
  );

  return (
    <Pin2GiveList
      headerProps={{
        title: t("products.title"),
        headerButton: {
          onClick: () => navigate("/productsmanagement/products"),
          title: t("products.buttonTooltip"),
        },
      }}
    >
      {seasons.selected !== -1 &&
        seasons.selected &&
        products?.data?.map((e, index) => (
          <Pin2GiveListItem
            key={index}
            check={getProductsChecked(Number(e?.idProduct), sct)}
            selected={Number(e?.idProduct) === Number(products.selected)}
            checked={getProductsChecked(Number(e?.idProduct), sct)}
            onClick={() => handleClick(e?.idProduct)}
            title={e?.stitle}
            checkDisabled
          />
        ))}
    </Pin2GiveList>
  );
};

export default memo(Products);
