import { Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip as RTooltip } from "react-bootstrap";
import { FaInfo, FaTrashAlt } from "react-icons/fa";
import { P2GLItem, P2GLItemImage, P2GLItemText } from "./Pin2GiveList.styles";

export const Pin2GiveListItemText = ({
  check,
  checkBoxRef,
  onCheck,
  checked,
  id,
  title,
  input,
  onRemove,
  tooltip,
  checkDisabled,
  selected,
}) => {
  if (check) {
    return (
      <P2GLItem.Item>
        <Form.Check
          ref={checkBoxRef}
          type="checkbox"
          disabled={checkDisabled}
          onChange={(e) => {
            // se houver erro colocar e.preventDefault()
            onCheck();
          }}
          id={`pin2givelist-item${id}`}
          checked={checked}
          value={`pin2givelist-item${id}`}
        />
        <P2GLItemText.Label>
          {title.length > 20 ? title.substring(0, 20) + "..." : title}
        </P2GLItemText.Label>
        {input && <P2GLItemText.Input>{input}</P2GLItemText.Input>}
        {tooltip && (
          <OverlayTrigger
            placement="right"
            overlay={<RTooltip>{tooltip}</RTooltip>}
          >
            <span className="ms-auto">
              <FaInfo color={selected ? "#fff" : "#a20013"} />
            </span>
          </OverlayTrigger>
        )}
      </P2GLItem.Item>
    );
  }

  return (
    <P2GLItem.Item>
      <P2GLItemText.Label>
        {title.length > 20 ? title.substring(0, 20) + "..." : title}
      </P2GLItemText.Label>
      {input && <P2GLItemText.Label>{input}</P2GLItemText.Label>}
      {tooltip && (
        <OverlayTrigger
          placement="right"
          overlay={<RTooltip>{tooltip}</RTooltip>}
        >
          <P2GLItemText.InfoTooltip selected={selected}>
            <FaInfo />
          </P2GLItemText.InfoTooltip>
        </OverlayTrigger>
      )}
      {onRemove && (
        <P2GLItemImage.RemoveButton
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
          variant="pin2give"
          size="sm"
        >
          <FaTrashAlt />
        </P2GLItemImage.RemoveButton>
      )}
    </P2GLItem.Item>
  );
};
