import "./NotFound404.css";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const NotFound404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "404";
  });
  return (
    <div className="app-not-found-404 p-5">
      <div className="not-found-404-number">
        <span>404</span>
      </div>
      <div className="not-found-404-title text-center">{t("404.title")}</div>
      <div className="not-found-404-subtitle text-center">
        {t("404.subtitle")}
      </div>
      <button
        className="not-found-404-button"
        onClick={() => navigate("/")}
        data-testid="button-404"
      >
        <FaHome size={24} className="me-2" />
        {t("404.button")}
      </button>
    </div>
  );
};

export default NotFound404;
