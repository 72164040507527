import "./IconsItem.jsx";
import { Overlay, Tooltip, Col, Button, OverlayTrigger } from "react-bootstrap";
import { useRef, useState } from "react";

const IconsItem = (props) => {
  const { icon, title } = props;

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{title}</Tooltip>}>
      <div>{icon}</div>
    </OverlayTrigger>
  );
};

export default IconsItem;
