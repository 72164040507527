import { Step, StepLabel, Stepper } from "@mui/material";
import { useAlert } from "react-alert";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import References from "./_lists/References";

import lodash from "lodash";
import OverlayConfirm from "../OverlayConfirm";
import Images from "./_lists/Images";
import SortableList from "./_lists/SortableList";
import DefaultImage from "./_lists/DefaultImage";
import Save from "./Save";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { addObject } from "./_actions/_actions";

const { createContext, useState } = require("react");

export const context = createContext({});

const Provider = () => {
  //!--------------------------------------------!\\
  //!-----------------useNavigate----------------!\\
  //!--------------------------------------------!\\
  const { fkSupplier } = usePM();
  const { referencesEndpoints } = useServices();

  //!--------------------------------------------!\\
  //!-----------------useNavigate----------------!\\
  //!--------------------------------------------!\\
  const navigate = useNavigate();

  //!--------------------------------------------!\\
  //!------------------useAlert------------------!\\
  //!--------------------------------------------!\\
  const alert = useAlert();

  //!--------------------------------------------!\\
  //!---------------useTranslation---------------!\\
  //!--------------------------------------------!\\
  const { t } = useTranslation(["pm/imagesReferences"]);

  //!--------------------------------------------!\\
  //!--------------------steps-------------------!\\
  //!--------------------------------------------!\\
  const steps = [
    { label: t("steps.1"), path: "/productsmanagement/references/-" },
    { label: t("steps.3"), path: null },
  ];

  //!-------------------------------------!\\
  //!---------------OVERLAY---------------!\\
  //!-------------------------------------!\\
  const [confirm, setConfirm] = useState({ visible: false, newSelected: "" });

  const handleShowConfirm = (selected) =>
    setConfirm({ visible: true, newSelected: selected });

  const handleCloseConfirm = () =>
    setConfirm({ visible: false, newSelected: "" });

  //!--------------------------------------------!\\
  //!------------------References----------------!\\
  //!--------------------------------------------!\\
  const [selected, setSelected] = useState("");
  const { data: references } = useQuery(
    ["getReferences", fkSupplier],
    () => referencesEndpoints.getAllReferencesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
      onSuccess: (response) => {
        setSelected(response.data[0].reference);
      },
      onError: (error) => {
        alert.error(t("alerts.error.references"));
      },
    }
  );

  //!--------------------------------------------!\\
  //!--------------------Images------------------!\\
  //!--------------------------------------------!\\
  const { data: images, refetch: refetchImages } = useQuery(
    ["getSupplierImages", fkSupplier],
    () => referencesEndpoints.getAllImagesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //* get Image
  const getImage = (id) => {
    const x = images?.data?.find((e) => e?.id === id);
    return x?.imageBase64 ?? "";
  };

  //!--------------------------------------------!\\
  //!----------------Default Images--------------!\\
  //!--------------------------------------------!\\
  const [initDimage, setInitDimage] = useState(-1);
  const [dimage, setDimage] = useState(-1);

  //* get dImage
  const getdImage = (data) => {
    const ri = [...data].find((e) => e.dreference === 1);

    if (ri === undefined) {
      setInitDimage(-1);
      setDimage(-1);
      return;
    }

    setDimage(ri);
    setInitDimage(ri);

    return;
  };

  //!--------------------------------------------!\\
  //!------------------Order List----------------!\\
  //!--------------------------------------------!\\
  const [initOrder, setInitOrder] = useState([]);
  const [orderList, setOrderList] = useState([]);

  //* handle drop
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;

    var updatedList = [...orderList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    setOrderList(updatedList);
  };

  //!--------------------------------------------!\\
  //!--------------References Images-------------!\\
  //!--------------------------------------------!\\
  const [referencesImages, setReferencesImages] = useState([]);

  const { data: initReferencesImages, refetch: refetchRI } = useQuery(
    ["getReferencesImages", selected],
    () => referencesEndpoints.getAllImagesByReference(selected),
    {
      enabled: selected !== "",
      onSuccess: (response) => {
        setReferencesImages(response.data);
        setInitOrder(response.data);
        setOrderList(response.data);
        getdImage(response.data);
      },
      onError: () => {
        alert.error(t("alerts.error.referencesImages"));
      },
    }
  );

  //* remove check
  const remove = (reference, imageId) => {
    let ri = referencesImages;

    ri = ri.filter(
      ({ referenceImagePK }) =>
        referenceImagePK?.reference === reference &&
        referenceImagePK?.image !== imageId
    );

    if (
      dimage?.referenceImagePK?.reference === reference &&
      dimage?.referenceImagePK?.image === imageId
    ) {
      setDimage(ri[0] ?? -1);
    }
    setOrderList(ri);
    setReferencesImages(ri);
    isUpdated(initOrder, ri);
    refetchImages();
  };

  //* add check
  const add = (obj) => {
    if (lodash.isEmpty(referencesImages)) {
      setDimage(obj);
      setInitDimage(obj);
    }
    setOrderList([...referencesImages, obj]);
    setReferencesImages([...referencesImages, obj]);
    refetchImages();
  };

  //* verify if is checked
  const getChecked = (id) => {
    const x = referencesImages.find((e) => e?.referenceImagePK?.image === id);

    if (x !== undefined) return true;

    return false;
  };

  //!--------------------------------------------!\\
  //!----------------handle Submit---------------!\\
  //!--------------------------------------------!\\
  const { mutate: handleSubmit } = useMutation(
    (data) => {
      let x = data;
      if (lodash.isEmpty(x)) {
        x = [addObject(selected, -1, 0)];
      }
      return referencesEndpoints.create_or_update_reference_image(x);
    },
    {
      onSuccess: () => {
        refetchRI();
        alert.success(
          <>
            <Alert.Heading>{t("alerts.success.submit.title")}</Alert.Heading>
            <p>{t("alerts.success.submit.message")}</p>
          </>
        );
      },
      onError: (error) => {
        alert.error(
          <>
            <Alert.Heading>ERROR {error.status ?? 500}</Alert.Heading>
            <p>{error.message}</p>
          </>
        );
      },
    }
  );

  //!--------------------------------------------!\\
  //!-------------------methods------------------!\\
  //!--------------------------------------------!\\
  const isUpdated = (initList, updatedList) => {
    return (
      lodash.isEqual(initList, updatedList) &&
      lodash.isEqual(dimage, initDimage)
    );
  };

  return (
    <context.Provider
      value={{
        references: {
          data: references?.data,
          selected: selected,
          setSelected: setSelected,
        },
        images: {
          data: images?.data,
          getImage: getImage,
          refetchImages,
        },
        dImage: {
          init: initDimage,
          data: dimage,
          setDimage: setDimage,
          getdImage: getdImage,
        },
        orderList: {
          data: orderList,
          init: initOrder,
          handleDrop: handleDrop,
          setOrderList,
          setInitOrder,
        },
        referenceImages: {
          data: referencesImages,
          init: initReferencesImages?.data,
          addCheck: add,
          removeCheck: remove,
          getChecked: getChecked,
          setReferencesImages,
        },
        overlayConfirm: {
          handleShowConfirm: handleShowConfirm,
        },
        handleSubmit: handleSubmit,
        isUpdated: isUpdated,
      }}
    >
      <Container className="pt-5">
        <Header
          left={{ backButton: true, menuButton: true }}
          center={{ title: t("title") }}
        />
        <Row className="mb-5">
          <Col xs="12">
            <Stepper activeStep={2}>
              {steps.map(({ label, path }) => (
                <Step
                  key={label}
                  onClick={() => (path ? navigate(path) : null)}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs="12" lg="3" md="6" className="mb-5">
            <References />
          </Col>
          <Col xs="12" lg="3" md="6" className="mb-5">
            <Images />
          </Col>
          <Col xs="12" lg="3" md="6" className="mb-5">
            <SortableList />
          </Col>
          <Col xs="12" lg="3" md="6" className="mb-5">
            <DefaultImage />
          </Col>
          <Col xs="12" className="text-end mt-5">
            <Save />
          </Col>
        </Row>
      </Container>
      <OverlayConfirm
        word="referência"
        title={t("references.title", { ns: "pm/overlayConfirm" })}
        content={t("references.content", { ns: "pm/overlayConfirm" })}
        show={confirm.visible}
        onSelect={() => {
          setSelected(confirm.newSelected);
          handleCloseConfirm();
        }}
        handleCloseConfirm={handleCloseConfirm}
      />
    </context.Provider>
  );
};

export default Provider;
