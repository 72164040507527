//? STYLED COMPONENTS
import { ScrollView, ItemList, Color, Remove } from "./Styles";
import styled from "styled-components";

//? LODASH
import lodash from "lodash";

//? REACT-ICONS
import { FaTrashAlt, FaPlus } from "react-icons/fa";

//? REACT-BOOTSTRAP
import { OverlayTrigger, Popover, Button, Form } from "react-bootstrap";

//? REACT
import { useEffect, useRef, useState } from "react";

//? useTranslation
import { useTranslation } from "react-i18next";

//!---------------------------------------------------!\\
//!---------------------CONTAINER---------------------!\\
//!---------------------------------------------------!\\

const Container = ({ initColors, activeColor, editable, onClick }) => {
  const [colors, setColors] = useState([...initColors]);
  const { t } = useTranslation(["pm/references"]);
  //* onDELETE
  const onDelete = (color) => {
    const x = colors.filter((e) => e !== color);
    onClick("color", null);
    setColors(x);
  };

  //* useEffect
  useEffect(() => {
    if (!lodash.isEmpty(activeColor)) {
      if (!isExist(colors, activeColor)) {
        setColors([...initColors, activeColor]);
      }
    }
  }, [activeColor]);

  //* if !editable
  if (!editable) {
    return (
      <>
        {lodash.isEmpty(activeColor) || lodash.isUndefined(activeColor) ? (
          <p>{t("fields.color.errors.notFound")}</p>
        ) : (
          <ScrollView>
            <Item colorRef={activeColor} />
          </ScrollView>
        )}
      </>
    );
  }

  //* render
  return (
    <>
      <ScrollView>
        {!lodash.isEmpty(colors) ? (
          colors.map((e, index) => (
            <Item
              key={index}
              colorRef={e}
              selected={e === activeColor || e === "#" + activeColor}
              onClick={onClick}
              deletable={!isExist(initColors, e)}
              onDelete={() => {
                onDelete(e);
              }}
            />
          ))
        ) : (
          <></>
        )}
        <ColorPickerOverlay
          onClick={(newColor) => {
            if (!isExist(colors, newColor)) {
              setColors([...colors, newColor]);
              onClick("color", newColor);
            }
          }}
        />
      </ScrollView>
    </>
  );
};

//!----------------------------------------------!\\
//!---------------------ITEM---------------------!\\
//!----------------------------------------------!\\
const Item = ({ colorRef, selected, deletable, onClick, onDelete }) => {
  return (
    <ItemList
      selected={selected}
      onClick={(e) => {
        e.stopPropagation();
        if (selected) {
          return onClick("color", null);
        }
        return onClick("color", colorRef);
      }}
    >
      <Color
        colorRef={colorRef.substr(0, 1) === "#" ? colorRef : "#" + colorRef}
      >
        {deletable ? (
          <Remove
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <FaTrashAlt />
          </Remove>
        ) : (
          <></>
        )}
      </Color>
    </ItemList>
  );
};

const AddButton = styled.button`
  border-radius: 100px;
  height: 3rem;
  width: 3rem;
  color: #a20013;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  transition: 200ms ease;
  &:hover {
    background-color: #a20013;
    color: white;
    transition: 200ms ease;
  }
`;

//verificar se a cor existe
const isExist = (array, color) => {
  const colorn = color.charAt(0) == "#" ? color : "#" + color;
  const x = array.find((e) => e === colorn);

  if (x === undefined) return false;
  return true;
};

const ColorPickerOverlay = ({ onClick }) => {
  const { t } = useTranslation(["pm/references"]);
  const colorRef = useRef("#ffffff");
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover>
          <Popover.Body>
            <div className="h5 app-sub-title">
              {t("fields.color.popover.title")}
            </div>
            <Form.Group>
              <Form.Label>{t("fields.color.label")}</Form.Label>
              <Form.Control
                ref={colorRef}
                type="color"
                className="mx-auto w-100"
              />
            </Form.Group>
            <Button
              className="w-100 mt-3"
              variant="secondary"
              type="button"
              onClick={() => onClick(colorRef.current.value)}
            >
              {t("fields.color.popover.button")}
            </Button>
          </Popover.Body>
        </Popover>
      }
    >
      <ItemList>
        <AddButton type="button">
          <FaPlus />
        </AddButton>
      </ItemList>
    </OverlayTrigger>
  );
};

const Pin2GiveColorPicker = (props) => <Container {...props} />;
export default Pin2GiveColorPicker;
