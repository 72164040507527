//? LODASH

import { isEmpty } from "lodash";
//? REACT
import React, { memo, useCallback } from "react";

//? REACT-BOOTSTRAP
import {
  Badge,
  Button,
  Col,
  Form,
  ListGroup,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

//? REACT-ICONS
import { FaCheck, FaTimes, FaTrashAlt } from "react-icons/fa";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

//? SEASONCROSSTABLE DATA
import { useSeasonCrossTable } from "./useSeasonCrossTable";

//? ACTIONS
import {
  getCompositionChecked,
  getCompositionTitle,
} from "./_actions/compositions";
import { getCountryListByComposition } from "./_actions/countries";
import {
  handleEnableComposition,
  checkEnableComposition,
  removeSCTValuesByComposition,
  handlePostComposition,
} from "./_actions/seasonCrossTable";

//? STYLES
import { Container, FormTitle2 } from "./_forms/Forms.styles";

function CompositionPreview() {
  //!-------------------------------------------------------!\\
  //!-------------------------Hooks-------------------------!\\
  //!-------------------------------------------------------!\\
  //* season cross table
  const {
    compositions,
    sct,
    countries,
    products,
    saveObject,
    seasons,
    modalRemove,
    confirm,
  } = useSeasonCrossTable();

  //* authentication
  const { role } = useAuth();

  //* translation
  const { t } = useTranslation(["pm/sct"]);

  //!-------------------------------------------------------!\\
  //!-----------------------Callbacks-----------------------!\\
  //!-------------------------------------------------------!\\
  //* is enabled
  const isEnabled = useCallback(
    () => checkEnableComposition(sct, products.selected, compositions.selected),
    [sct, products.selected, compositions.selected]
  );

  //* country list
  const countryList = useCallback(
    () =>
      getCountryListByComposition(
        sct,
        countries?.data ?? [],
        products.selected,
        compositions.selected
      ),
    [sct, countries?.data, products.selected, compositions.selected]
  );

  //!-------------------------------------------------------!\\
  //!------------------------events-------------------------!\\
  //!-------------------------------------------------------!\\
  //* handle enabled
  const handleEnabled = () => {
    confirm.set((e) => ({
      ...e,
      show: true,
      onClick: () => {
        saveObject(
          handleEnableComposition(sct, products.selected, compositions.selected)
        );
        confirm.set((i) => ({ ...i, show: false }));
      },
      title:
        isEnabled() === 5 || isEnabled() === 4
          ? t("compositionPreview.alerts.rejectComposition.title")
          : t("compositionPreview.alerts.approveComposition.title"),
      content:
        isEnabled() === 5 || isEnabled() === 4
          ? t("compositionPreview.alerts.rejectComposition.content")
          : t("compositionPreview.alerts.approveComposition.content"),
      primaryButtonLabel:
        isEnabled() === 5 || isEnabled() === 4
          ? t("compositionPreview.alerts.rejectComposition.primaryButtonLabel")
          : t(
              "compositionPreview.alerts.approveComposition.primaryButtonLabel"
            ),
    }));
  };

  //* handle post
  const handlePost = () => {
    saveObject(
      handlePostComposition(
        sct,
        products.selected,
        compositions.selected,
        isEnabled() === 5 ? 4 : 5
      )
    );
  };

  //* remove compositon
  const removeComposition = () => {
    return modalRemove.setRemove((e) => ({
      ...e,
      show: true,
      onRemove: () =>
        saveObject(
          removeSCTValuesByComposition(
            sct,
            products.selected,
            compositions.selected,
            seasons.selected
          )
        ),
      title: t("compositionPreview.removeAlert.title"),
      content: t("compositionPreview.removeAlert.content"),
    }));
  };

  //!-------------------------------------------------------!\\
  //!------------------------render-------------------------!\\
  //!-------------------------------------------------------!\\
  //* não renderizar nada enquanto não houver composição selecionada
  if (compositions.selected === -1) {
    return <></>;
  }

  return (
    <Container>
      <Row
        className={`mb-2 ${
          countries.selected !== -1 ? "flex-column-reverse" : ""
        }`}
      >
        {getCompositionChecked(
          sct,
          products.selected,
          compositions.selected
        ) && (
          <>
            <Col xs={`${countries.selected !== -1 ? "12" : "6"}`}>
              <Row>
                <Col xs="12" className="mb-3">
                  <div>
                    <b>{t("compositionPreview.fields.state") + " "}</b>{" "}
                    {isEnabled() === 5 ? (
                      <Badge pill bg="primary">
                        {t("compositionPreview.badges.posted")}
                      </Badge>
                    ) : isEnabled() === 4 ? (
                      <Badge pill bg="secondary">
                        {t("compositionPreview.badges.nPosted")}
                      </Badge>
                    ) : isEnabled() === 3 ? (
                      <Badge pill bg="success">
                        {t("compositionPreview.badges.approved")}
                      </Badge>
                    ) : isEnabled() === 2 ? (
                      <Badge pill bg="danger">
                        {t("compositionPreview.badges.rejected")}
                      </Badge>
                    ) : isEnabled() === 1 ? (
                      <Badge pill bg="warning" text="dark">
                        {t("compositionPreview.badges.pending")}
                      </Badge>
                    ) : (
                      <Badge pill bg="light" text="dark">
                        {t("compositionPreview.badges.inactive")}
                      </Badge>
                    )}
                  </div>
                </Col>
                {isEnabled() !== 0 && isEnabled() !== 1 && isEnabled() !== 2 && (
                  <Col xs="12">
                    <div className="d-flex align-items-center">
                      <b>{t("compositionPreview.fields.post") + " "}</b>
                      <Form.Check
                        type="switch"
                        className="ms-2"
                        onChange={handlePost}
                        checked={isEnabled() === 5 ? true : false}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              xs={`${countries.selected !== -1 ? "12" : "6"}`}
              className={`d-flex align-items-center mb-3 ${
                countries.selected !== -1
                  ? "justify-content-center"
                  : "justify-content-end"
              }`}
            >
              {role === 4 && (
                <ToggleButton
                  className=" d-flex"
                  variant={`${
                    isEnabled() !== 0 && isEnabled() !== 1 && isEnabled() !== 2
                      ? "outline-danger"
                      : "outline-success"
                  }`}
                  onClick={handleEnabled}
                  size="sm"
                >
                  {isEnabled() !== 0 &&
                  isEnabled() !== 1 &&
                  isEnabled() !== 2 ? (
                    <div className="d-flex align-items-center">
                      <FaTimes className="me-2" />
                      {t("compositionPreview.buttons.reject")}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <FaCheck className="me-2" />
                      {t("compositionPreview.buttons.approve")}
                    </div>
                  )}
                </ToggleButton>
              )}
              <Button
                variant="danger"
                size="sm"
                className={`${role === 4 ? "ms-2" : "ms-auto"}`}
                onClick={removeComposition}
              >
                <FaTrashAlt />
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row className="mb-4">
        <Col xs="12">
          <FormTitle2 className="d-flex align-items-center flex-column mb-0">
            {getCompositionTitle(compositions?.data, compositions?.selected)}
          </FormTitle2>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <div className="h6 fw-bold app-title text-center mb-3">
            {t("compositionPreview.availability")}
          </div>
        </Col>
        <Col xs="12">
          <ListGroup as="ul">
            {countryList().map((e, index) => (
              <ListGroup.Item
                as="li"
                key={index}
                className="d-flex justify-content-between align-items-center "
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{e?.name}</div>
                </div>
              </ListGroup.Item>
            ))}
            {isEmpty(countryList()) && (
              <div className="text-center">
                {t("compositionPreview.naCountries")}
              </div>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(CompositionPreview);
