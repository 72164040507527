import React from "react";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useProductsContext from "../../../../contexts/ProductsManagement/Products/useProductsContext";
import lodash from "lodash";
import Pin2GiveForm from "../../../../components/Pin2GiveForm/Pin2GiveForm";
import ReactQuill from "react-quill";
import { _quillModules } from "../_actions";
import PlSkeleton from "../PlSkeleton";

// import { Container } from './styles';

function Idiomatics() {
  //!----------------------------------------------------!\\
  //!-----------------------hooks------------------------!\\
  //!----------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/products", "countries"]);

  //* products
  const { forms, initProductsLanguages } = useProductsContext();
  const formik = forms.productLanguages;

  //!----------------------------------------------------!\\
  //!-----------------------methods----------------------!\\
  //!----------------------------------------------------!\\
  const isInvalidTab = (key) =>
    !lodash.isEmpty(formik.errors[key]?.title) ||
    !lodash.isEmpty(formik.errors[key]?.description) ||
    !lodash.isEmpty(formik.errors[key]?.shortdescription);

  //!----------------------------------------------------!\\
  //!-----------------------values-----------------------!\\
  //!----------------------------------------------------!\\
  const keys = [
    { key: "PT", title: t("PT.country", { ns: "countries" }) },
    { key: "EN", title: t("EN.country", { ns: "countries" }) },
    { key: "ES", title: t("ES.country", { ns: "countries" }) },
    { key: "FR", title: t("FR.country", { ns: "countries" }) },
    { key: "DE", title: t("DE.country", { ns: "countries" }) },
    { key: "IT", title: t("IT.country", { ns: "countries" }) },
  ];

  return (
    <Container>
      <Row>
        <Col xs="12">
          <form onSubmit={formik.handleSubmit}>
            <Tabs className="mb-3 form-tabs" fill variant="tabs">
              {keys.map((e, index) => (
                <Tab
                  tabClassName={`${isInvalidTab(e?.key) ? "is-invalid" : ""}`}
                  key={index}
                  eventKey={e?.key}
                  title={e.title}
                >
                  <Container>
                    {initProductsLanguages.isLoading ? (
                      <PlSkeleton />
                    ) : (
                      <Row>
                        {
                          //!----------------------------------------------------!\\
                          //!-----------------------title------------------------!\\
                          //!----------------------------------------------------!\\
                        }
                        <Col xs="12" lg="8">
                          <Pin2GiveForm.Input
                            label={t("fields.iTitle.label")}
                            inputProps={{
                              name: `${e.key}.title`,
                              value: formik.values[`${e.key}`]?.title ?? "",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              readOnly: !forms.editable,
                            }}
                            error={formik.errors[`${e.key}`]?.title}
                          />
                        </Col>
                        {
                          //!----------------------------------------------------!\\
                          //!-----------------------tooltip----------------------!\\
                          //!----------------------------------------------------!\\
                        }
                        <Col xs="12" lg="4">
                          <Pin2GiveForm.Input
                            label={t("fields.iTooltip.label")}
                            inputProps={{
                              name: `${e.key}.tooltip`,
                              value: formik.values[`${e.key}`]?.tooltip ?? "",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              readOnly: !forms.editable,
                            }}
                            error={formik.errors[`${e.key}`]?.tooltip}
                          />
                        </Col>
                        {
                          //!----------------------------------------------------!\\
                          //!------------------shortdescription------------------!\\
                          //!----------------------------------------------------!\\
                        }
                        <Col xs="12">
                          <Pin2GiveForm.Input
                            label={t("fields.ishortdescription.label")}
                            inputProps={{
                              name: `${e.key}.shortdescription`,
                              as: "textarea",
                              value:
                                formik.values[`${e.key}`]?.shortdescription ??
                                "",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              readOnly: !forms.editable,
                            }}
                            error={formik.errors[`${e.key}`]?.shortdescription}
                          />
                        </Col>
                        {
                          //!----------------------------------------------------!\\
                          //!---------------------description--------------------!\\
                          //!----------------------------------------------------!\\
                        }
                        <Col xs="12">
                          <Form.Group className="mb-4">
                            <Form.Label>
                              {t("fields.idescription.label")}
                            </Form.Label>
                            <ReactQuill
                              className={`rq-form-input ${
                                formik.errors[`${e.key}`]?.description
                                  ? "is-invalid"
                                  : ""
                              } ${!forms.editable ? "bordered" : ""}`}
                              modules={_quillModules(forms.editable)}
                              readOnly={!forms.editable}
                              value={
                                formik.values[`${e.key}`]?.description ?? ""
                              }
                              onChange={(value) => {
                                let str = value;
                                str = str.replace(/(<([^>]+)>)/gi, "");
                                if (lodash.isEmpty(str)) {
                                  formik.setFieldValue(
                                    `${e.key}.description`,
                                    ""
                                  );
                                } else {
                                  formik.setFieldValue(
                                    `${e.key}.description`,
                                    value
                                  );
                                }
                              }}
                            />
                            <Form.Text className="text-danger">
                              {formik.errors[`${e.key}`]?.description}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        {
                          //!----------------------------------------------------!\\
                          //!---------------------specification------------------!\\
                          //!----------------------------------------------------!\\
                        }
                        <Col xs="12" lg="12">
                          <Form.Group className="mb-4">
                            <Form.Label>
                              {t("fields.ispecification.label")}
                            </Form.Label>
                            <ReactQuill
                              className={`rq-form-input ${
                                formik.errors[`${e.key}`]?.specification
                                  ? "is-invalid"
                                  : ""
                              } ${!forms.editable ? "bordered" : ""}`}
                              modules={_quillModules(forms.editable)}
                              readOnly={!forms.editable}
                              value={
                                formik.values[`${e.key}`]?.specification ?? ""
                              }
                              onChange={(value) => {
                                let str = value;
                                str = str.replace(/(<([^>]+)>)/gi, "");
                                if (lodash.isEmpty(str)) {
                                  formik.setFieldValue(
                                    `${e.key}.specification`,
                                    ""
                                  );
                                } else {
                                  formik.setFieldValue(
                                    `${e.key}.specification`,
                                    value
                                  );
                                }
                              }}
                            />
                            <Form.Text className="text-danger">
                              {formik.errors[`${e.key}`]?.specification}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Container>
                </Tab>
              ))}
            </Tabs>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Idiomatics;
