import lodash from "lodash";

/**
 * !-------------------------------------------------------!
 * !--------------------Format Producer--------------------!
 * !-------------------------------------------------------!
 * @param {object} producer dados do producer
 * @param {integer} fkSupplier id do supplier
 * @returns {object} retorna um objeto com os dados do producer e fkSupplier atualizado
 */
export const formatProducer = (producer, fkSupplier) => {
  return {
    ...producer,
    fkPtype: Number(producer.fkPtype),
    fkCountry: Number(producer.fkCountry),
    fk_supplier: fkSupplier,
  };
};

//!-------------------------------------------------------!
//!----------------------New Producer---------------------!
//!-------------------------------------------------------!
//* é o objeto utilizado no formulário quando
//* um novo producer é criado
export const newProducer = {
  idProducer: -1,
  fkPtype: -1,
  fkCountry: -1,
  designation: "",
  email: "",
  url: "",
  is_promotation: 0,
  fk_supplier: -1,
};

/**
 * !-------------------------------------------------------!
 * !----------Convert Producer Languages To Form-----------!
 * !-------------------------------------------------------!
 * @param {array} producerLanguages array producerLanguages obtido no serviço
 * @returns {object} retorna o objeto utilizado no formulário com o formato {EN:{}, PT:{}, ES:{}, FR:{}, DE:{}, IT:{}}
 */
export const convertProducerLanguagesToForm = (producerLanguages) => {
  //* array producer languages
  const x = [...producerLanguages];

  //* o objeto do producerLanguages que será utilizado
  //* no formulário
  let y = {
    EN: { designation: "", description: "" },
    PT: { designation: "", description: "" },
    ES: { designation: "", description: "" },
    FR: { designation: "", description: "" },
    DE: { designation: "", description: "" },
    IT: { designation: "", description: "" },
  };

  //* se houver dados no array de idiomáticas
  //* será adicionado ao objeto do formulário
  if (!lodash.isEmpty(x))
    x.forEach((e) => {
      if (!lodash.isEmpty(e.designation) && !lodash.isEmpty(e.description)) {
        y[`${e.producerLanguagePK.fkLanguage}`] = {
          designation: e.designation,
          description: e.description,
        };
      }
    });

  if (lodash.isEmpty(y)) return null;

  return y;
};

/**
 * !-------------------------------------------------------!
 * !----------Convert Producer Languages To Array----------!
 * !-------------------------------------------------------!
 * @param {object} form objeto que vem no formulário das producerLanguages
 * @param {*} fkProducer  id do producer
 * @returns {array} retorna array que será utilizado para submeter as idiomáticas
 */
export const convertFormToProducerLanguages = (form, fkProducer) => {
  const x = { ...form };
  const y = [];
  const keys = Object.keys(x);

  keys.forEach((e) => {
    let z = x[`${e}`];
    z.producerLanguagePK = {
      fkProducer: fkProducer,
      fkLanguage: `${e}`.toUpperCase(),
    };
    y.push(z);
  });

  return y;
};

export const compareData = (obj1, obj2) => {
  let x = obj1;
  let y = obj2;

  delete x.EN.description;
  delete x.PT.description;
  delete x.ES.description;
  delete x.DE.description;
  delete x.FR.description;
  delete x.IT.description;

  delete x.EN.producerLanguagePK;
  delete x.PT.producerLanguagePK;
  delete x.ES.producerLanguagePK;
  delete x.DE.producerLanguagePK;
  delete x.FR.producerLanguagePK;
  delete x.IT.producerLanguagePK;

  delete y.EN.producerLanguagePK;
  delete y.PT.producerLanguagePK;
  delete y.ES.producerLanguagePK;
  delete y.DE.producerLanguagePK;
  delete y.FR.producerLanguagePK;
  delete y.IT.producerLanguagePK;

  delete y.EN.description;
  delete y.PT.description;
  delete y.ES.description;
  delete y.DE.description;
  delete y.FR.description;
  delete y.IT.description;

  return lodash.isEqual(x, y);
};
