import lodash from "lodash";

export const initValues = {
  showConfirm: {
    show: false,
    newSelect: {},
  },
};

export const useSteps = (t = null, fkProduct) => [
  { label: t("steps.products"), path: null },
  {
    label: t("steps.categories"),
    path: `/productsmanagement/productcategories/${fkProduct}`,
  },
  {
    label: t("steps.compositions"),
    path: `/productsmanagement/compositions/${fkProduct}/-`,
  },
  {
    label: t("steps.compositionReferences"),
    path: `/productsmanagement/compositionsreferences/${fkProduct}`,
  },
];

export const convertProductLanguagesToForm = (producerLanguages) => {
  const x = [...producerLanguages];

  let y = {
    EN: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
    PT: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
    ES: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
    DE: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
    IT: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
    FR: {
      title: "",
      tooltip: "",
      shortdescription: "",
      description: "",
      specification: "",
      isnew: 0,
    },
  };

  if (!lodash.isEmpty(x)) {
    x.forEach((e) => {
      y[`${e.productLanguagePK.fkLanguage}`] = {
        title: e.title,
        tooltip: e.tooltip,
        shortdescription: e.shortdescription,
        description: e.description,
        specification: e.specification,
        cdate: e.cdate,
        cstate: e.cstate,
        isnew: 1,
      };
    });
  }

  if (lodash.isEmpty(y)) return null;

  return y;
};

export const convertFormToProductLanguages = (form, fkProduct) => {
  const x = { ...form };
  const y = [];
  const keys = Object.keys(x);

  keys.forEach((e) => {
    let z = x[`${e}`];

    z.productLanguagePK = {
      fkProduct,
      fkLanguage: `${e}`.toUpperCase(),
    };

    delete z.isnew;

    if (
      !(
        lodash.isEmpty(z.title) ||
        lodash.isEmpty(z.shortdescription) ||
        lodash.isEmpty(z.description)
      )
    ) {
      if (lodash.isEmpty(z.tooltip)) {
        z.tooltip = "-";
      }

      if (lodash.isEmpty(z.specification)) {
        z.specification = "-";
      }

      y.push(z);
    }
  });

  return y;
};

export const newProduct = {
  idProduct: -1,
  fkSupplier: -1,
  fkProducer: -1,
  stitle: "",
  observations: "",
  specialcares: "",
  specificregulation: "",
  fkProtocol: 0,
  preparationtime: 0,
  tconsignment: 0,
  dwarranty: 0,
  dcomission: 0,
  dpaymentForm: 0,
  state: 0,
  relevance: 0,
  cstate: 0,
  cdate: 0,
  dimage: 0,
  type: 0,
};

export const formatProduct = (product, fkSupplier) => ({
  ...product,
  fkSupplier,
  fkProducer: Number(product.fkProducer),
  fkProtocol: Number(product.fkProtocol),
});
