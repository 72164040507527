import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";

function FormTitle({ selected, editable, setEditable }) {
  //!--------------------------------------------------------!\\
  //!-------------------------hooks--------------------------!\\
  //!--------------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/producers", "pm/overlayConfirm"]);

  return (
    <div className="app-sub-title w-100 container-fluid">
      <Row>
        <Col xs="12">
          <div className="border-bottom pb-2 d-flex justify-content-between">
            <div>{selected?.designation || t("list.newProducer")}</div>
            <div>
              {!editable ? (
                <Row>
                  <Col xs="12" className="text-end">
                    <Button
                      size="sm"
                      className="rounded-circle"
                      variant="warning"
                      onClick={() => setEditable(true)}
                    >
                      <FaEdit />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FormTitle;
