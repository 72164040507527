//? REACT
import { useState, useEffect } from "react";

//? REACT-BOOTSTRAP
import { Dropdown, NavDropdown } from "react-bootstrap";

//? REACT-ROUTER-DOM
import { useNavigate } from "react-router-dom";

//? I18N
import { useTranslation } from "react-i18next";

//? FLAGS
import flagPt from "../../assets/images/flags/pt.png";
import flagEn from "../../assets/images/flags/gb.png";
import flagDe from "../../assets/images/flags/de.png";
import flagEs from "../../assets/images/flags/es.png";
import flagFr from "../../assets/images/flags/fr.png";
import flagIt from "../../assets/images/flags/it.png";
import "./DropdownLang.css";

const getTitleComponent = (titleString) => {
  switch (titleString) {
    case "pt-PT":
      return (
        <img
          src={flagPt}
          alt="pt"
          title=""
          className="drodown-lang-title-flag"
        />
      );
    case "es":
      return (
        <img
          src={flagEs}
          title=""
          alt="es"
          className="drodown-lang-title-flag"
        />
      );
    case "de":
      return (
        <img
          src={flagDe}
          title=""
          alt="de"
          className="drodown-lang-title-flag"
        />
      );
    case "fr":
      return (
        <img
          src={flagFr}
          title=""
          alt="fr"
          className="drodown-lang-title-flag"
        />
      );
    case "it":
      return (
        <img
          src={flagIt}
          title=""
          alt="it"
          className="drodown-lang-title-flag"
        />
      );
    default:
      return (
        <img
          src={flagEn}
          alt="en"
          title=""
          className="drodown-lang-title-flag"
        />
      );
  }
};

const DropdownLang = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [title, setTitle] = useState({
    lang: i18n?.language,
    img: getTitleComponent(i18n?.language),
  });

  const getLanguage = () => {
    return (
      i18n?.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    );
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setTitle({ lang: lang, img: getTitleComponent(lang) });
    if (props?.toggle) {
      props.toggle();
    }
    if (!props.redirect) {
      navigate("/");
    }
  };

  useEffect(() => {
    //console.log("language", getLanguage());
    setTitle({ lang: getLanguage(), img: getTitleComponent(getLanguage()) });
    //console.log("list languages", i18next.languages);
  }, []);

  return (
    <NavDropdown
      align="end"
      id="dropdownlang-button align-self-center"
      title={title.img}
      className="dropdown-lang"
    >
      <NavDropdown.Header>{t("Header.langDropdown.header")}</NavDropdown.Header>
      <NavDropdown.Item
        disabled={title.lang === "en"}
        className={`${title.lang === "en" ? "active" : ""}`}
        onClick={() => changeLanguageHandler("en")}
      >
        <img src={flagEn} alt="en" className="shadow" title="" />
        <span>{t("Header.langDropdown.en")}</span>
      </NavDropdown.Item>
      <NavDropdown.Item
        disabled={title.lang === "pt-PT"}
        className={`${title.lang === "pt-PT" ? "active" : ""}`}
        onClick={() => changeLanguageHandler("pt-PT")}
      >
        <img src={flagPt} alt="pt" className="shadow" title="" />
        <span>{t("Header.langDropdown.pt-PT")}</span>
      </NavDropdown.Item>
      {/*<Dropdown.Toggle variant="link" data-testid="dropdown-lang">
          {title.img}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>{t("Header.langDropdown.header")}</Dropdown.Header>
          <Dropdown.Item
            onClick={() => changeLanguageHandler("en")}
            className={title.lang === "en" ? "active" : ""}
            data-testid="en"
            title=""
          >
            <img src={flagEn} alt="en" className="shadow" title="" />
            <span>{t("Header.langDropdown.en")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => changeLanguageHandler("pt-PT")}
            className={title.lang === "pt-PT" ? "active" : ""}
            data-testid="pt"
            title=""
          >
            <img src={flagPt} alt="pt" className="shadow" title="" />
            <span>{t("Header.langDropdown.pt-PT")}</span>
          </Dropdown.Item>*/}
      {/* <Dropdown.Item
            onClick={() => changeLanguageHandler("es")}
            className={title.lang === "es" ? "active" : ""}
            data-testid="es"
          >
            <img src={flagEs} alt="es" className="shadow" />
            <span>{t("Header.langDropdown.es")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => changeLanguageHandler("de")}
            className={title.lang === "de" ? "active" : ""}
            data-testid="de"
          >
            <img src={flagDe} alt="de" className="shadow" />
            <span>{t("Header.langDropdown.de")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => changeLanguageHandler("fr")}
            className={title.lang === "fr" ? "active" : ""}
            data-testid="fr"
          >
            <img src={flagFr} alt="fr" className="shadow" />
            <span>{t("Header.langDropdown.fr")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => changeLanguageHandler("it")}
            className={title.lang === "it" ? "active" : ""}
            data-testid="it"
          >
            <img src={flagIt} alt="it" className="shadow" />
            <span>{t("Header.langDropdown.it")}</span>
          </Dropdown.Item> */}
      {/*</Dropdown.Menu>*/}
    </NavDropdown>
  );
};

export default DropdownLang;
