/* eslint-disable react-hooks/exhaustive-deps */
//? LODASH
import lodash from "lodash";

//? REACT
import React, { useState, useEffect } from "react";

//? REACT-ICONS
import { FaArrowLeft, FaEdit } from "react-icons/fa";

//? REACT-BOOTSTRAP
import { Container, Row, Col, Button, Alert } from "react-bootstrap";

//? COMPONENTS
import Form from "./Forms";
import Header from "../Header";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";
import OverlayConfirm from "../OverlayConfirm";

//? REACT-I18NEXT
import { useTranslation } from "react-i18next";
import { createObject, formatValues } from "./actions";

//? FORMIK
import { useFormik } from "formik";
import Loading from "../Loading";
import { validationSchema } from "../../../_yup/ProductsManagement/CreateSeason";

//? useAlert
import { useAlert } from "react-alert";

import { useMutation, useQuery } from "react-query";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";

const CreateSeason = () => {
  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  //!----------------useTranslation----------------!\\
  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  const { t } = useTranslation(["pm/createSeasons", "pm/overlayConfirm"]);

  //!------------------------------------------!\\
  //!------------------------------------------!\\
  //!----------------useContext----------------!\\
  //!------------------------------------------!\\
  //!------------------------------------------!\\
  const { fkSupplier } = usePM();
  const { seasonsEndpoints } = useServices();

  //!----------------------------------------!\\
  //!----------------------------------------!\\
  //!----------------useAlert----------------!\\
  //!----------------------------------------!\\
  //!----------------------------------------!\\
  const alert = useAlert();

  //!----------------------------------------!\\
  //!----------------------------------------!\\
  //!----------------editable----------------!\\
  //!----------------------------------------!\\
  //!----------------------------------------!\\
  const [editable, setEditable] = useState(false);

  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  //!----------------confirm overlay----------------!\\
  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: {},
  });
  const handleCloseConfirm = () => setShowConfirm(false);

  //!---------------------------------------!\\
  //!---------------------------------------!\\
  //!----------------seasons----------------!\\
  //!---------------------------------------!\\
  //!---------------------------------------!\\
  const [selected, setSelected] = useState(null);
  //* get seasons
  const {
    isLoading,
    data,
    refetch: getSeasons,
  } = useQuery(
    ["getSeasons", fkSupplier],
    () => seasonsEndpoints.getAllSeasonBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //!--------------------------------------------!\\
  //!--------------------------------------------!\\
  //!----------------handleSubmit----------------!\\
  //!--------------------------------------------!\\
  //!--------------------------------------------!\\
  const mutation = useMutation(
    (newSeason) => {
      if (
        newSeason.depublication === "Invalid date" ||
        newSeason.depublication === ""
      ) {
        newSeason.depublication = "";
      }
      return seasonsEndpoints.create_or_update(formatValues(newSeason));
    },
    {
      onSuccess: (data) => {
        if (!lodash.isEmpty(data?.data)) {
          setSelected(data.data);
          setEditable(false);
          alert.success(
            <>
              <Alert.Heading>{t("alerts.success.submit.title")}</Alert.Heading>
              <p>{t("alerts.success.submit.message")}</p>
            </>
          );
        }
      },
      onSettled: () => {
        getSeasons();
      },
    }
  );

  const { mutate: handleRemove } = useMutation(
    (data) => {
      return seasonsEndpoints.remove(formatValues(data));
    },
    {
      onSuccess: (res) => {
        setSelected(data?.data[0] ?? null);
        getSeasons();

        if (res.data === 1) {
          alert.error(
            <>
              <p>
                Não foi possível remover a temporada. A mesma poderá estar a ser
                utilizada!
              </p>
            </>
          );
        } else {
          alert.success(
            <>
              <p>A temporada foi removida com sucesso!</p>
            </>
          );
        }
      },
    }
  );

  //!--------------------------------------!\\
  //!--------------------------------------!\\
  //!----------------formik----------------!\\
  //!--------------------------------------!\\
  //!--------------------------------------!\\
  const formik = useFormik({
    initialValues: selected ?? createObject(fkSupplier),
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  //!------------------------------------------!\\
  //!------------------------------------------!\\
  //!----------------useEffects----------------!\\
  //!------------------------------------------!\\
  //!------------------------------------------!\\
  //* data
  useEffect(() => {
    const x = data?.data;
    if (lodash.isEmpty(x) || !x) {
      setSelected(null);
    } else {
      //se houver seasons
      //verificar se existe uma season selecionada
      if (!selected) {
        setSelected(data?.data[0]);
      }
    }
  }, [data?.data]);

  //!--------------------------------------!\\
  //!--------------------------------------!\\
  //!----------------RENDER----------------!\\
  //!--------------------------------------!\\
  //!--------------------------------------!\\
  return (
    <div className="app-products-create-seasons">
      <Container>
        {
          //!--------------------------------------------!\\
          //!-------------------HEADER-------------------!\\
          //!--------------------------------------------!\\
        }
        <Header
          left={{ menuButton: true, backButton: true }}
          center={{ title: t("title") }}
          infoKey="seasons"
        />
        {
          //!---------------------------------------------!\\
          //!-------------------LOADING-------------------!\\
          //!---------------------------------------------!\\
          isLoading ? (
            <Loading />
          ) : (
            <Row className={`h-100 ${!selected ? "align-items-center" : ""}`}>
              {
                //!--------------------------------------------------!\\
                //!-------------------Pin2GiveList-------------------!\\
                //!--------------------------------------------------!\\
              }
              <Col xs="12" lg="3" className="mb-5">
                <Pin2GiveList
                  headerProps={{
                    title: t("list.title"),
                    headerButton: {
                      onClick: () => {
                        setSelected(createObject(fkSupplier));
                        setEditable(true);
                      },
                      title: t("list.buttonTooltip"),
                    },
                  }}
                >
                  {data?.data.map((e, index) => (
                    <Pin2GiveListItem
                      key={index}
                      type="text"
                      onRemove={() =>
                        setShowConfirm({
                          show: true,
                          newSelect: e,
                          title: t("seasons.title2", {
                            ns: "pm/overlayConfirm",
                          }),
                          content: t("seasons.content2", {
                            ns: "pm/overlayConfirm",
                          }),
                          type: 1,
                        })
                      }
                      selected={e?.idSeason === selected?.idSeason}
                      title={e?.title}
                      onClick={() => {
                        if (lodash.isEqual(formik.values, selected)) {
                          setSelected(e);
                          setEditable(false);
                        } else {
                          setShowConfirm({
                            show: true,
                            newSelect: e,
                            title: t("seasons.title", {
                              ns: "pm/overlayConfirm",
                            }),
                            content: t("seasons.content", {
                              ns: "pm/overlayConfirm",
                            }),
                          });
                        }
                      }}
                    />
                  ))}
                  {lodash.isEqual(selected, createObject(fkSupplier)) ? (
                    <Pin2GiveListItem
                      selected
                      type="text"
                      title={t("newSeason")}
                    />
                  ) : (
                    <></>
                  )}
                </Pin2GiveList>
              </Col>
              <Col xs="12" lg="9" className="position-relative">
                {
                  //!-------------------------------------------!\\
                  //!-------------------EMPTY-------------------!\\
                  //!-------------------------------------------!\\
                  !selected ? (
                    <div className="h-100 d-flex flex-column align-items-center empty-active-reference">
                      <FaArrowLeft size={30} />
                      <div className="empty-active-reference">
                        {t("newSeason")}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Container fluid className="">
                        <Row className="mb-5">
                          {
                            //!-------------------------------------------------!\\
                            //!-------------------SeasonTitle-------------------!\\
                            //!-------------------------------------------------!\\
                          }
                          <Col xs="12">
                            <div className="app-sub-title w-100 container-fluid">
                              <Row>
                                <Col xs="12">
                                  <div className="border-bottom pb-2 d-flex justify-content-between">
                                    <div>
                                      {selected?.title || t("newSeason")}
                                    </div>
                                    <div>
                                      {!editable ? (
                                        <Row>
                                          <Col xs="12" className="text-end">
                                            <Button
                                              size="sm"
                                              className="rounded-circle"
                                              variant="warning"
                                              onClick={() => setEditable(true)}
                                            >
                                              <FaEdit />
                                            </Button>
                                          </Col>
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        {
                          //!------------------------------------------!\\
                          //!-------------------FOFM-------------------!\\
                          //!------------------------------------------!\\
                        }
                        <Row>
                          <Col xs="12">
                            <Form
                              editable={editable}
                              setEditable={setEditable}
                              formik={formik}
                              lb={mutation.isLoading}
                              selected={selected}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </>
                  )
                }
              </Col>
            </Row>
          )
        }
      </Container>
      <OverlayConfirm
        show={showConfirm.show}
        title={showConfirm?.title}
        content={showConfirm?.content}
        handleCloseConfirm={handleCloseConfirm}
        onSelect={() => {
          setSelected(showConfirm.newSelect);
          handleCloseConfirm();
          setEditable(false);

          if (showConfirm?.type === 1) {
            handleRemove(showConfirm.newSelect);
            handleCloseConfirm();
            setEditable(false);
          }
        }}
        remove={showConfirm?.type === 1}
      />
    </div>
  );
};

export default CreateSeason;
