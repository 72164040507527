import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaEye } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { Table } from "../_styles/maintable.styles";
import Search from "./Search";
import { getSortIcon, handleSort } from "./_sort";
import "../_styles/dashboard_pagination.css";
import { useNavigate } from "react-router-dom";
import { usePM } from "../../../../contexts/PMProvider/usePM";
import { useTranslation } from "react-i18next";

const itemsPerPage = 10;

function MainTable({ color, hoverColor, id, data, isLoading }) {
  const [keys, setKeys] = useState([]);
  const [sort, setSort] = useState();
  const [search, setSearch] = useState("");
  const { t } = useTranslation(["pm/dashboard"]);

  const { setPMfkSupplier } = usePM();

  //* pagination
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data.length ?? 0);
    setItemOffset(newOffset);
  };

  const handleChangeSort = useCallback(
    (nKey) => setSort(handleSort(sort, nKey)),
    [sort]
  );

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    setPageCount(0);
    setItemOffset(0);
  };

  const getLink = (id, object) => {
    switch (id) {
      case 0:
        return `/productsmanagement/products?fkp=${object?.id_product ?? -1}`;
      case 1:
        return `/productsmanagement/references/${object?.reference}`;
      case 2:
        return `/productsmanagement/seasons/?fkse=${object.fk_season}&fkp=${object.fk_product}&fkc=${object.fk_composition}`;
      case 3:
        return `/productsmanagement/?dfks=${object?.fk_supplier}`;
      default:
        return `path undefined`;
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      setSort(undefined);
      setPageCount(0);
      setItemOffset(0);
      setSearch("");
      switch (id) {
        case 0:
          return setKeys([
            { title: t("labels.fkSupplier"), key: "fk_supplier" },
            { title: t("labels.supplier"), key: "name_company" },
            { title: t("labels.fkProduct"), key: "id_product" },
            { title: t("labels.product"), key: "name_product" },
          ]);
        case 1:
          return setKeys([
            { title: t("labels.fkSupplier"), key: "fk_supplier" },
            { title: t("labels.supplier"), key: "name_company" },
            { title: t("labels.reference"), key: "reference" },
            { title: t("labels.stock"), key: "stock" },
          ]);
        case 2:
          return setKeys([
            { title: t("labels.fkSupplier"), key: "fk_supplier" },
            { title: t("labels.supplier"), key: "name_company" },
            { title: t("labels.season"), key: "name_season" },
            { title: t("labels.composition"), key: "name_composition" },
          ]);
        case 3:
          return setKeys([
            { title: t("labels.fkSupplier"), key: "fk_supplier" },
            { title: t("labels.supplier"), key: "name_company" },
            { title: t("labels.protocol"), key: "protocol" },
          ]);
        default:
          return setKeys([]);
      }
    }
  }, [id]);

  //* quando houver dados vindos do serviço
  //* calcular o número de páginas
  useEffect(() => {
    if (!isEmpty(data)) {
      const nData = data
        .filter((e) =>
          String(e.name_company)
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
        .sort((a, b) =>
          sort?.sort === 1
            ? a[sort?.key] < b[sort?.key]
            : a[sort?.key] > b[sort?.key]
        );
      const endOffset = itemOffset + itemsPerPage;
      setItems(nData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(nData.length / itemsPerPage));
    }
  }, [data, itemOffset, sort, search]);

  return (
    <Table.Container>
      <Table.Table responsive>
        <Table.Header color={color || "#fff"} hoverColor={hoverColor || "#fff"}>
          <tr className="border-bottom-0">
            <th colSpan={keys.length + 1}>
              <div className="d-flex justify-content-end">
                <Search
                  color={color}
                  placeholder={t("fields.search.placeholder")}
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
            </th>
          </tr>
          <tr>
            {keys.map((e, index) => (
              <th
                onClick={() => handleChangeSort(e.key)}
                key={index}
                style={{
                  textAlign: "center",
                }}
              >
                {e.title} <span>{getSortIcon(sort ?? {}, e.key)}</span>
              </th>
            ))}
            <th style={{ textAlign: "center" }}>{t("labels.actions")}</th>
          </tr>
        </Table.Header>
        <Table.Body>
          {isLoading ? (
            <tr>
              <td colSpan={keys.length + 1} className="text-center">
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
              </td>
            </tr>
          ) : !(isEmpty(data) || isEmpty(items)) ? (
            items.map((e) => (
              <tr>
                {keys.map((i, index) => (
                  <td key={index} style={{ textAlign: "center" }}>
                    {e[i.key] === 0 && i.key === "protocol"
                      ? "Preço Fixo"
                      : e[i.key] === 1 && i.key === "protocol"
                      ? "Margem Fixa"
                      : e[i.key] ?? ""}
                  </td>
                ))}
                <td style={{ textAlign: "center" }}>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => {
                      setPMfkSupplier(e?.fk_supplier);

                      navigate(getLink(id, e));
                    }}
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={keys.length + 1} className="text-center">
                {t("labels.notFound")}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={keys.length + 1}>
              <div className="d-flex justify-content-center py-4">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<FaAngleRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={3}
                  pageCount={pageCount}
                  previousLabel={<FaAngleLeft />}
                  nextClassName="dahsboard-page-pagination-arrow"
                  previousClassName="dahsboard-page-pagination-arrow"
                  className="dashboard-pagination"
                  pageClassName="dashboard-pagination-page"
                />
              </div>
            </td>
          </tr>
        </Table.Body>
      </Table.Table>
    </Table.Container>
  );
}

export default MainTable;
