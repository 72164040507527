import lodash from "lodash";
import { useRef } from "react";

//? components
import Pin2GiveList from "./Pin2GiveList.Container";
import { P2GLItem } from "./Pin2GiveList.styles";
import { handleHoverable } from "./_actions";
import { Pin2GiveListItemImg } from "./Pin2GiveList.Item.Img";
import { Pin2GiveListItemText } from "./Pin2GiveList.Item.Text";
import { Form } from "react-bootstrap";

//*@param type deve deve ser "text" ou "img"
const Pin2GiveListItem = ({
  id,
  type,
  onClick,
  hoverableImage,
  selected,
  checkDisabled,
  src,
  title,
  check,
  hoverableOnCheck,
  onRemove,
  onCheck,
  checked,
  tooltip,
  input,
  search,
  handleSearch,
  handleEnabled,
  enabled,
  secondaryComponent,
}) => {
  //checkBox
  const checkBoxRef = useRef(false);

  const handleCLick = (e) => {
    if (onClick && e.target.id !== `pin2givelist-item${id}` && !selected) {
      onClick();
    }
  };

  return (
    <P2GLItem.Container
      hoverable={handleHoverable(onClick, hoverableOnCheck, checkBoxRef)}
      selected={selected}
      search={search}
      handleSearch={handleSearch}
      onClick={handleCLick}
    >
      <P2GLItem.Content>
        {type === "img" ? (
          <Pin2GiveListItemImg
            src={src}
            onCheck={onCheck}
            hoverableImage={hoverableImage}
            onRemove={onRemove}
            id={id}
            title={title}
            checkDisabled={checkDisabled}
            check={check}
            checked={checked}
            checkBoxRef={checkBoxRef}
            handleEnabled={handleEnabled}
            enabled={enabled}
            secondaryComponent={secondaryComponent}
          />
        ) : lodash.isEmpty(title) ? (
          "New"
        ) : (
          <Pin2GiveListItemText
            checkBoxRef={checkBoxRef}
            check={check}
            checkDisabled={checkDisabled}
            onRemove={onRemove}
            onCheck={onCheck}
            checked={checked}
            id={id}
            title={title}
            input={input}
            tooltip={tooltip}
            selected={selected}
          />
        )}
      </P2GLItem.Content>
    </P2GLItem.Container>
  );
};

export { Pin2GiveList, Pin2GiveListItem };
