import React from "react";
import { P } from "../Element.styles";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function DescriptionInvoice() {
  const { t } = useTranslation(["informationPages/descriptionInvoice"]);
  return <P dangerouslySetInnerHTML={{ __html: t("body") }} />;
}

export default DescriptionInvoice;
