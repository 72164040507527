import { useContext } from "react";
import { productContactsContext } from "./PCProvider";

/**
 *
 * @returns {React.Context<object>}
 */
export const useProductCategories = () => {
  const context = useContext(productContactsContext);
  return context;
};
