/* eslint-disable react-hooks/exhaustive-deps */
//Components and Styles
import "./Store.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { CardStore } from "../../components/Cards/Cards";
import SearchInput from "../../components/SearchInput/SearchInput";
import Tabs from "../../components/Tabs/Tabs";
import { useServices } from "../../services/useServices";

//react-router
import { useNavigate } from "react-router-dom";

//i18n
import { useTranslation } from "react-i18next";

//react
import { useState, useEffect } from "react";

//others
import lodash from "lodash";
import ButtonScrollLink from "../../components/ButtonScroll/ButtonScroll";

const Store = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { productsWebEndpoints, getLanguage, pin2giveWebEndpoints } =
    useServices();

  /**
   * ------------INIT OBJECTS-------------
   */
  const initData = { data: null, loading: true };

  /**
   *
   * ------------Hooks for search input----------------
   * @param set search
   * @param setState setSearch
   */
  const [search, setSearch] = useState(initData);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (!lodash.isEmpty(title)) {
      productsWebEndpoints
        .wfindProducts(
          title,
          getLanguage(),
          localStorage.getItem("country_code")
        )
        .then((response) =>
          setSearch({
            data: response?.data,
            loading: false,
            status: response?.status,
          })
        )
        .catch(() => setSearch({ ...initData, loading: false }));
    }
  }, [title, i18n.language]);

  /**
   *
   * ----------HOOKS FOR DATA---------
   * @var useState categories, topSales, news
   * @func useState setCategories, setTopSales, setNews
   */
  const [categories, setCategories] = useState(initData);
  const [topSales, setTopSales] = useState(initData);
  const [news, setNews] = useState(initData);

  useEffect(() => {
    /**
     * Title Browser Tab
     */
    document.title = t("MainTitles.Store");

    /**
     * -------------------
     * get categories data
     * --------------------
     */
    productsWebEndpoints
      .wgetCategorys(getLanguage())
      .then((response) => {
        setCategories({ data: response?.data, loadind: false });
      })
      .catch(() => setCategories({ ...initData, loading: false }));

    /**
     *----------------------
     * get TopSales and News
     * ---------------------
     */
    pin2giveWebEndpoints
      .wdatahome(getLanguage(), localStorage.getItem("country_code"))
      .then((response) => {
        setTopSales({
          data: response?.data?.sugestoes_top_vendas,
          loading: false,
        });
        setNews({ data: response?.data?.sugestoes_novidades, loading: false });
      })
      .catch(() => {
        setTopSales({ ...initData, loading: false });
        setNews({ ...initData, loading: false });
      });
  }, [i18n.language]);

  /**
   *
   *
   *
   * --------------GET TABS----------------
   *
   */
  const getTabs = () => {
    let tabs = [];
    if (!lodash.isEmpty(news?.data)) {
      tabs = [
        ...tabs,
        {
          href: "novidades",
          title: t("Store.Titles.news"),
          offset: -100,
        },
      ];
    }
    if (!lodash.isEmpty(topSales?.data)) {
      tabs = [
        ...tabs,
        {
          href: "topvendas",
          title: t("Store.Titles.topSales"),
          offset: -100,
        },
      ];
    }

    tabs = [
      ...tabs,
      {
        href: "categorias",
        title: t("Store.Titles.categories"),
        offset: -100,
      },
    ];

    return tabs;
  };

  /**
   *
   *
   *
   *
   * ---------------------------------------
   * ----------------COMPONENT--------------
   * ---------------------------------------
   */
  return (
    <div className="app-store">
      {/**
       *---------------- TESTS-----------------
       */}
      <div className="d-none" data-testid="app-store">
        <button
          data-testid="link-backbutton"
          onClick={() => navigate("/store/Perfumaria/18/-1/-1")}
        >
          backbuton
        </button>
        store
      </div>
      {/**
       *---------------- STICKY TOP NAVBAR-----------------
       */}
      <Container fluid className="bg-white py-4 sticky-top">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs="12"
              sm="10"
              md="8"
              lg="8"
              className="mb-3 mb-lg-0 d-flex justify-content-center justify-content-lg-start"
            >
              {!lodash.isEmpty(title) ? <></> : <Tabs tabslist={getTabs()} />}
            </Col>
            <Col xs="12" xl="4" sm="10" md="8" lg="4" className="mb-3 mb-lg-0">
              <SearchInput
                handleChange={(event) => {
                  setTitle(event?.target?.value);
                  window.scrollTo(0, 0);
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        {!lodash.isEmpty(title) ? (
          <>
            {/**
             *
             *---------------------SEARCH PAGE---------------
             */}
            <Row className="">
              <Col xs="12" className="text-left mt-3 mb-3">
                <span className="app-sub-title">
                  {t("Search.ResultsFor") + " " + title}:
                </span>
              </Col>
            </Row>
            <Row id="Search" className="justify-content-center px-lg-5">
              {search?.loading ? (
                <Col xs="12" className="text-center">
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                </Col>
              ) : lodash.isEmpty(search?.data) ? (
                <Col xs="12">
                  {lodash.isEqual(search?.status, 204)
                    ? t("Store.EmptyLanguage.data")
                    : t("Store.Empty.Search")}
                </Col>
              ) : (
                search?.data.map((e, index) => (
                  <Col
                    xs="9"
                    sm="6"
                    md="5"
                    lg="4"
                    xl="3"
                    key={index}
                    className="mb-5 px-4"
                  >
                    <CardStore
                      title={e?.designation}
                      image={e?.fk_default_image}
                      price={e?.price}
                      symbol={e?.symbol}
                      onClick={() =>
                        navigate("/store/products/details/" + e?.id_product)
                      }
                    />
                  </Col>
                ))
              )}
            </Row>
          </>
        ) : (
          <>
            {/**
             *
             *
             *   -----------------------NOVIDADES-----------------
             */}
            {lodash.isEmpty(news?.data) ? (
              <></>
            ) : news?.loading ? (
              <Col xs="12" className="text-center">
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
                <Spinner animation="grow" variant="pin2give" />
              </Col>
            ) : (
              <Row className="mb-5" id="novidades">
                <Col xs="12" className="mt-3 mb-3">
                  <span className="app-sub-title">
                    {t("Store.Titles.news")}
                  </span>
                </Col>
                <Col xs="12">
                  <div className="store-horizontal-scroll">
                    {news?.data.map((e, index) => (
                      <Col
                        xs="9"
                        sm="6"
                        md="5"
                        lg="4"
                        xl="3"
                        key={(e, index)}
                        className="px-4 horizontal-card"
                      >
                        <CardStore
                          title={e?.designation}
                          price={e?.price}
                          key={index}
                          symbol={e?.symbol}
                          onClick={() =>
                            navigate("products/details/" + e?.id_product)
                          }
                          image={e?.fk_default_image}
                        />
                      </Col>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
            {/**
             *
             *
             *---------------------TOP SALES--------------------------
             *
             */}
            {lodash.isNull(topSales?.data) ? (
              <></>
            ) : (
              <Row className="mb-5 " id="topvendas">
                <Col xs="12" className="text-left mt-3 mb-3">
                  <span className="app-sub-title">
                    {t("Store.Titles.topSales")}
                  </span>
                </Col>
                {topSales?.loading ? (
                  <Col xs="12" className="text-center">
                    <Spinner animation="grow" variant="pin2give" />
                    <Spinner animation="grow" variant="pin2give" />
                    <Spinner animation="grow" variant="pin2give" />
                    <Spinner animation="grow" variant="pin2give" />
                  </Col>
                ) : (
                  <Col xs="12">
                    <div className="store-horizontal-scroll">
                      {topSales?.data?.map((e, index) => (
                        <Col
                          xs="10"
                          sm="6"
                          md="5"
                          lg="4"
                          xl="3"
                          key={index}
                          price={e?.price}
                          className="px-4 horizontal-card"
                        >
                          <CardStore
                            symbol={e?.symbol}
                            title={e?.designation}
                            price={e?.price}
                            key={index}
                            image={e?.fk_default_image}
                            onClick={() =>
                              navigate("products/details/" + e?.id_product)
                            }
                          />
                        </Col>
                      ))}
                    </div>
                  </Col>
                )}
              </Row>
            )}
            {/**
             *
             *
             *------------------------CATEGORIES------------------------
             */}
            <Row
              id="categorias"
              className="justify-content-center justify-content-lg-start  "
            >
              <Col xs="12" className="text-left mt-3 mb-3">
                <span className="app-sub-title">
                  {t("Store.Titles.categories")}
                </span>
              </Col>
              {categories?.loading ? (
                <Col xs="12" className="text-center">
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                  <Spinner animation="grow" variant="pin2give" />
                </Col>
              ) : lodash.isNull(categories?.data) ? (
                <Col xs="12">{t("Store.Empty.Categories")}</Col>
              ) : (
                categories?.data.map((e) => (
                  <Col
                    xs="10"
                    sm="6"
                    md="5"
                    lg="4"
                    xl="3"
                    key={e?.id}
                    className="mb-5 px-4"
                  >
                    <CardStore
                      title={e?.name}
                      image={e?.path}
                      onClick={() =>
                        navigate(
                          "/store/" +
                            encodeURIComponent(e?.name) +
                            "/" +
                            e?.id +
                            "/-1/-1"
                        )
                      }
                    />
                  </Col>
                ))
              )}
            </Row>
          </>
        )}
      </Container>
      <ButtonScrollLink />
    </div>
  );
};
export default Store;
