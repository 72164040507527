import styled from "styled-components";

export const ScrollView = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  padding: 1rem 0.5rem 2rem 0.5rem;
`;

export const Label = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
`;

export const ItemList = styled.div`
  width: 3rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  &::last-child {
    margin-right: 0;
  }
  &::after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: #a20013;
    margin-top: 0.3rem;
    transition: width 200ms ease-in;
  }
  &:hover:after {
    width: 100%;
    transition: width 200ms ease-in;
  }
  ${(props) =>
    props.selected &&
    `
    &:after{
      width:100%
    }
  `}
`;

export const Color = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  border: 1px solid #ccc;
  background-color: ${(props) => props.colorRef};
`;

export const Remove = styled.button`
  position: absolute;
  background-color: white;
  border: 2px solid #333;
  border-radius: 100px;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  top: -0.3rem;
  right: -0.3rem;
  transition: 200ms ease-out;
  &:hover {
    background-color: #a20013;
    color: white;
    transition: 200ms ease-in;
  }
`;
