import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./BannerPolicy.css";
import lodash from "lodash";

const BannerPolicy = () => {
  const [visible, setVisible] = useState(true);
  const handleClose = () => setVisible(false);
  const handleShow = () => setVisible(true);
  const { t } = useTranslation(["privacyPolicy"]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const status = localStorage.getItem("privacy_status");
    if (location.pathname === "/policy") {
      handleClose();
    } else if (lodash.isEmpty(status)) {
      handleShow();
    } else if (
      lodash.isEqual(status, "accept_not") &&
      (location.pathname === "/store" || location.pathname === "/partners")
    ) {
      handleShow();
    } else {
      handleClose();
    }
  }, [location.pathname]);
  return (
    <>
      <Modal
        autoFocus
        backdrop="static"
        show={visible}
        centered
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t("title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="content-privacy-policy-banner"
            dangerouslySetInnerHTML={{
              __html: t("banner"),
            }}
          />
          <p className="text-center">
            <Button variant="link" onClick={() => navigate("/policy")}>
              {t("buttons.viewMore")}
            </Button>
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <button
            className="policy-banner-button"
            onClick={() => {
              localStorage.setItem("privacy_status", "accept_not");
              window.location.href = "/";
            }}
          >
            {t("buttons.da")}
          </button>
          <button
            className="policy-banner-button"
            onClick={() => {
              localStorage.setItem("privacy_status", "accepted");
              window.location.href = "/";
            }}
          >
            {t("buttons.a")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerPolicy;
