import React from "react";
import useProductsContext from "../../../../contexts/ProductsManagement/Products/useProductsContext";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";
import { useTranslation } from "react-i18next";
import lodash from "lodash";

// import { Container } from './styles';

function ProductsList() {
  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* products
  const { products, forms, overlayConfirm } = useProductsContext();

  //* translation
  const { t } = useTranslation(["pm/products", "pm/overlayConfirm"]);

  //!----------------------------------------------------!\\
  //!-----------------------methods----------------------!\\
  //!----------------------------------------------------!\\
  //* handle click (new product)
  const handleClick = () => {
    products.setSelected(products.newProduct);
    forms.setEditable(true);
  };

  //* handle select (click between products)
  const handleSelect = (newSelect) => {
    if (forms.isUpdated()) {
      products.setSelected(newSelect);
      forms.setEditable(false);
    } else {
      overlayConfirm.setShowConfirm({
        show: true,
        newSelect: () => {
          products.setSelected(newSelect);
          overlayConfirm.handleCloseConfirm();
          forms.setEditable(false);
        },
        v2: false,
        title: t("products.title", { ns: "pm/overlayConfirm" }),
        content: t("products.content", { ns: "pm/overlayConfirm" }),
      });
    }
  };

  return (
    <div className="sticky-top">
      <Pin2GiveList
        headerProps={{
          title: t("list.title"),
          headerButton: {
            onClick: handleClick,
            title: t("list.buttonTooltip"),
          },
        }}
      >
        {[...(products?.data || [])].map((e, index) => (
          <Pin2GiveListItem
            key={index}
            type="text"
            title={
              <span
                className={
                  products?.selected?.idProduct !== e?.idProduct
                    ? e?.state === 5
                      ? "text-success"
                      : e?.state === 2
                      ? "text-danger"
                      : e?.state === 1
                      ? "text-warning"
                      : undefined
                    : undefined
                }
              >
                {e?.stitle}
              </span>
            }
            onClick={() => handleSelect(e)}
            selected={e?.idProduct === products.selected?.idProduct}
          />
        ))}
        {lodash.isEqual(products.selected, products.newProduct) && (
          <Pin2GiveListItem selected type="text" title={t("newProduct")} />
        )}
      </Pin2GiveList>
    </div>
  );
}

export default ProductsList;
