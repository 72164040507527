import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useProductsContext from "../../../contexts/ProductsManagement/Products/useProductsContext";
import Header from "../Header";
import Loading from "../Loading";
import lodash from "lodash";
import ProductsList from "./_lists/ProductsList";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import FormTitle from "./FormTitle";
import Form from "./_forms/Form";
import Idiomatics from "./_forms/Idiomatics";
import { useNavigate } from "react-router-dom";
import OverlayConfirm from "../OverlayConfirm";
import Accordions from "./_accordions/Accordions";

function Products() {
  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/products", "pm/overlayConfirm"]);

  //* use products context
  const { steps, products, forms, overlayConfirm, initProductsLanguages } =
    useProductsContext();

  //* navigate
  const navigate = useNavigate();

  return (
    <Container>
      <Header
        left={{ backButton: true, menuButton: true }}
        center={{ title: t("title") }}
        infoKey="products"
        adicionalItem={products?.selected}
      />
      <Row className="mb-5">
        <Col xs="12">
          <Stepper activeStep={0}>
            {steps.map(({ label, path }) => (
              <Step
                onClick={() => (path ? navigate(path) : undefined)}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Col>
      </Row>
      {products?.isLoading && <Loading />}
      {!products?.isLoading && (
        <Row
          className={`h-100 mb-5 ${
            lodash.isEmpty(products?.selected) ? "align-items-center" : ""
          }`}
        >
          <Col xs="12" lg="3" className="mb-5">
            <ProductsList />
          </Col>
          <Col xs="12" lg="9" className="position-relative">
            {!products.selected ? (
              <>
                {
                  //!-------------------------------------!\\
                  //!--------------EMPTY LABEL------------!\\
                  //!-------------------------------------!\\
                }
                <div className="h-100 d-flex flex-column align-items-center empty-active-reference">
                  <FaArrowLeft size={30} />
                  <div className="empty-active-reference">
                    {t("newProduct")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Container fluid>
                  <Row className="mb-5">
                    <Col xs="12">
                      <FormTitle />
                    </Col>
                  </Row>
                  {initProductsLanguages.isLoading ? (
                    <Row>
                      <Col xs="12">
                        <div className="text-center h5 fw-bold">
                          A obter Idiomaticas do Produto!
                        </div>
                        <div className="text-center">
                          <Spinner animation="grow" variant="pin2give" />
                          <Spinner animation="grow" variant="pin2give" />
                          <Spinner animation="grow" variant="pin2give" />
                          <Spinner animation="grow" variant="pin2give" />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col xs="12">
                          <Form />
                        </Col>
                      </Row>
                      {!forms.editable && (
                        <Row>
                          <Col xs="12" className="mb-5">
                            <Accordions />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col xs="12">
                          <Idiomatics />
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </>
            )}
          </Col>
          {products?.selected && (
            <Col xs="12" className="mt-5 text-end">
              {!forms.editable ? (
                <div className="d-flex align-items-center">
                  {
                    //!---------------------------------------------!\\
                    //!-----------------Back BUTTON-----------------!\\
                    //!---------------------------------------------!\\
                  }
                  <Button
                    className="d-flex align-items-center"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                  >
                    <FaArrowLeft className="me-2" />
                    {t("buttons.back")}
                  </Button>
                  {
                    //!---------------------------------------------!\\
                    //!----------------PREVIEW BUTTON---------------!\\
                    //!---------------------------------------------!\\
                  }
                  <Button
                    variant="pin2give-link"
                    className="me-3 text-secondary ms-auto"
                    onClick={() =>
                      window.open(
                        process.env.PUBLIC_URL +
                          `/productsmanagement/product/preview/${products?.selected.idProduct}`,
                        "",
                        "width=800, height=600"
                      )
                    }
                  >
                    {t("buttons.preview")}
                  </Button>
                  {
                    //!---------------------------------------------!\\
                    //!----------------SEASONS BUTTON---------------!\\
                    //!---------------------------------------------!\\
                  }
                  <Button
                    variant="pin2give-link"
                    className="me-3"
                    onClick={() => navigate("/productsmanagement/seasons")}
                  >
                    {t("buttons.seasons")}
                  </Button>
                  {
                    //!---------------------------------------------!\\
                    //!----------------Categories BUTTON------------!\\
                    //!---------------------------------------------!\\
                  }
                  <Button
                    variant="pin2give"
                    className="me-3"
                    onClick={() =>
                      navigate(
                        "/productsmanagement/productcategories/" +
                          products?.selected.idProduct
                      )
                    }
                  >
                    {t("buttons.categories")} <FaArrowRight className="ms-2" />
                  </Button>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <Button
                    variant="secondary"
                    onClick={forms.cancel}
                    className="ms-auto me-2"
                  >
                    {t("buttons.cancel")}
                  </Button>
                  <Button variant="success" onClick={forms.handleSubmit}>
                    {forms.createProduct?.isLoading ||
                    forms.createProductLanguages?.isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <div className="d-flex align-items-center">
                        <FaSave className="me-2" /> {t("buttons.save")}
                      </div>
                    )}
                  </Button>
                </div>
              )}
            </Col>
          )}
        </Row>
      )}
      <OverlayConfirm
        title={overlayConfirm.showConfirm?.title}
        content={overlayConfirm.showConfirm?.content}
        show={overlayConfirm.showConfirm?.show}
        handleCloseConfirm={overlayConfirm.handleCloseConfirm}
        onSelect={() => {
          overlayConfirm.showConfirm.newSelect();
        }}
        v2={overlayConfirm.showConfirm.v2}
        primaryButtonLabel={overlayConfirm.showConfirm.primaryButtonLabel}
      />
    </Container>
  );
}

export default Products;
