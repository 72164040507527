import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Caracterization from "./Caracterization/Caracterization";
import Categories from "./Categories";
import Compositions from "./Compositions";

// import { Container } from './styles';

function Accordions() {
  return (
    <Container className="mt-5">
      <Row>
        <Col xs="12">
          <Accordion>
            <Categories />
            <Caracterization />
            <Compositions />
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default Accordions;
