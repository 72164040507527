/**
 * !----------------------------------------------!
 * !---------------Get Season Title---------------!
 * !----------------------------------------------!
 * @param {array} seasonsList lista de temporadas
 * @param {integer} fkSeason id de uma temporada
 * @returns {string} retorna titulo da temporada
 */
export function getSeasonTitle(seasonsList, fkSeason) {
  const data = seasonsList || [];

  const object = data.find((e) => e?.idSeason === fkSeason);

  if (object !== undefined) return object.title;

  return "";
}
