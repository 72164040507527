import React from "react";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";
import { useProductCategories } from "../../../contexts/ProductsManagement/ProductsCategories/useProductCategories";
import lodash from "lodash";
import { newSubSubCategoryObject } from "../../../contexts/ProductsManagement/ProductsCategories/utils";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function SubSubCategories() {
  //!-------------------------------------------------------------!\\
  //!---------------------------Contexts--------------------------!\\
  //!-------------------------------------------------------------!\\
  const {
    subSubCategories: dSubSubCategories,
    categories,
    subCategories,
    result,
    fkProduct,
  } = useProductCategories();
  const { t } = useTranslation("pm/productsCategories");

  //!-------------------------------------------------------------!\\
  //!---------------------------Methods---------------------------!\\
  //!-------------------------------------------------------------!\\
  /**
   * * Handle Select
   * @param {number} fkSubSubCategory
   */
  const handleSelect = (fkSubSubCategory) => {
    dSubSubCategories.setSelected(fkSubSubCategory);
  };

  /**
   * * Get Check
   * @param {number} fkCategory
   * @param {number} fkSubSubCategory
   */
  function getCheck(fkCategory, fkSubSubCategory) {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.find(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não existir retorna false
    if (categoryIsInRes === undefined) return false;

    //* se existir, verifica se a subcategoria associada corresponde
    //* à subcategoria pretendida
    //* se sim retorna true
    //* senão false
    if (
      categoryIsInRes?.productVsCategoriesPK?.fkSubsubcategory ===
      fkSubSubCategory
    )
      return true;

    return false;
  }

  /**
   *
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @param {number} fkSubSubCategory
   * @returns {boolean}
   */
  function addCheck(fkCategory, fkSubCategory, fkSubSubCategory) {
    const country_code = localStorage.getItem("country_code");

    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.findIndex(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se existir a categoria pretendida (categoryIsInRes !== -1) é necessário
    //* substituir a categoria para os valores pretendidos
    if (categoryIsInRes !== -1) {
      const newRes = res.filter(
        ({ productVsCategoriesPK: pk }) => pk.fkCategory !== fkCategory
      );
      console.log("newRes", newRes);
      result.setResult([
        ...newRes,
        newSubSubCategoryObject(
          fkCategory,
          fkSubCategory,
          fkSubSubCategory,
          country_code,
          fkProduct
        ),
      ]);
      return true;
    }

    const subSubCategoryObject = newSubSubCategoryObject(
      fkCategory,
      fkSubCategory,
      fkSubSubCategory,
      country_code,
      fkProduct
    );

    result.setResult((e) => [...e, subSubCategoryObject]);
  }

  function removeCheck(fkCategory) {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.find(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não houver categoria retorna false
    //* não é possível remover uma categoria que não existe
    if (categoryIsInRes === undefined) return false;

    //* se houver categoria
    //* a mesma será removida
    const newRes = res.filter(
      ({ productVsCategoriesPK: pk }) => pk.fkCategory !== fkCategory
    );

    result.setResult(newRes);
    return true;
  }

  function handleCheck(fkCategory, fkSubCategory, fkSubSubCategory) {
    //* saber se a subcategoria tem check
    const isCheck = getCheck(fkCategory, fkSubSubCategory);

    //* se houver check a subcategoria irá ser removida
    if (isCheck) return removeCheck(fkCategory);

    //* se não houver check a subcategoria será adicionada
    return addCheck(fkCategory, fkSubCategory, fkSubSubCategory);
  }

  return (
    <Pin2GiveList
      check
      headerProps={{
        title: t("lists.subSubCategories.label"),
        headerButton: {},
      }}
    >
      {dSubSubCategories?.data?.map((e) => (
        <Pin2GiveListItem
          key={e?.id}
          title={e?.name}
          check
          onCheck={() =>
            handleCheck(categories?.selected, subCategories.selected, e?.id)
          }
          onClick={() => handleSelect(e?.id)}
          selected={e?.id === dSubSubCategories.selected}
          checked={getCheck(categories?.selected, e?.id)}
        />
      ))}
      {lodash.isEmpty(dSubSubCategories?.data) && (
        <div className="text-center">{t("lists.subSubCategories.na")}</div>
      )}
    </Pin2GiveList>
  );
}

export default SubSubCategories;
