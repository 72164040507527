import React from "react";
import { Container, HorizontalScroll, Item, Label } from "./ImagePicker.styles";
import lodash from "lodash";
// import { Container } from './styles';

// list array object must be
// [{id:1, value:"link to image", selected:true|false, handleSelect }, etc...]

function ImagePicker({
  label = "Empty Label",
  list = null,
  editable = false,
  emptyListLabel = null,
  isUndefinedLabel = null,
  errorLabel = null,
}) {
  const existSelected = () => {
    const x = list?.find((e) => e.selected === true);

    if (x === undefined) return false;

    return true;
  };

  const isEmptyList = lodash.isEmpty(list);

  return (
    <Container>
      <Label>{label}</Label>
      {emptyListLabel && editable && isEmptyList && !errorLabel && (
        <div>{emptyListLabel}</div>
      )}
      {isUndefinedLabel && !editable && !existSelected() && (
        <div>{isUndefinedLabel}</div>
      )}
      <HorizontalScroll>
        {list &&
          list?.map((e, index) =>
            !editable && e?.selected ? (
              <div className="me-2" key={index}>
                <Item.Container
                  selected={e?.selected}
                  onClick={editable ? e?.handleSelect : undefined}
                >
                  <Item.Image src={e?.value} />
                </Item.Container>
              </div>
            ) : editable ? (
              <div className="me-3 position-relative my-2" key={index}>
                {e?.selected && e?.secondaryButton && (
                  <Item.SecondaryButton
                    type="button"
                    onClick={e?.handleEnabled}
                  >
                    {e?.secondaryButtonLabel}
                  </Item.SecondaryButton>
                )}
                <Item.Container
                  selected={e?.selected}
                  onClick={editable ? e?.handleSelect : undefined}
                >
                  <Item.Image src={e?.value} />
                </Item.Container>
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
      </HorizontalScroll>
      {errorLabel && editable && (
        <div className="text-danger text-mutted">{errorLabel}</div>
      )}
    </Container>
  );
}

export default ImagePicker;
