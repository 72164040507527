import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

const PrivateRoute = ({ children }) => {
  useEffect(() => {
    document.title = "Pin2Give";
  }, []);
  const { auth } = useAuth();
  return auth.loading ? (
    <></>
  ) : auth.token ? (
    children
  ) : (
    <Navigate to="/user/login" />
  );
};
export default PrivateRoute;
