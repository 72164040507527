import "./Sidebar.css";
import {
  FaHome,
  FaShieldAlt,
  FaStore,
  FaUserTie,
  FaQuestion,
  FaBoxes,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../contexts/AuthProvider/useAuth";

const Sidebar = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  return (
    <nav className="sidebar-nav d-none d-md-block ">
      <ul>
        <li>
          <NavLink activeclassname="active" to="/" data-testid="link-home">
            <span className="icon">
              <FaHome />
            </span>
            <span className="link">{t("MainTitles.Home")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/store">
            <span className="icon">
              <FaStore />
            </span>
            <span className="link">{t("MainTitles.Store")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/partners">
            <span className="icon">
              <FaUserTie />
            </span>
            <span className="link">{t("MainTitles.BusinessPartners")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="active"
            to="/policy"
            data-testid="link-policy"
          >
            <span className="icon">
              <FaShieldAlt />
            </span>
            <span className="link">{t("MainTitles.PrivacyPolicy")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/support">
            <span className="icon">
              <FaQuestion />
            </span>
            <span className="link">{t("MainTitles.Support")}</span>
          </NavLink>
        </li>
        {auth.token ? (
          <>
            <li>
              <NavLink activeclassname="active" to="/productsmanagement">
                <span className="icon">
                  <FaBoxes />
                </span>
                <span className="link">
                  {t("MainTitles.ProductManagement")}
                </span>
              </NavLink>
            </li>
          </>
        ) : (
          <></>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
