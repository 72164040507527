import * as Yup from "yup";
export const validationSchema = (t) => {
  return Yup.object({
    stitle: Yup.string().required(t("fields.stitle.errors.required")),
    fkProducer: Yup.number()
      .integer(t("fields.fkProducer.errors.integer"))
      .required(t("fields.fkProducer.errors.required"))
      .min(0, t("fields.fkProducer.errors.required"))
      .typeError(t("fields.fkProducer.errors.typeError")),
    fkProtocol: Yup.number()
      .integer(t("fields.fkProtocol.errors.integer"))
      .required(t("fields.fkProtocol.errors.required"))
      .typeError(t("fields.fkProtocol.errors.typeError")),
    dimage: Yup.number()
      .integer(t("fields.dimage.errors.integer"))
      .when("idProduct", {
        is: (idProduct) => idProduct !== -1,
        then: Yup.number()
          .integer("fields.dimage.errors.integer")
          .required(t("fields.dimage.errors.required"))
          .moreThan(0, t("fields.dimage.errors.moreThan"))
          .typeError(t("fields.dimage.errors.typeError")),
      })

      .typeError(t("fields.dimage.errors.typeError")),
    observations: Yup.string()
      .notRequired()
      .max(500, t("fields.observations.errors.max")),
    specialcares: Yup.string()
      .notRequired()
      .max(500, t("fields.specialcares.errors.max")),
    specificregulation: Yup.string()
      .notRequired()
      .max(500, t("fields.specificregulation.errors.max")),
  });
};

export const validationSchemaLanguages = (t) => {
  return Yup.object().shape({
    EN: Yup.object().shape({
      title: Yup.string()
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
    PT: Yup.object().shape({
      title: Yup.string()
        .required(t("fields.iTitle.errors.required"))
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .required(t("fields.ishortdescription.errors.required"))
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .required(t("fields.idescription.errors.required"))
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
    ES: Yup.object().shape({
      title: Yup.string()
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
    DE: Yup.object().shape({
      title: Yup.string()
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
    FR: Yup.object().shape({
      title: Yup.string()
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
    IT: Yup.object().shape({
      title: Yup.string()
        .max(50, t("fields.iTitle.errors.max"))
        .typeError(t("fields.iTitle.errors.typeError")),
      shortdescription: Yup.string()
        .max(80, t("fields.ishortdescription.errors.max"))
        .typeError(t("fields.ishortdescription.errors.typeError")),
      description: Yup.string()
        .max(1200, t("fields.idescription.errors.max"))
        .typeError(t("fields.idescription.errors.typeError")),
      tooltip: Yup.string()
        .max(50, t("fields.iTooltip.errors.max"))
        .typeError(t("fields.iTooltip.errors.typeError")),
      specification: Yup.string()
        .max(1200, t("fields.ispecification.errors.max"))
        .typeError(t("fields.ispecification.errors.typeError")),
    }),
  });
};
