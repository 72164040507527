//? REACT-BOOTSTRAP
import { Alert, Button, Col, Row } from "react-bootstrap";

//? MUI-MATERIAL
import { Tooltip, IconButton } from "@mui/material";

//? REACT-ICONS
import { FaHome, FaEye, FaEyeSlash } from "react-icons/fa";

//? STYLES
import "./Login.css";

//? COMPONENTS
import Pin2GiveForm from "../../components/Pin2GiveForm/Pin2GiveForm";
import DropdownLang from "../../components/DropdownLang/DropdownLang";

//? REACT-ROUTER-DOM
import { useNavigate } from "react-router-dom";

//? YUP
import { isNumberPhone } from "../../_yup/methods";
import * as Yup from "yup";
import { initValues, validationSchema } from "../../_yup/Register";

//? FORMIK
import { useFormik } from "formik";
//? REACT
import React, { useState } from "react";

//? USETRANSLATION
import { useTranslation } from "react-i18next";

//? USEALERT
import { useAlert } from "react-alert";

//? ACTIONS
import { formatRegisterValues } from "./_actions";

//? ENCRYPT
import JSEncrypt from "jsencrypt";
import VerifyEmail from "./VerifyEmail";
import {
  Brand,
  Container,
  ExternalLink,
  Form,
  Header,
  SocialContent,
} from "./login.styles";

import Logo from "../../assets/images/logo.png";
import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { useServices } from "../../services/useServices";
import { useMutation, useQuery } from "react-query";

const Register = () => {
  //!--------------------------------------------------------!\\
  //!--------------------------------------------------------!\\
  //!--------------------REACT ROUTER DOM--------------------!\\
  //!--------------------------------------------------------!\\
  //!--------------------------------------------------------!\\
  const navigate = useNavigate();

  //!--------------------------------------------------!\\
  //!--------------------------------------------------!\\
  //!--------------------useContext--------------------!\\
  //!--------------------------------------------------!\\
  //!--------------------------------------------------!\\
  const { publicKey } = useAuth();
  const { authenticationEndpoints, getLanguage } = useServices();

  //!---------------------------------------------------!\\
  //!---------------------------------------------------!\\
  //!--------------------REACT ALERT--------------------!\\
  //!---------------------------------------------------!\\
  //!---------------------------------------------------!\\
  const alert = useAlert();

  //!-------------------------------------------!\\
  //!-------------------------------------------!\\
  //!--------------------YUP--------------------!\\
  //!-------------------------------------------!\\
  //!-------------------------------------------!\\
  Yup.addMethod(Yup.string, "numberPhone", isNumberPhone);

  //!---------------------------------------------------!\\
  //!---------------------------------------------------!\\
  //!--------------------verifyAlert--------------------!\\
  //!---------------------------------------------------!\\
  //!---------------------------------------------------!\\
  const [verifyAlert, setVerifyAlert] = useState({ show: false, email: "" });

  //!-----------------------------------------------------!\\
  //!-----------------------------------------------------!\\
  //!--------------------REACT I18NEXT--------------------!\\
  //!-----------------------------------------------------!\\
  //!-----------------------------------------------------!\\
  const { i18n, t } = useTranslation(["register"]);

  //!-----------------------------------------------------!\\
  //!-----------------------------------------------------!\\
  //!--------------------TYPE PASSWORD--------------------!\\
  //!-----------------------------------------------------!\\
  //!-----------------------------------------------------!\\
  const [typePassword, setTypePassword] = useState("password");

  //* handlePassword
  const handlePassword = () => {
    if (typePassword === "password") {
      setTypePassword("text");
    } else {
      setTypePassword("password");
    }
  };

  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  //!--------------------TITLES--------------------!\\
  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  const { data: titles } = useQuery(["getTitles", i18n.language], () =>
    authenticationEndpoints.getTitles()
  );

  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  //!--------------------FORMIK--------------------!\\
  //!----------------------------------------------!\\
  //!----------------------------------------------!\\
  const { mutate: handleSubmit } = useMutation(
    (values) => {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);

      return authenticationEndpoints.register(
        formatRegisterValues(
          values,
          getLanguage(),
          encrypt.encrypt(values.password)
        )
      );
    },
    {
      onSuccess: (response) => {
        const requestObject = JSON.parse(response.config.data);

        //* se a response for sucesso
        if (Number(response.data) === 1) {
          setVerifyAlert({ show: true, email: requestObject.email });
          alert.success(
            <>
              <Alert.Heading>{t("alerts.success.submit.title")}</Alert.Heading>
              <p>{t("alerts.success.submit.message")}</p>
            </>
          );
        } else {
          setVerifyAlert({ show: false, email: requestObject.email });
          alert.error(
            <>
              <Alert.Heading>{t("alerts.error.submit.title")}</Alert.Heading>
              <p>{t("alerts.error.submit.message")}</p>
            </>
          );
        }
      },
      onError: () => {
        alert.error(
          <>
            <Alert.Heading>{t("alerts.error.submit2.title")}</Alert.Heading>
            <p>{t("alerts.error.submit2.message")}</p>
          </>
        );
        setVerifyAlert({ show: false, email: "" });
      },
    }
  );

  const formik = useFormik({
    initialValues: initValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <Container register>
      <Header>
        <Tooltip title="Home" placement="right">
          <IconButton onClick={() => navigate("/")}>
            <FaHome />
          </IconButton>
        </Tooltip>
        <div>
          <DropdownLang />
        </div>
      </Header>
      <Brand.Container>
        <Brand.Logo src={Logo} />
        <Brand.Title>{t("title")}</Brand.Title>
      </Brand.Container>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mt-3">
          {
            //!--------------------------------------------!\\
            //!--------------------------------------------!\\
            //!--------------------NAME--------------------!\\
            //!--------------------------------------------!\\
            //!--------------------------------------------!\\
          }
          <Col xs="8">
            <Pin2GiveForm.Input
              label={t("fields.name.label")}
              inputProps={{
                name: "name",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.name ?? "",
                autoComplete: "off",
              }}
              error={formik.errors.name}
            />
          </Col>
          {
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
            //!--------------------TITLE--------------------!\\
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.Select
              label={t("fields.title.label")}
              inputProps={{
                name: "fkPtitle",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.fkPtitle,
              }}
              error={formik.errors.fkPtitle}
            >
              <option value="0">Choose...</option>
              {titles?.data.map((e, index) => (
                <option key={index} value={e?.idTitle}>
                  {e[`${getLanguage().toUpperCase()}title`]}
                </option>
              ))}
            </Pin2GiveForm.Select>
          </Col>
          {
            //!-------------------------------------------!\\
            //!-------------------------------------------!\\
            //!--------------------NIF--------------------!\\
            //!-------------------------------------------!\\
            //!-------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.Input
              label={t("fields.nif.label")}
              inputProps={{
                name: "nif",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.nif ?? "",
                autoComplete: "off",
              }}
              error={formik.errors.nif}
            />
          </Col>
          {
            //!-------------------------------------------!\\
            //!-------------------------------------------!\\
            //!--------------------NIB--------------------!\\
            //!-------------------------------------------!\\
            //!-------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.Input
              label={t("fields.nib.label")}
              inputProps={{
                name: "nib",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.nib ?? "",
                autoComplete: "off",
              }}
              error={formik.errors.nib}
            />
          </Col>
          {
            //!------------------------------------------!\\
            //!------------------------------------------!\\
            //!--------------------CC--------------------!\\
            //!------------------------------------------!\\
            //!------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.Input
              label={t("fields.cc.label")}
              inputProps={{
                name: "cc",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.cc ?? "",
                autoComplete: "off",
              }}
              error={formik.errors.cc}
            />
          </Col>
          {
            //!----------------------------------------------!\\
            //!----------------------------------------------!\\
            //!--------------------MOBILE--------------------!\\
            //!----------------------------------------------!\\
            //!----------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.PhoneInput
              label={t("fields.mobile.label")}
              inputProps={{
                name: "mobile",
                value: formik.values.mobile ?? "",
                onChange: (e) => formik.setFieldValue("mobile", e),
                onBlur: formik.handleBlur,
                autoComplete: "off",
                placeholder: "+351 000 000 000",
              }}
              error={formik.errors.mobile}
            />
          </Col>
          {
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
            //!--------------------PHONE--------------------!\\
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
          }
          <Col xs="4">
            <Pin2GiveForm.PhoneInput
              label={t("fields.phone.label")}
              inputProps={{
                name: "phone",
                value: formik.values.phone ?? "",
                onChange: (e) => formik.setFieldValue("phone", e),
                onBlur: formik.handleBlur,
                autoComplete: "off",
                placeholder: "+351 000 000 000",
              }}
              error={formik.errors.phone}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          {
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
            //!--------------------EMAIL--------------------!\\
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
          }
          <Col xs="6">
            <Pin2GiveForm.Input
              label={t("fields.email.label")}
              inputProps={{
                name: "email",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.email,
                autoComplete: "off",
              }}
              error={formik.errors.email}
            />
          </Col>
          {
            //!------------------------------------------------!\\
            //!------------------------------------------------!\\
            //!--------------------PASSWORD--------------------!\\
            //!------------------------------------------------!\\
            //!------------------------------------------------!\\
          }
          <Col xs="6">
            <Pin2GiveForm.Input
              label={t("fields.password.label")}
              inputProps={{
                name: "password",
                type: typePassword,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.password,
                autoComplete: "off",

                help: (
                  <>
                    <Button
                      variant="mini-password-button"
                      size="sm"
                      className="bg-transparent"
                      onClick={handlePassword}
                    >
                      {typePassword === "password" ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </>
                ),
              }}
              error={formik.errors.password}
            />
          </Col>
          {
            //!----------------------------------------------------!\\
            //!----------------------------------------------------!\\
            //!--------------------CONFPASSWORD--------------------!\\
            //!----------------------------------------------------!\\
            //!----------------------------------------------------!\\
          }
          <Col xs="6">
            <Pin2GiveForm.Input
              label={t("fields.confPassword.label")}
              inputProps={{
                name: "confPassword",
                type: "password",
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.confPassword,
                autoComplete: "off",
              }}
              error={formik.errors.confPassword}
            />
          </Col>
        </Row>
        <div className="d-flex align-items-center mt-5">
          {
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
            //!--------------------LOGIN--------------------!\\
            //!---------------------------------------------!\\
            //!---------------------------------------------!\\
          }
          <Button
            className="w-100"
            variant="outline-secondary"
            type="button"
            onClick={() => navigate("/user/login")}
          >
            {t("buttons.login")}
          </Button>
          {
            //!------------------------------------------------!\\
            //!------------------------------------------------!\\
            //!--------------------REGISTER--------------------!\\
            //!------------------------------------------------!\\
            //!------------------------------------------------!\\
          }
          <Button type="submit" className="ms-3 w-100" variant="pin2give">
            {t("buttons.register")}
          </Button>
        </div>
      </Form>
      <SocialContent>
        <ExternalLink
          href="https://www.facebook.com/pin2give"
          aria-label="facebook"
        >
          <FacebookRounded />
        </ExternalLink>
        <ExternalLink
          href="https://www.instagram.com/pin2give/"
          aria-label="instagram"
        >
          <Instagram />
        </ExternalLink>
        <ExternalLink
          href="https://www.linkedin.com/company/pin2give/"
          aria-label="linkedin"
        >
          <LinkedIn />
        </ExternalLink>
        <ExternalLink
          href="https://www.pinterest.pt/pin2give_/_created/"
          aria-label="pinterest"
        >
          <Pinterest />
        </ExternalLink>
        <ExternalLink href="https://twitter.com/Pin2Give" aria-label="twitter">
          <Twitter />
        </ExternalLink>
      </SocialContent>

      <VerifyEmail
        email={verifyAlert.email}
        show={verifyAlert.show}
        clickPrimary={() => navigate("/user/login")}
        clickSecondary={() => {
          setVerifyAlert({ show: false, email: "" });
          formik.handleReset();
        }}
      />
    </Container>
  );
};

export default Register;
