import "./BackButton.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="back-button"
      data-testid="backbutton"
      onClick={() => navigate(-1)}
    >
      <FaArrowLeft />
    </button>
  );
};

export default BackButton;
