import styled from "styled-components";

export const ItemContainer = styled.div`
  margin-bottom: 0.6rem;
  padding: 1rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  &:hover {
    background-color: #c9636f !important;
    color: white;
  }
  &:focus {
    background-color: #a20013;
  }
`;

//item-container mb-1 p-2 rounded d-flex align-items-center
