import React from "react";
import { useTranslation } from "react-i18next";
import { P } from "../Element.styles";

// import { Container } from './styles';

function PriceSale() {
  const { t } = useTranslation(["informationPages/priceSale"]);
  return (
    <>
      <P dangerouslySetInnerHTML={{ __html: t("body") }} />
    </>
  );
}

export default PriceSale;
