import React from "react";
import { useTranslation } from "react-i18next";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";

// import { Container } from './styles';

function ImagesList({ images, overlayConfirm, handleSubmit }) {
  const { t } = useTranslation(["pm/uploadimages"]);
  return (
    <Pin2GiveList
      sticky
      headerProps={{
        title: t("list.header"),
        headerButton: {},
      }}
    >
      {images?.map((e, index) => (
        <Pin2GiveListItem
          hoverableImage
          key={index}
          type="img"
          src={e?.imageBase64}
          onRemove={() =>
            overlayConfirm.set((i) => ({
              ...i,
              show: true,
              title: "Remover Imagem",
              content:
                "<p>Tem a certeza que pretende remover a imagem?</p><p>A imagem será removida de todos os produtos, composições e referências não aprovados.</p>",
              primaryButtonLabel: "Remover",
              event: () => {
                handleSubmit({ ...e, isRemove: 1 });
                overlayConfirm.handleClose();
              },
            }))
          }
          enabled={true}
        />
      ))}
    </Pin2GiveList>
  );
}

export default ImagesList;
