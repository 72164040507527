import styled from "styled-components";

export const Screen = styled.div`
  width: 275px;
  max-height: 552px;
  min-height: 525px;
  overflow-y: auto;
  border: 0px solid black;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 0.3rem;
`;

export const Carousel = {
  Container: styled.div`
    width: 100%;
    height: 300px;
    display: flex;
  `,
  Image: styled.img`
    width: 100%;
    object-fit: scale-down;
  `,
};

export const Card = {
  Container: styled.div`
    width: 95%;
    height: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    min-height: 100px;
    border-radius: 4px;
    padding: 1rem 0.2rem;
    text-align: center;
  `,
  Image: styled.img`
    width: 50%;
  `,
  Title: styled.div`
    margin-bottom: 0 !important;
    color: #a20013;
    font-size: 0.5rem;
    margin-top: 0.5rem;
  `,
};

export const Details = {
  Container: styled.div`
    padding: 0.3rem 0.5rem;
  `,
  Title: styled.div`
    font-weight: bold;
    color: #a20013;
    margin-bottom: 0.5rem;
  `,
  Description: styled.p`
    padding: 1rem;
    text-align: justify;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  `,
};
