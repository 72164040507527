import axios from "axios";

export function SuppliersEndpoints(initPath) {
  const PATH = `${initPath}/supplierendpoint`;

  //!----------------------------------------------------------!\\
  //!---------------------------GETS---------------------------!\\
  //!----------------------------------------------------------!\\
  const getAllSupplierByPerson = async () =>
    await axios.get(`${PATH}/getAllSupplierByPerson`);

  const getTypesSupplier = async () =>
    await axios.get(`${PATH}/getTypesSupplier`);

  const getTypeByIdSupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getTypeByIdSupplier/${fkSupplier}`);

  const getProtocolByIdSupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getProtocolByIdSupplier/${fkSupplier}`);

  const getRole = async () => await axios.get(`${PATH}/role`);

  const getSupplierConditions = async (fkSupplier) =>
    await axios.get(`${PATH}/getSupplierConditions/${fkSupplier}`);

  const getAddressByIdSup = async (fkSupplier) =>
    await axios.get(`${PATH}/getAddressByIdSup/${fkSupplier}`);

  //!----------------------------------------------------------!\\
  //!--------------------------POSTS---------------------------!\\
  //!----------------------------------------------------------!\\
  const create_or_update = async (data) =>
    await axios.post(`${PATH}/create_or_update`, data);

  const create_or_update_supplier_protocol = async (data) =>
    await axios.post(`${PATH}/create_or_update_supplier_protocol`, data);

  const setSupplierConditions = async (data) =>
    await axios.post(`${PATH}/setSupplierConditions`, data);

  const create_or_update_address = async (data) =>
    await axios.post(`${PATH}/create_or_update_address`, data);

  return {
    getAllSupplierByPerson,
    getTypesSupplier,
    getTypeByIdSupplier,
    getProtocolByIdSupplier,
    getRole,
    getSupplierConditions,
    create_or_update,
    create_or_update_supplier_protocol,
    setSupplierConditions,
    getAddressByIdSup,
    create_or_update_address,
  };
}
