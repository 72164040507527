//-------------------------------------------------\\
//-----------------REACT-BOOTSTRAP-----------------\\
//-------------------------------------------------\\
import { Button, Modal } from "react-bootstrap";

//-------------------------------------------------\\
//---------------------I18NEXY---------------------\\
//-------------------------------------------------\\
import { useTranslation } from "react-i18next";

//-------------------------------------------------\\
//-------------------REACT-ICONS-------------------\\
//-------------------------------------------------\\
import { FaExclamationCircle } from "react-icons/fa";

const ModalRemove = ({ show, handleRemove, title, onRemove, content }) => {
  //!------------------------------------------------!\\
  //!-----------------useTranslation-----------------!\\
  //!------------------------------------------------!\\
  const { t } = useTranslation(["pm/sct"]);

  //!------------------------------------------------!\\
  //!--------------------component-------------------!\\
  //!------------------------------------------------!\\
  return (
    <Modal show={show} onHide={handleRemove} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <FaExclamationCircle className="text-danger me-2" />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: content }} />
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={handleRemove}>
          {t("buttons.back")}
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => {
            onRemove();
            handleRemove();
          }}
        >
          {t("buttons.remove")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRemove;
