import { useState } from "react";
import { AppBar as AppBarMui, Box, Container, Toolbar } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import DownloadButton from "../DownloadButton/DownloadButton";
import DropdownLang from "../DropdownLang/DropdownLang";
import UserMenu from "./UserMenu";
import { FiMenu } from "react-icons/fi";
import Canvas from "./Canvas";
const AppBar = () => {
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const handleShow = () => setVisible(true);

  return (
    <>
      <AppBarMui
        position="relative"
        color=""
        sx={{ zIndex: 100 }}
        elevation={1}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <a className="header-top-brand" href="/">
              <img src={Logo} alt="" />
              <span>Pin2Give</span>
            </a>
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "auto",
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <div>
                <DownloadButton placement="bottom" />
              </div>
              <DropdownLang />
              <div>
                <UserMenu />
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "auto",
                display: { xs: "flex", md: "none" },
                alignItems: "center",
              }}
            >
              <button className="burguer-toggle ms-auto" onClick={handleShow}>
                <FiMenu />
              </button>
            </Box>
          </Toolbar>
        </Container>
      </AppBarMui>
      <Canvas
        handleClose={handleClose}
        visible={visible}
        Logo={Logo}
        setVisible={setVisible}
      />
    </>
  );
};

export default AppBar;
