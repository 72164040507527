/* eslint-disable react-hooks/exhaustive-deps */
//? REACT
import React, { useEffect, useState } from "react";

//? REACT-BOOTSTRAP
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

//? LODASH
import lodash from "lodash";

//? REACT-ICONS
import { FaArrowLeft, FaArrowRight, FaEdit } from "react-icons/fa";

//? COMPONENTS
import Form from "./Form";
import Header from "../Header";
import OverlayConfirm from "../OverlayConfirm";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";

//? REACT-ROUTER-DOM
import { useNavigate, useParams } from "react-router-dom";

//? i18Next
import { useTranslation } from "react-i18next";

//? FORMIK
import { useFormik } from "formik";
import { validationSchema } from "../../../_yup/ProductsManagement/Compositions";
import Loading from "../Loading";
import { useAlert } from "react-alert";
import { useMutation, useQuery } from "react-query";
import { newComposition, formatValues, getSteps } from "./_actions/_actions";
import { useServices } from "../../../services/useServices";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

//?-------------------------PAGE------------------?\\
const Compositions = () => {
  //!----------------------------------------------------!\\
  //!------------------------hooks-----------------------!\\
  //!----------------------------------------------------!\\
  //* params
  const { fkProduct, idComposition } = useParams();

  //* products management context
  const { fkSupplier } = usePM();

  //* services
  const { productCompositionsEndpoints } = useServices();

  //* alert
  const alert = useAlert();

  //* translation
  const { t } = useTranslation(["pm/compositions", "pm/overlayConfirm"]);

  //* navigate
  const navigate = useNavigate();

  //* authentication
  const { role } = useAuth();

  //!----------------------------------------------------!\\
  //!------------------------states----------------------!\\
  //!----------------------------------------------------!\\
  //* editable
  const [editable, setEditable] = useState(false);

  //* selected
  const [selected, setSelected] = useState(null);

  //!----------------------------------------------------!\\
  //!--------------------compositions--------------------!\\
  //!----------------------------------------------------!\\
  const {
    data: compositions,
    refetch: refetchCompositions,
    isLoading: isLoadingCompositions,
  } = useQuery(
    ["getCompositions", fkProduct],
    () => productCompositionsEndpoints.getAllCompositionsBySupplier(fkProduct),
    {
      onError: () => {
        alert.error(t("alerts.error.compositions.message"));
        setSelected(null);
      },
    }
  );

  //!----------------------------------------------------!\\
  //!-----------------------overlay----------------------!\\
  //!----------------------------------------------------!\\
  //* state
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    newSelect: {},
  });

  //* handle close
  const handleCloseConfirm = () => setShowConfirm(false);

  //!----------------------------------------------------!\\
  //!--------------------handle submit-------------------!\\
  //!----------------------------------------------------!\\
  const { mutate: createComposition, isLoading: isLoadingCreate } = useMutation(
    (values) => {
      values.fkProduct = fkProduct;
      if (values.dimage === null) values.dimage = 0;
      return productCompositionsEndpoints.create_or_update(
        formatValues(values)
      );
    },
    {
      onSettled: () => {
        refetchCompositions();
      },
      onSuccess: (response) => {
        setSelected(response.data);
        setEditable(false);

        alert.success(
          <>
            <Alert.Heading>{t("alerts.success.submit.title")}</Alert.Heading>
            <p>{t("alerts.success.submit.message")}</p>
          </>
        );
      },
      onError: () => {
        alert.error(
          <>
            <Alert.Heading>{t("alerts.error.submit.title")}</Alert.Heading>
            <p>{t("alerts.error.submit.message")}</p>
          </>
        );
      },
    }
  );

  //!--------------------------------------------!\\
  //!-------------------formik-------------------!\\
  //!--------------------------------------------!\\
  const formik = useFormik({
    initialValues: { ...selected, state: 0 } ?? newComposition,
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => createComposition(values),
  });

  //!--------------------------------------------!\\
  //!--------------------------------------------!\\
  //!-----------------useEffects-----------------!\\
  //!--------------------------------------------!\\
  //!--------------------------------------------!\\
  //* este useEffect é necessário para aparecer
  //* uma composição selecionada por defeito
  useEffect(() => {
    if (lodash.isEmpty(compositions?.data) || !compositions?.data) {
      return setSelected(null);
    } else {
      if (idComposition !== "-") {
        const x = compositions?.data?.findIndex(
          (e) => e.idComposition === Number(idComposition)
        );

        if (x !== -1) return setSelected(compositions?.data[x]);
      } else if (!selected) {
        return setSelected(compositions?.data[0]);
      }
    }
  }, [compositions?.data, idComposition]);

  return (
    <div>
      <Container>
        {
          //!--------------------------------!\\
          //!--------------HEADER------------!\\
          //!--------------------------------!\\
        }
        <Header
          left={{ backButton: true, menuButton: true }}
          center={{ title: t("title") }}
          infoKey="composition"
        />
        {
          //!------------------------------------------------!\\
          //!-----------------STEP INDICATOR-----------------!\\
          //!------------------------------------------------!\\
        }
        <Row className="mb-5">
          <Col xs="12">
            <Stepper activeStep={2}>
              {getSteps(t, fkProduct).map(({ label, path }) => (
                <Step
                  onClick={() => (path ? navigate(path) : undefined)}
                  key={label}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>
        {isLoadingCompositions ? (
          <Loading />
        ) : (
          <Row
            className={`h-100 ${
              lodash.isEmpty(selected) ? "align-items-center" : ""
            }`}
          >
            {
              //!--------------------------------------!\\
              //!--------------SECTION LIST------------!\\
              //!--------------------------------------!\\
            }
            <Col xs="12" lg="3" className="mb-5">
              <Pin2GiveList
                headerProps={{
                  title: t("list.title"),
                  headerButton: {
                    onClick: () => {
                      setSelected(newComposition);
                      setEditable(true);
                    },
                    title: t("list.buttonTooltip"),
                  },
                }}
              >
                {compositions?.data?.map((e, index) => (
                  <Pin2GiveListItem
                    key={index}
                    id={e?.idProducer}
                    type="text"
                    title={e?.stitle === "" ? "<Empty Title>" : e?.stitle}
                    onClick={() => {
                      if (
                        lodash.isEqual(
                          { ...formik.values, state: 0 },
                          { ...selected, state: 0 }
                        )
                      ) {
                        setSelected(e);
                        setEditable(false);
                      } else {
                        setShowConfirm({ show: true, newSelect: e });
                      }
                    }}
                    selected={e?.idComposition === selected?.idComposition}
                  />
                ))}
                {lodash.isEqual(selected, newComposition) ? (
                  <>
                    <Pin2GiveListItem
                      selected
                      type="text"
                      title={t("list.newTitle")}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Pin2GiveList>
            </Col>
            <Col xs="12" lg="9" className="position-relative">
              {lodash.isEmpty(selected) ? (
                <>
                  {
                    //!-------------------------------------!\\
                    //!--------------EMPTY LABEL------------!\\
                    //!-------------------------------------!\\
                  }
                  <div className="h-100 d-flex flex-column align-items-center empty-active-reference">
                    <FaArrowLeft size={30} />
                    <div className="empty-active-reference">
                      {t("list.buttonTooltip")}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Container fluid>
                    {
                      //!------------------------------------!\\
                      //!--------------FORM TITLE------------!\\
                      //!------------------------------------!\\
                    }
                    <Row className="mb-5">
                      <Col xs="12">
                        <div className="app-sub-title w-100 container-fluid">
                          <Row>
                            <Col xs="12">
                              <div className="border-bottom pb-2 d-flex justify-content-between">
                                <div>
                                  {selected?.stitle || t("list.newTitle")}
                                </div>
                                <div>
                                  {!editable && (
                                    <Row>
                                      <Col xs="12" className="text-end">
                                        <Button
                                          size="sm"
                                          className="me-2"
                                          variant={
                                            selected?.state === 1
                                              ? "success"
                                              : "secondary"
                                          }
                                          onClick={() => {
                                            if (selected?.state === 1) {
                                              createComposition({
                                                ...selected,
                                                state: 0,
                                              });
                                            } else {
                                              createComposition({
                                                ...selected,
                                                state: 1,
                                              });
                                            }
                                          }}
                                        >
                                          {selected?.state === 1
                                            ? t("buttons.enabled")
                                            : t("buttons.disabled")}
                                        </Button>
                                        <Button
                                          size="sm"
                                          className="rounded-circle"
                                          variant="warning"
                                          onClick={() => setEditable(true)}
                                        >
                                          <FaEdit />
                                        </Button>
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {
                      //!------------------------------!\\
                      //!--------------FORM------------!\\
                      //!------------------------------!\\
                    }
                    <Row>
                      <Col xs="12">
                        <Form
                          fields={selected}
                          fkProduct={fkProduct}
                          editable={editable}
                          formik={formik}
                          setEditable={setEditable}
                          fkSupplier={fkSupplier}
                          selected={selected}
                          loadingCreate={isLoadingCreate}
                          handleCancel={() => {
                            if (formik.values.idComposition === -1) {
                              formik.setValues(compositions.data[0] ?? null);
                              setSelected(compositions.data[0] ?? null);
                            } else {
                              formik.setValues(selected);
                            }
                            setEditable(false);
                          }}
                        />
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </Col>
            {!editable && (
              <Col xs="12" className="mt-5 d-flex">
                <Button
                  variant="secondary"
                  className="d-flex align-items-center me-auto"
                  onClick={() =>
                    navigate(
                      "/productsmanagement/productcategories/" + fkProduct
                    )
                  }
                >
                  <FaArrowLeft className="me-2" />
                  {t("buttons.categories")}
                </Button>
                <Button
                  variant="pin2give"
                  className="d-flex align-items-center "
                  onClick={() =>
                    navigate(
                      "/productsmanagement/compositionsreferences/" + fkProduct
                    )
                  }
                >
                  {t("buttons.addReference")} <FaArrowRight className="ms-2" />
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Container>
      <OverlayConfirm
        word="composição"
        show={showConfirm?.show}
        handleCloseConfirm={handleCloseConfirm}
        title={t("compositions.title", { ns: "pm/overlayConfirm" })}
        content={t("compositions.content", { ns: "pm/overlayConfirm" })}
        onSelect={() => {
          setSelected(showConfirm.newSelect);
          handleCloseConfirm();
          setEditable(false);
        }}
      />
    </div>
  );
};

export default Compositions;
