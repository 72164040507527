/* eslint-disable eqeqeq */
import "./Cards.css";
import "./CardStore.css";
import {
  FaRegPlayCircle,
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { IconsItem } from "../Items/Items";
import lodash from "lodash";

export const CardNews = (props) => {
  const [show, setShow] = useState(false);
  const { item, index } = props;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        id={`cardView${index}`}
        className={`app-card-news ${props.video ? "video" : ""}`}
        onClick={(event) => {
          if (event.target.id === "cardView" + index) {
            handleShow();
          }
        }}
      >
        {props.video ? (
          <div
            className="app-card-media"
            onClick={() => window.open(item?.lvideo, "_blank")}
          >
            <FaRegPlayCircle />
          </div>
        ) : props.img ? (
          <img
            src={item?.link}
            className="img-compact-card-news"
            alt=""
            onClick={handleShow}
          />
        ) : (
          <></>
        )}
        <div className="app-card-news-content w-100" onClick={handleShow}>
          <span dangerouslySetInnerHTML={{ __html: item?.title }} />
          <p
            dangerouslySetInnerHTML={{
              __html: item?.body,
            }}
          />
        </div>
        <div className="app-card-extern-links h-100">
          {!lodash.isEmpty(item?.lfacebook) ? (
            <a href={item?.lfacebook} rel="noreferrer" target="_blank">
              <FaFacebookF />
            </a>
          ) : (
            <></>
          )}
          {!lodash.isEmpty(item?.linstagram) ? (
            <a href={item?.linstagram} rel="noreferrer" target="_blank">
              <FaInstagram />
            </a>
          ) : (
            <></>
          )}
          {!lodash.isEmpty(item?.lpintrest) ? (
            <a href={item?.lpintrest} rel="noreferrer" target="_blank">
              <FaPinterest />
            </a>
          ) : (
            <></>
          )}
          {!lodash.isEmpty(item?.llinkedin) ? (
            <a href={item?.lpintrest} rel="noreferrer" target="_blank">
              <FaLinkedin />
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className="border-0">
          <Modal.Title dangerouslySetInnerHTML={{ __html: item?.title }} />
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
          <Container>
            {props.img ? (
              <>
                <Row className="justify-content-center">
                  <Col xs="12" sm="9" md="6" className="text-center">
                    <img
                      src={item?.link}
                      alt=""
                      className="w-100"
                      onClick={() =>
                        item?.lvideo
                          ? window.open(item?.lvideo, "_blank")
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row className="justify-content-center">
              <Col
                xs="12"
                sm="9"
                md="6"
                xl="4"
                className="mt-4 d-flex justify-content-center"
              >
                <div className="h4 w-100 d-flex justify-content-center">
                  {!lodash.isEmpty(item?.lfacebook) ? (
                    <a
                      href={item?.lfacebook}
                      rel="noreferrer"
                      target="_blank"
                      className="ms-2"
                    >
                      <FaFacebookF />
                    </a>
                  ) : (
                    <></>
                  )}
                  {!lodash.isEmpty(item?.linstagram) ? (
                    <a
                      href={item?.linstagram}
                      rel="noreferrer"
                      target="_blank"
                      className="ms-2"
                    >
                      <FaInstagram />
                    </a>
                  ) : (
                    <></>
                  )}
                  {!lodash.isEmpty(item?.lpintrest) ? (
                    <a
                      href={item?.lpintrest}
                      rel="noreferrer"
                      target="_blank"
                      className="ms-2"
                    >
                      <FaPinterest />
                    </a>
                  ) : (
                    <></>
                  )}
                  {!lodash.isEmpty(item?.llinkedin) ? (
                    <a
                      href={item?.lfacebook}
                      rel="noreferrer"
                      target="_blank"
                      className="ms-2"
                    >
                      <FaLinkedin />
                    </a>
                  ) : (
                    <></>
                  )}
                  {item?.lvideo ? (
                    <a
                      href={item?.lvideo}
                      rel="noreferrer"
                      target="_blank"
                      className="ms-2"
                    >
                      <FaYoutube />
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <p
                  className="text-justify w-100 mt-5"
                  dangerouslySetInnerHTML={{
                    __html: item?.body,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const CardStore = (props) => {
  const { title, price, onClick, image, symbol, icons } = props;
  return (
    <div className="card-store" onClick={onClick}>
      <img src={image} alt={title} className="card-store-image" />
      <div className="card-content">
        <span
          data-testid="cardstore-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {price ? (
          <span data-testid="cardstore-price">
            {Number(price).toFixed(2) + " " + (symbol ? symbol : "€")}{" "}
          </span>
        ) : (
          <></>
        )}
      </div>
      {icons ? (
        <div className="cardstore-icons">
          {[
            { icon: <HiBadgeCheck />, title: "exclusive" },
            { icon: <HiBadgeCheck />, title: "exclusive" },
            { icon: <HiBadgeCheck />, title: "exclusive" },
          ].map((e, index) => (
            <IconsItem title={e?.title} icon={e?.icon} key={index} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
