import React, { createContext, useEffect, useState } from "react";
import lodash from "lodash";
import axios from "axios";
import {
  interceptRequestPrivate,
  interceptRequestPublic,
  interceptResponse,
} from "./_utils";

//?sadlkaskldsa
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { useServices } from "../../services/useServices";
import Overlay from "./Overlay";
import { useAlert } from "react-alert";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  //!--------------------------------------------------!\\
  //!---------------------authdata---------------------!\\
  //!--------------------------------------------------!\\
  const [auth, setAuth] = useState({
    loading: true,
    token: null,
    publicKey: null,
    expiration: null,
  });

  //!--------------------------------------------------!\\
  //!-----------------------Modal----------------------!\\
  //!--------------------------------------------------!\\
  const [show, setShow] = useState(false);

  //!--------------------------------------------------!\\
  //!-----------------------alert----------------------!\\
  //!--------------------------------------------------!\\
  const alert = useAlert();

  //!--------------------------------------------------!\\
  //!-------------------useTranslation-----------------!\\
  //!--------------------------------------------------!\\
  const { t } = useTranslation(["pm/pm", "login"]);

  //!--------------------------------------------------!\\
  //!---------------------services---------------------!\\
  //!--------------------------------------------------!\\
  const services = useServices();

  //!--------------------------------------------------!\\
  //!------------------get public key------------------!\\
  //!--------------------------------------------------!\\
  const { data: publicKey } = useQuery(
    ["getPublicKey", auth?.token],
    services.authenticationEndpoints.getPublicKey
  );

  //!--------------------------------------------------!\\
  //!---------------------get role---------------------!\\
  //!--------------------------------------------------!\\
  const { data: role } = useQuery(
    ["getRoles", auth?.token],
    () => services.supplierEndpoints.getRole(),
    {
      enabled: auth.token !== null,
    }
  );

  //!--------------------------------------------------!\\
  //!-----------------------login----------------------!\\
  //!--------------------------------------------------!\\
  const { mutate: login, isLoading: isLoadingLogin } = useMutation(
    (hash) => services.authenticationEndpoints.login(hash),
    {
      onSuccess: (response) => {
        //* obter a authorização com o bearer token
        const authorization = response.headers.authorization;

        //* remover a palavra "Bearer " da string
        const token = authorization.replace("Bearer ", "");

        //* definir o tempo de expiração do token
        const time = new Date();
        time.setMinutes(time.getMinutes() + 60);

        //* atualizar os dados de autenticação
        setAuth((beforeUpdate) => {
          return { ...beforeUpdate, token: token, expiration: time };
        });

        //* atualizas os dado na local storage
        localStorage.setItem("expiration", time);
        localStorage.setItem("token", token);
      },
      onError: () => {
        alert.error(
          <>
            <Alert.Heading>
              {t("alerts.error.incorrect.title", { ns: "login" })}
            </Alert.Heading>
            <p>{t("alerts.error.incorrect.message", { ns: "login" })}</p>
          </>
        );
      },
    }
  );

  //!--------------------------------------------------!\\
  //!----------------------logout----------------------!\\
  //!--------------------------------------------------!\\
  const logout = () => {
    //* remover na local storage
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");

    //* atualizar a variavel de estado
    setAuth((beforeUpdate) => {
      return { ...beforeUpdate, token: null, expiration: null };
    });
  };

  //!--------------------------------------------------!\\
  //!--------------------useEffects--------------------!\\
  //!--------------------------------------------------!\\
  //* obter os dados iniciais da local storage
  useEffect(() => {
    if (lodash.isEmpty(localStorage.getItem("token"))) {
      setAuth((e) => {
        return { ...e, loading: false, token: null, expiration: null };
      });
    } else {
      const t = localStorage.getItem("token");
      const e = localStorage.getItem("expiration");

      setAuth((beforeUpdate) => {
        return {
          ...beforeUpdate,
          loading: false,
          token: t,
          expiration: e ?? null,
        };
      });
    }
  }, []);

  //* obter o tempo de expiração atualizado
  useEffect(() => {
    if (auth.expiration) {
      const now = new Date();

      const timer = setTimeout(() => {
        setShow(true);
      }, moment(auth.expiration).diff(now, "milliseconds", true));

      return () => clearTimeout(timer);
    }
  }, [auth.expiration]);

  useEffect(() => {
    if (auth.loading === false) {
      if (auth.token) {
        //* intercept axios request
        const request = interceptRequestPrivate(
          services.isPrivateRoute,
          auth?.token
        );
        axios.interceptors.request.use(request.resolve, request.reject);
      } else {
        //* intercept axios request
        const request = interceptRequestPublic();
        axios.interceptors.request.use(request.resolve, request.reject);
      }
    }

    //* response
    const response = interceptResponse(
      services.isPrivateRoute,
      auth?.token,
      setShow
    );
    axios.interceptors.response.use(response.resolve, response.reject);
  }, [auth.token]);

  return (
    <authContext.Provider
      value={{
        auth,
        publicKey: publicKey?.data,
        role: role?.data,
        authentication: {
          login,
          isLoadingLogin,
          logout,
        },
        overlay: {
          show,
          setShow,
        },
      }}
    >
      {children}

      <Overlay />
    </authContext.Provider>
  );
};

export default AuthProvider;
