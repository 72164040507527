import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";

function CompositionList({
  fkProduct,
  isUpdated,
  selected,
  data,
  compositionReferences,
  initCR,
  setSelected,
  setShowConfirm,
}) {
  //!-------------------------------------------------------!\\
  //!--------------------------hooks------------------------!\\
  //!-------------------------------------------------------!\\
  //* navigate
  const navigate = useNavigate();

  //* translation
  const { t } = useTranslation([
    "pm/compositionReferences",
    "pm/overlayConfirm",
  ]);

  //!-------------------------------------------------------!\\
  //!-------------------------options-----------------------!\\
  //!-------------------------------------------------------!\\
  //* header Props
  const headerProps = {
    title: (
      <>
        <div>{`${t("lists.compositions.title")}`}</div>
        {`(${t("lists.compositions.subtitle")}: ${fkProduct})`}
      </>
    ),
    headerButton: {
      onClick: () => navigate("/productsmanagement/compositions/" + fkProduct),
      title: t("lists.compositions.button"),
    },
  };

  //!-------------------------------------------------------!\\
  //!--------------------------events-----------------------!\\
  //!-------------------------------------------------------!\\
  //* handleClick
  const handleClick = (e) =>
    isUpdated(compositionReferences, initCR?.data)
      ? setSelected(e?.idComposition)
      : setShowConfirm({
          show: true,
          newSelect: e?.idComposition,
        });

  return (
    <Pin2GiveList headerProps={headerProps}>
      {data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          type="text"
          title={e?.stitle}
          selected={e?.idComposition === selected}
          onClick={() => handleClick(e)}
        />
      ))}
    </Pin2GiveList>
  );
}

export default CompositionList;
