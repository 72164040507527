import Input from "./Input";
import Check from "./Check";
import PhoneInput from "./PhoneInput";
import ImageUpload from "./ImageUpload";
import Select from "./Select";
import ImagePicker from "./ImagePicker";

const Pin2GiveForm = {
  Input,
  Check,
  PhoneInput,
  ImageUpload,
  Select,
  ImagePicker,
};

export default Pin2GiveForm;
