import axios from "axios";

export function Pin2giveWebEndpoints(initPath) {
  const PATH = `${initPath}/v1.0/pin2givewebendpoint`;

  const wdatahome = async (fkLanguage, fkCountryCode) =>
    await axios.get(`${PATH}/wdatahome/${fkLanguage}/${fkCountryCode}`);

  const wgetFAQs = async (fkLanguage, type) =>
    await axios.get(`${PATH}/wgetFAQs/${fkLanguage}/${type}`);

  const getStrHtmlPolicie = async (fkLanguage) =>
    await axios.get(`${PATH}/getStrHtmlPolicie/${fkLanguage}`);

  const wgetNews = async (fkLanguage) =>
    await axios.get(`${PATH}/wgetNews/${fkLanguage}`);

  return {
    wdatahome,
    wgetFAQs,
    getStrHtmlPolicie,
    wgetNews,
  };
}
