import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useImagesReferences } from "./useImagesReferences";
import { ordernate, updateDImage } from "./_actions/_actions";

const Save = () => {
  const { t } = useTranslation(["pm/imagesReferences"]);
  const navigate = useNavigate();
  const { isUpdated, orderList, handleSubmit, dImage, referenceImages } =
    useImagesReferences();

  const handleClick = () => {
    const new0 = ordernate(orderList?.data);
    handleSubmit(updateDImage(new0, dImage.data));
  };

  return !isUpdated(referenceImages.init, ordernate(orderList?.data)) ? (
    <div className="d-flex">
      <Button
        variant="secondary"
        className="me-2 ms-auto"
        onClick={() => {
          orderList.setOrderList(referenceImages.init);
          referenceImages.setReferencesImages(referenceImages.init);
          orderList.setInitOrder(referenceImages.init);
          dImage.getdImage(referenceImages.init);
        }}
      >
        {t("buttons.cancel")}
      </Button>
      <Button
        className="d-flex align-items-center"
        variant="success"
        onClick={handleClick}
      >
        <FaSave className="me-2" /> {t("buttons.save")}
      </Button>
    </div>
  ) : (
    <Button onClick={() => navigate("/productsmanagement")} variant="pin2give">
      {t("buttons.cr")}
    </Button>
  );
};

export default Save;
