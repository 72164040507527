import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { useServices } from "../../services/useServices";
import ChangePasswordOverlay from "./ChangePassword";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function Data() {
  //!------------------------------------------------!\\
  //!----------------------hooks---------------------!\\
  //!------------------------------------------------!\\
  //* services
  const { authenticationEndpoints, getLanguage } = useServices();

  //* auth
  const { auth } = useAuth();

  //* translations
  const { t } = useTranslation(["profile"]);

  //!------------------------------------------------!\\
  //!------------------changePassword----------------!\\
  //!------------------------------------------------!\\
  const [changePassword, setChangePassword] = useState(false);

  //!------------------------------------------------!\\
  //!---------------------Titles---------------------!\\
  //!------------------------------------------------!\\
  const { data: titles } = useQuery(
    ["getTitles", auth.token],
    () => authenticationEndpoints.getTitles(),
    {
      enabled: auth.token !== null,
    }
  );
  const getTitleLabel = (fkTitle) => {
    const arrTitles = titles?.data ?? [];

    const exist = arrTitles?.find((e) => e?.idTitle === fkTitle);

    if (exist === undefined) return "N/A";

    return exist[`${getLanguage()}title`];
  };

  //!------------------------------------------------!\\
  //!------------------Profile Data------------------!\\
  //!------------------------------------------------!\\
  const { data: profileData } = useQuery(
    ["getProfileData", auth.token],
    () => authenticationEndpoints.getProfileData(""),
    {
      enabled: auth?.token !== null,
    }
  );

  return (
    <Container>
      <Row>
        <Col xs="12" md="6" className="mb-5 text-center">
          <div className="fw-bold h5">{t("name")}:</div>
          <div>{profileData?.data?.name}</div>
        </Col>
        <Col xs="12" md="6" className="mb-5 text-center">
          <div className="fw-bold h5">{t("email")}:</div>
          <div>{profileData?.data?.email}</div>
        </Col>
        <Col xs="12" md="6" className="mb-5 text-center">
          <div className="fw-bold h5">{t("dTitle")}:</div>
          <div>{getTitleLabel(profileData?.data?.fktitle)}</div>
        </Col>
        <Col xs="12" md="6" className="mb-5 text-center">
          <div className="fw-bold h5">{t("mobile")}:</div>
          <div>{profileData?.data?.mobile || "N/A"}</div>
        </Col>
        <Col xs="12" md="6" className="mb-5 text-center">
          <div className="fw-bold h5">{t("phone")}:</div>
          <div>{profileData?.data?.phone || "N/A"}</div>
        </Col>
        <Col xs="12" className="text-end">
          <Button variant="secondary" onClick={() => setChangePassword(true)}>
            {t("buttons.changePassword")}
          </Button>
        </Col>
      </Row>
      <ChangePasswordOverlay
        show={changePassword}
        handleClose={() => setChangePassword(false)}
      />
    </Container>
  );
}

export default Data;
