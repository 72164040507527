import styled from "styled-components";
import DCropper from "react-cropper";

export const Cropper = styled(DCropper)`
  height: 400px;
  width: 100%;
  & .cropper-container {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
`;

export const ImagePreview = styled.div`
  overflow: hidden;
  width: 300px;
  height: 300px;
  border: 0px solid #c4c4c4;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
`;
