import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

// import { Container } from './styles';

function Overlay() {
  const { overlay, authentication } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation(["pm/pm"]);

  return (
    <Dialog
      open={overlay.show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("modal.content")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            authentication.logout();
            overlay.setShow(false);
            navigate("/");
          }}
        >
          {t("buttons.home")}
        </Button>
        <Button
          variant="pin2give"
          size="sm"
          onClick={() => {
            authentication.logout();
            overlay.setShow(false);
            navigate("/user/login");
          }}
        >
          {t("buttons.login")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Overlay;
