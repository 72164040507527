/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "./Partners.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import lodasah from "lodash";
import ImageNF from "../../assets/images/notfound.png";
import lodash from "lodash";
import Tabs from "../../components/Tabs/Tabs";
import ButtonScroll from "../../components/ButtonScroll/ButtonScroll";
import { Tooltip } from "@mui/material";
import { useServices } from "../../services/useServices";

const Partners = () => {
  const { t } = useTranslation();
  const [partners, setPartners] = useState({ loading: true, data: null });
  const [faqs, setFaqs] = useState(null);
  const { productsWebEndpoints, pin2giveWebEndpoints, getLanguage } =
    useServices();

  const getTabs = () => {
    if (!lodash.isEmpty(faqs)) {
      return [
        {
          href: "htbap",
          title: t("MainTitles.BePartner") + "?",
          offset: -200,
        },
        {
          href: "PN",
          title: t("MainTitles.BusinessPartners"),
          offset: -200,
        },
        {
          href: "FAQS",
          title: "FAQ's",
          offset: -200,
        },
      ];
    } else {
      return [
        {
          href: "htbap",
          title: t("MainTitles.BePartner") + "?",
          offset: -200,
        },
        {
          href: "PN",
          title: t("MainTitles.BusinessPartners"),
          offset: -200,
        },
      ];
    }
  };
  useEffect(() => {
    document.title = t("MainTitles.BusinessPartners");
    //get partners
    productsWebEndpoints
      .wsuppliersInformation()
      .then((response) => {
        //console.log(response?.data);
        setPartners({ loading: false, data: response?.data });
      })
      .catch((error) => setPartners({ data: null, loading: false }));

    pin2giveWebEndpoints
      .wgetFAQs(getLanguage(), 1)
      .then((response) => setFaqs(response?.data))
      .catch((error) => console.log("Error on obtain supplier faqs", error));
  }, []);

  return (
    <div className="app-partners ">
      <Container fluid className="sticky-top">
        <Row>
          <Col xs="12">
            <Container className="py-2 py-md-4 bg-white">
              <Row>
                <Col xs="12">
                  <Tabs tabslist={getTabs()} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="px-xl-5">
        <Row className="justify-content-center" id="htbap">
          <Col xs="12" md="8" className="text-center mt-5 mb-3">
            <span className="app-title">{t("MainTitles.BePartner")}?</span>
          </Col>
          <Col xs="12" md="8" className="text-left mt-5 mb-3">
            <span className="app-sub-title">{t("Support.PFB.Title")}</span>
          </Col>
          <Col xs="12" md="8" className="text-justify">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.PFB.p1"),
              }}
            />
          </Col>
          <Col xs="12" md="8" className="text-left mt-5 mb-3">
            <span className="app-sub-title">{t("Support.WP.Title")}</span>
          </Col>
          <Col xs="12" md="8" className="text-justify">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.WP.p1"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.WP.p2"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.WP.p3"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.WP.p4"),
              }}
            />
          </Col>
          <Col xs="12" md="8" className="mt-5 mb-3">
            <span className="app-sub-title">{t("Support.WO.Title")}</span>
          </Col>
          <Col xs="12" md="8" className="text-justify">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.WO.p1"),
              }}
            />
          </Col>
          <Col xs="12" md="8" className="mt-5 mb-3">
            <span className="app-sub-title">{t("Support.HIT.Title")}</span>
          </Col>
          <Col xs="12" md="8" className="text-justify">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Support.HIT.p1"),
              }}
            />
          </Col>
        </Row>
        {/*---------------------------------SUPPLIERS-------------------- */}
        <Row className="justify-content-center" id="PN">
          <Col xs="12" className="mt-5 text-center mb-3">
            <span className="app-title">
              {t("MainTitles.BusinessPartners")}
            </span>
          </Col>
          <Col xs="12" md="8" className="text-center">
            {t("BusinessPartners.Content")}
          </Col>
          {partners.loading ? (
            <Col xs="12" className="text-center mt-4">
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
            </Col>
          ) : lodasah.isEmpty(partners?.data) ? (
            <Col xs="12" className="text-center app-sub-title mt-4">
              {t("Store.Empty.Search")}
            </Col>
          ) : (
            <Row className=" justify-content-center justify-content-sm-start px-xl-5">
              {partners?.data.map((e, index) => (
                <Col
                  key={index}
                  xs="7"
                  sm="6"
                  md="4"
                  lg="3"
                  className="mb-3 text-center mt-5 px-xl-5"
                >
                  <Tooltip title={e?.name}>
                    <img
                      src={lodasah.isEqual(e?.photo, "-") ? ImageNF : e?.photo}
                      alt={e?.name}
                      className="image-partner"
                    />
                  </Tooltip>
                </Col>
              ))}
            </Row>
          )}
        </Row>
        {/*---------------------------------FAQS-------------------- */}
        <Row className="justify-content-center mt-5" id="FAQS">
          <Col xs="12" className="mb-5">
            <Container fluid className="m-0 p-0">
              {lodash.isEmpty(faqs) ? (
                <></>
              ) : (
                <>
                  <Row className="justify-content-center mt-5">
                    <Col xs="12" md="8" className="text-center mt-5 mb-3">
                      <span className="app-title">FAQ's</span>
                    </Col>
                  </Row>
                  {faqs.map((e, index) => (
                    <Row className="justify-content-center" key={index}>
                      <Col xs="12" md="8" className="mb-3">
                        <div className="app-sub-title">{e?.title}</div>
                      </Col>
                      <Col xs="12" md="8" className="text-justify mb-5">
                        <p>{e?.question}</p>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
      <ButtonScroll />
    </div>
  );
};

export default Partners;
