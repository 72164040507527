import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

const OnlyPublicRoute = ({ children }) => {
  useEffect(() => {
    document.title = "Pin2Give";
  }, []);
  const { auth } = useAuth();
  return auth.token ? <Navigate to="/" /> : children;
};
export default OnlyPublicRoute;
