/**
 * !-------------------------------------------------------!
 * !----------------Get Composition Checked----------------!
 * !-------------------------------------------------------!
 * @param {array} seasonCrossTable
 * @param {integer} idProduct
 * @param {integer} idComposition
 * @return true if checked
 * @return false if unchecked
 * @description Este método é utilizado no checks da lista de composições
 */
export function getCompositionChecked(
  seasonCrossTable,
  idProduct,
  idComposition
) {
  //* procurar na seasoncrosstable se existe algum registo
  //* com o id do produto e o id da composição pretendido
  const x = seasonCrossTable.find(
    ({ seasonCrosstablePK: y }) =>
      y.fkProduct === idProduct && y.fkComposition === idComposition
  );

  //* se não encontrar nenhum
  //* registo retorna false
  if (x !== undefined) return true;

  //* se encontrar retorna true
  return false;
}

/**
 * !--------------------------------------------------!
 * !---------------Get Composition Title--------------!
 * !--------------------------------------------------!
 * @param {array} compositions
 * @param {integer} fkComposition
 * @returns {string}
 * @description este método serve para
 * encontrar a label de uma determinada composição
 */
export function getCompositionTitle(compositions, fkComposition) {
  const data = compositions || [];

  const object = data.find((e) => e?.idComposition === fkComposition);

  if (object !== undefined) return object.stitle;

  return "";
}
