//? REACT-BOOTSTRAP
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";

//? useNavigate
import { useNavigate } from "react-router-dom";

//? REACT-I18NEXT
import { useTranslation } from "react-i18next";

//? REACT-ICONS
import { FaSave } from "react-icons/fa";

//? PIN2GIVEFORM
import Pin2GiveForm from "../../../components/Pin2GiveForm/Pin2GiveForm";

//? MOMENT
import moment from "moment";

//? useAuth
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

//? FORM
const Form = ({ editable, setEditable, formik, lb, selected }) => {
  //!------------------------------------------------!\\
  //!------------------------------------------------!\\
  //!------------------------------------------------!\\
  //!-----------------useTranslation-----------------!\\
  //!------------------------------------------------!\\
  //!------------------------------------------------!\\
  //!------------------------------------------------!\\
  const { t } = useTranslation(["pm/createSeasons"]);

  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  //!-----------------useNavigation-----------------!\\
  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  //!-----------------------------------------------!\\
  const navigate = useNavigate();

  const { role } = useAuth();
  //?----------------------------------------?\\
  //?----------------------------------------?\\
  //?----------------------------------------?\\
  //?-----------------RENDER-----------------?\\
  //?----------------------------------------?\\
  //?----------------------------------------?\\
  //?----------------------------------------?\\
  return (
    <Container fluid className="crate-season-form">
      {
        //!----------------------------------------------!\\
        //!----------------EDITABLE BUTTON---------------!\\
        //!----------------------------------------------!\\
      }
      <form onSubmit={formik.handleSubmit}>
        <Row>
          {
            //!------------------------------------!\\
            //!----------------STATE---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="3">
            <Pin2GiveForm.Select
              label={t("fields.state.label")}
              inputProps={{
                name: "state",
                disabled: !editable || role === 2,
                value: formik.values?.state,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
              error={formik.errors?.state}
            >
              <option value="0">{t("states.s0")}</option>
              <option value="1">{t("states.s1")}</option>
              <option value="2">{t("states.s2")}</option>
              <option value="3">{t("states.s3")}</option>
              <option value="4">{t("states.s4")}</option>
              <option value="5">{t("states.s5")}</option>
              <option value="6">{t("states.s6")}</option>
              <option value="7">{t("states.s7")}</option>
              <option value="8">{t("states.s8")}</option>
              <option value="9">{t("states.s9")}</option>
            </Pin2GiveForm.Select>
          </Col>
          {
            //!------------------------------------!\\
            //!----------------Title---------------!\\
            //!------------------------------------!\\
          }
          <Col xs="12" lg="9">
            <Pin2GiveForm.Input
              label={t("fields.title.label")}
              inputProps={{
                name: "title",
                readOnly: !editable,
                value: formik.values?.title ?? "",
                onChange: formik.handleChange,
              }}
              error={formik.errors?.title}
            />
          </Col>
          {
            //!-----------------------------------------------!\\
            //!----------------PUBLICATION DATE---------------!\\
            //!-----------------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.dpublication.label")}
              inputProps={{
                type: "date",
                name: "dpublication",
                readOnly: !editable,
                value:
                  moment(formik.values.dpublication).format("YYYY-MM-DD") ?? 0,
                onChange: (e) => {
                  formik.setFieldValue(
                    "dpublication",
                    moment(e.target.value).format("YYYYMMDD")
                  );
                  formik.setFieldValue("depublication", "");
                },
                onBlur: formik.handleBlur,
              }}
              error={formik.errors?.dpublication}
            />
          </Col>
          {
            //!---------------------------------------------------!\\
            //!----------------END PUBLICATION DATE---------------!\\
            //!---------------------------------------------------!\\
          }
          <Col xs="12" lg="4">
            <Pin2GiveForm.Input
              label={t("fields.depublication.label")}
              inputProps={{
                type: "date",
                name: "depublication",
                readOnly: !editable,
                value: moment(formik.values.depublication ?? "").format(
                  "YYYY-MM-DD"
                ),
                onChange: (e) => {
                  formik.setFieldValue(
                    "depublication",
                    moment(e.target.value).format("YYYYMMDD")
                  );
                },
                min:
                  moment(formik.values.dpublication).format("YYYY-MM-DD") ?? 0,
              }}
              error={formik.errors?.depublication}
            />
          </Col>
          {
            //!-------------------------------------------!\\
            //!----------------observations---------------!\\
            //!-------------------------------------------!\\
          }
          <Col xs="12">
            <Pin2GiveForm.Input
              label={t("fields.observations.label")}
              inputProps={{
                as: "textarea",
                rows: "4",
                name: "observations",
                readOnly: !editable,
                value: formik.values.observations ?? "",
                onChange: formik.handleChange,
              }}
              error={formik.errors?.observations}
            />
          </Col>
          {
            //!-------------------------------------!\\
            //!----------------BUTTONS--------------!\\
            //!-------------------------------------!\\
          }
          <Col xs="12" className="mt-5 text-end">
            {editable ? (
              <>
                <Button
                  variant="secondary"
                  type="button"
                  className="me-2"
                  onClick={() => {
                    formik.setValues(selected);
                    setEditable(false);
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
                <Button variant="success" type="submit">
                  {lb ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <>
                      <FaSave className="me-2" />
                      {t("buttons.save")}
                    </>
                  )}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="pin2give"
                  type="button"
                  onClick={() => navigate("/productsmanagement/seasons")}
                >
                  {t("buttons.seasonsProducts")}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default Form;
