import React from "react";
import { Card, Carousel, Details, Screen } from "./Styles";
import HeaderMobile from "../../assets/images/headermobile.jpg";
import HeaderMobile2 from "../../assets/images/HeaderMobile2.jpg";
import { Col, Container, Row } from "react-bootstrap";

// import { Container } from './styles';

function PhonePreview(props) {
  if (props.type === "details") {
    return (
      <Screen>
        <img src={HeaderMobile2} alt="" style={{ width: "100%" }} />
        <Carousel.Container>
          <Carousel.Image src={props.image} alt="" />
        </Carousel.Container>
        <Details.Container>
          <Details.Title>{props.title}</Details.Title>
          <Details.Description
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </Details.Container>
      </Screen>
    );
  }
  return (
    <Screen>
      <img src={HeaderMobile} alt="" style={{ width: "100%" }} />
      <Container fluid className="">
        <Row>
          <Col xs="12" className="my-2 mb-4">
            Top Vendas
          </Col>
          <Col xs="6">
            <Card.Container>
              <Card.Image src={props.image} alt="" />
              <Card.Title>{props.title}</Card.Title>
            </Card.Container>
          </Col>
          <Col xs="6">
            <Card.Container>
              <Card.Image src={props.image} alt="" />
              <Card.Title>{props.title}</Card.Title>
            </Card.Container>
          </Col>
        </Row>
      </Container>
    </Screen>
  );
}

export default PhonePreview;
