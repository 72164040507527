export const initCards = (t) => [
  {
    id: 0,
    color: "#064c08",
    hoverColor: "#fff",
    selected: false,
    name: t("cards.products"),
    counter: 0,
  },
  {
    id: 1,
    color: "#3d94c6",
    hoverColor: "#fff",
    name: t("cards.references"),
    counter: 0,
    selected: false,
  },
  {
    id: 2,
    color: "#f28b2b",
    hoverColor: "#fff",
    name: t("cards.sct"),
    selected: false,
    counter: 0,
  },
  {
    id: 3,
    color: "#a20013",
    hoverColor: "#fff",
    name: t("cards.protocols"),
    selected: false,
    counter: 0,
  },
];

export const newProtocol = (fkSupplier) => ({
  dateSubmission: "",
  dcomission: 0,
  dpreparationTime: 1,
  enabled: 3,
  fkSupplier,
  type_: 0,
  wrapprice: 0,
});
