import { CloseRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Filters from "./Filters";
import { Container, Table } from "./MainTable.styles";
import { useServices } from "../../../../services/useServices";
import ReactPaginate from "react-paginate";
import { isEmpty } from "lodash";
import moment from "moment";
import { formatLabelData, getKeys } from "./_utils";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { usePM } from "../../../../contexts/PMProvider/usePM";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

const itemsPerPage = 10;

function MainTable({ setSelected, type, update }) {
  //!-------------------------------------------------------------!\\
  //!----------------------------hooks----------------------------!\\
  //!-------------------------------------------------------------!\\
  //* services
  const { ordersEndpoints } = useServices();

  //* authentication data
  const { role } = useAuth();

  //* supplier data
  const { fkSupplier } = usePM();

  //* usetranslations
  const { t } = useTranslation(["pm/deliveries"]);

  //!-------------------------------------------------------------!\\
  //!----------------------------state----------------------------!\\
  //!-------------------------------------------------------------!\\
  //* current items
  //* é utilizado para armazenar o array de objetos ativos na tabela
  //* é calculado com base na lista proveniente do serviço:
  //* listaServiço.slice(paginaAtiva + (paginaAtiva + itemsPerPage))
  const [currentItems, setCurrentItems] = useState(null);

  //* page count
  //* é onde é armazenado o número de páginas existentes com base na lista
  //* proveniente do serviço
  const [pageCount, setPageCount] = useState(0);

  //* é onde é armazenado o index da página ativa
  const [itemOffset, setItemOffset] = useState(0);

  //* fitlers
  //* é onde são armazenados os filtros escritos nas inputs da tabela
  const [filters, setFilters] = useState({
    order_id: "",
    date: "",
    supplier_name: "",
    user_id: "",
    state: "",
    date2: "",
  });

  //!-------------------------------------------------------------!\\
  //!---------------------------Methods---------------------------!\\
  //!-------------------------------------------------------------!\\
  //* is cancelable
  const isCancelable = (state) => state === 10;
  //* is empty filters
  const isEmptyFitlers = () =>
    (!isEmpty(filters.date2) && type === 0) ||
    !isEmpty(filters.order_id) ||
    !isEmpty(filters.date) ||
    !isEmpty(filters.supplier_name) ||
    !isEmpty(String(filters.user_id)) ||
    !isEmpty(String(filters.state))
      ? false
      : true;

  //* handle change filter
  //* é utilizado quando uma input é alterada
  const handleChangeFilter = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    //* se o campo for data
    //* o valor é formatado
    if (name === "date" || name === "date2") {
      value = !value || moment(value).format("YYYYMMDD");
    }

    //* se for um número o campo é convertido para
    //* número
    if (name === "user_id") {
      value = Number(value) || "";
    }

    //* se for um número o campo é convertido para
    //* número
    if (name === "state") {
      value = Number(value) || "";
    }

    //* alterar os filtros
    return setFilters((e) => ({ ...e, [name]: value }));
  };

  //* handle page click
  //* é utilizado quando uma pagina é clicada
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % orders?.data?.data?.length;
    setItemOffset(newOffset);
  };

  //!-------------------------------------------------------------!\\
  //!---------------------------services--------------------------!\\
  //!-------------------------------------------------------------!\\
  //* get all orders
  const orders = useQuery(
    ["getAllOrders", role],
    () => ordersEndpoints.getAllOrders(),
    {
      enabled: role === 4,
    }
  );

  //* get all orders by supplier
  const orderBySupplier = useQuery(
    ["getAllOrdersBySupplier", fkSupplier],
    () => ordersEndpoints.getAllOrdersBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1 && type !== 1,
      onSuccess: () => update && setSelected((e) => ({ ...e, update: false })),
    }
  );

  //* cancel orders
  const cancelOrder = useMutation((e) => ordersEndpoints.cancelOrder(e), {
    onSettled: () => orders.refetch(),
  });

  //!-------------------------------------------------------------!\\
  //!---------------------------useEffects-------------------------!\\
  //!-------------------------------------------------------------!\\
  //* é executado quando:
  //* os dados do serviço são alterados,
  //* quando uma página é clicado
  //* quando os filtros são alterados
  useEffect(() => {
    //* dados do serviço
    let data = type === 0 ? orderBySupplier.data?.data : orders?.data?.data;

    //* se não houver filtros
    //* os dados apresentados são os provenientes do serviços
    //* senão, os dados são apresentados com base nos filtros introduzidos
    if (!isEmptyFitlers()) {
      data = data.filter((e) => {
        return (
          String(e.order_id).search(filters.order_id) > -1 &&
          String(e.date_order).search(filters.date) > -1 &&
          String(e.fk_user).includes(filters.user_id.toString()) === true &&
          String(e.state_shipping).includes(filters.state.toString()) ===
            true &&
          String(e.name_supplier)
            .toLowerCase()
            .search(filters.supplier_name.toLowerCase()) > -1 &&
          String(e.date_forecast).search(filters.date2) > -1
        );
      });
    }

    //* calcular os dados da paginação
    //* obter o index até onde vai o slice
    const endOffset = itemOffset + itemsPerPage;

    //* atualizar os dados apresentados
    setCurrentItems(data?.slice(itemOffset, endOffset));

    //* atualizar o número de páginas
    setPageCount(Math.ceil((data?.length || 0) / (itemsPerPage || 0)));
  }, [itemOffset, orders?.data?.data, filters, orderBySupplier.data?.data]);

  useEffect(() => {
    if (update) {
      orderBySupplier.refetch();
    }
  }, [update]);

  return (
    <Container>
      <Table.Container responsive>
        <Table.THead>
          <Table.Tr>
            {getKeys(type, t)?.map((e, index) => (
              <Table.Th
                key={index}
                rowSpan={
                  e.id === "actions" || e.id === "state_error" ? 2 : undefined
                }
              >
                {e.label}
              </Table.Th>
            ))}
            <Table.Th className="text-center" rowSpan={2}>
              {t("table.actions")}
            </Table.Th>
          </Table.Tr>
          <Filters
            handleChange={handleChangeFilter}
            filters={filters}
            type={type}
          />
        </Table.THead>
        <Table.TBody>
          {currentItems?.map((e, index) => (
            <Table.Tr key={index}>
              {getKeys(type, t).map((i, index) => (
                <Table.Td key={index}>
                  {formatLabelData(e[i.id], i.id, t)}
                </Table.Td>
              ))}
              <Table.Td className="text-center ">
                <div className="d-flex">
                  <Tooltip title={t("buttons.view")} placement="bottom">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() =>
                        setSelected({
                          order_id: e?.order_id,
                          state_shipping: e?.state_shipping,
                        })
                      }
                    >
                      <VisibilityRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {isCancelable(e?.state_shipping) && type === 1 && (
                    <Tooltip
                      title={t("buttons.cancel")}
                      placement="bottom"
                      onClick={() => cancelOrder.mutate(e?.order_id)}
                    >
                      <IconButton size="small" color="error">
                        <CloseRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Table.Td>
            </Table.Tr>
          ))}
          {isEmpty(currentItems) && (
            <Table.Tr>
              <Table.Td
                colSpan={getKeys(type, t).length + 1 || 0}
                className="text-center"
              >
                No Data Found!!!
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr className="border-white">
            <Table.Td colSpan={getKeys(type, t).length + 1 || 0}>
              <ReactPaginate
                breakLabel="..."
                nextLabel={t("pagination.next")}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={t("pagination.previous")}
                renderOnZeroPageCount={null}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination m-0 justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            </Table.Td>
          </Table.Tr>
        </Table.TBody>
      </Table.Container>
    </Container>
  );
}

export default MainTable;
