import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import useProductsContext from "../../../contexts/ProductsManagement/Products/useProductsContext";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

// import { Container } from './styles';

function FormTitle() {
  const { forms, products, overlayConfirm } = useProductsContext();
  const handleEditable = () => forms.setEditable(true);
  const { t } = useTranslation(["pm/products", "pm/overlayConfirm"]);
  const { role } = useAuth();

  const getBadge = (state) => {
    switch (state) {
      case 1:
        return (
          <Badge pill bg="warning" text="dark">
            {t("badges.pending")}
          </Badge>
        );
      case 2:
        return (
          <Badge pill bg="danger">
            {t("badges.refused")}
          </Badge>
        );
      case 4:
        return (
          <Badge pill bg="secondary">
            {t("badges.nVisible")}
          </Badge>
        );
      case 5:
        return (
          <Badge pill bg="success">
            {t("badges.approved")}
          </Badge>
        );
      default:
        return (
          <Badge pill bg="secondary">
            {t("badges.created")}
          </Badge>
        );
    }
  };

  return (
    <Container fluid className="app-sub-title w-100">
      <Row>
        <Col xs="12">
          <div className="border-bottom pb-2 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {products?.selected?.stitle || t("newProduct")}
              <div className="h6 m-0">
                {getBadge(products?.selected?.state)}
              </div>
            </div>
            <div>
              {!forms.editable && (
                <Row>
                  <Col xs="12" className="text-end">
                    {products?.selected?.state === 0 && (
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-2"
                        onClick={() =>
                          forms.createProduct.mutate({
                            ...products?.selected,
                            state: 1,
                          })
                        }
                      >
                        {t("buttons.submitToApprove")}
                      </Button>
                    )}
                    {role === 4 &&
                      (products?.selected?.state === 1 ||
                        products?.selected?.state === 4) && (
                        <>
                          <Button
                            variant="danger"
                            size="sm"
                            className="me-2"
                            onClick={() =>
                              overlayConfirm.setShowConfirm((e) => ({
                                ...e,
                                show: true,
                                v2: true,
                                primaryButtonLabel: t(
                                  "alerts.refuseProduct.primaryButtonLabel"
                                ),
                                newSelect: () => {
                                  forms.createProduct.mutate({
                                    ...products?.selected,
                                    state: 2,
                                  });
                                  overlayConfirm.setShowConfirm((e) => ({
                                    ...e,
                                    show: false,
                                  }));
                                },
                                title: t("alerts.refuseProduct.title"),
                                content: t("alerts.refuseProduct.content"),
                              }))
                            }
                          >
                            {t("buttons.reject")}
                          </Button>
                          <Button
                            variant="success"
                            size="sm"
                            className="me-2"
                            onClick={() =>
                              overlayConfirm.setShowConfirm((e) => ({
                                ...e,
                                show: true,
                                v2: true,
                                primaryButtonLabel: t(
                                  "alerts.approveProduct.primaryButtonLabel"
                                ),
                                newSelect: () => {
                                  forms.createProduct.mutate({
                                    ...products?.selected,
                                    state: 5,
                                  });
                                  overlayConfirm.setShowConfirm((e) => ({
                                    ...e,
                                    show: false,
                                  }));
                                },
                                title: t("alerts.approveProduct.title"),
                                content: t("alerts.approveProduct.content"),
                              }))
                            }
                          >
                            {t("buttons.approve")}
                          </Button>
                        </>
                      )}
                    {role === 4 && products?.selected?.state === 5 && (
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2"
                        onClick={() =>
                          overlayConfirm.setShowConfirm((e) => ({
                            ...e,
                            show: true,
                            v2: true,
                            primaryButtonLabel: t(
                              "alerts.disableProduct.primaryButtonLabel"
                            ),
                            newSelect: () => {
                              forms.createProduct.mutate({
                                ...products?.selected,
                                state: 4,
                              });
                              overlayConfirm.setShowConfirm((e) => ({
                                ...e,
                                show: false,
                              }));
                            },
                            title: t("alerts.disableProduct.title"),
                            content: t("alerts.disableProduct.content"),
                          }))
                        }
                      >
                        {t("buttons.disable")}
                      </Button>
                    )}
                    <Button
                      size="sm"
                      className="rounded-circle"
                      variant="warning"
                      onClick={handleEditable}
                    >
                      <FaEdit />
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FormTitle;
