import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FaSort } from "react-icons/fa";
import Pin2GiveList from "../../../../components/Pin2GiveList/Pin2GiveList.Container";
import { ItemContainer } from "../_styles/ImagesReferences.styles";
import { useImagesReferences } from "../useImagesReferences";

const SortableList = () => {
  //!--------------------------------------------!\\
  //!---------------useTranslation---------------!\\
  //!--------------------------------------------!\\
  const { t } = useTranslation(["pm/imagesReferences"]);

  //!--------------------------------------------!\\
  //!--------------------data--------------------!\\
  //!--------------------------------------------!\\
  const { orderList, images } = useImagesReferences();

  return (
    <Pin2GiveList
      headerProps={{
        headerButton: {},
        title: t("sortImagesList.title"),
      }}
    >
      <DragDropContext onDragEnd={orderList?.handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {orderList?.data?.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={item.referenceImagePK?.reference + index}
                  index={index}
                >
                  {(provided) => (
                    <ItemContainer
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <span>{index + 1}</span>
                      <FaSort className="ms-auto" />
                      <img
                        src={images.getImage(item?.referenceImagePK?.image)}
                        width="50"
                        height="50"
                        className="ms-auto rounded"
                        alt=""
                      />
                    </ItemContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Pin2GiveList>
  );
};

export default SortableList;
