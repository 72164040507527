import React, { useContext } from "react";
import { suppliersContext } from "./Provider";

// import { Container } from './styles';

function useSuppliers() {
  const context = useContext(suppliersContext);
  return context;
}

export default useSuppliers;
