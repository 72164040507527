import styled, { css } from "styled-components";
import { Button, Form } from "react-bootstrap";
export const Container = styled.div``;

export const P2GLHeader = {
  Container: styled.div`
    padding: 1rem;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  `,
  Content: styled.div`
    background-color: #f9f9f9;
    padding: 0.5rem 1rem;
    width: 100%;
    font-weight: bold;
    color: #333;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ButtonContainer: styled.div`
    text-align: right;
  `,
  Title: styled.div,
};

export const P2GLContainer = {
  Container: styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    min-height: 20rem;
    max-height: 35rem;

    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #575757;
      border: 0px none #ffffff;
      border-radius: 50px;
      &:hover {
        background: #333;
      }
      &:active {
        background: #1c1c1c;
      }
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
      border: 0px none #ffffff;
      border-radius: 0px;
      margin: 1rem;
      &:hover {
        background: #ffffff;
      }
      &:active {
        background: #fafafa;
      }
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    ${({ sticky }) =>
      sticky &&
      css`
        position: sticky;
        top: 0;
      `}
  `,
  Content: styled.div`
    padding: 0 1rem;
  `,
};

export const P2GLItem = {
  Container: styled.div`
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
    padding: 0.3rem;
    cursor: pointer;
    border-radius: 4px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    transition: all 100ms linear;
    ${({ selected }) =>
      selected &&
      css`
        background-color: #c9636f !important;
        color: white !important;
        font-weight: normal !important;
      `}
    ${({ hoverable }) =>
      hoverable &&
      css`
        &:hover {
          background-color: #f9f9f9;
          color: #333;
          font-weight: 600;
          transition: all 100ms linear;
        }
      `}
  `,
  Content: styled.div`
    width: 100%;
  `,
  Item: styled.div`
    display: flex;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    align-items: center;
  `,
};

export const P2GLItemImage = {
  Image: styled.img`
    border-radius: 8px;
    transition: 200ms ease-in;
    width: 75px;
    height: 75px;
    ${({ left }) =>
      !left &&
      css`
        margin-left: auto;
      `}

    ${({ hoverableImage }) =>
      hoverableImage &&
      css`
        &:hover {
          transform: scale(2);
          position: relative;
          z-index: 1000;
          transition: 200ms ease-in;
        }
      `}
  `,
  RemoveButton: styled(Button)`
    margin-left: auto;
    border-radius: 100px;
  `,
};

export const P2GLItemText = {
  Label: styled.span`
    margin-left: 1rem;
    width: 100%;
  `,
  Input: styled.div`
    margin-right: 1.5rem;
  `,
  InfoTooltip: styled.span`
    margin-left: auto;
    color: #a20013;
    ${({ selected }) =>
      selected &&
      css`
        color: white;
      `};
  `,
};

export const Search = {
  Container: styled.div`
    padding: 1rem;
    padding-top: 0;
  `,
  Form: styled(Form)``,
};
