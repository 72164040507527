import { createContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider/useAuth";
import "./ProductsManagement.css";

export const pmContext = createContext({});

const PMProvider = ({ children }) => {
  const [fkSupplier, setFkSuppier] = useState(-1);
  const [protocol, setProtocol] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { role } = useAuth();

  // use location
  const location = useLocation();

  const setPMfkSupplier = (idSupplier) => setFkSuppier(idSupplier);
  const setPMprotocol = (supplierProtocol) => setProtocol(supplierProtocol);

  useEffect(() => {
    setIsLoading(true);
    const searchUrl = new URLSearchParams(location.search);

    const dFkSupplier = searchUrl.get("fks");

    if (dFkSupplier !== null) {
      setPMfkSupplier(Number(dFkSupplier));
    }

    setIsLoading(false);
  }, [location.pathname, location.search]);

  //protocol === null ||
  return (
    <pmContext.Provider
      value={{ setPMfkSupplier, fkSupplier, protocol, setPMprotocol }}
    >
      {!isLoading &&
      location.pathname !== "/productsmanagement" &&
      !location.pathname.includes("/product/preview") &&
      !location.pathname.includes("/deliveries") &&
      fkSupplier === -1 ? (
        <Navigate to="/productsmanagement" />
      ) : (
        children
      )}
    </pmContext.Provider>
  );
};

export default PMProvider;
