//!-----------------------------------------------!\\
//!---------------------LAYOUTS-------------------!\\
//!-----------------------------------------------!\\
import { Outlet, Route, Routes as RouterRoutes } from "react-router-dom";
import MainLayout from "../MainLayout";
import React from "react";

//!-----------------------------------------------!\\
//!------------------PRIVATE PAGES----------------!\\
//!-----------------------------------------------!\\
import PrivateRoute from "./PrivateRoute";

//*Products Management Pages*\\
import References from "../../pages/ProductsManagement/References/References";
import Compositions from "../../pages/ProductsManagement/Compositions/Compositions";
import UploadImages from "../../pages/ProductsManagement/UploadImages/UploadImages";
import ImagesReferences from "../../pages/ProductsManagement/ImagesReferences/Index";
import CompositionsReferences from "../../pages/ProductsManagement/CompositionsReferences/CompositionsReferences";
import CreateSeason from "../../pages/ProductsManagement/CreateSeason/CreateSeason";
import Producer from "../../pages/ProductsManagement/Producer/Producer";

//*Login Pages*\\
import Login from "../../pages/Login/Login";
import Register from "../../pages/Login/Register";

//!-----------------------------------------------!\\
//!-------------------PUBLC PAGES-----------------!\\
//!-----------------------------------------------!\\
import Home from "../../pages/Home/Home";
import Partners from "../../pages/Partners/Partners";
import Policy from "../../pages/Policy/Policy";
import SubCategories from "../../pages/Store/SubCategories/SubCategories";
import Store from "../../pages/Store/Store";
import ProductDetails from "../../pages/Store/ProductDetails/ProductDetails";
import Support from "../../pages/Support/Support";
import NotFound404 from "../../pages/NotFound404/NotFound404";
import Products from "../../pages/Store/Products/Products";
import PMProvider from "../../contexts/PMProvider/pmContext";
import LoginLayout from "../LoginLayout";
import SeasonCrossTable from "../../pages/ProductsManagement/Seasons/Index";
import InvoiceManagement from "../../pages/ProductsManagement/InvoiceManagement/InvoiceManagement";
import Preview from "../../pages/ProductsManagement/Preview/Preview";
import Profile from "../../pages/Profile/Profile";
import PCProvider from "../../contexts/ProductsManagement/ProductsCategories/PCProvider";
import ProductsProvider from "../../contexts/ProductsManagement/Products/Provider";
import SuppliersProvider from "../../contexts/ProductsManagement/Suppliers/Provider";
import RecoverPassword from "../../pages/Login/recoverPassword/RecoverPassword";
import Deliveries from "../../pages/ProductsManagement/Deliveries/Deliveries";

const Routes = () => {
  return (
    <RouterRoutes>
      {
        //!----------------------------------------!\\
        //!-------------PRIVATE PAGES--------------!\\
        //!----------------------------------------!\\
      }
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/user/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        {
          //*-------------------*\\
          //*Products Management*\\
          //*-------------------*\\
        }
        <Route
          path="/productsmanagement"
          element={
            <PrivateRoute>
              <PMProvider>
                <Outlet />
              </PMProvider>
            </PrivateRoute>
          }
        >
          <Route
            path="compositionsreferences/:fkProduct"
            element={<CompositionsReferences />}
          />
          {/* <Route path="dashboard" element={<Dashboard />} />*/}
          <Route
            path="compositions/:fkProduct/:idComposition"
            element={<Compositions />}
          />
          <Route path="createseasons" element={<CreateSeason />} />
          <Route path="productcategories/:fkProduct" element={<PCProvider />} />
          <Route path="imagesreferences" element={<ImagesReferences />} />
          <Route index element={<SuppliersProvider />} />
          <Route path="producer" element={<Producer />} />
          <Route path="products" element={<ProductsProvider />} />
          <Route path="references/:fkReference" element={<References />} />
          <Route path="seasons" element={<SeasonCrossTable />} />
          <Route path="imagesupload" element={<UploadImages />} />
          {/* <Route path="invoicemanagement" element={<InvoiceManagement />} />*/}
          {process.env.NODE_ENV === "development" && (
            <Route path="invoicemanagement" element={<InvoiceManagement />} />
          )}
          <Route path="product/preview/:fkProduct" element={<Preview />} />
          <Route path="deliveries" element={<Deliveries />} />
        </Route>

        {
          //!----------------------------------------!\\
          //!-------------PUBLIC PAGES--------------!\\
          //!----------------------------------------!\\
        }

        <Route index element={<Home />} />
        <Route path="partners" element={<Partners />} />
        <Route path="policy" element={<Policy />} />
        <Route path="support" element={<Support />} />
        <Route path="*" element={<NotFound404 />} />
        {
          //*-----------*\\
          //*---Store---*\\
          //*-----------*\\
        }
        <Route
          path="store"
          element={
            <>
              <Outlet />
            </>
          }
        >
          <Route index element={<Store />} />
          <Route
            path=":name/:idCategorie/:idSubCategorie/:idSubSubCategorie"
            element={<SubCategories />}
          />

          <Route
            path="products/details/:fk_product"
            element={<ProductDetails />}
          />
          <Route path="products/:provider" element={<Products />} />
        </Route>
      </Route>
      {
        //!--------------------------------------------!\\
        //!-------------Only PUBLIC PAGES--------------!\\
        //!--------------------------------------------!\\
      }
      <Route
        path="/user"
        element={
          <LoginLayout>
            <Outlet />
          </LoginLayout>
        }
      >
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/register" element={<Register />} />
        <Route path="/user/recoverpassword" element={<RecoverPassword />} />
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
