import { useAlert } from "react-alert";
import { Alert, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useServices } from "../../services/useServices";

const VerifyEmail = ({ email, clickPrimary, show, clickSecondary }) => {
  const { t } = useTranslation(["register"]);
  const alert = useAlert();
  const { authenticationEndpoints } = useServices();

  const resendEmail = (email) =>
    authenticationEndpoints
      .resendEmail(email)
      .then((response) => {
        if (response.data === 1) {
          alert.success(
            <>
              <Alert.Heading>{t("verifyEmail.success.title")}</Alert.Heading>
              <p>{t("verifyEmail.success.message")}</p>
            </>
          );
        } else {
          alert.error(
            <>
              <Alert.Heading>{t("verifyEmail.errors.2.title")}</Alert.Heading>
              <p>{t("verifyEmail.errors.2.message")}</p>
            </>
          );
        }
      })
      .catch((error) => {
        console.error("ERROR", error);
        alert.error(
          <>
            <Alert.Heading>{t("verifyEmail.errors.1.title")}</Alert.Heading>
            <p>{t("verifyEmail.errors.1.message")}</p>
          </>
        );
      });
  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{t("verifyEmail.modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("verifyEmail.modal.body")} <strong>{email}</strong>!
        </p>
        <div className="d-flex align-items-center">
          {t("verifyEmail.modal.resend.message") + " "}
          <Button variant="link" size="sm" onClick={() => resendEmail(email)}>
            {t("verifyEmail.modal.resend.button")}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={clickSecondary}>
          {t("verifyEmail.modal.footer.back")}
        </Button>
        <Button variant="pin2give" onClick={clickPrimary}>
          {t("verifyEmail.modal.footer.login")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyEmail;
