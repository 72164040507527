import React from "react";
import { useTranslation } from "react-i18next";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";
import { compareData, newProducer } from "../_actions";
import { isEqual } from "lodash";

function ProducerList({
  setSelected,
  setEditable,
  data,
  selected,
  producerLang,
  formikProducer,
  formikLanguages,
  setShowConfirm,
}) {
  //!--------------------------------------------------------!\\
  //!-------------------------hooks--------------------------!\\
  //!--------------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/producers", "pm/overlayConfirm"]);

  //!--------------------------------------------------------!\\
  //!-------------------------props--------------------------!\\
  //!--------------------------------------------------------!\\
  //* header props
  const headerProps = {
    title: t("list.title"),
    headerButton: {
      onClick: () => {
        setSelected(newProducer);
        setEditable(true);
      },
      title: t("list.buttonTitle"),
    },
  };

  //!--------------------------------------------------------!\\
  //!-------------------------events-------------------------!\\
  //!--------------------------------------------------------!\\
  //* onSelect
  //* se o produto foi editado, mostra o madal para confirmar se pretende mudar de produto
  //* senão muda de produto
  const handleSelect = (e) => {
    if (
      compareData(
        { ...selected, ...producerLang },
        {
          ...formikProducer.values,
          ...formikLanguages.values,
        }
      )
    ) {
      setSelected(e);
      setEditable(false);
    } else {
      setShowConfirm({ show: true, newSelect: e });
    }
  };

  return (
    <div className="sticky-top">
      <Pin2GiveList headerProps={headerProps}>
        {data.map((e, index) => (
          <div key={index}>
            {e?.fk_supplier !== 0 && (
              <Pin2GiveListItem
                key={index}
                type="text"
                title={e?.designation}
                onClick={() => handleSelect(e)}
                selected={e.idProducer === selected?.idProducer}
              />
            )}
          </div>
        ))}
        {isEqual(selected, newProducer) && (
          <Pin2GiveListItem
            selected
            type="text"
            title={t("list.newProducer")}
          />
        )}
      </Pin2GiveList>
    </div>
  );
}

export default ProducerList;
