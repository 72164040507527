import axios from "axios";

export const OrdersEndpoints = (initPath) => {
  const PATH = `${initPath}/ordersendpoint`;

  const getAllOrders = async () => await axios.get(`${PATH}/getAllOrders`);

  const getAllOrdersBySupplier = async (fkSupplier) =>
    await axios.get(`${PATH}/getAllOrdersBySupplier/${fkSupplier}`);

  const detailsorder = async (fkOrder) =>
    await axios.get(`${PATH}/detailsorder/${fkOrder}`);

  const updateTransportGuide = async (fkOrder, data) =>
    await axios.put(`${PATH}/updateTransportGuide`, {
      order_id: fkOrder,
      base64: data,
    });

  const detailsordersupplier = async (fkOrder, fkSupplier) =>
    await axios.get(`${PATH}/detailsordersupplier/${fkOrder}/${fkSupplier}`);

  const cancelOrder = async (fkOrder) =>
    await axios.put(`${PATH}/cancelOrder/${fkOrder}`);

  const changestateordersupp = async (
    fkOrder,
    fkSupplier,
    state,
    numberE = null
  ) =>
    await axios.get(
      `${PATH}/changestateordersupp/${fkOrder}/${fkSupplier}/${state}/${
        numberE === null ? "" : "?nr_recolha=" + numberE
      }`
    );

  return {
    getAllOrders,
    detailsorder,
    updateTransportGuide,
    cancelOrder,
    getAllOrdersBySupplier,
    detailsordersupplier,
    changestateordersupp,
  };
};
