/* eslint-disable react-hooks/exhaustive-deps */
//components & styles
import { Container, Row, Col, Form, Spinner, Accordion } from "react-bootstrap";
import BackButton from "../../../components/BackButton/BackButton";
import { ProductCarousel } from "../../../components/Carousel/Carousel";
import { FiHeart, FiShare2 } from "react-icons/fi";
import "./ProductDetails.css";
import {
  ButtonCart,
  ButtonColorPicker,
  ButtonWhite,
  ButtonImagePicker,
} from "../../../components/ButtonProductDetails/ButtonProductDetails";
import ProviderTooltip from "../../../components/ProviderTooltip/ProviderTooltip";

//hooks, libraries & endpoints
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import lodash from "lodash";
import { useServices } from "../../../services/useServices";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { fk_product } = useParams();
  const { t, i18n } = useTranslation();
  const [responseCode, setResponseCode] = useState();
  const { productsWebEndpoints, getLanguage } = useServices();

  const [data, setData] = useState({
    active: {
      type: 0,
      size: { size: "", active: false },
      pattern: { index: 0, active: false },
      color: { index: 0, active: false },
    },
    productDetails: null,
    defaultValues: {
      size: false,
      color: false,
      pattern: false,
    },
    price: 0,
    loading: true,
  });

  //active pattern, sizes or colors
  const type = () => {
    let activeOld = data?.active;
    let defaultValuesOld = data?.defaultValues;
    if ((data?.active?.type & 1) > 0) {
      activeOld = { ...activeOld, color: { ...activeOld.color, active: true } };
      defaultValuesOld = {
        ...defaultValuesOld,
        color: data?.productDetails?.list_references[0]?.color,
      };
    }
    if ((data?.active?.type & 2) > 0) {
      activeOld = {
        ...activeOld,
        pattern: { ...activeOld.pattern, active: true },
      };
      defaultValuesOld = {
        ...defaultValuesOld,
        pattern: data?.productDetails?.list_references[0]?.pattern,
      };
    }
    if ((data?.active?.type & 4) > 0) {
      activeOld = { ...activeOld, size: { ...activeOld.size, active: true } };
      defaultValuesOld = {
        ...defaultValuesOld,
        size: data?.productDetails?.list_references[0]?.fk_scale_value,
      };
    }
    setData({
      ...data,
      active: activeOld,
      defaultValues: defaultValuesOld,
    });
  };

  //get product details
  useEffect(() => {
    if (fk_product || fk_product !== 0) {
      productsWebEndpoints
        .wdetailsproduct(
          fk_product,
          getLanguage(),
          localStorage.getItem("country_code")
        )
        .then((response) => {
          setResponseCode(response?.status);
          setData({
            ...data,
            productDetails: response?.data,
            active: { ...data?.active, type: response?.data?.type },
            price: response?.data?.list_references[0]?.pvp,
            loading: false,
          });
          //console.log("response", response?.data);
          setResponseCode(response?.status);
        })
        .catch((error) => {
          //console.log("ERRO on obtain product details: ", error);
          setData({ ...data, productDetails: null, loading: false });
        });
    }
  }, [i18n.language, fk_product]);

  //start type
  useEffect(() => {
    if (!lodash.isEmpty(data?.productDetails)) {
      type();
    }
  }, [data?.active?.type]);

  //get default values for active size, colors or patterns
  useEffect(() => {
    if (!lodash.isEmpty(data?.productDetails)) {
      let references = data?.productDetails?.list_references;
      if ((data?.active?.type & 1) > 0) {
        references = references?.filter(
          (e) => e?.color === data?.defaultValues?.color
        );
      }
      if ((data?.active?.type & 2) > 0) {
        references = references?.filter(
          (e) => e?.pattern === data?.defaultValues?.pattern
        );
      }
      if ((data?.active?.type & 4) > 0) {
        references = references?.filter(
          (e) => e?.fk_scale_value === data?.defaultValues?.size
        );
      }

      setData({ ...data, price: references ? Number(references[0]?.pvp) : 0 });
    }
  }, [data?.defaultValues]);

  return (
    <div className="app-store-product-details">
      <Container>
        <Row className="justify-content-between mt-5 ">
          <Col xs="4" className="text-left d-flex align-items-center">
            <BackButton />
          </Col>
        </Row>
        {!data?.loading ? (
          !lodash.isEmpty(data?.productDetails) ? (
            <Row className="my-3 justify-content-center">
              <Col xs="10" sm="8" md="6" lg="5" xl="4" className="mb-5">
                <ProductCarousel
                  images={
                    data?.productDetails
                      ? data?.productDetails?.product_photos
                      : []
                  }
                />
              </Col>
              <Col xs="12" md="10" lg="7" xl="8">
                <Container fluid className="d-flex flex-column">
                  <Row className="justify-content-between  flex-column-reverse flex-lg-row">
                    <Col
                      xs="12"
                      lg="9"
                      className="mb-3 overflow-hidden flex-column d-flex"
                    >
                      <span className="app-title ">
                        {data?.productDetails?.title_product}
                      </span>
                      <span className="product-details-categories d-flex align-items-center">
                        <ProviderTooltip
                          viewMore
                          title={data?.productDetails?.producer_name}
                          description={
                            data?.productDetails?.description_supplier
                          }
                          onClickViewMore={() =>
                            navigate(
                              "/store/products/" +
                                data?.productDetails?.fk_supplier
                            )
                          }
                        />
                      </span>
                    </Col>
                    {/* <Col
                      xs="6"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="3"
                      className="d-flex align-items-center justify-content-around mb-3"
                    >
                      <ButtonWhite icon={<FiShare2 />} />
                      <ButtonWhite icon={<FiHeart />} />
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p
                        className="productdetails-description"
                        dangerouslySetInnerHTML={{
                          __html: data?.productDetails?.description_product,
                        }}
                      />
                    </Col>
                  </Row>
                  {!lodash.isEmpty(data?.productDetails?.specification) ? (
                    <Row className="mb-5 mt-3">
                      <Col xs="12">
                        <Accordion className="shadow-none">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="pin2give-accordion-button shadow-none">
                              {t("MainTitles.ViewMore")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                className="productdetails-description"
                                dangerouslySetInnerHTML={{
                                  __html: data?.productDetails?.specification,
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {
                    //active size
                    data?.active?.size?.active ? (
                      <Row>
                        <Col xs="12" sm="5" xl="3">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {t("Store.selectionOptions")}
                            </Form.Label>
                            <Form.Select
                              className="shadow-none"
                              value={
                                data?.defaultValues?.size !== false
                                  ? data?.defaultValues?.size
                                  : false
                              }
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  defaultValues: {
                                    ...data.defaultValues,
                                    size: event.target.value,
                                  },
                                })
                              }
                            >
                              {data?.productDetails?.products_sizes.map(
                                (e, index) => (
                                  <option value={e?.fk_scale_value} key={index}>
                                    {e?.fk_scale_value}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )
                  }
                  {
                    //active colors
                    data?.active?.color?.active ? (
                      <Row>
                        <Col xs="12" className="mb-3">
                          <span className="form-label">
                            {t("Store.colorSelection")}
                          </span>
                          <div className="product-details-horizontal-selector">
                            {data?.productDetails?.product_colors.map(
                              (e, index) => (
                                <ButtonColorPicker
                                  key={index}
                                  active={
                                    data?.active?.color?.index === index
                                      ? true.toString()
                                      : undefined
                                  }
                                  colorref={e?.color}
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      defaultValues: {
                                        ...data?.defaultValues,
                                        color: e?.color,
                                      },
                                      active: {
                                        ...data?.active,
                                        color: {
                                          ...data?.active?.color,
                                          index: index,
                                        },
                                      },
                                    });
                                  }}
                                />
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )
                  }
                  {
                    //active patterns
                    data?.active?.pattern?.active ? (
                      <Row>
                        <Col xs="12" className="mb-3">
                          <span className="form-label">
                            {t("Store.imageSelection")}
                          </span>
                          <div className="product-details-horizontals-selector">
                            {data?.productDetails?.products_patterns.map(
                              (e, index) => (
                                <ButtonImagePicker
                                  key={index}
                                  active={
                                    data?.active?.pattern?.index === index
                                      ? true.toString()
                                      : undefined
                                  }
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      defaultValues: {
                                        ...data?.defaultValues,
                                        pattern: Number(e?.pattern),
                                      },
                                      active: {
                                        ...data?.active,
                                        pattern: {
                                          ...data?.active?.pattern,
                                          index: index,
                                        },
                                      },
                                    });
                                  }}
                                  imagesrc={e?.url_pattern}
                                />
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )
                  }

                  <Row className="mb-5 mt-5">
                    <Col
                      xs="12"
                      sm="7"
                      xl="8"
                      className="d-flex align-items-center mb-3"
                    >
                      <span className="productdetails-price text-center w-100 text-sm-start">
                        {lodash.isNumber(data?.price)
                          ? data?.price.toFixed(2)
                          : "NaN"}{" "}
                        {data?.productDetails?.currency_product}
                      </span>
                    </Col>
                    <Col xs="12" sm="5" xl="4">
                      {/* <ButtonCart /> */}
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12" className="text-center">
                {lodash.isEqual(responseCode, 204)
                  ? t("Store.EmptyLanguage.data")
                  : t("Store.Empty.ProductDetails")}
              </Col>
            </Row>
          )
        ) : (
          <Row>
            <Col xs="12" className="text-center">
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
              <Spinner animation="grow" variant="pin2give" />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ProductDetails;
