import lodash from "lodash";

export const createObject = (fkSupplier) => {
  return {
    idSeason: -1,
    fkSupplier,
    dsubmission: "",
    dapproval: "",
    dpublication: "",
    depublication: "",
    conditions: 0,
    //min:1, max:500
    observations: "",
    title: "",
    state: 0,
  };
};

export const formatValues = (values) => {
  return {
    idSeason: Number(values?.idSeason),
    fkSupplier: Number(values?.fkSupplier),
    dsubmission: "0",
    dapproval: "0",
    dpublication: values?.dpublication,
    depublication: values?.depublication,
    conditions: Number(values?.conditions),
    observations: lodash.isEmpty(values?.observations)
      ? "-"
      : values?.observations,
    state: Number(values?.state),
    title: values?.title,
  };
};
