import axios from "axios";

export function PropertyEndpoints(initPath) {
  const PATH = `${initPath}/propertyendpoint`;

  const getAllSeasonActiveByProduct = async (fkSupplier, fkProduct) =>
    await axios.get(
      `${PATH}/getAllSeasonActiveByProduct/${fkSupplier}/${fkProduct}`
    );

  const getPropertys = async (fkSupplier, fkLanguage) =>
    await axios.get(`${PATH}/getPropertys/${fkSupplier}/${fkLanguage}`);

  const getPropertyValues = async (fkProperty, fkSupplier, fkLanguage) =>
    await axios.get(
      `${PATH}/getPropertyValues/${fkProperty}/${fkSupplier}/${fkLanguage}`
    );

  const getAllPropertysByProduct = async (fkSupplier, fkLanguage, fkProduct) =>
    await axios.get(
      `${PATH}/getAllPropertysByProduct/${fkSupplier}/${fkLanguage}/${fkProduct}`
    );

  const addProductProperty = async (data) =>
    await axios.put(`${PATH}/addProductProperty`, data);

  const removeProductProperty = async (data) =>
    await axios.put(`${PATH}/removeProductProperty`, data);

  const addPropertyOperatorPin2Give = async (data) =>
    await axios.post(`${PATH}/addPropertyOperatorPin2Give`, data);

  const getPropertyOperatorPin2GiveByProduct = async (fkProduct) =>
    await axios.get(
      `${PATH}/getPropertyOperatorPin2GiveByProduct/${fkProduct}`
    );

  return {
    getAllSeasonActiveByProduct,
    getPropertys,
    getPropertyValues,
    addProductProperty,
    removeProductProperty,
    getAllPropertysByProduct,
    addPropertyOperatorPin2Give,
    getPropertyOperatorPin2GiveByProduct,
  };
}
