import React from "react";
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../components/Pin2GiveList/Pin2GiveList";
import { useProductCategories } from "../../../contexts/ProductsManagement/ProductsCategories/useProductCategories";
import lodash from "lodash";
import { newSubCategoryObject } from "../../../contexts/ProductsManagement/ProductsCategories/utils";
import { useTranslation } from "react-i18next";

function SubCategories() {
  //!-------------------------------------------------------------!\\
  //!---------------------------Contexts--------------------------!\\
  //!-------------------------------------------------------------!\\
  const {
    subCategories: dSubCategories,
    subSubCategories,
    categories,
    result,
    fkProduct,
  } = useProductCategories();
  const { t } = useTranslation("pm/productsCategories");

  //!-------------------------------------------------------------!\\
  //!---------------------------Methods---------------------------!\\
  //!-------------------------------------------------------------!\\
  /**
   * * Handle Select
   * @param {number} fkSubCategory
   */
  const handleSelect = (fkSubCategory) => {
    //* quando selecionar uma subCategoria
    //* os select da  subSubCategory
    //* será removido
    subSubCategories.setSelected(-1);
    dSubCategories.setSelected(fkSubCategory);
  };

  /**
   * * Get Check
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @returns {boolean} false se não existir a categoria em result
   * @returns {boolean} false se o campo do objeto "fkSubcategory" não corresponder com o parâmetro fkSubCategory
   * @returns {boolean} true se o campo do objeto "fkSubcategory" corresponder com o parâmetro fkSubCategory
   */
  const getCheck = (fkCategory, fkSubCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.find(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não existir retorna false
    if (categoryIsInRes === undefined) return false;

    //* se existir, verifica se a subcategoria associada corresponde
    //* à subcategoria pretendida
    //* se sim retorna true
    //* senão false
    if (categoryIsInRes?.productVsCategoriesPK?.fkSubcategory === fkSubCategory)
      return true;

    return false;
  };

  /**
   * * Check Disabled
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @returns {boolean} false se for diferente de 0 em result
   * @returns {boolean} false se for igual a 0 em result
   * @returns {boolean} true se não encontrar nenhum registo em result
   */
  const checkDisabled = (fkCategory, fkSubCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.find(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não encontrar nenhum registo (undefined) retorna false
    if (categoryIsInRes === undefined) return false;

    //* se existir e o campo "productVsCategoriesPK.fkSubsubcategory" não for 0
    //* ou o campo "fkSubcategory" for diferente ao campo passado como parâmetro "fkSubCategory"
    //* returna false (é possível utilizar)
    const pk = categoryIsInRes?.productVsCategoriesPK;
    if (pk.fkSubsubcategory === 0) return false;

    //* caso contrário retorna true
    return true;
  };

  /**
   * * Add Check
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @returns {boolean} true se a categoria for adicionada | atualizada
   */
  const addCheck = (fkCategory, fkSubCategory) => {
    const country_code = localStorage.getItem("country_code");

    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.findIndex(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se existir a categoria pretendida (categoryIsInRes !== -1) é necessário
    //* substituir a categoria com o fkSubCategory pretendido
    if (categoryIsInRes !== -1) {
      const newRes = res.filter(
        ({ productVsCategoriesPK: pk }) => pk.fkCategory !== fkCategory
      );
      result.setResult([
        ...newRes,
        newSubCategoryObject(
          fkCategory,
          fkSubCategory,
          country_code,
          fkProduct
        ),
      ]);
      return true;
    }

    //* se não houver a categoria selecionada,
    //* é necessário adicionar a categoria
    const subCategoryObject = newSubCategoryObject(
      fkCategory,
      fkSubCategory,
      country_code,
      fkProduct
    );

    result.setResult((e) => [...e, subCategoryObject]);
    return true;
  };

  /**
   * * Remove Check
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @returns {boolean} false se não for possível remover a categoria
   * @returns {boolean} true se for possível remover a categoria
   */
  const removeCheck = (fkCategory, fkSubCategory) => {
    //* obter a lista de categorias existentes
    //* para o produto pretendido
    const res = result?.data;

    //* verificar se existe a categoria em res
    const categoryIsInRes = res.find(
      (e) => e.productVsCategoriesPK?.fkCategory === fkCategory
    );

    //* se não houver categoria retorna false
    //* não é possível remover uma categoria que não existe
    if (categoryIsInRes === undefined) return false;

    //* se houver categoria
    //* a mesma será removida
    const newRes = res.filter(
      ({ productVsCategoriesPK: pk }) => pk.fkCategory !== fkCategory
    );

    result.setResult(newRes);
    return true;
  };

  /**
   * * Handle Check
   * @param {number} fkCategory
   * @param {number} fkSubCategory
   * @returns {boolean} Boolean
   */
  const handleCheck = (fkCategory, fkSubCategory) => {
    //* saber se a subcategoria tem check
    const isCheck = getCheck(fkCategory, fkSubCategory);

    //* se houver check a subcategoria irá ser removida
    if (isCheck) return removeCheck(fkCategory, fkSubCategory);

    //* se não houver check a subcategoria será adicionada
    return addCheck(fkCategory, fkSubCategory);
  };

  return (
    <Pin2GiveList
      check
      headerProps={{
        title: t("lists.subCategories.label"),
        headerButton: {},
      }}
    >
      {dSubCategories?.data?.map((e) => (
        <Pin2GiveListItem
          key={e?.id}
          title={e?.name}
          check
          onCheck={() => handleCheck(categories?.selected, e?.id)}
          onClick={() => handleSelect(e?.id)}
          checkDisabled={checkDisabled(categories?.selected, e?.id)}
          selected={e?.id === dSubCategories.selected}
          checked={getCheck(categories?.selected, e?.id)}
        />
      ))}
      {lodash.isEmpty(dSubCategories?.data) && (
        <div className="text-center">{t("lists.subCategories.na")}</div>
      )}
    </Pin2GiveList>
  );
}

export default SubCategories;
