import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";

// import { Container } from './styles';

function EmptyLabel() {
  //!--------------------------------------------------------!\\
  //!-------------------------hooks--------------------------!\\
  //!--------------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/producers", "pm/overlayConfirm"]);

  return (
    <div className="h-100 d-flex flex-column align-items-center empty-active-reference">
      <FaArrowLeft size={30} />
      <div className="empty-active-reference">{t("list.newProducer")}</div>
    </div>
  );
}

export default EmptyLabel;
