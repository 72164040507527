import React from "react";
import { Accordion, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { usePM } from "../../../../../contexts/PMProvider/usePM";
import useProductsContext from "../../../../../contexts/ProductsManagement/Products/useProductsContext";
import { useServices } from "../../../../../services/useServices";
import Form from "./Form";

function Caracterization() {
  //!--------------------------------------------------------------------!\\
  //!--------------------------------hooks-------------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* use translation
  const { t } = useTranslation(["pm/products"]);

  //* services
  const { seasonCrossTableEndpoints, propertyEndpoints, getLanguage } =
    useServices();

  //* product data
  const { products } = useProductsContext();

  //* supplier data
  const { fkSupplier } = usePM();

  //!--------------------------------------------------------------------!\\
  //!------------------------------countries-----------------------------!\\
  //!--------------------------------------------------------------------!\\
  //* endpoint
  const countries = useQuery("getCountries", () =>
    seasonCrossTableEndpoints.getAllCountries(getLanguage())
  );

  //!--------------------------------------------------------------------!\\
  //!-------------------------------seasons------------------------------!\\
  //!--------------------------------------------------------------------!\\
  const seasons = useQuery("getSeasonsP", () =>
    propertyEndpoints.getAllSeasonActiveByProduct(
      fkSupplier,
      products?.selected.idProduct || -1
    )
  );

  //!--------------------------------------------------------------------!\\
  //!-----------------------------properties-----------------------------!\\
  //!--------------------------------------------------------------------!\\
  const properties = useQuery("getPropertiesP", () =>
    propertyEndpoints.getPropertys(fkSupplier, getLanguage())
  );

  return (
    <Accordion.Item className="pin2give-accordion-button" eventKey="1">
      <Accordion.Header>
        {t("caracterization.caracterization")}
      </Accordion.Header>
      <Accordion.Body className=" text-start ">
        <Table responsive className="mb-0">
          <thead>
            <tr>
              <th>{t("caracterization.form.season")}</th>
              <th>{t("caracterization.form.country")}</th>
              <th>{t("caracterization.form.property")}</th>
              <th>{t("caracterization.form.propertyValues")}</th>
              <th>{t("caracterization.form.relevance")}</th>
              <th>{t("caracterization.form.actions")}</th>
            </tr>
          </thead>
          <tbody className="align-middle">
            <Form
              seasons={[...(seasons.data?.data || [])].map((e) => ({
                value: e.idSeason,
                label: e.title,
              }))}
              countries={[...(countries.data?.data || [])].map((e) => ({
                value: e.idCountry,
                label: e[String(getLanguage()).toLowerCase()],
              }))}
              properties={[...(properties.data?.data || [])].map((e) => ({
                value: e.idProperty,
                label: e[String(getLanguage()).toLowerCase()],
              }))}
              fkProduct={products.selected?.idProduct || -1}
            />
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Caracterization;
