export const handleHoverable = (onClick, hoverableOnCheck, ref) =>
  onClick !== undefined
    ? hoverableOnCheck
      ? isChecked(ref)
        ? true
        : false
      : true
    : false;

export const isChecked = (ref) => {
  if (ref?.current?.checked) {
    return true;
  }
  return false;
};
