import styled from "styled-components";

/*export const CardMenu = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: 200ms ease-out;
  color: #a20013;
  &:hover {
    box-shadow: rgba(161, 9, 9, 0.24) 0px 3px 8px;
    transition: 200ms ease-in;
  }
`;*/

export const CardMenu = {
  Container: styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: 200ms ease-out;
    height: 100%;
    color: #a20013;
    &:hover {
      box-shadow: rgba(161, 9, 9, 0.24) 0px 3px 8px;
      transition: 200ms ease-in;
      border-radius: 20px;
      color: #fff !important;
      background-color: #a20013;
    }
    & i {
      color: black !important;
    }
  `,
  Icon: styled.div`
    font-size: 2.5rem;
    margin-bottom: 1rem;
  `,
};
