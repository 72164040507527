import Header from "./Header/Header";
import ScrollToTop from "./ScrollToTop";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import lodash from "lodash";
import { useState, useEffect } from "react";
import BannerPolicy from "../components/BannerPolicy/BannerPolicy";
import AppBar from "../components/Appbar/Appbar";

const MainLayout = () => {
  const [showPrivacyStatus, setShowPrivacyStatus] = useState(false);

  const checkPrivacyStatus = () => {
    const status = localStorage.getItem("privacy_status");

    if (lodash.isEmpty(status)) {
      setShowPrivacyStatus(false);
    } else {
      setShowPrivacyStatus(true);
    }
  };

  useEffect(() => {
    checkPrivacyStatus();
  }, []);
  return (
    <>
      <AppBar />
      {/*<Header />*/}
      <div className="app-divider">
        <Sidebar />
        <main>
          <div className="content">
            <ScrollToTop>
              <Outlet />
            </ScrollToTop>
          </div>
          <Footer />
        </main>
        <BannerPolicy show={showPrivacyStatus} />
      </div>
    </>
  );
};

export default MainLayout;
