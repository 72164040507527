import React, { useState } from "react";
import { useQuery } from "react-query";
import { usePM } from "../../../contexts/PMProvider/usePM";
import { useServices } from "../../../services/useServices";
import lodash from "lodash";
import { isInList } from "./_actions/_actions";
import { Accordion, Button, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function References({ fkComposition, fkProduct, editable }) {
  const { fkSupplier } = usePM();
  const { referencesEndpoints, productCompositionsEndpoints } = useServices();
  const navigate = useNavigate();
  const { t } = useTranslation(["pm/compositions"]);

  const [compRefs, setCompRefs] = useState([]);

  //* get references
  const { data: references } = useQuery(
    ["getReferences", fkSupplier],
    () => referencesEndpoints.getAllReferencesBySupplier(fkSupplier),
    {
      enabled: fkSupplier !== -1,
    }
  );

  //* obter as referências quando uma composição
  //* for alterada
  useQuery(
    ["getCompositionReferences", fkComposition],
    () =>
      productCompositionsEndpoints.getAllReferencesByComposition(fkComposition),
    {
      enabled:
        fkComposition !== null &&
        fkComposition !== -1 &&
        Boolean(references?.data),
      onSuccess: (response) => {
        let x = [];

        references?.data?.forEach((e, index) => {
          if (
            isInList(response.data, {
              fkProduct: Number(fkProduct),
              fkComposition: Number(fkComposition),
              reference: e?.reference,
            })
          ) {
            x.push(e);
          }
        });
        setCompRefs(x);
      },
    }
  );

  return (
    <Accordion>
      <Accordion.Item className="pin2give-accordion-button" eventKey="0">
        <Accordion.Header>{t("accordion.title")}</Accordion.Header>
        <Accordion.Body className="pb-0 text-end">
          {lodash.isEmpty(compRefs) ? (
            <div className="text-start pb-3">{t("accordion.empty")}</div>
          ) : (
            <Table responsive className="mb-0">
              <thead className="text-center">
                <tr>
                  <th>{t("accordion.field1")}</th>
                  <th>{t("accordion.field2")}</th>
                  <th>{t("accordion.field3")}</th>
                  <th>{t("accordion.field4")}</th>
                  <th>{t("accordion.field5")}</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {compRefs.slice(0, 5).map((e, index) => (
                  <tr key={index}>
                    <td>{e?.reference}</td>
                    <td>{e?.sreference}</td>
                    <td>
                      {lodash.isEmpty(e?.barcode) ? "<empty>" : e?.barcode}
                    </td>
                    <td>{e?.stock}</td>
                    <td>
                      <Button
                        disabled={editable}
                        variant="link"
                        size="sm"
                        onClick={() =>
                          navigate(
                            `/productsmanagement/references/${e?.reference}`
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    </td>
                  </tr>
                ))}
                {compRefs.length > 5 ? (
                  <tr className="border-white">
                    <td colSpan="5">
                      <Button
                        variant="pin2give-link"
                        onClick={() =>
                          navigate(`/productsmanagement/references/-`)
                        }
                      >
                        {t("buttons.viewMore")}
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default References;
