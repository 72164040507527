import { useTranslation } from "react-i18next";
import { Pin2GiveListItem } from "../../../../components/Pin2GiveList/Pin2GiveList";
import Pin2GiveList from "../../../../components/Pin2GiveList/Pin2GiveList.Container";
import { useImagesReferences } from "../useImagesReferences";
import lodash from "lodash";

const DefaultImage = () => {
  const { t } = useTranslation(["pm/imagesReferences"]);
  const { referenceImages, dImage, images } = useImagesReferences();
  return (
    <Pin2GiveList
      headerProps={{ headerButton: {}, title: t("defaultImageList.title") }}
    >
      {[...referenceImages?.data].map((e, index) => (
        <Pin2GiveListItem
          key={index}
          type="img"
          selected={lodash.isEqual(
            dImage?.data.referenceImagePK,
            e?.referenceImagePK
          )}
          onClick={() => {
            dImage.setDimage(e);
          }}
          src={images.getImage(e?.referenceImagePK?.image)}
        />
      ))}
    </Pin2GiveList>
  );
};

export default DefaultImage;
