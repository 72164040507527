//? LODASH
import lodash from "lodash";

//? REACT
import { useState } from "react";

//? REACT-ICONS
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";

//? STYLES
import "./UploadPattern.css";

//? i18Next
import { useTranslation } from "react-i18next";

//?----------------RENDER----------------?\\
const UploadPattern = ({ value, formik, editable }) => {
  const { t } = useTranslation(["pm/references"]);
  const [remove, setRemove] = useState(false);
  //* onSelectFile
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        formik.setFieldValue("patternBase64", reader.result.toString() || "");
        formik.setFieldValue("pattern", -1);
        setRemove(false);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //* RENDER *\\
  return editable ? (
    <>
      <div className="uploadPattern-container">
        {
          //! Empty pattern !\\
          lodash.isEmpty(formik.values?.patternBase64) ? (
            <></>
          ) : !remove ? (
            <>
              {
                //! pattern img !\\
              }
              <div className="uploadPattern-item">
                <img src={formik.values.patternBase64} alt="pattern" />
                <div
                  className="remove-pattern-badge"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.setFieldValue("pattern", 0);
                    formik.setFieldValue("patternBase64", "");
                    setRemove(true);
                  }}
                >
                  <FaTrashAlt />
                </div>
              </div>
            </>
          ) : (
            <></>
          )
        }
        {
          //! Add & Edit pattern !\\
        }
        <label htmlFor="uploadPattern" className="uploadPattern-button">
          <input
            className="uploadPattern-input"
            type="file"
            aria-label=""
            id="uploadPattern"
            accept="image/*"
            onChange={onSelectFile}
          />
          {lodash.isEmpty(value) || remove ? <FaPlus /> : <FaEdit />}
        </label>
      </div>
    </>
  ) : (
    <>
      {lodash.isEmpty(value) ? (
        t("fields.pattern.errors.notFound")
      ) : (
        <>
          {
            //! pattern img !\\
          }
          <div className="uploadPattern-item mt-3">
            <img src={value} alt="pattern" />
          </div>
        </>
      )}
    </>
  );
};

export default UploadPattern;
