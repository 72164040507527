import {
  LogoutRounded,
  LoginRounded,
  PersonSharp,
  PersonRounded,
} from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { role, auth, authentication } = useAuth();
  const { t } = useTranslation(["userMenu"]);

  //* methods
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const getLabel = () => {
    if (auth?.token) {
      switch (role) {
        case 2:
          return t("roles.supplier");
        case 4:
          return t("roles.operator");
        default:
          return t("roles.user");
      }
    }
    return "Login";
  };

  return (
    <>
      <Tooltip title={getLabel()}>
        <IconButton
          size="small"
          onClick={handleOpenUserMenu}
          sx={{}}
          aria-haspopup="true"
        >
          <Avatar
            sx={{
              width: "1.7rem",
              height: "1.7rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PersonSharp fontSize="1rem" />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {auth?.token ? (
          <div>
            {
              <MenuItem
                onClick={() => {
                  navigate("/user/profile");
                  handleCloseUserMenu();
                }}
              >
                <ListItemIcon>
                  <PersonRounded fontSize="small" />
                </ListItemIcon>
                {t("profile")}
              </MenuItem>
            }
            <MenuItem
              onClick={() => {
                authentication.logout();
                handleCloseUserMenu();
              }}
            >
              <ListItemIcon>
                <LogoutRounded fontSize="small" />
              </ListItemIcon>
              {t("logout")}
            </MenuItem>
          </div>
        ) : (
          <MenuItem onClick={() => navigate("/user/login")}>
            <ListItemIcon>
              <LoginRounded fontSize="small" />
            </ListItemIcon>
            {t("login")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default UserMenu;
