import { createContext } from "react";
import { useQuery } from "react-query";
import DEV_CONFIG from "./config.dev.json";
import { ipApiEndpoints as ipApiE } from "./endpoints/ipApi";
import { SuppliersEndpoints } from "./endpoints/suppliers";
import lodash from "lodash";
import { InvoicesEndpoints } from "./endpoints/invoices";
import {
  ProductLanguagesEndpoints,
  ProductsEndpoints,
} from "./endpoints/products";
import { getLanguage, isPrivateRoute } from "./_utils";
import {
  ProducerLanguagesEndpoints,
  ProducersEndpoints,
} from "./endpoints/producers";
import { ProductCompositionsEndpoint } from "./endpoints/compositions";
import { ReferencesEndpoints } from "./endpoints/references";
import { SeasonsEndpoints } from "./endpoints/seasons";
import { SeasonCrossTableEndpoints } from "./endpoints/seasonCrossTable";
import { AuthenticationEndpoints } from "./endpoints/authentication";
import PROD_CONFIG from "./config.prod.json";
import { ProductsWebEndpoint } from "./endpoints/productsWeb";
import { Pin2giveWebEndpoints } from "./endpoints/pin2giveWeb";
import { DashboardEndpoints } from "./endpoints/dashboard";
import { PropertyEndpoints } from "./endpoints/propertyEndpoint";
import { OrdersEndpoints } from "./endpoints/ordersendpoint";

export const servicesContext = createContext();

const Provider = ({ children }) => {
  let PATH = "";
  const DEV_PATH = `${DEV_CONFIG.protocol}://${DEV_CONFIG.ip}:${DEV_CONFIG.port}/${DEV_CONFIG.initPath}`;
  const PROD_PATH = `${PROD_CONFIG.protocol}://${PROD_CONFIG.ip}/${PROD_CONFIG.initPath}`;

  useQuery("getCountryData", () => ipApiEndpoints.getCountryData(), {
    onSuccess: (response) => {
      const country = response?.data?.country_code;
      if (lodash.isEmpty(country)) {
        localStorage.setItem("country_code", "EN");
      } else {
        localStorage.setItem("country_code", country);
      }
    },
  });

  if (process.env.NODE_ENV === "development") {
    PATH = DEV_PATH;
  } else if (process.env.NODE_ENV === "production") {
    PATH = PROD_PATH;
  }

  //* products web (public)
  const productsWebEndpoints = ProductsWebEndpoint(PATH);

  //* pin2give web (public)
  const pin2giveWebEndpoints = Pin2giveWebEndpoints(PATH);

  //* suppliers
  const supplierEndpoints = SuppliersEndpoints(PATH);

  //* invoices
  const invoicesEndpoints = InvoicesEndpoints(PATH);

  //* products
  const productsEndpoints = ProductsEndpoints(PATH);
  const productLanguagesEndpoints = ProductLanguagesEndpoints(PATH);

  //* producers
  const producersEndpoints = ProducersEndpoints(PATH);
  const producerLanguagesEndpoints = ProducerLanguagesEndpoints(PATH);

  //* compositions
  const productCompositionsEndpoints = ProductCompositionsEndpoint(PATH);

  //* references
  const referencesEndpoints = ReferencesEndpoints(PATH);

  //* seasons
  const seasonsEndpoints = SeasonsEndpoints(PATH);

  //* seasonCrossTable
  const seasonCrossTableEndpoints = SeasonCrossTableEndpoints(PATH);

  //* country code
  const ipApiEndpoints = ipApiE();

  //* authentication
  const authenticationEndpoints = AuthenticationEndpoints(PATH);

  //* dashboard
  const dashboardEndpoints = DashboardEndpoints(PATH);

  //* properties
  const propertyEndpoints = PropertyEndpoints(PATH);

  //* orders
  const ordersEndpoints = OrdersEndpoints(PATH);

  return (
    <servicesContext.Provider
      value={{
        authenticationEndpoints,
        getLanguage: getLanguage,
        isPrivateRoute: isPrivateRoute,
        invoicesEndpoints:
          process.env.NODE_ENV === "development"
            ? invoicesEndpoints
            : undefined,
        productsEndpoints,
        productsWebEndpoints,
        pin2giveWebEndpoints,
        productLanguagesEndpoints,
        producersEndpoints,
        producerLanguagesEndpoints,
        productCompositionsEndpoints,
        referencesEndpoints,
        supplierEndpoints,
        seasonCrossTableEndpoints,
        seasonsEndpoints,
        dashboardEndpoints,
        propertyEndpoints,
        ordersEndpoints,
      }}
    >
      {children}
    </servicesContext.Provider>
  );
};

export default Provider;
