import styled from "styled-components";
import { Table as DTable } from "react-bootstrap";
export const Table = {
  Container: styled.div`
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 1rem;
  `,
  Table: styled(DTable)`
    margin: 0 !important;
  `,
  Header: styled.thead`
    color: ${({ color }) => color} !important;
    padding: 1rem !important;
    & th {
      padding-top: 0.8rem !important;
      padding-bottom: 0.8rem !important;
      cursor: pointer;
    }
    & tr:first-child th:first-child {
      color: ${({ color }) => color};
      padding-left: 0.5rem;
    }

    & tr {
      border-color: #fff !important;
    }

    /* & th:last-child {
      padding-right: 1.5rem;
      background-color: ${({ color }) => color};
      display: inline-block;
      width: 100%;
      border-top-right-radius: 8px;
    }

    & th {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: ${({ color }) => color};
    }*/
  `,
  Body: styled.tbody`
    & td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
    }
    & tr {
      vertical-align: middle !important;
    }

    & tr:last-child {
      border-bottom-color: white !important;
    }
  `,
};
