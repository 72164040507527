import { Carousel } from "react-bootstrap";
import styled from "styled-components";

export const CarouselHome = {
  Container: styled(Carousel)`
    height: 40rem !important;
    z-index: 1000 !important;
    & .carousel-control-prev {
      z-index: 1000 !important;
    }
    & .carousel-control-next {
      z-index: 1000 !important;
    }
  `,
  Item: {
    Image: styled.img`
      height: 40rem !important;
      object-fit: cover !important;
      width: 100% !important;
      display: block !important;
    `,
    Caption: styled(Carousel.Caption)`
      background: rgb(219, 15, 56);
      background: linear-gradient(
        135deg,
        rgba(219, 15, 56, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 100%
      );
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-direction: column !important;
    `,
  },
};

export const CarouselProducts = {
  Container: styled(Carousel)`
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius: 8px;
    & .carousel-indicators button {
      background-color: #a20013;
    }
    & .carousel-control-prev {
      z-index: 1000 !important;
    }
    & .carousel-control-next {
      z-index: 1000 !important;
    }
  `,
  Icon: styled.span`
    font-size: 2rem;
    color: #a20013;
  `,
  Item: {
    BackGround: styled.div`
      height: 30rem;
    `,
    Caption: styled(Carousel.Caption)`
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & img {
        max-width: 100%;
      }
    `,
  },
};
