import { Row, Container, Col } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const Loading = () => {
  return (
    <Row>
      <Col xs="12" lg="3" className="mb-5">
        <Skeleton variant="rectangular" height={500} />
      </Col>
      <Col xs="12" lg="9">
        <Container>
          <Row>
            <Col xs="12" lg="3">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="9">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="6">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="6">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="4">
              <Skeleton variant="text" height={50} />
            </Col>
            <Col xs="12" lg="12">
              <Skeleton variant="text" height={200} />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default Loading;
