import styled from "styled-components";

export const Container = styled.div``;

export const Link = styled.a`
  text-decoration: none;
  color: #0051a2;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  transition: 200ms ease;

  &:hover {
    color: #333;
    transition: 200ms ease;
  }

  &:after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: #333;
    border-radius: 100px;
    transition: 200ms ease;
  }

  &:hover:after {
    width: 100%;
    transition: 200ms ease;
  }
`;
