import {
  CachedRounded,
  CancelOutlined,
  CheckRounded,
  HomeRounded,
  LocalShipping,
  PublishedWithChangesRounded,
  ReportRounded,
} from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import moment from "moment";

export const getBadge = (state, t) => {
  let badgeProps = {};
  switch (state) {
    case 10:
      badgeProps = {
        label: t("badges.pending"),
        color: undefined,
        icon: <CachedRounded fontSize="small" />,
      };
      break;

    case 20:
      badgeProps = {
        label: t("badges.confirmed"),
        color: "warning",
        icon: <PublishedWithChangesRounded fontSize="small" />,
      };
      break;

    case 30:
      badgeProps = {
        label: t("badges.ready"),
        color: "success",
        icon: <CheckRounded fontSize="small" />,
      };
      break;
    case 40:
      badgeProps = {
        label: t("badges.sent"),
        color: "primary",
        icon: <LocalShipping fontSize="small" />,
      };
      break;
    case 50:
      badgeProps = {
        label: t("badges.received"),
        color: "success",
        icon: <HomeRounded fontSize="small" />,
      };
      break;
    case 60:
      badgeProps = {
        label: t("badges.canceled"),
        color: "error",
        icon: <CancelOutlined fontSize="small" />,
      };
      break;

    default:
      badgeProps = {};
  }

  return <Chip size="small" variant="outlined" {...badgeProps} />;
};

export const getAlert = (state, t) => {
  let label = "";

  switch (state) {
    case 1:
      label = t("table.alert.error1");
      break;
    case 2:
      label = t("table.alert.error2");
      break;
    case 3:
      label = t("table.alert.error3");
      break;
    default:
      break;
  }

  return label;
};

export const getKeys = (type = 0, t) => {
  if (type === 0) {
    return [
      { id: "order_id", label: t("table.order") },
      { id: "date_order", label: t("table.date_order") },
      { id: "date_forecast", label: t("table.date_forecast") },
      { id: "name_supplier", label: t("table.supplier") },
      { id: "fk_user", label: t("table.user") },
      { id: "state_shipping", label: t("table.state") },
    ];
  }

  return [
    { id: "order_id", label: t("table.order") },
    { id: "date_order", label: t("table.date_order") },
    { id: "name_supplier", label: t("table.supplier") },
    { id: "fk_user", label: t("table.user") },
    { id: "state_shipping", label: t("table.state") },
    { id: "state_error", label: t("table.info") },
  ];
};

export const formatLabelData = (value, key, t) => {
  if (key === "date_forecast" || key === "date_order") {
    return moment(value).format("YYYY-MM-DD");
  }

  if (key === "state_error") {
    return (
      <Tooltip title={getAlert(value, t)}>
        <ReportRounded fontSize="small" color="error" />
      </Tooltip>
    );
  }

  if (key === "state_shipping") {
    return getBadge(value, t);
  }

  return value;
};
