import * as Yup from "yup";

//* utilizado para o select da relevância 0-20
export const rData = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
];

//* construir o objeto utilizado na remoção
export const formatDeleteObject = (values) => ({
  productPropertyPK: {
    fkProduct: values.fk_product,
    fk_season: values.fk_season,
    fkCountry: values.fk_country,
    fkProperty: values.fk_property,
    fkPropertyValue: values.fk_property_value,
  },
  relevance: values.relevance,
  iconProperty: 0,
});

//* construir formulário inicial
export const initialValues = (fkProduct) => ({
  productPropertyPK: {
    fkProduct: fkProduct,
    fk_season: -1,
    fkCountry: -1,
    fkProperty: -1,
    fkPropertyValue: -1,
  },
  relevance: -1,
  iconProperty: 0,
});

//* construir validação do objeto com yup
export const validationSchema = Yup.object({
  productPropertyPK: Yup.object({
    fk_season: Yup.number()
      .required("Season is required!")
      .min(0, "Season is required!"),
    fkProperty: Yup.number()
      .required("Property is required!")
      .min(0, "Property is required!"),
    fkCountry: Yup.number()
      .required("Country is required!")
      .min(0, "Country is required!"),
    fkPropertyValue: Yup.number()
      .required("Property value is required!")
      .min(0, "Property value is required!"),
  }),
  relevance: Yup.number()
    .required("Relevance value is required!")
    .min(0, "Relevance value is required!"),
});
