import React from "react";
import { Table } from "./MainTable.styles";

// import { Container } from './styles';

function Filters({ handleChange, filters, type = 1 }) {
  return (
    <Table.Tr>
      <Table.Th>
        <Table.Input
          onChange={handleChange}
          name="order_id"
          placeholder="search..."
        />
      </Table.Th>
      <Table.Th>
        <Table.Input
          onChange={handleChange}
          name="date"
          type="date"
          placeholder="search..."
        />
      </Table.Th>
      {type === 0 && (
        <Table.Th>
          <Table.Input
            onChange={handleChange}
            name="date2"
            type="date"
            placeholder="search..."
          />
        </Table.Th>
      )}
      <Table.Th>
        <Table.Input
          onChange={handleChange}
          name="supplier_name"
          placeholder="search..."
        />
      </Table.Th>

      <Table.Th>
        <Table.Input
          onChange={handleChange}
          name="user_id"
          placeholder="search..."
          value={filters.user_id}
        />
      </Table.Th>
      <Table.Th>
        <Table.Select
          onChange={handleChange}
          name="state"
          placeholder="search..."
        >
          <option value="">Choose...</option>
          <option value="10">Pendente</option>
          <option value="20">Confirmada</option>
          <option value="30">Pronta</option>
          <option value="40">Enviada</option>
          <option value="50">Recebida</option>
          <option value="60">Cancelada</option>
        </Table.Select>
      </Table.Th>
    </Table.Tr>
  );
}

export default Filters;
