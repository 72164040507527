import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { P } from "../Element.styles";

// import { Container } from './styles';

function Composition() {
  const navigate = useNavigate();
  const { t } = useTranslation(["informationPages/composition"]);
  return (
    <>
      <P dangerouslySetInnerHTML={{ __html: t("body") }}></P>
      <div className="align-middle">
        <span>{t("buttons.sectionLabel")}</span>{" "}
        <Button
          variant="link"
          size="sm"
          onClick={() => navigate("/productsmanagement/products")}
        >
          {t("buttons.products")}
        </Button>
        ,{" "}
        <Button
          variant="link"
          size="sm"
          onClick={() => navigate("/productsmanagement/references/-")}
        >
          {t("buttons.references")}
        </Button>
      </div>
    </>
  );
}

export default Composition;
