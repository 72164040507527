/* eslint-disable react-hooks/exhaustive-deps */
//-----------------------------------\\
//---------------REACT---------------\\
//-----------------------------------\\
import React, { memo, useCallback } from "react";

//----------------------------------------------\\
//---------------REACT-ROUTER-DOM---------------\\
//----------------------------------------------\\
import { useNavigate } from "react-router-dom";

//------------------------------------------\\
//---------------PIN2GIVELIST---------------\\
//------------------------------------------\\
import {
  Pin2GiveList,
  Pin2GiveListItem,
} from "../../../../components/Pin2GiveList/Pin2GiveList";

//-------------------------------------\\
//---------------I18NEXT---------------\\
//-------------------------------------\\
import { useTranslation } from "react-i18next";

//----------------------------------------------\\
//---------------SEASONCROSSTABLE---------------\\
//----------------------------------------------\\
import { useSeasonCrossTable } from "../useSeasonCrossTable";

//? RENDER
const Seasons = () => {
  //!------------------------------------------------!\\
  //!-----------------USETRANSLATION-----------------!\\
  //!------------------------------------------------!\\
  const { t } = useTranslation(["pm/sct"]);

  //!------------------------------------------------!\\
  //!----------------------DATA----------------------!\\
  //!------------------------------------------------!\\
  //* season crosstable
  const { seasons, references, compositions, products, countries } =
    useSeasonCrossTable();

  //* products
  const { setSelected: setPSelected } = products;

  //* compositions
  const { setSelected: setCSelected } = compositions;

  //* references
  const { setSelected: setRSelected, setARefs } = references;

  //* seasons
  const { data, selected, setSelected } = seasons;

  //!------------------------------------------!\\
  //!-----------------NAVIGATE-----------------!\\
  //!------------------------------------------!\\
  const navigate = useNavigate();

  //!----------------------------------------------!\\
  //!-----------------handle click-----------------!\\
  //!----------------------------------------------!\\
  const handleClick = useCallback(
    (fkSeason) => {
      //* quando uma composição é selecionada, tudo o que está para a frente é descelecionado
      setSelected(fkSeason);
      setPSelected(-1);
      setCSelected(-1);
      setRSelected("");
      countries.setSelected(-1);

      return setARefs([]);
    },
    [seasons?.selected]
  );

  return (
    <Pin2GiveList
      headerProps={{
        title: t("seasons.title"),
        headerButton: {
          onClick: () => navigate("/productsmanagement/createseasons"),
          title: t("seasons.buttonTooltip"),
        },
      }}
    >
      {data?.map((e, index) => (
        <Pin2GiveListItem
          key={index}
          selected={e?.idSeason === selected}
          onClick={() => handleClick(e?.idSeason)}
          title={e?.title}
        />
      ))}
    </Pin2GiveList>
  );
};

export default memo(Seasons);
