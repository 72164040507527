//* validate number phone
import { isValidPhoneNumber } from "react-phone-number-input";
export function isNumberPhone(message) {
  return this.test("isValidCountry", message, function (value) {
    const { path, createError } = this;

    if (!value) {
    } else if (!isValidPhoneNumber(value)) {
      return createError({
        path,
        message: message ?? "Number Phone is not valid!",
      });
    }

    return true;
  });
}
