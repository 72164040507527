import { isEmpty } from "lodash";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// import { Container } from './styles';

function BottomButtons({
  editable,
  formikProducer,
  formikLanguages,
  mutation,
  isPlLoading,
}) {
  //!--------------------------------------------------------!\\
  //!-------------------------hooks--------------------------!\\
  //!--------------------------------------------------------!\\
  //* translation
  const { t } = useTranslation(["pm/producers", "pm/overlayConfirm"]);

  //* navigate
  const navigate = useNavigate();

  //!--------------------------------------------------------!\\
  //!-------------------------events-------------------------!\\
  //!--------------------------------------------------------!\\
  //* quando o save é clicado é necessário fazer a validação dos dois formulários
  //* (formulário do produtor + formulário de idiomáticas)
  const onSubmit = () => {
    formikProducer.validateForm().then((responseProduct) =>
      formikLanguages.validateForm().then((responseLang) => {
        if (isEmpty(responseProduct) && isEmpty(responseLang)) {
          formikProducer.submitForm();
        }
      })
    );
  };

  if (!editable)
    return (
      <Button
        variant="pin2give"
        onClick={() => navigate("/productsmanagement/products")}
      >
        {t("buttons.products")}
      </Button>
    );

  return (
    <Button variant="success" onClick={onSubmit}>
      {isPlLoading || mutation.isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <div className="d-flex align-items-center">
          <FaSave className="me-2" /> {t("buttons.save")}
        </div>
      )}
    </Button>
  );
}

export default BottomButtons;
